import { ButtonRounded, Stack, TipsTurbo } from 'components/atoms';

import { StackActions } from './styled';
import { useTheme } from 'hooks/useTheme';

export interface IFooterModalEditProps {
  enableButtonSave: () => boolean;
  onSave: () => void;
  onClose: (atualizar: boolean) => void;
  boot?: boolean;
  theme?: 'toUpdate' | 'confirmToUpdate';
  tip?: string;
}

export const FooterModalEdit: React.FC<IFooterModalEditProps> = ({
  enableButtonSave,
  onSave,
  onClose,
  theme,
  tip,
}) => {
  const themes = (internalTheme?: string) => {
    const { currentTheme } = useTheme();

    switch (internalTheme) {
      case 'toUpdate':
        return {
          background: currentTheme.colors.primary,
          color: currentTheme.colors.white,
          icon: '/assets/icons/icon-enter-white.svg',
          label: ' Atualizar',
        };
      case 'confirmToUpdate':
        return {
          background: currentTheme.colors.primary,
          color: currentTheme.colors.white,
          icon: '/assets/icons/icon-enter-white.svg',
          label: 'Confirmar alteração',
        };

      default:
        return {
          background: currentTheme.colors.primary,
          color: currentTheme.colors.white,
          icon: '/assets/icons/icon-enter-white.svg',
          label: ' Atualizar',
        };
    }
  };

  const { currentTheme } = useTheme();

  return (
    <StackActions data-testid="footer-modal-edit" direction={'row-reverse'}>
      <Stack height="28.69px" minWidth="260px" justifyContent="flex-end">
        <ButtonRounded
          outline={true}
          color={currentTheme.colors.primary}
          label="Cancelar"
          iconSrc="/assets/icons/icon-close-red.svg"
          marginRight="0.5rem"
          click={() => {
            onClose(false);
          }}
        />
        <ButtonRounded
          background={themes(theme).background}
          color={themes(theme).color}
          label={themes(theme).label}
          iconSrc={!enableButtonSave() ? '/assets/icons/icon-enter-white.svg' : themes(theme).icon}
          disabled={!enableButtonSave()}
          click={() => {
            onSave();
          }}
          padding="8px"
        />
      </Stack>
      {tip && (
        <Stack flex="1">
          <TipsTurbo tip={tip} />
        </Stack>
      )}
    </StackActions>
  );
};
