import { Label, Stack } from 'components/atoms';
import Information from 'components/atoms/Information';
import { DragAndDrop, ModalEditPopup } from 'components/molecules';
import { compareValues } from 'helpers/functions';
import { useEffect, useRef, useState } from 'react';

import { StackActionPriority } from './styled';
import { useTheme } from 'hooks/useTheme';

interface IEditPriorityPopupProps {
  data: any;
  onSave: (val: any) => void;
  onClose: () => void;
  id_popup: number;
  name: string;
}

export const EditPriorityPopup: React.FC<IEditPriorityPopupProps> = ({
  data,
  onSave,
  onClose,
  id_popup,
  name,
}) => {
  const [popups, setPopups] = useState(data);
  let tableRef: any = useRef();

  const onDragEnd = (popupsEdit: any) => {
    setPopups(popupsEdit);
  };

  const enableButton = () => {
    const equals = compareValues(popups, data.popups);
    return !equals;
  };

  const handleSave = () => {
    onSave(popups);
  };

  useEffect(() => {
    try {
      const position = popups.findIndex((item: any) => item.value === id_popup);
      const qtdItems = popups?.length || 0;
      tableRef?.scrollTo({
        top: (tableRef?.scrollHeight / qtdItems) * position,
      });
    } catch (e) { }
  }, [popups]);

  const { currentTheme } = useTheme();

  return (
    <ModalEditPopup
      onClose={onClose}
      onSave={handleSave}
      enableButtonSave={enableButton}
      name={name}
    >
      <StackActionPriority>
        <Stack direction="column" marginBottom="0.75rem">
          <Information
            label="Ordem de aparição*"
            tooltipTitle="Obrigatório"
            tooltipLabel="Defina em que ordem aparecerão os pop-ups no sistema."
            labelColor={currentTheme.colors.secondary}
            tooltipColor={currentTheme.colors.white}
            place="right"
          />
          <Label
            fontSize="10px"
            title={' Clique e arraste para ajustar a priorização de pop-ups ativos.'}
          />
        </Stack>
        <Stack padding="0 20px 0 1px" overflowY="auto" ref={(ref) => (tableRef = ref)}>
          <DragAndDrop
            title={'Nome do pop-up'}
            list={popups}
            onDragEnd={onDragEnd}
            editKey={id_popup}
          />
        </Stack>
      </StackActionPriority>
    </ModalEditPopup>
  );
};
