import { LinkTextNotification } from './styled';

interface ITextNotificationProps {
  textLink: string;
  text: string;
  onClickLink: () => void;
}

const TextNotification = (props: ITextNotificationProps) => {
  const { textLink, text, onClickLink } = props;
  return (
    <span>
      <LinkTextNotification onClick={onClickLink}>{textLink}</LinkTextNotification>
      {text}
    </span>
  );
};

export default TextNotification;
