import styled from 'styled-components';

export const WrapperTypes = styled.div`
  margin-top: 10px;
  .subtitle {
    font-size: 0.625rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary}
  }
  .btn-next {
    display: flex;
    justify-content: flex-end;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
