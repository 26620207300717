import { Image, Paragraph, Stack } from 'components/atoms';
import Title from 'components/atoms/Title';

interface ILayoutTitle {
  title: string;
  subtitle: string;
  iconTitle?: string;
}

export const LayoutTitle = ({ title, iconTitle, subtitle }: ILayoutTitle) => {
  return (
    <Stack direction="column" gap="6px">
      <Stack display="flex" width="fit-content" height="fit-content" alignItems="center" gap="4px">
        {iconTitle && <Image src={iconTitle} width="21px" height="12px" />}
        <Title marginBottom="0px" label={title} />
      </Stack>
      <Paragraph
        fontWeight="400"
        fontSize="0.75rem"
        color="#2e2b37"
        lineHeight="120%"
        label={subtitle}
      />
    </Stack>
  );
};
