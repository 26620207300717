import TopicSummary from 'components/atoms/TopicSummary';
import TopicSummaryItemArray from 'components/atoms/TopicSummaryItemArray';
import { EditAudience } from 'components/molecules';
import { classificationPeriodList, planConditionList } from 'helpers/enums';
import { usePopupEdit } from 'hooks/useEditPopup';
import { IListsModel } from 'models';
import { useMemo, useState } from 'react';

interface IResumeFourtargetAudience {
  lists?: IListsModel;
}
export const ResumeFourtargetAudience: React.FC<IResumeFourtargetAudience> = ({ lists }) => {
  const { setHidenPreview, formPopup } = usePopupEdit();
  const {
    whichBranches,
    whichClassifications,
    classificationPeriod,
    segments,
    planCondition,
    active,
    planPoints,
    csvFile,
    whoCanSee,
    popupButtonName,
  } = formPopup.watch();

  const [openEdit, setOpenEdit] = useState(false);
  const initialState = {
    whichBranches,
    csvFile,
    whoCanSee,
    classificationPeriod,
    whichClassifications,
    segments,
    planCondition,
    planPoints,
    name: popupButtonName,
  };

  const randomId = useMemo(() => (Math.random() * Math.random()).toString(), []);

  const branchsSelecteds = useMemo(() => {
    const whichBranchesSelected = whichBranches ? whichBranches.map((item) => item.value) : [];
    const res =
      lists?.branchs.filter((filial) => whichBranchesSelected.includes(filial.value)) ?? [];
    return res.length > 0 ? res : [];
  }, [lists, whichBranches]);

  const categoriesSelecteds = useMemo(() => {
    const whichClassificationsList = whichClassifications
      ? whichClassifications.map((item) => item.value)
      : [];
    const res =
      lists?.categories.filter((category) => whichClassificationsList.includes(category.value)) ??
      [];
    return res.length > 0 ? res : [{ label: 'Todas', value: '0' }];
  }, [lists, whichClassifications]);

  const periodSelected = useMemo(() => {
    return classificationPeriodList.find((period) => period.value === classificationPeriod);
  }, [classificationPeriodList, classificationPeriod]);

  const segmentsSelected = useMemo(() => {
    const segmentsList = segments ? segments.map((item) => item.value) : [];
    const res = lists?.segments.filter((segment) => segmentsList.includes(segment.value)) ?? [];
    return res.length > 0 ? res : [{ label: 'Todos', value: '0' }];
  }, [segments, lists]);

  const planConditionSelected = useMemo(() => {
    return planConditionList.find((condition) => condition.value === planCondition);
  }, [planConditionList, planCondition]);

  const handleOpen = (val: any) => {
    setOpenEdit(val);
    setHidenPreview(val);
  };

  const save = (data: any) => {
    formPopup.setValue('whichBranches', data.whichBranches, {
      shouldDirty: true,
    });
    formPopup.setValue('csvFile', data.csvFile, { shouldDirty: true });
    formPopup.setValue('whoCanSee', data.whoCanSee, { shouldDirty: true });
    formPopup.setValue('classificationPeriod', data.classificationPeriod, {
      shouldDirty: true,
    });
    formPopup.setValue('whichClassifications', data.whichClassifications, {
      shouldDirty: true,
    });
    formPopup.setValue('segments', data.segments, { shouldDirty: true });
    formPopup.setValue('planCondition', data.planCondition, {
      shouldDirty: true,
    });
    formPopup.setValue('planPoints', data.planPoints, { shouldDirty: true });
    handleOpen(false);
  };

  return (
    <>
      <TopicSummary
        disabled={!active}
        title="Público alvo*"
        link="Editar >"
        itens={[
          // removendo o item "todos" do array para cair no else e ficar na mesma linha
          branchsSelecteds.length >= 1 ? (
            <TopicSummaryItemArray
              title="Filiais:"
              key={randomId}
              arrayItems={branchsSelecteds?.map((item) => item.label)}
              columns={2}
              customClickMaisItens={() => handleOpen(true)}
            />
          ) : (
            'Filiais: Todas'
          ),
          `Classificação: ${categoriesSelecteds
            ?.map((classification) => ' ' + classification.label)
            .toString()} - ${periodSelected?.label}`,
          `Segmentos: ${segmentsSelected?.map((segment) => ' ' + segment.label).toString()}`,
          `Pontuação Platinum: ${planConditionSelected?.label ?? ''} ${planPoints ?? '-'}`,
          `CNPJs: ${csvFile?.name ?? 'Todos'}`,
        ]}
        click={() => handleOpen(true)}
      />
      {openEdit && (
        <EditAudience
          popup={initialState}
          onClose={() => handleOpen(false)}
          handleSave={save}
          list={lists}
        />
      )}
    </>
  );
};
