import { Stack } from 'components/atoms';
import Tooltip from 'components/atoms/Tooltip';
import { AlertPreview, Icon, StackEditUpload, TextLink } from 'globalStyles';
import { useTheme } from 'hooks/useTheme';
import { fileToBase64 } from 'helpers/functions';
import { useEffect, useRef, useState } from 'react';
import { device } from 'styles/breakpoint';

import {
  Description,
  IconEdit,
  IconUpload,
  SectionUpload,
  StackPreview,
  StackPreviewImage,
  StackPreviewText,
  StackSectionUpload,
  SubWrapper,
  Title,
  Wrapper,
} from './styled';
import useIconThemes from 'hooks/iconThemes';

interface IPreviewPopupImageProps {
  onChange: (file: File) => void;
  edit?: boolean;
  imageLink?: string;
  disableEdit?: boolean;
  marginLeft?: string;
  popupFile?: File;
}
export const PreviewPopupImage = (props: IPreviewPopupImageProps) => {
  const { onChange, edit, imageLink, disableEdit = false, marginLeft = '52px', popupFile } = props;

  let inputRef: any = useRef();

  const label =
    'O arquivo deve ser em formato PNG, de no máximo 1MB e nas proporções 620px x 375px.';

  const [tooltipError, setTooltipError] = useState(false);
  const [base64, setBase64] = useState('');
  const [showLabel, setShowLabel] = useState(false);

  const showTooltipError = () => {
    if (inputRef) inputRef.value = '';
    if (tooltipError) return;
    setTooltipError(true);

    setTimeout(() => setTooltipError(false), 5000);
  };

  const handleOndragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    if (popupFile) {
      validateFile(popupFile);
    }
  }, [popupFile]);

  // validate file size and type  (max 2MB, only image)
  const validateFile = (file?: File) => {
    setTooltipError(false);
    if (!file) return;

    const validTypes = ['image/png'];
    const isLessThan1MB = file.size <= 1000000;

    // validate dimensions
    if (!validTypes.includes(file.type) || !isLessThan1MB) {
      return showTooltipError();
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      window.URL.revokeObjectURL(img.src);
      if (width !== 620 || height !== 375) {
        showTooltipError();
      } else {
        setTooltipError(false);
        fileToBase64(file).then((result: string) => {
          const base64 = `data:${file.type};base64,${result}`;
          setBase64(base64);
          onChange(file);
        });
      }
    };
  };

  const handleOndrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const imageFile = event.dataTransfer.files[0];
    validateFile(imageFile);
  };
  const iconThemes = useIconThemes();
  const { currentTheme } = useTheme();

  return (
    <StackPreview
      direction="column"
      marginLeft={marginLeft}
      justifyContent="center"
      flex="1"
      width="100%"
      alignItems="center"
      edit={edit ?? false}
    >
      <StackPreviewImage direction="column">
        <Tooltip
          width={'100%'}
          title={'Arquivo no formato inválido.'}
          content={'Arquivo no formato inválido. O arquivo deve ser no formato PNG e de até 1MB.'}
          direction={'bottom'}
          isOpenTootip={tooltipError}
        >
          <Wrapper edit={edit ?? false}>
            <Stack flex="1" width="100%">
              <Stack direction="column" flex="1" width="100%">
                <StackPreviewText justifyContent="center">
                  <AlertPreview>Pré-visualização</AlertPreview>
                </StackPreviewText>
                <StackSectionUpload disableEdit={disableEdit}>
                  <SectionUpload
                    base64={base64 === '' && imageLink ? imageLink : base64}
                    edit={edit ?? false}
                    onDragOver={handleOndragOver}
                    onDrop={handleOndrop}
                    onClick={() => {
                      if (!disableEdit) inputRef.click();
                    }}
                    onKeyDown={() => inputRef.current.click()}
                  >
                    <SubWrapper>
                      {!base64 && !imageLink ? (
                        <>
                          <IconUpload src="/assets/icons/icon-upload.svg" />

                          <Description>{label}</Description>
                        </>
                      ) : null}
                      {/* {!base64 && imageLink && (
                        <ImageLink
                          src={imageLink}
                          alt="Imagem banner"
                          edit={edit ?? false}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = '/assets/images/popup_image_default.png';
                          }}
                        />
                      )} */}

                      {!base64 && !imageLink && <Title>Escolher arquivo do computador</Title>}
                    </SubWrapper>
                  </SectionUpload>

                  {(base64 || imageLink) && !disableEdit && (
                    <StackEditUpload
                      htmlFor="upload"
                      bottom="0px"
                      right="0px"
                      onMouseEnter={() => setShowLabel(true)}
                      onMouseLeave={() => setShowLabel(false)}
                      onClick={() => inputRef.click()}
                      onKeyDown={() => inputRef.current.click()}
                    >
                      <Stack gap="4px" alignItems="center">
                        <IconEdit src={iconThemes[currentTheme.theme]?.IconEditWhite} />

                        {showLabel && (
                          <>
                            <Stack marginRight="12px">
                              <Description fontWeight="bold">Editar</Description>
                            </Stack>
                            <Stack direction="column">
                              <Description>
                                O arquivo deve ser em formato PNG, de no máximo 1MB
                              </Description>
                              <Description>e nas proporções 620px x 375px.</Description>
                            </Stack>
                          </>
                        )}
                      </Stack>
                    </StackEditUpload>
                  )}
                </StackSectionUpload>
              </Stack>
              {!edit && (
                <Stack
                  marginLeft="0.75rem"
                  alignItems="flex-start"
                  marginTop="32px"
                  breakpointDown={device.laptopM}
                >
                  <Icon src="/assets/icons/icon-close.svg" width={'22px'} />
                </Stack>
              )}
            </Stack>

            <input
              ref={(ref) => (inputRef = ref)}
              type="file"
              id="upload"
              hidden
              accept="image/png"
              onChange={(e) => e.target.files && validateFile(e.target.files[0])}
            />
          </Wrapper>
        </Tooltip>
        <Stack justifyContent="center" marginTop="1.255rem" breakpointDown={device.laptopM}>
          <TextLink textDecoration="underline" fontSize="10px">
            Não lembrar mais &gt;
          </TextLink>
        </Stack>
      </StackPreviewImage>
    </StackPreview>
  );
};
