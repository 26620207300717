import styled from 'styled-components';

interface IFlexProps {
  gap?: string;
  marginBottom?: string;
  marginTop?: string;
}
export const Flex = styled.div<IFlexProps>`
  display: flex;
  gap: ${(props) => (props.gap ? props.gap : '9px')};
  margin-bottom: ${(props) => props.marginBottom};
  margin-top: ${(props) => props.marginTop};
`;
export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 444px;
`;

interface IWrapInputProps {
  marginBottom?: string;
  marginTop?: string;
  justifyContent?: string;
}
export const WrapRadio = styled.div<IWrapInputProps>`
  display: flex;
  flex-direction: row;
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
  ${({ marginTop }) => marginTop && `margin-top:${marginTop}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom:${marginBottom}`};
`;
export const WrapperInformation = styled.div`
  margin-bottom: 1rem;
`;
export const Text = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #2e2b37;
`;
export const InputSimple = styled.input`
  background: #ffffff;
  border: 0.2px solid rgba(0, 0, 0, 0.34);
  border-radius: 0.375rem;
  margin-left: 0.625rem;
  width: 290px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 0.75rem;
`;
