export class CustomErrorApi extends Error {
  apiError = true;
  constructor(message: string) {
    super(message);
    this.name = 'ApiErro';
  }
}

export class CustomErrorAnauthorized extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Anauthorized';
  }
}
