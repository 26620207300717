import { PopupTypes } from 'components/organisms/PopupType';
import { Layout } from 'components/templates/Layout';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { useEffect } from 'react';
import { turboPopupsURL } from 'routers/routes';

export const PopupsSelectType = () => {
  const { currentTheme } = useTheme();

  useEffect(() => {
    document.title = 'Menu Pop-ups | Turbo |  Backoffice Lojas';
  }, []);

  const iconThemes = useIconThemes();

  return (
    <Layout.Container>
      <Layout.Page width={currentTheme.sizes[537]} height={currentTheme.sizes[547]}>
        <Layout.Header subMenu={subMenuTurbo} />
        <Layout.Body.Root>
          <Layout.Body.Back routerBack={turboPopupsURL} />
          <PopupTypes
            item={[
              {
                title: 'Pop-up de Imagem',
                subtitle: 'Um pop-up com imagem. Ideal para comunicação de peças gráficas.',
                icon:
                  iconThemes[currentTheme.theme]?.iconPopupImage ||
                  '/assets/icons/icon-popup-img-red.svg',
                iconHover:
                  iconThemes[currentTheme.theme]?.iconPopupImageHover ||
                  '/assets/icons/icon-popup-img-red.svg',
                img: '/assets/images/icon-image-popup.svg',
                value: 'image',
              },
              {
                title: 'Pop-up com Botão',
                subtitle:
                  'Um pop-up com botões de interação. Ideal para comunicações rápidas em texto ou lembretes.',
                icon:
                  iconThemes[currentTheme.theme]?.iconPopupButton ||
                  '/assets/icons/icon-popup-turbo-red.svg',
                iconHover:
                  iconThemes[currentTheme.theme]?.iconPopupButtonHover ||
                  '/assets/icons/icon-popup-turbo-red.svg',
                img: '/assets/images/icon-button-popup.svg',
                value: 'button',
              },
            ]}
          />
        </Layout.Body.Root>
      </Layout.Page>
    </Layout.Container>
  );
};
