import { HeaderButtonBackAndTitle, Stack, StackModal } from 'components/atoms';

import { FooterModalEdit } from '../FooterModalEdit';
import { Modal } from '../Modal';

interface IModalEditProps {
  children: React.ReactNode;
  onClose: (val: boolean) => void;
  onSave: () => void;
  enableButtonSave: () => boolean;
  height?: string;
  width?: string;
  hidenFooter?: boolean;
  title: string;
}
export const ModalEdit: React.FC<IModalEditProps> = ({
  children,
  onClose,
  onSave,
  enableButtonSave,
  height = '30.625rem',
  width = '33.25rem',
  hidenFooter = false,
  title,
}) => {
  return (
    <StackModal>
      <Modal
        width={width}
        height={height}
        background={'#F1F1F1'}
        zIndex="3"
        display="flex"
        flexDirection="column"
        fullScreenTabled
      >
        <Stack direction="column" justifyContent="space-between" height="100%">
          <Stack direction="column" gap="0.5rem" padding="33px 10px 0 30px">
            <HeaderButtonBackAndTitle onClick={() => onClose(false)} title={title} />
          </Stack>
          <Stack
            direction="column"
            gap="0.5rem"
            padding="0px 10px 0 30px"
            height="100%"
            overflow="auto"
          >
            {children}
          </Stack>
          {!hidenFooter && (
            <FooterModalEdit
              enableButtonSave={enableButtonSave}
              onSave={onSave}
              onClose={onClose}
              theme="confirmToUpdate"
            />
          )}
        </Stack>
      </Modal>
    </StackModal>
  );
};
