import { CustomErrorApi } from 'helpers/customErrorApi';
import { fileToBase64, formatDate } from 'helpers/functions';
import { IAPIResponse } from 'models/dtos/api-response.model';
import { IBackgroundResponseModel } from 'models/dtos/background.model';
import { IPaginationModel } from 'models/dtos/pagination.model';
import { IBackgroundModel } from 'models/entities/background.model';

import { api } from '../services/api';

export const GetAllBackgrounds = async (filter: {
  page: number;
  quantity: number;
  name: string;
}): Promise<IPaginationModel<IBackgroundModel[]>> => {
  const params = {
    pagina: filter.page,
    quantidade: filter.quantity,
    nome: filter.name,
  };
  const response = await api.get<
    IAPIResponse<{
      quantidade_total: number;
      backgrounds: IBackgroundResponseModel[];
    }>
  >('/carrossel/background/listar', {
    params,
  });
  const { quantidade_total, backgrounds } = response.data.Dado;
  const data: IBackgroundModel[] = backgrounds.map((item) => {
    const defineDate = item.tipo_vigencia === 2 ? 2 : 1;
    const _startDate = defineDate === 2 ? new Date() : new Date(item.data_inicio);
    const _endDate = defineDate === 2 ? new Date() : new Date(item.data_fim);
    const dataItem: IBackgroundModel = {
      id: item.id_background,
      active: item.status === 'Agendado' || item.status === 'Ativo',
      startDate: _startDate,
      endDate: _endDate,
      endDateUndefined: item.data_indefinida,
      defineDate,
      backgroundURL: item.imagem,
      status: item.status,
      name: item.nome,
      validity: item.vigencia,
    };
    return dataItem;
  });

  return { data, total: quantidade_total };
};

export const EditBackground = async (payload: {
  data: IBackgroundModel;
  nameImage?: string | null;
  imageAltered: boolean;
  imagebase64?: string | null;
  typeValidity: number;
}): Promise<boolean> => {
  try {
    const { data, nameImage, imageAltered, imagebase64, typeValidity } = payload;
    const internalData = {
      IdBackground: data.id,
      Nome: data.name,
      defineDate: data.defineDate,
      Datainicio: data.startDate ? formatDate(data.startDate) : null,
      // data final deverá ser passada apenas quando tiver vigencia e com expiração
      Datafim: data.endDate ? formatDate(data.endDate) : null,
      NomeImagem: nameImage,
      ImagemAlterada: imageAltered,
      imagembase64: imagebase64,
      Status: data.active,
      TipoVigencia: typeValidity,
    };
    const response = await api.post<IAPIResponse<void>>(
      'carrossel/background/editar',
      internalData
    );
    if (response.data.HttpStatus !== 200) {
      const notifications = response.data.Notificacoes;
      if (notifications && notifications?.length > 0) {
        throw new CustomErrorApi(notifications[0].Mensagem);
      } else {
        throw new Error();
      }
    } else {
      return true;
    }
  } catch (error) {
    if (error instanceof CustomErrorApi) {
      throw new Error(error.message);
    } else throw new Error('Não foi possível editar o plano de fundo.');
  }
};

export const PostBackground = async (payload: IBackgroundModel): Promise<boolean> => {
  try {
    const internalData = {
      nome: payload.name,
      nomeimagem: payload.backgroundFile?.name,
      imagembase64: await fileToBase64(payload.backgroundFile),
      TipoVigencia: payload.endDateUndefined ? 3 : payload.defineDate,
      datainicio:
        payload.defineDate === 1 || payload.endDateUndefined ? formatDate(payload.startDate) : null,
      datafim: payload.defineDate === 1 ? formatDate(payload.endDate) : null,
    };
    const response = await api.post<IAPIResponse<void>>(
      'carrossel/background/incluir',
      internalData
    );

    const notifications = response.data.Notificacoes;
    if (response.data.HttpStatus !== 200) {
      if (notifications && notifications?.length > 0) {
        throw new CustomErrorApi(notifications[0].Mensagem);
      } else {
        throw new Error('Não foi possível criar o plano de fundo');
      }
    }
    return true;
  } catch (error) {
    if (error instanceof CustomErrorApi) {
      throw new Error(error.message);
    } else throw new Error('Não foi possível criar o plano de fundo.');
  }
};
