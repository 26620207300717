import { IMenuItem } from 'components/atoms/Menu';
import { turboBannersURL, turboCarrosselURL, turboHomeURL, turboPopupsURL } from 'routers/routes';

export const subMenuTurbo: IMenuItem[] = [
  {
    id: 1,
    label: 'Início',
    url: turboHomeURL,
  },
  {
    id: 2,
    label: 'Banners',
    url: turboBannersURL,
  },
  {
    id: 3,
    label: 'POP-UPS',
    url: turboPopupsURL,
  },
  {
    id: 4,
    label: 'CARROSSEL',
    url: turboCarrosselURL,
  },
  // {
  //   id: 5,
  //   label: 'USUÁRIOS',
  //   url: turboUsersURL,
  // },
];
