import { IAPIResponse } from 'models/dtos/api-response.model';
import { IListsResponseModel } from 'models/dtos/lists-response.model';
import { IListsModel } from 'models/entities/lists.model';

import { api } from '../services/api';

export const GetAllLists = async (modulo: number): Promise<IListsModel> => {
  const response = await api.get<IAPIResponse<IListsResponseModel>>('/listas', {
    params: { modulo },
  });
  const dado = response.data.Dado;

  const obj: IListsModel = {
    banners: dado.banners?.map((banner) => ({
      value: banner.id_banner,
      name: banner.nome,
    })) || [],
    branchs: dado.filiais?.map((filial) => ({
      value: filial.id_filial,
      label: filial.nome,
    })) || [],
    categories: dado.categorias?.map((categoria) => ({
      value: categoria.id_categoria,
      label: categoria.nome,
    })) || [],
    segments: dado.segmentos?.map((segmento) => ({
      value: segmento.id_segmento,
      label: segmento.nome,
    })) || [],
    links: dado.links_internos?.map((link) => ({
      value: link.id_link_interno,
      label: link.nome,
      link: link.link,
    })) || [],
    popups: dado.popups?.map((link) => ({
      value: link.id_popup,
      name: link.nome,
    })) || [],
  };
  return obj;
};


