import { Stack } from 'components/atoms';
import { Icon } from 'globalStyles';
import { INotification } from 'hooks/notification';
import { useWindowSize } from 'hooks/useWindowSize';

import { Modal } from '../Modal';
import { StackNotify } from './styled';

interface INotifyProps {
  notification: INotification;
  onClose: () => void;
}
export const Notify: React.FC<INotifyProps> = ({ notification, onClose }) => {
  const { width } = useWindowSize();
  const isTablet = (width ?? 0) <= 768 && width;

  return (
    <Stack
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      zIndex="999"
      background="rgba(0,0,0,.32)"
    >
      <Modal
        background={notification.bgColor}
        close={() => onClose()}
        display={'flex'}
        displayButtonCloseOnTable="block"
        width={isTablet ? '80%' : undefined}
      >
        <StackNotify>
          <Icon src={notification.icon} width="24px" />
          <p className="notifyTitle">{notification.title}</p>
          {typeof notification.message === 'string' && (
            <p className="notifyMessage">{notification.message}</p>
          )}
          {typeof notification.message === 'object' && notification.message}
        </StackNotify>
      </Modal>
    </Stack>
  );
};
