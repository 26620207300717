import { useEffect, useRef, useState } from 'react';
import { Controller, Control } from 'react-hook-form';

import Stack from '../Stack';
import { Wrap } from './styled';
import { useTheme } from 'hooks/useTheme';

export interface IInputDragAndDropFormProps {
  name: string;
  control: Control<any>;
  marginTop?: string;
  marginLeft?: string;
  title?: string;
  accept?: string[];
  buttonTitle: string;
  titleChange: string;
  slin?: boolean;
  maxSize?: number;
  errorMessage?: string;
  onChange?: (e: any) => void;
  imageConfig?: { width: number; height: number };
}
export const InputDragAndDropForm: React.FC<IInputDragAndDropFormProps> = ({
  name,
  control,
  marginTop,
  marginLeft,
  title,
  accept,
  buttonTitle,
  titleChange,
  slin,
  maxSize,
  errorMessage,
  onChange,
  imageConfig,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [tooltipError, setTooltipError] = useState('');
  const defaultAccept = ['image/*'];
  const validTypes = accept ?? defaultAccept;
  const tooltipContent = errorMessage ?? 'Por favor, selecione um arquivo \n no formato correto.';

  const showTooltipError = (error = tooltipContent) => {
    // inputRef.current.value = '';
    if (tooltipError) return;
    setTooltipError(error);

    setTimeout(() => setTooltipError(''), 5000);
  };

  const fileIsValid = (file: File) => {
    const isLessThanMaxSize = file.size <= (maxSize ?? file.size);

    // validate dimensions
    if (!validTypes.includes(file.type) || !isLessThanMaxSize) {
      return showTooltipError();
    }

    // validate dimensions
    if (!validTypes.includes(file.type)) {
      showTooltipError();
      return false;
    }
    return true;
  };

  const handleFile = (file: File, formOnChange: (file: File) => void) => {
    if (file && !fileIsValid(file)) {
      return;
    }
    formOnChange(file);
  };

  const validateFileDimension = (file: File, formOnChange: (file: File) => void) => {
    if (!imageConfig) {
      handleFile(file, formOnChange);
    }

    // validate dimensions
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      const { width, height } = imageConfig ?? { width: undefined, height: undefined };
      const isWidthValid = width && width === img.width;
      const isHeightValid = height && height === img.height;
      if (isWidthValid && isHeightValid) {
        return handleFile(file, formOnChange);
      }
      showTooltipError();
    };
  };

  const handleOndrop = (
    event: React.DragEvent<HTMLDivElement>,
    formOnChange: (file: File | null) => void
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];

    if (!fileIsValid(file)) {
      formOnChange(null);
      return;
    }

    validateFileDimension(file, formOnChange);
  };
  const { currentTheme } = useTheme();

  useEffect(() => {
    if (inputRef?.current?.value) {
      inputRef.current.value = '';
    }
  }, [imageConfig?.width]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, field: { value }, fieldState: { error } }) => (
        <>
          <Wrap
            marginTop={marginTop}
            marginLeft={marginLeft}
            className="drop_zone"
            onDragOver={(event) => event?.preventDefault()}
            onDrop={(e) => handleOndrop(e, field.onChange)}
            onClick={() => inputRef.current?.click()}
            onKeyDown={(e) => {
              if (e.code === 'Space' || e.code === 'Enter') {
                inputRef.current?.click();
              }
            }}
            role="presentation"
            opacityWrap={!value}
            slin={slin}
            data-testid="input-drag"
          >
            {title && <p>{title}</p>}
            <div>
              <button>{buttonTitle}</button>
              <p>{value ? value.name : titleChange} </p>
              <input
                ref={inputRef}
                data-testid="input-drag-and-drop"
                type="file"
                accept={accept ? accept[0] : defaultAccept[0]}
                hidden
                onChange={(e) => {
                  if (e.target.files?.length) {
                    validateFileDimension(e.target.files[0], field.onChange);
                    if (onChange) onChange(e.target.files[0]);
                  }
                }}
              />
            </div>
          </Wrap>
          <Stack height="16px">
            <p style={{ color: currentTheme.colors.error, fontSize: '12px' }}>
              {tooltipError || error?.message}
            </p>
          </Stack>
        </>
      )}
    />
  );
};
