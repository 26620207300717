import { useTheme } from 'hooks/useTheme';
import { limitLenghtString } from '../../../helpers/functions';
import StatusUser from '../../atoms/StatusUser';
import Title from '../../atoms/Title';
import { Wrap } from './styled';

interface ITitleAndStatusUserProps {
  title: string;
  active: boolean;
}
export const TitleAndStatusUser: React.FC<ITitleAndStatusUserProps> = ({ title, active }) => {
  // todo verificar função
  const isSchedule = () => {
    return status === 'Agendado';
  };
  const { currentTheme } = useTheme(); 
  return (
    <Wrap>
      <Title fontWeight="700" fontSize="1rem" color={currentTheme.colors.primary}  marginRight="10px" marginBottom="0px">
        Editando: {limitLenghtString(title)}
      </Title>
      <StatusUser type="Banner" status={active ? (isSchedule() ? 'agendado' : active) : active} />
    </Wrap>
  );
};
