import { SelectFileCarouselPictureDicionary } from 'components/molecules/CarouselPictureDicionary/SelectFileCarouselPictureDicionary';
import { SelectNameCarouselPictureDicionary } from 'components/molecules/CarouselPictureDicionary/SelectNameCarouselPictureDicionary';
import { SelectTypeCarouselPictureDicionary } from 'components/molecules/CarouselPictureDicionary/SelectTypeCarouselPictureDicionary';
import { TitleAndLabel } from 'components/molecules/TitleAndLabel';
import { useCarouselPictureDictionary } from 'hooks/carouselPictureDictionary';
import { useEffect } from 'react';

export const CreateCarouselPictureContent = () => {
  const { createFormPicture } = useCarouselPictureDictionary();
  useEffect(() => {
    // define title page
    document.title = 'Criar Dicionário de imagens | Turbo |  Backoffice Lojas';
  }, []);
  return (
    <>
      <TitleAndLabel label="Personalizando o Carrossel" title="Criar nova imagem de prêmio" />
      <SelectTypeCarouselPictureDicionary form={createFormPicture} />
      <SelectNameCarouselPictureDicionary form={createFormPicture} />
      <SelectFileCarouselPictureDicionary form={createFormPicture} />
    </>
  );
};
