import { IMenuItem } from "components/atoms/Menu";
import { chamasAnalyticalURL, chamasHomeURL } from "routers/routes";

export const subMenuChamas: IMenuItem[] = [
  {
    id: 1,
    label: "Início",
    url: chamasHomeURL,
  },
  {
    id: 2,
    label: "ANALÍTICO",
    url: chamasAnalyticalURL,
  },

]