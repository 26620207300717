import { api } from 'services/api';

export const GetAllUsers = async (page: number, quantity: number, name: string) => {
  const response = await api.get('/usuario/listar', {
    params: { pagina: page, quantidade: quantity, nome: name },
  });

  return response.data;
};

export const InsertUser = async (name: string, email: string) => {
  const data = { email, nome: name };

  const response = await api.post('/usuario/incluir', data);

  return response.data;
};

export const ResendInvite = async (id: string, typeRequest: number) => {
  // A tela de Login quando o user esquecer a senha, o campo email deve ser informado e o tipoRequisicao será o 2;
  // Ao editar quando o user clicar em Redefinir senha, o campo id deve ser informado e o tipoRequisicao será o 2;
  // Ao editar quando o user clicar em Reenviar convite, o campo id deve ser informado e o tipoRequisicao será o 1.
  const response = await api.post('/senha/solicitar', {
    id,
    tipoRequisicao: typeRequest,
  });
  return response.data;
};

export const UpdateUser = async (name: string, email: string, id: string, active: boolean) => {
  const data = { email, nome: name, id, ativo: active };

  const response = await api.post('/usuario/editar', data);

  return response.data;
};
