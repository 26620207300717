import { PopupImageCreate } from 'components/organisms';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import { CreatePopupImageProvider } from 'hooks/popupImage';
import { turboPopupsURL } from 'routers/routes';

export const TurboPopupImageCreate = () => {
  return (
    <CreatePopupImageProvider>
      <PopupImageCreate subMenu={subMenuTurbo} routerBack={turboPopupsURL} />
    </CreatePopupImageProvider>
  );
};
