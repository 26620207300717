import { useCallback, createContext, useContext, useState } from 'react';

interface ILoadingContext {
  handleShowLoading: () => void;
  handleHideLoading: () => void;
  handleChangeLoading: (val: boolean) => void;
  showLoading: boolean;
}

export const LoadingContext = createContext({} as ILoadingContext);

export const LoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const [showLoading, setShowLoading] = useState(false);

  const handleShowLoading = useCallback(() => {
    setShowLoading(true);
  }, []);

  const handleHideLoading = useCallback(() => {
    setShowLoading(false);
  }, []);

  const handleChangeLoading = useCallback((val: boolean) => {
    setShowLoading(val);
  }, []);

  return (
    <LoadingContext.Provider
      value={{
        handleShowLoading,
        handleHideLoading,
        showLoading,
        handleChangeLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const context = useContext(LoadingContext);
  return context;
};
