interface IThemeColors {
  primary: string;
  secondary: string;
  darkGray: string;
  backgroundWhite: string;
  backgroundGray: string;
  error: string;
  success: string;
  information: string;
  disabled: string;
  gold: string;
  silver: string;
  bronze: string;
  platinum: string;
  black: string;
  white: string;
  silverDark: string;
  silverLight: string;
  backgroundHeader: string;
  colorText: string;
}

interface IThemeFontSizes {
  xs: string;
  sm: string;
  md: string;
  xmd: string;
  lg: string;
  xl: string;
  xxl: string;
  xxxl: string;
}

interface IThemeFontWeights {
  normal: string;
  bold: string;
}

interface IThemeBorderRadius {
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

interface IThemeSpacing {
  [key: number]: string;
}

interface IThemeBoxShadow {
  sm: string;
  md: string;
  lg: string;
}

interface IThemeDisplay {
  desktop: string;
  tablet: string;
  mobile: string;
}

interface IThemeSizes {
  537: string;
  547: string;
}

export interface ITheme {
  theme: string;
  colors: IThemeColors;
  fontSizes: IThemeFontSizes;
  fontWeights: IThemeFontWeights;
  borderRadius: IThemeBorderRadius;
  spacing: IThemeSpacing;
  boxShadow: IThemeBoxShadow;
  display: IThemeDisplay;
  sizes: IThemeSizes;
}

const themeBackoffice: ITheme = {
  theme: 'themeBackoffice',

  colors: {
    primary: '#2F80ED',
    secondary: '#2F80ED',
    darkGray: '#2E2B37',
    backgroundWhite: '#FFFFFF',
    backgroundGray: '#f1f1f1',
    error: '#ff8332',
    success: '#00D217',
    information: '#2f80ed',
    disabled: '#c4c4c4',
    gold: '#fece35',
    silver: '#E9E9E9',
    bronze: '#c68c50',
    platinum: '#696977',
    black: '#000000',
    white: '#FFFFFF',
    silverDark: '#D9D9D966',
    silverLight: 'rgba(217, 217, 217, 0.4)',
    backgroundHeader: '#2F80ED',
    colorText: '#2E2B37',
  },
  fontSizes: {
    xs: '',
    sm: '10px',
    md: '12px',
    xmd: '14px',
    lg: '16px',
    xl: '20px',
    xxl: '24px',
    xxxl: '30px',
  },
  fontWeights: {
    normal: '400',
    bold: '700',
  },
  borderRadius: {
    sm: '4px',
    md: '8px',
    lg: '16px',
    xl: '24px',
  },
  spacing: {
    0: '0rem',
    1: '0.125rem',
    2: '0.25rem',
    3: '0.375rem',
    4: '0.5rem',
    5: '0.625rem',
    6: '0.75rem',
    7: '0.875rem',
    8: '1rem',
    9: '1.125rem',
    10: '1.25rem',
    11: '1.375rem',
    12: '1.5rem',
    13: '1.625rem',
    14: '1.75rem',
    15: '1.875rem',
    16: '2rem',
    17: '2.125rem',
    18: '2.25rem',
    19: '2.375rem',
    20: '2.5rem',
    21: '2.625rem',
    22: '2.75rem',
    23: '2.875rem',
    24: '3rem',
    25: '3.125rem',
  },
  boxShadow: {
    sm: '0px 0px 4px 0px rgba(0, 0, 0, 0.13)',
    md: '0px 0px 16px 0px rgba(0, 0, 0, 0.13)',
    lg: '0px 0px 32px 0px rgba(0, 0, 0, 0.13)',
  },
  display: {
    desktop: '740px',
    tablet: '740px',
    mobile: '100%',
  },
  sizes: {
    537: '537px',
    547: '547px',
  },
};

export default themeBackoffice;
