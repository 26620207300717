import { Stack } from 'components/atoms';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

export const IconFilter = styled.img`
  width: 8px;
  height: 8px;
`;

export const Subtitle = styled.p`
  font-size: 0.625rem;
  color: #2e2b37;
`;

export const ImageList = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 0.25rem;
  margin-top: -3px;
`;

export const FilerText = styled.span`
  font-size: 0.75rem;
  color: #2e2b37;
`;

export const DivDot = styled.div`
  height: 33px;
  border-left: 1px solid #2e2b37;
  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;

export const StackFilter = styled(Stack)`
  margin-top: 1.625rem;
  justify-content: space-between;
  height: 33px;
  flex-direction: row;
  gap: 0.5rem;
  @media (max-width: ${device.tablet}) {
    flex-direction: column-reverse;
    height: 66px;
    gap: 1rem;
  }
`;
