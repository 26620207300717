import styled from 'styled-components';

import Stack from '../Stack';

export const StackModal = styled(Stack)`
  width: 100%;
  height: 100%;
  background-color: #000000b0;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
`;
