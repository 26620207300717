import { Stack } from 'components/atoms';
import TopicSummary from 'components/atoms/TopicSummary';
import TopicSummaryItemArray from 'components/atoms/TopicSummaryItemArray';
import { classificationPeriodList, planConditionList, popupActionListPopUp } from 'helpers/enums';
import { diffDaysLabel } from 'helpers/functions';
import { usePopupImage } from 'hooks/popupImage';
import { useMemo } from 'react';
import { device } from 'styles/breakpoint';

import { PreviewPopupImage } from '../PreviewPopupImage';

export const StepPopupImageResume = () => {
  const { setActiveStep, createFormPopupImageStepper, setIsEditing } = usePopupImage();

  const randomId = useMemo(() => (Math.random() * Math.random()).toString(), []);

  const onEditStep = (step: number) => {
    setIsEditing(true);
    setActiveStep(step);
  };

  const [
    action,
    classificationPeriod,
    planCondition,
    popups,
    defineDate,
    startDate,
    endDateUndefined,
    endDate,
    whichBranches,
    whichClassifications,
    segments,
    planPoints,
    whoCanSee,
    csvFile,
    popupFile,
  ] = createFormPopupImageStepper.watch([
    'action',
    'classificationPeriod',
    'planCondition',
    'popups',
    'defineDate',
    'startDate',
    'endDateUndefined',
    'endDate',
    'whichBranches',
    'whichClassifications',
    'segments',
    'planPoints',
    'whoCanSee',
    'csvFile',
    'popupFile',
  ]);

  const actionSelected = useMemo(() => {
    return popupActionListPopUp.find((actionValue) => actionValue.value === action);
  }, [popupActionListPopUp, action]);

  const periodSelected = useMemo(() => {
    return classificationPeriodList.find((period) => period.value === classificationPeriod);
  }, [classificationPeriodList, classificationPeriod]);

  const planConditionSelected = useMemo(() => {
    return planConditionList.find((condition) => condition.value === planCondition);
  }, [planConditionList, planCondition]);

  const viewOrderNumber = (): string => {
    const popup: number = popups?.findIndex((e: { value: number }) => e.value === 0) ?? 0;
    return String(popup + 1);
  };

  return (
    <Stack gap="0.5rem" direction="column" marginBottom="0.75rem">
      <Stack
        flex="1"
        justifyContent="center"
        alignItems="center"
        breakpointDownInvert={device.laptopM}
        marginBottom="0.75rem"
      >
        <PreviewPopupImage
          onChange={(image) => {
            createFormPopupImageStepper.setValue('popupFile', image);
            createFormPopupImageStepper.trigger('popupFile');
          }}
          popupFile={popupFile}
        />
      </Stack>
      <TopicSummary
        title="Ação do pop-up*"
        link="Editar >"
        itens={[actionSelected?.label ?? '']}
        click={() => onEditStep(1)}
      />
      <TopicSummary
        title="Ordem de aparição*"
        link="Editar >"
        itens={[viewOrderNumber()]}
        click={() => onEditStep(2)}
      />
      <TopicSummary
        title="Vigência do pop-up*"
        link="Editar >"
        itens={
          defineDate === 1
            ? [
                `Data início: ${startDate.toLocaleDateString('pt-BR', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                })} ${diffDaysLabel(startDate)}`,
                `Data fim: ${
                  endDateUndefined
                    ? 'Indefinida'
                    : endDate.toLocaleDateString('pt-BR', {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                      })
                }`,
              ]
            : ['Exibir apenas durante período de resgate']
        }
        click={() => onEditStep(3)}
      />
      <TopicSummary
        title="Público alvo*"
        link="Editar >"
        itens={[
          // removendo o item "todos" do array para cair no else e ficar na mesma linha
          whichBranches ? (
            whichBranches.filter((item) => item.value !== 0).length >= 1 ? (
              <TopicSummaryItemArray
                title="Filiais:"
                key={randomId}
                arrayItems={whichBranches?.map((item) => item.label)}
              />
            ) : (
              'Filiais: Todas'
            )
          ) : (
            'Filiais: Todas'
          ),
          `Classificação: ${
            whichClassifications && whichClassifications.length
              ? whichClassifications?.map((classification) => ' ' + classification.label).toString()
              : 'Todas'
          } - ${periodSelected?.label ?? ''}`,
          `Segmentos: ${
            segments && segments.length
              ? segments?.map((segment) => ' ' + segment.label).toString()
              : 'Todos'
          }`,
          `Pontuação Platinum: ${planConditionSelected?.label ?? ''} ${planPoints ?? '-'}`,
          `CNPJs: ${whoCanSee === 1 ? 'Todos' : csvFile?.name ?? ''}`,
        ]}
        click={() => onEditStep(4)}
      />
    </Stack>
  );
};
