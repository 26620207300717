import Information from 'components/atoms/Information';
import Stack from 'components/atoms/Stack';
import { Controller } from 'react-hook-form';

import { DownChevron, Flex, Label, ReactSelectElement } from '../styled';
import { useTheme } from 'hooks/useTheme';
import useIconThemes from 'hooks/iconThemes';

interface IOptionProps {
  value: number;
  label: string;
}

interface ISelectColorCustomFormProps {
  label?: string;
  isMulti?: boolean;
  options: IOptionProps[];
  defaultValue?: IOptionProps;
  showSelectAll?: boolean;
  placeholder?: string;
  fixedHeight?: boolean;
  control: any;
  name: string;
  onChange?: (val: any, newVal: any) => void;
  tooltipTitle?: string;
  tooltipLabel?: string;
}

export const SelectColorCustomForm: React.FC<ISelectColorCustomFormProps> = ({
  label,
  options,
  placeholder = 'Digite para pesquisar...',
  control,
  name,
  tooltipTitle,
  tooltipLabel,
  onChange,
}) => {
  const iconThemes = useIconThemes();
  const formatOptionLabel = (prop: any, value: any) => (
    <Flex>
      <Stack padding="2px" border={`2px solid ${prop.color}`} borderRadius="50%">
        <Stack background={prop.color} width="14px" height="14px" borderRadius="50%" />
      </Stack>

      <Label>{prop.label}</Label>
    </Flex>
  );

  const arrowRenderer = () => {
    return (
      <DownChevron
        height={'4px'}
        alt="chevron"
        src={iconThemes[currentTheme.theme]?.IconDownChevron}
      />
    );
  };

  const getValue = (value: any) => {
    const val = options.find((el) => el.value === value);
    return val;
  };

  const { currentTheme } = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack width="100%" direction="column">
          <Information
            label={label}
            tooltipTitle={tooltipTitle}
            tooltipLabel={tooltipLabel}
            marginBottom="0.375rem"
            labelColor={currentTheme.colors.secondary}
            tooltipColor={error ? currentTheme.colors.error : currentTheme.colors.white}
          />
          <ReactSelectElement
            {...field}
            classNamePrefix="mySelect"
            options={options}
            formatOptionLabel={formatOptionLabel}
            components={{ DropdownIndicator: arrowRenderer }}
            placeholder={placeholder}
            value={getValue(field.value)}
            onChange={(e: any) => {
              field.onChange(e.value);
              if (onChange) onChange(e, field.value);
            }}
            noOptionsMessage={() => 'Sem dados'}
            fixedHeight
            selectColor
          />
          <Stack minHeight="1rem">
            <p style={{ color: currentTheme.colors.error, fontSize: '12px' }}>{error?.message}</p>
          </Stack>
        </Stack>
      )}
    />
  );
};
