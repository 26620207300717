import { RegisterDuplicateError } from 'helpers/registerDuplicateError';
import { IAPIResponse } from 'models/dtos/api-response.model';
import { IValidityNomeModel } from 'models/dtos/validity-name.model';
import { api } from 'services/api';

export enum screenName {
  background = 1,
  banner = 2,
  dicionario = 3,
  popup = 4,
  usuario = 5,
}

const screenDescription = {
  [screenName.background]: 'fundo',
  [screenName.banner]: 'banner',
  [screenName.dicionario]: 'prêmio',
  [screenName.popup]: 'pop-up',
  [screenName.usuario]: 'usuario',
};

interface IValidateNameProps {
  name: string;
  screen: screenName;
  id?: number;
}

export const ValidityName = async ({ name, screen, id }: IValidateNameProps): Promise<boolean> => {
  const response = await api.get<IAPIResponse<IValidityNomeModel>>(
    `/validar/nome?nome=${name}&tipo_validacao=${screen}${id ? `&id=${id}` : ''}`
  );
  if (response.data.HttpStatus === 200) {
    if (response.data.Dado.existe_nome) {
      throw new RegisterDuplicateError(
        `O nome de ${screenDescription[screen]} já está em uso. Tente novamente com outro nome.`
      );
    }
    return true;
  }
  throw new Error('Verifique sua conexão e tente novamente.');
};
