/* eslint-disable eqeqeq */
import { limitLenghtString } from 'helpers/functions';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { Row, RowHeader, TableBody, TableComponent, TableHeader, TD, TH } from './styled';
import { useTheme } from 'hooks/useTheme';

interface IDragAndDropProps {
  title: string;
  list: { value: number; name: string }[];
  onDragEnd: (list: { value: number; name: string }[]) => void;
  editKey?: number;
}
export const DragAndDrop: React.FC<IDragAndDropProps> = ({ title, list, onDragEnd, editKey }) => {
  const { currentTheme } = useTheme();

  // Function to update list on drop
  const handleDragEnd = (e: any) => {
    if (!e.destination) return;
    const tempData = Array.from(list);
    const [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    onDragEnd(tempData);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <TableComponent>
        <TableHeader>
          <RowHeader color={'#FFFFFF'} background={'#2E2B37'}>
            <TH width="10%" borderRight="1.6px solid #FFFFFF;">
              #
            </TH>
            <TH width="90%">{title}</TH>
          </RowHeader>
        </TableHeader>

        <Droppable droppableId="droppable-1">
          {(provider) => (
            <TableBody ref={provider.innerRef} {...provider.droppableProps}>
              {list?.map((item, index) => (
                <Draggable
                  isDragDisabled={item.value !== (editKey ?? 0)}
                  key={item.value}
                  draggableId={item.value.toString()}
                  index={index}
                >
                  {(provider) => (
                    <Row
                      {...provider.draggableProps}
                      ref={provider.innerRef}
                      active={item.value == (editKey ?? 0)}
                    >
                      <TD width="10%" borderRight={`1.6px solid ${currentTheme.colors.primary};`}>
                        {index + 1}
                      </TD>
                      <TD
                        width="90%"
                        {...provider.dragHandleProps}
                        active={item.value == (editKey ?? 0)}
                      >
                        {limitLenghtString(item.name)}
                      </TD>
                    </Row>
                  )}
                </Draggable>
              ))}

              {provider.placeholder}
            </TableBody>
          )}
        </Droppable>
      </TableComponent>
    </DragDropContext>
  );
};
