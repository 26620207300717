import styled from 'styled-components';

interface ISectionPaginator {
  visibility?: string;
}
export const SectionPaginator = styled.section<ISectionPaginator>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  visibility: ${({ visibility }) => visibility ?? '1'};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface IWrapperPagination {
  biggerSize: boolean;
}
export const WrapperPagination = styled.div<IWrapperPagination>`
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  width: ${({ biggerSize }) => (biggerSize ? '198px' : '189px')};
  height: 35px;
  color: ${({ theme }) => (theme.colors.white)};
  padding: 5px 6px;
  border-radius: 0px 0px 0.5rem 0.5rem;
  margin-top: -18px;
`;

export const Grid = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ListPagination = styled.section`
  font-size: 0.875rem;
  line-height: 109%;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 109%;
  color: ${({ theme }) => (theme.colors.secondary)};
  margin-top: 0.75rem;
`;

interface IButtonSectionPages {
  left?: boolean;
  right?: boolean;
}
export const ButtonSectionPages = styled.button<IButtonSectionPages>`
  color: inherit;
  cursor: pointer;
  background: ${({ theme }) => (theme.colors.secondary)};
  width: 37px;
  height: 24px;
  border-radius: 0px 0.75rem 0.75rem 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${(props) => (props.left ? '180deg' : '0deg')});
  padding: 5px 0 3px;
  // insert a image icon here to the right of the button
  &:after {
    content: url('/moreloyalty/assets/icons/icon-arrow-pagination.svg');
  }
`;

export const ItemList = styled.div`
  height: 26px;
  width: 26px;
  /* width: fit-content; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.clicked {
    background: ${({ theme }) => (theme.colors.white)};
    color: ${({ theme }) => theme.colors.primary};
    border-radius: 0.75rem;
    padding: 0.625rem;
  }
`;

export const ArrowIcon = styled.span`
  font-weight: bold;
  cursor: pointer;
`;
