import { Stack } from 'components/atoms';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

export const StackNotify = styled(Stack)`
  flex-direction: column;
  padding: 1.5rem;
  min-height: 116px;
  width: 443px;
  @media (max-width: ${device.tablet}) {
    width: 100%;
    flex: 1;
  }
`;
