import { getCookie, removeCookie, setCookie, getData } from 'helpers/cookies';
import { useMixpanel } from 'helpers/mixpanel';
import { createContext, useContext, useEffect, useState } from 'react';
import { PostAuthentication, PostForgotPassword } from 'requests/Authentication';

import { useLoading } from './loading';
import { useNotification } from './notification';
import { backofficeHomeURL } from 'routers/routes';

interface IUser {
  name?: string;
  email?: string;
  id?: string;
  profile?: any;
  status?: LoginState;
}
export enum LoginState {
  SUCCESS = '0',
  CHANGE_PASSWORD = '1',
  EXPIRE_PASSWORD = '2',
}

interface IAuthContext {
  signed: boolean;
  user: IUser;
  token?: string;
  signin: (email: string, password: string) => Promise<void>;
  signout: () => void;
  error: boolean;
  loading: boolean;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const notify = useNotification();
  const [token, setToken] = useState(getCookie('BACKOFFICE_VEICULOS_TOKEN'));
  const [user, setUser] = useState<IUser>({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleChangeLoading } = useLoading();

  const forgotPassword = async (email: string) => {
    setLoading(true);
    try {
      await PostForgotPassword(email, 2);
      notify.success(
        'Enviamos para o seu e-mail as instruções de recuperação.',
        'Utilize o link enviado para recuperar sua senha.',
        20000
      );
    } catch (e) {
      notify.error('Não foi possível realizar a requisição', 'Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };

  const signin = async (email: string, password: string) => {
    setError(false);
    setLoading(true);
    await PostAuthentication(email, password)
      .then((response) => {
        if (response.data.data.Dado) {
          const data = getData(response.data.data.Dado.Token);
          if (data.Status === LoginState.CHANGE_PASSWORD) {
            notify.error(
              'Não foi possível fazer seu login.',
              <div>
                Verifique o e-mail enviado para recuperar sua senha ou&nbsp;
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => forgotPassword(email)}
                >
                  clique aqui
                </span>
                &nbsp;para solicitar uma nova recuperação de senha.
              </div>
            );
          } else {
            setCookie(
              'BACKOFFICE_VEICULOS_TOKEN',
              response.data.data.Dado.Token,
              new Date(new Date().getTime() + 60 * 60 * 24 * 1000).toISOString()
            );

            if (data.Status === LoginState.EXPIRE_PASSWORD) {
              document.location.href = '/senha-expirada';
            } else {
              document.location.href = backofficeHomeURL;
            }
          }
        }
        if (response.data.data.HttpStatus !== 200) {
          notify.error(
            'E-mail ou senha incorretos.',
            'Verifique seus dados e tente novamente.',
            3000
          );
          setError(true);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (token) {
      const data = getData(token);
      setUser({
        name: data.Name,
        email: data.Email,
        id: data.Id,
        status: data.Status,
      });

      useMixpanel.identify(data.Email);
      useMixpanel.track('Login', {
        Email: data.Email,
        Name: data.Name,
      });
    }
  }, [token]);

  useEffect(() => {
    handleChangeLoading(loading);
  }, [handleChangeLoading, loading]);

  const signout = () => {
    removeCookie('BACKOFFICE_VEICULOS_TOKEN');
    setToken('');
    setUser({});
  };

  return (
    <AuthContext.Provider
      value={{
        signed: !!token,
        signin,
        signout,
        token,
        user,
        error,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthentiction = () => {
  const context = useContext(AuthContext);

  return context;
};
