import { useTheme } from 'hooks/useTheme';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

interface ITableWrapperProps {
  width?: string;
}
export const TableWrapper = styled.table<ITableWrapperProps>`
  width: ${({ width }) => width ?? '100%'};
  border-collapse: collapse;
`;

interface ITableProps {
  bgColor?: string;
  color?: string;
}
export const Header = styled.tr<ITableProps>`
  width: 100%;
  font-size: 0.875rem;
  background-color: ${({ bgColor, theme }) => bgColor ?? theme.colors.secondary};
  color: ${({ color }) => color ?? 'white'};
  height: 53px;
`;

interface IBodyProps {
  bgColor?: string;
}
export const Body = styled.tr<IBodyProps>`
  width: 100%;
  height: 43px;
  font-size: 0.75rem;
  background-color: ${({ bgColor, theme }) => bgColor ?? theme.colors.backgroundGray};
  color: ${({ color }) => color ?? '#2E2B37'};

  &:nth-child(even) {
    background-color: ${(props) => props.theme.colors.white};
  }
`;

interface ITitleProps {
  showBorder?: boolean;
}
export const Title = styled.th<ITitleProps>`
  .data {
    display: flex;
    padding-left: 2rem;
    align-items: center;
    border-right: ${({ showBorder }) => (!showBorder ? '' : '1.6px solid white')};
  }
`;

interface IDataProps {
  showBorder?: boolean;
}
export const Data = styled.td<IDataProps>`
  .data {
    display: flex;
    align-content: center;

    padding-left: 2rem;
    align-items: center;
    border-right: ${({ showBorder, theme }) =>
      !showBorder ? '' : `${theme.colors.primary} 0.6px solid`};
  }
`;

export const Tbody = styled.tbody`
  @media (max-width: ${device.tablet}) {
    box-shadow: -6px 0px 9px -7px ${({ theme }) => theme.colors.black};
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
`;
