import { Stack, StepCreated } from 'components/atoms';
import { IMenuItem } from 'components/atoms/Menu';
import { LayoutRegister } from 'components/templates';
import { usePopupImage } from 'hooks/popupImage';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetAllLists } from 'requests/Lists';
import { device } from 'styles/breakpoint';
import CreatePopupImage from '../CreatePopupImage';
import { PreviewPopupImage } from '../PreviewPopupImage';

interface IStepperBannerProps {
  subMenu?: IMenuItem[];
  routerBack: string;
}

export const PopupImageCreate = ({ subMenu, routerBack }: IStepperBannerProps) => {
  const {
    setList,
    setActiveStep,
    handleCloseConfirm,
    createFormPopupImageStepper,
    activeStep,
    created,
  } = usePopupImage();
  const navigate = useNavigate();

  const name = createFormPopupImageStepper.watch('name');
  const popupFile = createFormPopupImageStepper.watch('popupFile');

  useEffect(() => {
    document.title = 'Criar Pop-up | Turbo |  Backoffice Lojas';
  }, []);

  const handleChangeStep = (step: number) => {
    if (step === 0) {
      // saindo do fluxo, exibir modal
      handleCloseConfirm(routerBack);
      return;
    }
    setActiveStep(step);
  };

  useEffect(() => {
    GetAllLists(4).then((result) => {
      setList(result);
      // adicionar um objeto com value - que será referenciado ao novo popup criado

      const popups = result.popups ? result.popups : [];
      createFormPopupImageStepper.setValue('popups', [{ name, value: 0 }, ...popups]);
    });
  }, [setList]);
  return (
    <LayoutRegister.Container>
      {!created ? (
        <LayoutRegister.Card
          description="Criando um novo pop-up"
          subMenu={subMenu}
          onBack={() => handleChangeStep(activeStep - 1)}
        >
          <CreatePopupImage handleChangeStep={handleChangeStep} routerBack={routerBack} />
        </LayoutRegister.Card>
      ) : (
        <LayoutRegister.Card
          backText="Criando um novo pop-up"
          subMenu={subMenu}
          onBack={() => navigate(routerBack, { replace: true })}
          confirmOnClick={false}
        >
          <StepCreated
            title={name ?? ''}
            name="pop-ups"
            route={`${routerBack}/gerenciador`}
            height="100%"
          >
            <Stack flex="1" breakpointDownInvert={device.laptopM} display={'flex'}>
              <PreviewPopupImage
                onChange={(image) => {
                  createFormPopupImageStepper.setValue('popupFile', image);
                  createFormPopupImageStepper.trigger('popupFile');
                }}
                popupFile={popupFile}
                disableEdit
              />
            </Stack>
          </StepCreated>
        </LayoutRegister.Card>
      )}
      <Stack flex="1" breakpointDown={device.laptopM} display={'flex'}>
        <PreviewPopupImage
          onChange={(image) => {
            createFormPopupImageStepper.setValue('popupFile', image);
            createFormPopupImageStepper.trigger('popupFile');
          }}
          popupFile={popupFile}
          disableEdit={created}
        />
      </Stack>
    </LayoutRegister.Container>
  );
};
