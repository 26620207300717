import { BannerCreate } from 'components/organisms/BannerCreate';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import { CreateBannerProvider } from 'hooks/banner';

export const TurboBannerCreate = () => {
  return (
    <CreateBannerProvider>
      <BannerCreate subMenu={subMenuTurbo} />
    </CreateBannerProvider>
  );
};
