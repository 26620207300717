import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';

interface IReactSelectElementProps {
  width?: string;
}
export const ReactSelectElement = styled(ReactSelect)<IReactSelectElementProps>`
  font-size: 12px;
  ${({ width }) => width && `width: ${width};`}

  .mySelect__control--is-focused {
    border: 1px solid ${(props) => props.theme.colors.secondary};
    box-shadow: none;
  }

  .mySelect__single-value {
    color: ${(props) => props.theme.colors.white};
  }

  .css-1s2u09g-control {
    background: ${(props) => props.theme.colors.white};
    border: 0.2px solid rgba(0, 0, 0, 0.34);
    min-height: 30px !important;
  }

  .css-319lph-ValueContainer {
    padding: 0 8px;
  }

  .css-1d8n9bt {
    padding: 0 8px !important;
  }
  .css-6j8wv5-Input {
    margin: 0;
  }

  .mySelect__control {
    min-height: 30px !important;
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 8px 0px 0px 8px;

    &:hover {
      border: 1px solid ${(props) => props.theme.colors.secondary};
    }
  }

  .mySelect__indicator-separator {
    display: none;
  }

  .css-1v5bs2s-control {
    background: ${(props) => props.theme.colors.white};
    border-radius: 12px;
    cursor: pointer;
    flex-wrap: inherit;
  }

  .css-9gakcf-option {
    background-color: ${(props) => props.theme.colors.secondary};
  }

  .fMzeIc {
    height: 0;
  }

  .css-14el2xx-placeholder {
    font-size: 12px;
  }

  .mySelect__option--is-selected {
    background: #696977;
  }
`;

export const DownChevron = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 0.625rem;
`;

// export const Flex = styled.div`
//   display: flex;
//   gap: 0.375rem;
//   align-items: center;
//   /* color: ${(props) => props.theme.colors.white}; */
//   width: 100%;
// `;

export const IconCheck = styled.img`
  width: 19px;
  height: 19px;
`;

export const Label = styled.span`
  font-weight: 400;
  /* font-size: 12px; */
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.secondary};
`;

interface IInputStyled {
  isError?: boolean;
}
export const InputStyled = styled.input<IInputStyled>`
  background: none;
  border: none;
  border-radius: none;
  height: 30px;
  width: 100%;
  padding: 0 10px;
  position: relative;
  font-size: 9px;

  &:focus {
    outline: none !important;
    border-color: none !important;
  }

  &::placeholder {
    color: #2e2b37;
    opacity: 0.35;
  }

  /*when disabled change color text with opacity 0.35 in text*/
  &:disabled {
    color: rgba(46, 43, 52, 0.35);
    cursor: not-allowed;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  ${({ isError }) =>
    isError &&
    css`
      color: ${(props) => props.theme.colors.error};
    `}
`;
