import { InputForm, RadioGroupForm, SelectCustomForm, Stack } from 'components/atoms';
import { linkTypeList } from 'helpers/enums';
import { useForm } from 'react-hook-form';

interface IChooseLinkProps {
  form: any;
  links: { value: number; label: string }[];
  nameFieldLinkType?: string;
  nameFiedLink?: string;
  nameFieldInternalLink?: string;
}
export function ChooseLink({
  form,
  links,
  nameFieldLinkType = 'linkType',
  nameFiedLink = 'link',
  nameFieldInternalLink = 'internalLink',
}: IChooseLinkProps) {
  const { control, watch, setValue } = form;
  const linkType = watch(nameFieldLinkType);

  return (
    <Stack direction="column">
      <RadioGroupForm
        options={linkTypeList}
        control={control}
        name={nameFieldLinkType}
        label="Tipo de link*"
        onChange={() => {
          setValue(nameFiedLink, '');
          setValue(nameFieldInternalLink, '');
        }}
        gap="0.3rem"
      />
      <Stack direction="column" width="100%">
        {linkType === 1 && (
          <InputForm
            name={nameFiedLink}
            label="Link*"
            type="text"
            placeholder="Digite aqui..."
            control={control}
            onChange={() => {
              setValue(nameFieldInternalLink, '');
            }}
          />
        )}
        {linkType === 2 && (
          <SelectCustomForm
            options={links}
            label="Selecione a página*"
            control={control}
            placeholder="Pesquisar..."
            name={nameFieldInternalLink}
            fixedHeight
            onChange={() => {
              setValue(nameFiedLink, '');
            }}
          />
        )}
      </Stack>
    </Stack>
  );
}
