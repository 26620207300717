import styled from 'styled-components';
import { StyledStack } from '../Stack';

export const Modal = styled(StyledStack)`
  /* The Modal (background) */
  z-index: 999991;
  display: ${(props) => (props.display ? props.display : 'none')};
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */

  padding-top: 50px;
  justify-content: center;
  align-items: center;
  /* Style the horizontal ruler */
  hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 1.5rem;
  }
`;

// export const ModalContent = styled.section<{
//   hasMessageInside?: boolean;
//   height?: string;
//   borderRadius?: string;
//   alignItems?: string;
//   width?: string;
// }>`
//   width: ${(props) => props.width};
//   position: relative;
//   overflow: hidden;
//   width: 100%;
//   height: ${(props) => props.height};

//   background: rgb(21 20 23 / 75%);
//   border-radius: 0.375rem 0.375rem 0px 0px;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   text-align: center;
//   align-items: ${(props) => props.alignItems};
//   padding: 1.875rem;
//   animation: fadeIn linear 0.5s;
//   -webkit-animation: fadeIn linear 0.5s;
//   -moz-animation: fadeIn linear 0.5s;
//   -o-animation: fadeIn linear 0.5s;
//   -ms-animation: fadeIn linear 0.5s;

//   @keyframes fadeIn {
//     0% {
//       opacity: 0;
//     }
//     100% {
//       opacity: 1;
//     }
//   }

//   @-moz-keyframes fadeIn {
//     0% {
//       opacity: 0;
//     }
//     100% {
//       opacity: 1;
//     }
//   }

//   @-webkit-keyframes fadeIn {
//     0% {
//       opacity: 0;
//     }
//     100% {
//       opacity: 1;
//     }
//   }

//   @-o-keyframes fadeIn {
//     0% {
//       opacity: 0;
//     }
//     100% {
//       opacity: 1;
//     }
//   }

//   @-ms-keyframes fadeIn {
//     0% {
//       opacity: 0;
//     }
//     100% {
//       opacity: 1;
//     }
//   }
// `;

export const IconClose = styled.img`
  width: 24px;
  cursor: pointer;
`;

interface IModalContentProps {
  width?: string;
  marginBottom?: string;
}
export const Description = styled.p<IModalContentProps>`
  width: ${(props) => (props.width ? props.width : '100%')};
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 120%;
  text-align: center;
  color: ${(props) => props.color ?? props.theme.colors.white};
  margin-bottom: ${(props) => props.marginBottom ?? '0'};
`;

export const Information = styled.p`
  font-size: 0.75rem;
  line-height: 120%;
  text-align: center;
  color: ${(props) => props.color ?? props.theme.colors.white};
  margin-top: 1.25rem;
`;

interface IButtonProps {
  background?: string;
  color?: string;
  borderRadius?: string;
}
export const Button = styled.button<IButtonProps>`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border-radius: ${(props) => props.borderRadius};
  width: 50%;
  box-shadow: 0px 0px 16.9476px rgba(0, 0, 0, 0.13);
  padding: 1.625rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 120%;
`;
