import { ButtonEdit, StatusDot } from 'components/atoms';
import { IMenuItem } from 'components/atoms/Menu';
import { HeaderListPictureDictinaryManagement, ListDefault } from 'components/molecules';
import { Layout } from 'components/templates/Layout';
import { generateKeyId, limitLenghtString } from 'helpers/functions';
import { useLoading } from 'hooks/loading';
import { useNotification } from 'hooks/notification';
import { IPictureDictionaryModel } from 'models';
import { useEffect, useState } from 'react';
import {
  GetAllPictureDictionary,
  GetExportPoctureDictionary,
} from 'requests/PictureDictionaryManagement';
import { EditPictureDictionaryManagement } from '../EditPictureDictionaryManagement';

interface IPictureDictionaryManagementProps {
  subMenu: IMenuItem[];
  routerBack: string;
}

export const PictureDictionaryManagement = ({
  subMenu,
  routerBack,
}: IPictureDictionaryManagementProps) => {
  const pageLimit = 5;
  const [search, setSearch] = useState('');
  const [pictures, setPictures] = useState<Array<Array<string | number | JSX.Element | undefined>>>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataEdit, setDataEdit] = useState<IPictureDictionaryModel | null>(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const columnsHeader = ['ID', 'Nome do Prêmio', 'Status', 'Tipo', 'Editar'];
  const notify = useNotification();
  const { handleHideLoading, handleShowLoading } = useLoading();

  const getStatusDescription = (status: boolean) => {
    return status
      ? { description: 'Ativo', key: 'Ativo', color: '#00D217' }
      : { description: 'Inativo', key: 'Inativo', color: '#FF8332' };
  };

  const formatColumns = (
    list: IPictureDictionaryModel[]
  ): Array<Array<string | number | JSX.Element | undefined>> => {
    try {
      return list.map((item) => [
        item?.idPictureDicionary,
        limitLenghtString(item?.name),
        <StatusDot
          description={getStatusDescription(item.active).description}
          color={getStatusDescription(item.active).color}
          key={generateKeyId()}
        />,
        item.typeAward,
        <ButtonEdit onClick={() => setDataEdit(item)} key={generateKeyId()} />,
      ]);
    } catch (error) {
      notify.error('Erro ao listar Dicionário', 'Tente novamente mais tarde.');
      return [];
    }
  };

  const GetAllPictureDictionaryData = async (
    _currentPage: number,
    _pageLimit: number,
    _searchText: string,
    _idTipoPremio: number | null
  ) => {
    setLoading(true);
    setPictures([]);
    await GetAllPictureDictionary({
      page: _currentPage,
      quantity: _pageLimit,
      name: _searchText,
      idTypeAward: _idTipoPremio,
    }).then(({ data, total }) => {
      setTotalRecords(total);
      setPictures(formatColumns(data));
    });
    setLoading(false);
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      GetAllPictureDictionaryData(currentPage, pageLimit, search, typeFilter);
    }, 300);
    return () => clearTimeout(delayDebounce);
  }, [search, currentPage, typeFilter]);

  const handleClickExport = async () => {
    try {
      handleShowLoading();
      const { base64 } = await GetExportPoctureDictionary();
      if (base64) {
        const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
        const link = document.createElement('a');
        link.href = file;
        link.target = '_blank';
        link.download = 'Dicionario.xlsx';
        link.click();
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleHideLoading();
    }
  };
  return (
    <>
      <Layout.Container>
        <Layout.Page width="740px" height="547px">
          <Layout.Header subMenu={subMenu} />
          <Layout.Body.Root>
            <Layout.Body.Back routerBack={routerBack} />
            <HeaderListPictureDictinaryManagement
              search={search}
              setSearch={setSearch}
              typeFilter={typeFilter}
              setTypeFilter={setTypeFilter}
              handleClickExport={handleClickExport}
            />
            <ListDefault
              name="dicionário"
              columnsHeader={columnsHeader}
              totalRecords={totalRecords}
              loading={loading}
              list={pictures}
              setCurrentPage={setCurrentPage}
              showHeader={false}
              pageLimit={pageLimit}
            />
          </Layout.Body.Root>
        </Layout.Page>
      </Layout.Container>
      {dataEdit && (
        <EditPictureDictionaryManagement
          data={dataEdit}
          setDataEdit={setDataEdit}
          GetAllPictureDictionaryData={GetAllPictureDictionaryData}
          dataGetAllPictureDictionaryData={{
            currentPage,
            pageLimit,
            search,
            typeFilter,
          }}
        />
      )}
    </>
  );
};
