import { Stack } from 'components/atoms';

interface ISearchWithSelectContainerProps {
  children: React.ReactNode;
}

export const SearchWithSelectContainer: React.FC<ISearchWithSelectContainerProps> = ({
  children,
}) => {
  return (
    <Stack alignItems="center" width="100%">
      {children}
    </Stack>
  );
};
