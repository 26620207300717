import { PopupButtonCreate } from 'components/organisms';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import { PopupButtonProvider } from 'hooks/popupButton';
import { turboPopupsURL } from 'routers/routes';

export const TurboPopupButtonCreate = () => {
  return (
    <PopupButtonProvider>
      <PopupButtonCreate subMenu={subMenuTurbo} routerBack={turboPopupsURL} />
    </PopupButtonProvider>
  );
};
