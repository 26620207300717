import { CustomErrorApi } from 'helpers/customErrorApi';
import { fileToBase64, formatDate } from 'helpers/functions';
import { IAPIResponse } from 'models/dtos/api-response.model';
import { IPaginationModel } from 'models/dtos/pagination.model';
import { IPopupBaseResponseModel } from 'models/dtos/popup-base.model';
import {
  IPopupButtonButtonsModel,
  IPopupButtonModel,
  IPopupImagemModel,
  IPopupsModel,
} from 'models/entities/popup-base.model';

import { api } from '../services/api';

export const GetAllPopups = async (
  page: number,
  quantity: number,
  name: string
): Promise<IPaginationModel<IPopupsModel[]>> => {
  try {
    const response = await api.get<
      IAPIResponse<{
        quantidade_total: number;
        popups: IPopupBaseResponseModel[];
      }>
    >('/popup/listar', {
      params: { pagina: page, quantidade: quantity, nome: name },
    });
    const { quantidade_total, popups } = response.data.Dado;

    const data: IPopupsModel[] = popups.map((item) => {
      const popupButtons: IPopupButtonButtonsModel[] = item.botoes_popups.map((button) => ({
        action: button.tipo_acao,
        idColor: Number(button.cor),
        text: button.texto,
        idLinkInternal: button.id_link_interno,
        link: button.link,
        nameFileBucket: button.nome_arquivo_bucket,
        linkFile: button.link_arquivo,
      }));
      const defineDate = item.tipo_vigencia === 2 ? 2 : 1;
      const _startDate =
        item.tipo_vigencia === 2 ? new Date() : item.data_inicio && new Date(item.data_inicio);
      const _endDate = defineDate === 2 ? new Date() : item.data_fim && new Date(item.data_fim);
      return {
        // comum properties
        id: item.id_popup,
        active: item.status === 'Ativo' || item.status === 'Agendado',
        type: item.id_tipo,
        viewOrder: item.ordem_visualizacao,
        name: item.nome,
        startDate: _startDate,
        endDate: _endDate,
        endDateUndefined: item.data_indefinida,
        defineDate,
        classificationPeriod: item.tipo_classificacao,
        planCondition: item.tipo_pontuacao,
        planPoints: item.pontuacao,
        IdsWhichBranches: item.filiais,
        IdsSegments: item.segmentos,
        IdsWhichClassifications: item.categorias,
        customers: item.clientes,
        validity: item.vigencia,
        // popupButton properties
        popupButtonSubTitle: item.conteudo,
        popupButtonTitle: item.titulo,
        popupButtonIcon: item.id_icone,
        popupButtons,
        // popupImage properties
        image: item.imagem,
        action: item.tipo_acao,
        link: item.link,
        linkType: item.link ? 1 : item.id_link_interno ? 2 : null,
        IdInternalLink: item.id_link_interno,
        actionLinkFile: item.link_arquivo,
        nameFileBucket: item.nome_arquivo_bucket,
        nameFileClient: item.nome_arquivo_cliente,
        status: item.status,
      } as IPopupsModel;
    });

    return {
      data,
      total: quantidade_total,
    };
  } catch (_) {
    throw new Error('Verifique sua conexão e tente novamente.');
  }
};

const getBranchesSelected = (whichBranches: { value: number; label: string }[]) => {
  if (whichBranches.find((item) => item.value === 0)) {
    return null;
  } else {
    return whichBranches.map((branch) => branch.value);
  }
};

const getClassificationsSelected = (whichClassifications: { value: number; label: string }[]) => {
  if (whichClassifications.find((item) => item.value === 0)) {
    return null;
  } else {
    return whichClassifications.map((category) => category.value);
  }
};

const getSegmentsSelected = (segments: { value: number; label: string }[]) => {
  if (segments.find((item) => item.value === 0)) {
    return null;
  } else {
    return segments.map((segment) => segment.value);
  }
};

export const PostPopupImage = async (payload: IPopupImagemModel): Promise<boolean> => {
  try {
    const internalData = {
      // popup imagem
      IdTipo: 2,
      ImagemBase64: await fileToBase64(payload.popupFile),
      NomeImagem: payload.popupFile?.name,
      // step 1
      Nome: payload.name,
      TipoAcao: payload.action,
      TipoLink: payload.linkType,
      IdLinkInterno: payload?.internalLink?.value,
      LinkExterno: payload.link,
      LinkArquivo: payload.actionLinkFile,
      NomeArquivo: payload.actionFile?.name,
      ArquivoBase64: await fileToBase64(payload.actionFile),
      // step 2
      OrdemVisualizacao: payload.popups?.map((popup, index) => ({
        id: popup.value,
        ordem: index + 1,
      })),
      // step 3
      // vigencia:
      // ComVigencia = 1,
      // PeriodoResgate = 2,
      // SemExpiracao = 3
      TipoVigencia: payload.endDateUndefined ? 3 : payload.defineDate,
      // data inicio e data fim nao poderá ser passados quando periodo regaste for selecionado
      // data inicio deve ser passada quando tiver vigencia ou sem SemExpiracao
      DataInicio:
        payload.defineDate === 1 || payload.endDateUndefined ? formatDate(payload.startDate) : null,
      // data final deverá ser passada apenas quando tiver vigencia e com expiração
      DataFim: payload.defineDate === 1 ? formatDate(payload.endDate) : null,
      // step 4
      // passar arquivo csv dos CNPJS quando opção Fazer upload de uma lista for selecionado
      ClientesBase64: payload.whoCanSee === 2 ? await fileToBase64(payload.csvFile) : null,
      // passar nome do arquivo csv dos CNPJS quando opção Fazer upload de uma lista for selecionado
      NomeArquivoCliente: payload.whoCanSee === 2 ? payload.csvFile?.name : null,
      TipoClassificacao: payload.classificationPeriod,
      Filiais: getBranchesSelected(payload?.whichBranches ?? []),
      Categorias: getClassificationsSelected(payload?.whichClassifications ?? []),
      Segmentos: getSegmentsSelected(payload?.segments ?? []),
      TipoPontuacao: payload.planCondition,
      ValorPontuacao: payload.planPoints,
    };

    const response = await api.post<IAPIResponse<void>>('popup/incluir', internalData);
    const notifications = response.data.Notificacoes;
    if (response.data.HttpStatus !== 200) {
      if (notifications && notifications?.length > 0) {
        throw new CustomErrorApi(notifications[0].Mensagem);
      } else {
        throw new Error('Não foi possível criar o popup.');
      }
    }
    return true;
  } catch (error) {
    if (error instanceof CustomErrorApi) {
      throw new Error(error.message);
    } else throw new Error('Não foi possível criar a imagem.');
  }
};

const getDataPopupButton = async (popupButton: IPopupButtonButtonsModel) => {
  return {
    Texto: popupButton.text,
    Cor: popupButton.idColor,
    // TipoAcao
    // - Link = 1,
    // - Arquivo = 2,
    // - NenhumaAcao = 3,
    // - Fechar = 4
    TipoAcao: popupButton.action,
    // TipoLink
    // - LinkExterno = 1,
    // - PaginaInterna = 2
    TipoLink: popupButton.action === 1 ? popupButton.linkType : null,
    LinkExterno: popupButton.action === 1 ? popupButton.link : null,
    IdLinkInterno: popupButton.linkType === 2 ? popupButton?.internalLink?.value : null,
    LinkArquivo: popupButton.actionLinkFile,
    // ou
    NomeArquivo: popupButton.action === 2 ? popupButton.actionFile?.name : null,
    ArquivoBase64: popupButton.action === 2 ? await fileToBase64(popupButton.actionFile) : null,
  };
};

export const PostPopupButton = async (payload: IPopupButtonModel): Promise<boolean> => {
  try {
    const getDataPopupButtons = async () => {
      if (payload.popupButtonCount === 1) {
        return [await getDataPopupButton(payload.popupButtons[0])];
      } else if (payload.popupButtonCount === 2) {
        return [
          await getDataPopupButton(payload.popupButtons[0]),
          await getDataPopupButton(payload.popupButtons[1]),
        ];
      }
      return [];
    };
    const internalData = {
      // step 1
      // - PopupBotao = 1,
      // - PopupImagem = 2
      IdTipo: 1,
      Nome: payload.popupButtonName,
      Icone: payload.popupButtonIcon, // inteiro
      Titulo: payload.popupButtonTitle,
      Conteudo: payload.popupButtonSubTitle,
      // step 2
      Botao: await getDataPopupButtons(),
      // step 3
      OrdemVisualizacao: payload.popups?.map((banner, index) => ({
        id: banner.value,
        ordem: index + 1,
      })),
      // step 4
      // TipoVigencia
      // - Definida = 1,
      // - PeriodoResgate = 2,
      // - Indefinida = 3
      TipoVigencia: payload.endDateUndefined ? 3 : payload.defineDate,
      DataInicio:
        payload.defineDate === 1 || payload.endDateUndefined ? formatDate(payload.startDate) : null,
      DataFim: payload.defineDate === 1 ? formatDate(payload.endDate) : null,
      // step 5
      ClientesBase64: payload.whoCanSee === 2 ? await fileToBase64(payload.csvFile) : null,
      NomeArquivoCliente: payload.whoCanSee === 2 ? payload.csvFile?.name : null,
      Filiais: getBranchesSelected(payload?.whichBranches ?? []),
      // TipoClassificacao
      // - PeriodoAtual = 1,
      // - PeriodoPassado = 2
      TipoClassificacao: payload.classificationPeriod,
      Categorias: getClassificationsSelected(payload?.whichClassifications ?? []),
      Segmentos: getSegmentsSelected(payload?.segments ?? []),
      TipoPontuacao: payload.planCondition,
      ValorPontuacao: payload.planCondition ? payload.planPoints : null,
    };
    const response = await api.post<IAPIResponse<void>>('popup/incluir', internalData);
    const notifications = response.data.Notificacoes;
    if (response.data.HttpStatus !== 200) {
      if (notifications && notifications?.length > 0) {
        throw new CustomErrorApi(notifications[0].Mensagem);
      } else {
        throw new Error('Não foi possível criar o popup.');
      }
    }
    return true;
  } catch (error) {
    if (error instanceof CustomErrorApi) {
      throw new Error(error.message);
    } else throw new Error('Não foi possível criar a imagem.');
  }
};

export const EditPopup = async (payload: IPopupsModel): Promise<boolean> => {
  try {
    const getDataPopupButton = async (popupButton: IPopupButtonButtonsModel) => {
      return {
        Texto: popupButton.text,
        Cor: popupButton.idColor,
        TipoAcao: popupButton.action,
        TipoLink: popupButton.action === 1 ? popupButton.linkType : null,
        LinkExterno: popupButton.action === 1 ? popupButton.link : null,
        IdLinkInterno: popupButton.linkType === 2 ? popupButton.internalLink?.value ?? null : null,
        // LinkArquivo:
        //   popupButton.actionLinkFile ??
        //   popupButton.actionLinkFileBucket ??
        //   null,
        LinkArquivo: popupButton.actionLinkFile
          ? popupButton.actionLinkFile
          : popupButton.nameFileBucket && popupButton.linkFileBucket
            ? popupButton.linkFileBucket
            : null,
        NomeArquivoBucket: popupButton.nameFileBucket ?? null,
        NomeArquivo: popupButton.action === 2 ? popupButton.actionFile?.name ?? null : null,
        ArquivoBase64:
          popupButton.action === 2 && popupButton.actionFile?.size
            ? await fileToBase64(popupButton.actionFile)
            : null,
      };
    };
    const getDataPopupButtons = async () => {
      if (payload.popupButtonCount === 1) {
        return [await getDataPopupButton(payload.popupButtons[0])];
      } else if (payload.popupButtonCount === 2) {
        return [
          await getDataPopupButton(payload.popupButtons[0]),
          await getDataPopupButton(payload.popupButtons[1]),
        ];
      }
      return [];
    };

    const internalData = {
      //
      IdPopup: payload.id,
      IdTipo: payload.type,
      Nome: payload.popupButtonName,
      Icone: payload.popupButtonIcon,
      Titulo: payload.popupButtonTitle,
      Conteudo: payload.popupButtonSubTitle,

      Botao: await getDataPopupButtons(),
      OrdemVisualizacao: payload.popups?.map((banner, index) => ({
        id: banner.value,
        ordem: index + 1,
      })),
      TipoVigencia: payload.endDateUndefined ? 3 : payload.defineDate,
      DataInicio:
        payload.defineDate === 1 || payload.endDateUndefined ? formatDate(payload.startDate) : null,

      DataFim: payload.defineDate === 1 ? formatDate(payload.endDate) : null,
      // só passo os dados do arquivo se foi feito um novo upload
      ClientesBase64:
        payload.whoCanSee === 2
          ? payload?.csvFile?.size
            ? await fileToBase64(payload.csvFile)
            : null
          : null,

      NomeArquivoCliente:
        payload.whoCanSee === 2
          ? // ? data.csvFile?.size
          payload.csvFile?.name
          : // : null
          null,
      clientes: payload.whoCanSee === 2 ? payload.customers : [],
      Filiais: getBranchesSelected(payload?.whichBranches ?? []),
      // TipoClassificacao
      // - PeriodoAtual = 1,
      // - PeriodoPassado = 2
      TipoClassificacao:
        getClassificationsSelected(payload?.whichClassifications ?? []) == null
          ? null
          : payload.classificationPeriod,
      Categorias: getClassificationsSelected(payload?.whichClassifications ?? []),
      Segmentos: getSegmentsSelected(payload?.segments ?? []),
      TipoPontuacao: payload.planCondition,
      pontuacao: payload.planCondition ? payload.planPoints : null,

      TipoAcao: payload.action,
      TipoLink: payload.linkType,
      IdLinkInterno: payload?.internalLink && payload?.internalLink?.value,
      LinkExterno: payload.link,
      NomeArquivoBucket:
        payload.action === 2 && payload.nameFileBucket ? payload.nameFileBucket : null,

      // hash ou link do arquivo
      LinkArquivo:
        payload.action === 2
          ? payload.actionLinkFile
            ? payload.actionLinkFile
            : payload.nameFileBucket && payload.linkFileBucket
              ? payload.linkFileBucket
              : null
          : null,

      ArquivoBase64: payload.action === 1 && payload.nameFileBucket ? payload.nameFileBucket : null,
      NomeArquivo: payload.action === 2 ? payload.actionFile?.name ?? null : null,
      LinkArquivoBase64: payload.action === 2 ? await fileToBase64(payload.actionFile) : null,

      ImagemAlterada: !!payload.popupFile,
      ImagemBase64: payload.popupFile ? await fileToBase64(payload.popupFile) : null,
      nomeImagem: payload.popupFile ? payload.popupFile?.name : null,
      ativo: payload.active,
    };
    const response = await api.post<IAPIResponse<void>>('popup/editar', internalData);
    const notifications = response.data.Notificacoes;
    if (response.data.HttpStatus !== 200) {
      if (notifications && notifications?.length > 0) {
        throw new CustomErrorApi(notifications[0].Mensagem);
      } else {
        throw new Error('Não foi possível editar o pop-up.');
      }
    }
    return true;
  } catch (error) {
    if (error instanceof CustomErrorApi) {
      throw new Error(error.message);
    } else throw new Error('Não foi possível editar o pop-up.');
  }
};
