import styled from 'styled-components';

interface IWrap {
  marginTop?: string;
  width?: string;
}
export const Wrap = styled.div<IWrap>`
  ${({ marginTop }) => marginTop && `margin-top:${marginTop}`};
  div {
    .Title {
      font-weight: 400;
      font-size: 0.75rem;
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  input {
    border: 0.2px solid rgba(0, 0, 0, 0.34);
    border-radius: 0.375rem;
    width: 100%;
    padding: 8px 10px;
    width: ${({ width }) => width};
    :disabled {
      color: rgba(105, 105, 119, 0.51);
      cursor: not-allowed;
    }
  }
`;
