import { Stack, TextEditor } from 'components/atoms';
import { useForm } from 'react-hook-form';
import { device } from 'styles/breakpoint';

import { PreviewButtonComponent } from '../PreviewButtonComponent';
import {
  SelectNamePopupButton,
  SelectPopupButtonIcons,
  SelectTitlePopupButton,
} from './components';

interface IModalEditPopupButtonProps {
  form: any;
  hiddenName?: boolean;
  showPreview?: boolean;
}
export const ModalEditPopupButton: React.FC<IModalEditPopupButtonProps> = ({
  form,
  hiddenName,
  showPreview,
}) => {
  return (
    <Stack direction="column" width="100%" data-testid="ModalEditPopupButton">
      {showPreview && (
        <Stack breakpointUp={device.laptopM} marginTop="0.5rem" marginBottom="0.5rem">
          <PreviewButtonComponent form={form} />
        </Stack>
      )}
      {!hiddenName && <SelectNamePopupButton form={form} />}
      <SelectPopupButtonIcons form={form} />
      <SelectTitlePopupButton control={form.control} />
      {/* <SelectSubTitlePopupButton form={form} /> */}
      <TextEditor
        label="Conteúdo do pop-up*"
        showCurrentCount
        placeholder="Insira o conteúdo do pop-up..."
        onChangeHTML={(value) => {
          form.setValue('popupButtonSubTitle', value, { shouldValidate: true, shouldDirty: true });
        }}
        defaultValue={form.getValues('popupButtonSubTitle')}
        maxLength={160}
      />
    </Stack>
  );
};
