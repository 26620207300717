import { Stack } from 'components/atoms';
import { Icon } from 'globalStyles';
import { useNavigate } from 'react-router-dom';
import { ButtonMenuHorizontal, ContainerMenuHorizontal } from './styled';
import { useTheme } from 'hooks/useTheme';
interface IMenu {
  id: number;
  name: string;
  icon: string;
  backgroundColor: string | { gradient: string };
  route: string;
  thema: string;
}
interface IMenuItem {
  menus: IMenu[];
}

export const MenuHorizontal = ({ menus }: IMenuItem) => {
  const navigate = useNavigate();
  const { currentTheme } = useTheme();

  const clickButtonMenu = (route: string) => {
    navigate(route);
  };

  return (
    <ContainerMenuHorizontal>
      {menus.map((item) => {
        const background =
          typeof item.backgroundColor === 'string'
            ? item.backgroundColor
            : item.backgroundColor.gradient;
        return (
          <ButtonMenuHorizontal
            background={background}
            key={`item-menu-horizontal-${item.id}`}
            onClick={() => clickButtonMenu(item.route)}
            data-testid={`item-menu-horizontal-${item.id}`}
            boxShadow={
              currentTheme.theme !== item.thema
                ? '-11px 0 5px -5px rgba(0, 0, 0, 0.5) inset'
                : 'none'
            }
          >
            <Icon src={item.icon} width="20px" height="20px" cursor="pointer" />
          </ButtonMenuHorizontal>
        );
      })}
      <Stack
        direction="column"
        gap="6px"
        flex="1"
        justifyContent="flex-end"
        paddingRight="8px"
        paddingBottom="18px"
      >
        <Icon src="/assets/images/sistemas.svg" height="130px" width="30px" marginBottom="15px" />
        <Icon src="/assets/images/logo-backoffice-fidelidade.svg" height="150px" width="30px" />
      </Stack>
    </ContainerMenuHorizontal>
  );
};
