import { Stack } from 'components/atoms';
import Title from 'components/atoms/Title';

import { LabelTitle } from './styled';
import { useTheme } from 'hooks/useTheme';

interface ITitleAndLabeL {
  title: string;
  label: string;
}
export const TitleAndLabel: React.FC<ITitleAndLabeL> = ({ title, label }): JSX.Element => {
  const { currentTheme } = useTheme();
  return (
    <Stack marginBottom="1rem" direction="column">
      <LabelTitle>{label}</LabelTitle>
      <Title label={title} color={currentTheme.colors.secondary} />
    </Stack>
  );
};
