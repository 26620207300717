import { backofficeHomeURL, chamasHomeURL, turboHomeURL } from 'routers/routes';

export const menuHorizontalItems = [
  {
    id: 1,
    name: 'Turbo',
    thema: 'themeTurbo',
    icon: '/assets/icons/icon_mais_fidelidade.svg',
    backgroundColor: 'black',
    route: turboHomeURL,
  },
  {
    id: 2,
    name: 'Chamas',
    thema: 'themeChamas',
    icon: '/assets/icons/logo-chamas.svg',
    backgroundColor: {
      gradient:
        'linear-gradient(80.26deg, #EC0928 -16.43%, #2F80ED 81.05%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))',
    },
    route: chamasHomeURL,
  },
  {
    id: 3,
    name: 'Home',
    thema: 'themeBackoffice',
    icon: '/assets/icons/logo-home.svg',
    backgroundColor: '#F1F1F1',
    route: backofficeHomeURL,
  },
];
