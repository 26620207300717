import { Stack } from 'components/atoms';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

export const StackContainer = styled(Stack)`
  width: 100%;

  /* margin-left: 52px;

  @media (min-width: ${device.laptopM}) {
    margin-left: 0;
  } */
`;

export const StackImageContainer = styled(Stack)`
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  @media (max-width: ${device.laptopM}) {
    width: 443px;
    height: 211px;
  }
  @media (max-width: ${device.mobileL}) {
    width: 100%;
    height: 168px;
  }
  @media (max-width: ${device.mobileM}) {
    width: 100%;
    height: 155px;
  }
`;

export const StackImage = styled(Stack)`
  width: 500px;
  height: 238px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: ${device.laptopM}) {
    flex: 1;
    width: 100%;
    height: 211px;
    background-size: contain;
    background-position: center;
  }
  @media (max-width: ${device.mobileL}) {
    height: 168px;
  }
  @media (max-width: ${device.mobileM}) {
    height: 155px;
  }
`;
