import { useTheme } from 'hooks/useTheme';
import { useState } from 'react';
import Stack from '../Stack';
import Title from '../Title';
import { CardImage, IconNext } from './styled';
import useIconThemes from 'hooks/iconThemes';

interface ICardWithImageProps {
  index?: string;
  img?: string;
  icon?: string;
  iconHover?: string;
  title?: string;
  subtitle?: string;
  onClick: () => void;
  active?: boolean;
  customHover?: boolean;
}

const CardWithImage = (props: ICardWithImageProps) => {
  const { index, img, icon, iconHover, title, subtitle, onClick, active, customHover } = props;
  const [hover, setHover] = useState(false);
  const { currentTheme } = useTheme();
  const iconThemes = useIconThemes();

  function handleClick() {
    onClick();
  }

  return (
    <CardImage
      key={index}
      onClick={handleClick}
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      active={active}
      data-testid="card-image"
      theme={currentTheme}
      customHover={customHover}
    >
      <div className={'blue-space'} />
      <img className="img" src={img} alt={'img-option-popup-type'} />

      <Stack
        direction="column"
        justifyContent="space-between"
        padding="17px 24px 24px 24px"
        flex="1"
        gap="8px"
      >
        <div>
          {icon && (
            <img
              className="icon"
              src={active ? iconHover : hover ? iconHover : icon}
              alt={'icon-option-popup-type'}
            />
          )}
          <Title
            label={title}
            color={
              active
                ? currentTheme.colors.backgroundWhite
                : hover
                ? currentTheme.colors.backgroundWhite
                : currentTheme.colors.secondary
            }
          />
          <p>{subtitle}</p>
        </div>
        <IconNext
          src={
            hover
              ? iconThemes?.[currentTheme.theme].IconEnterHover
              : iconThemes?.[currentTheme.theme].IconEnter
          }
        />
      </Stack>
    </CardImage>
  );
};

export default CardWithImage;
