import styled from 'styled-components';
export interface ITextTitle {
  fontWeight?: string;
  fontSize?: string;
  marginBottom?: string;
  marginRight?: string;
  color?: string; 
}

export const TextTitle = styled.h2<ITextTitle>`
  font-weight: ${({ fontWeight }) => fontWeight ?? '700'};
  font-size: ${({ fontSize }) => fontSize ?? '1rem'};
  line-height: 120%;
  color: ${({ theme, color }) => color ?? theme.colors.secondary}; 
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '0.25rem;'};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
`;
