import React from 'react';
import Information from '../Information';
import { Wrap } from './styled';
import { useTheme } from 'hooks/useTheme';

interface IInputLabel extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  placeholder?: string;
  id?: string;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  marginTop?: string;
  information?: string;
  label?: string;
  value?: string;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const InputLabel: React.FC<IInputLabel> = ({
  title,
  placeholder,
  id,
  type,
  onChange,
  marginTop,
  information,
  label,
  value,
  children,
  disabled,
  ...props
}): JSX.Element => {
  const { currentTheme } = useTheme();

  return (
    <Wrap {...props} marginTop={marginTop}>
      <div style={{ display: 'flex', marginBottom: '5px' }}>
        <p className="Title">{title}</p>
        {information && (
          <Information
            label={label}
            tooltipTitle="Obrigatório"
            tooltipLabel={information}
            labelColor={currentTheme.colors.secondary}
            tooltipColor={currentTheme.colors.white}
            width='300px'
          />
        )}
      </div>
      {children ?? (
        <input
          placeholder={placeholder}
          id={id}
          type={type}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      )}
    </Wrap>
  );
};
