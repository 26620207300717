import { SelectCustomForm } from 'components/atoms';
import { useForm } from 'react-hook-form';

interface IFiliaisSeeProps {
  form: any;
  label: string;
  branchs: { value: number; label: string }[];
}

export const FiliaisSee: React.FC<IFiliaisSeeProps> = ({ form, branchs, label }) => {
  return (
    <SelectCustomForm
      label={`Quais filiais poderão ver o ${label}?*`}
      control={form.control}
      name="whichBranches"
      showSelectAll
      isMulti
      options={branchs}
      defaultValue={{ value: 0, label: 'Todas' }}
    />
  );
};
