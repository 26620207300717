import styled from 'styled-components';

interface ILabelProps {
  fontWeight?: string;
  fontSize?: string;
  color?: string;
  marginTop?: string;
  marginBottom?: string;
  letterSpacing?: string;
  lineHeight?: string;
}
export const LabelCustom = styled.p<ILabelProps>`
  font-weight: ${({ fontWeight }) => fontWeight ?? '400'};
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  color: ${({ color, theme }) => color ?? theme.colors.secondary};
  ${({ marginTop }) => marginTop && `margin-top:${marginTop}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom:${marginBottom}`};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing:${letterSpacing}`};
  ${({ lineHeight }) => lineHeight && `line-height:${lineHeight}`};
`;
