import { TablePin } from 'components/molecules';
import { Layout } from 'components/templates/Layout';
import { subMenuChamas } from 'constants/subMenuChamas';
import { useChamasAnalytical } from 'hooks/useChamasAnalytical';
import { useEffect } from 'react';
import { ChamasAnalyticalFilter } from './ChamasAnalyticalFilter';
import { StackContainer } from './styled';

export const ChamasAnalytical = () => {
  const { dataReport, getDataTableAnalytical, showMore, gridApiRef } = useChamasAnalytical();
  const { header, rows, loading } = dataReport;

  useEffect(() => {
    document.title = 'Analítico | Chamas | Backoffice Lojas';
  }, []);

  const getShowMore = async () => {
    return getDataTableAnalytical(true);
  };

  return (
    <Layout.Container>
      <Layout.Page width="1100px" height="max-content">
        <StackContainer>
          <Layout.Header subMenu={subMenuChamas} />
          <Layout.Body.Root>
            <ChamasAnalyticalFilter />

            <TablePin
              rows={rows}
              columns={rows ? header : undefined}
              getShowMore={getShowMore}
              showMore={showMore}
              loadingTable={loading}
              gridApiRef={gridApiRef}
            />
          </Layout.Body.Root>
        </StackContainer>
      </Layout.Page>
    </Layout.Container>
  );
};
