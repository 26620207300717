import ButtonBack from 'components/atoms/ButtonBack';
import { useNavigate } from 'react-router-dom';

interface ILayoutBackProps {
  description?: string;
  backText?: string;
  onBack?: () => void;
  routerBack?: string;
}

export const LayoutBack = ({ description, backText, onBack, routerBack }: ILayoutBackProps) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onBack) return onBack();
    if (routerBack) return navigate(routerBack);
    navigate(-1);
  };

  return <ButtonBack description={description} text={backText} onBack={handleBack} padding="0" />;
};
