import { FooterTip, Stack } from 'components/atoms';
import { TitleAndLabel } from 'components/molecules';
import { SelectFileCarouselBackground } from 'components/molecules/CarouselBackground/SelectFileCarouselBackground';
import { SelectNameCarouselBackground } from 'components/molecules/CarouselBackground/SelectNameCarouselBackground';
import { SelectPeriodCarouselBackground } from 'components/molecules/CarouselBackground/SelectPeriodCarouselBackground';
import { useCarouselBackground } from 'hooks/carouselBackground';
import { device } from 'styles/breakpoint';

import { PreviewCarouselBackground } from '../PreviewCarouselBackground';

interface ICreateCarouselBackgroundContentProps {
  routerBack: string;
}

export const CreateCarouselBackgroundContent = ({
  routerBack,
}: ICreateCarouselBackgroundContentProps): JSX.Element => {
  const { createBackgroundForm, handleClose, loading, handleCreateCarouselBackground, isValid } =
    useCarouselBackground();

  const advance = () => {
    void handleCreateCarouselBackground();
  };

  const [backgroundFile] = createBackgroundForm.watch(['backgroundFile']);

  return (
    <>
      <Stack direction="column" padding="0.4375rem 0.9375rem 0 0.9375rem" flex="1" overflow="auto">
        <Stack
          direction="column"
          padding="0.4375rem 0.9375rem 0 0.9375rem"
          flex="1"
          overflow="auto"
        >
          <Stack
            flex="1"
            justifyContent="center"
            alignItems="center"
            breakpointDownInvert={device.laptopM}
          >
            <PreviewCarouselBackground file={backgroundFile} />
          </Stack>
          <Stack direction="column" paddingBottom="12px" scrollbarborder="#f1f1f1" marginTop="1rem">
            <TitleAndLabel
              label="Personalizando o Carrossel"
              title="Alterar plano de fundo do Carrossel"
            />
            <SelectNameCarouselBackground form={createBackgroundForm} />
            <SelectFileCarouselBackground form={createBackgroundForm} />
            <SelectPeriodCarouselBackground form={createBackgroundForm} />
          </Stack>
        </Stack>
      </Stack>

      <FooterTip
        tip={'O tamanho de imagem apropriado para um plano de fundo é de 1100px x 650px.'}
        advance={() => advance()}
        disabledAdvance={!isValid() || loading}
        cancel={() => handleClose(routerBack)}
        buttonFinalize
        buttonLabel="Publicar"
      />
    </>
  );
};
