import styled from 'styled-components';
import { useTheme } from 'hooks/useTheme';

import { StyledStack } from '../Stack';

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.375rem;
`;

interface IItemProps {
  active?: boolean;
}

export const Itens = styled.button<IItemProps>`
  height: 3px;
  background: ${({ active, theme }) => (active ? theme.colors.primary : theme.colors.secondary)};
  opacity: ${({ active }) => (active ? '100%' : '50%')};
  width: 100%;
  border-radius: 0.5rem;
  :disabled {
    cursor: not-allowed; 
  }
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.primary};
`;

export const SubTitle = styled.p`
  font-weight: 700;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.secondary};
`;

interface IItemStackProps {
  disabled?: boolean;
}

export const ItemStack = styled(StyledStack) <IItemStackProps>`
  flex: 1;
  margin-right: 10px;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  :last-child {
    margin-right: 0px;
  }
`;
