import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { ContainerMenu } from './styled';
import { useConfirm } from 'hooks/useConfirm';

export interface IMenuItem {
  id: number;
  label: string;
  url: string;
}

export interface IMenu {
  menuList: IMenuItem[];
  confirmOnClick?: boolean;
}

export const Menu = ({ menuList, confirmOnClick }: IMenu) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { confirm } = useConfirm();

  const handleClick = (url: string) => {
    if (confirmOnClick) {
      void confirm()
        .then(() => {
          navigate(url);
        })
        .catch((_) => {});
    } else {
      navigate(url);
    }
  };

  return (
    <ContainerMenu>
      {menuList.map((item) => (
        <NavLink
          key={`item-menu-${item.id}`}
          to={item.url}
          onClick={(e) => {
            e.preventDefault();
            handleClick(item.url);
          }}
          data-testid={`item-menu-${item.id}`}
          className={location.pathname.includes(item.url) ? 'button-menu-active' : ''}
        >
          {item.label}
        </NavLink>
      ))}
    </ContainerMenu>
  );
};
