import { RadioGroupForm } from 'components/atoms';
import { carouselPictureType } from 'helpers/enums';
import { IPictureDictionaryModel } from 'models';
import { useForm } from 'react-hook-form';

interface ISelectTypeCarouselPictureDicionaryProps {
  form: ReturnType<typeof useForm<IPictureDictionaryModel>>;
}
export const SelectTypeCarouselPictureDicionary: React.FC<
  ISelectTypeCarouselPictureDicionaryProps
> = ({ form }): JSX.Element => {
  const { setValue, control } = form;
  const onChange = () => {
    setValue('dictinaryFile', undefined, { shouldValidate: true });
  };
  return (
    <>
      <RadioGroupForm
        options={carouselPictureType}
        control={control}
        name="idTypeAward"
        label="Tipo de prêmio*"
        tooltipTitle="Obrigatório"
        tooltipLabel="Utilize o tipo Normal para produtos ou tipo Campanha para benefícios e campanhas internas"
        onChange={onChange}
      />
    </>
  );
};
