import { RadioGroupForm, SelectCustomForm, Stack } from 'components/atoms';
import { useForm } from 'react-hook-form';

interface IClassificationSeeProps {
  form: any;
  label: string;
  classificationPeriodList: { value: number; label: string }[];
  categories: { value: number; label: string }[];
}

export const ClassificationSee: React.FC<IClassificationSeeProps> = ({
  form,
  label,
  classificationPeriodList,
  categories,
}) => {
  return (
    <Stack direction="column">
      <RadioGroupForm
        label={`Quais classificações poderão ver o ${label}?*`}
        name="classificationPeriod"
        control={form.control}
        options={classificationPeriodList}
      />
      <SelectCustomForm
        control={form.control}
        name="whichClassifications"
        showSelectAll
        isMulti
        options={categories}
        defaultValue={{ value: 0, label: 'Todas' }}
      />
    </Stack>
  );
};
