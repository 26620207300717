// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';

import { Description, Icon, Modal, Title, ModalContent, Overlay } from './styled';

interface IPopupMessageProps {
  isOpen: boolean;
  title: string;
  description: string;
  closed: () => void;
}
function PopupMessage({ isOpen, title, description, closed }: IPopupMessageProps) {
  const [show, setShow] = useState(isOpen);

  function closeMessage() {
    setTimeout(() => {
      setShow(false);
      closed();
    }, 3000);
  }

  useEffect(() => {
    setShow(isOpen);

    closeMessage();
  }, [isOpen]);

  return (
    <>
      {show && (
        <>
          <Overlay />
          <Modal display={show ? 'flex' : 'none'} data-testid="modal-popup-message">
            <ModalContent>
              <Icon src="/assets/icons/icon-done-outline-white.svg" alt="icon-done" />

              <Title>{title}</Title>

              <Description>{description}</Description>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}

export default PopupMessage;
