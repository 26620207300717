import Stack from '../Stack';
import { Description } from './styled';

interface IStatusDot {
  description: string;
  color: string;
}

export const StatusDot: React.FC<IStatusDot> = ({ description, color }): JSX.Element => {
  return (
    <Stack alignItems="center" gap="0.25rem">
      <Stack
        width="6px"
        height="6px"
        borderRadius="50%"
        backgroundColor={color}
        data-testid="stack-dot"
      />
      <Description>{description}</Description>
    </Stack>
  );
};
