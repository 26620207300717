import TopicSummary from 'components/atoms/TopicSummary';
import { EditButtonsPopup } from 'components/molecules';
import { usePopupEdit } from 'hooks/useEditPopup';
import { useMemo, useState } from 'react';

interface IResumeButtonsPopupProps {
  links: any;
}
export const ResumeButtonsPopup: React.FC<IResumeButtonsPopupProps> = ({ links }) => {
  const { formPopup } = usePopupEdit();
  const [openEdit, setOpenEdit] = useState(false);
  const { watch } = formPopup;
  const {
    popupButtonCount,
    popupButtons,
    active,
    popupButtonIcon,
    popupButtonTitle,
    popupButtonSubTitle,
  } = watch();

  const popupButtonCountItem = useMemo(() => {
    return ((popupButtonCount ?? 0) > 2 ? 'Nenhum Botão' : popupButtonCount?.toString()) ?? '';
  }, [popupButtonCount]);

  const defaultValues = {
    popupButtonCount,
    popupButtons: popupButtons.map((item: any) => {
      return {
        ...item,
        action: Number(item.action),
        actionFile: item.actionFile
          ? item.actionFile
          : item.linkArquivo && item.nameFileBucket && !item.actionLinkFile
          ? { name: item.nameFileBucket }
          : null,
      };
    }),
    popupButtonIcon,
    popupButtonTitle,
    popupButtonSubTitle,
  };

  const handleSave = (popupButtonsData: any) => {
    formPopup.setValue('popupButtonCount', popupButtonsData.popupButtonCount, {
      shouldDirty: true,
    });
    formPopup.setValue('popupButtons', popupButtonsData.popupButtons, {
      shouldDirty: true,
    });
    setOpenEdit(false);
  };

  return (
    <>
      <TopicSummary
        title="Botões do pop-up*"
        link={'Editar >'}
        itens={[popupButtonCountItem]}
        click={() => setOpenEdit(true)}
        disabled={!active}
      />
      {openEdit && (
        <EditButtonsPopup
          defaultValues={defaultValues}
          links={links}
          onClose={() => setOpenEdit(false)}
          handleSave={handleSave}
        />
      )}
    </>
  );
};
