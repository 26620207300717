// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import {
  Button,
  Description,
  Flex,
  IconClose,
  Information,
  Modal,
  ModalContent,
  ModalContentBox,
  WrapperButtons,
} from './styled';
import { useTheme } from 'hooks/useTheme';

function ConfirmDefault({
  width,
  widthTitle,
  height,
  question,
  information,
  isOpen,
  onClose,
  labelButtonNegative,
  labelButtonConfirm,
  onConfirm,
  onNegative,
  closeOnConfirm = true,
  title,
  children,
}: {
  width: string;
  widthTitle?: string;
  height: string;
  question: string;
  information: string;
  isOpen: boolean;
  onClose: () => void;
  labelButtonNegative: string;
  labelButtonConfirm: string;
  onConfirm: () => void;
  onNegative: () => void;
  closeOnConfirm?: boolean;
  title?: string;
  children?: React.ReactNode;
}) {
  const [show, setShow] = useState(isOpen);
  const { currentTheme } = useTheme();

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  const handleNegative = () => {
    onNegative();
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    if (closeOnConfirm) onClose();
  };

  return (
    <>
      {show && (
        <Modal display={show ? 'flex' : 'none'} data-testid="modal-confirm-default">
          <Flex>
            <ModalContentBox width={width} height={height} data-testid="modal-content-box">
              <ModalContent>
                <Description color={currentTheme.colors.primary} marginBottom="0.25rem">
                  {title ?? 'Atenção!'}
                </Description>
                <Description width={widthTitle} data-testid="modal-description">
                  {parse(question)}
                </Description>
                {children}
                <Information>{information}</Information>
              </ModalContent>

              <WrapperButtons>
                <Button
                  background={currentTheme.colors.white}
                  borderRadius="0px 0px 0px 9px"
                  color={currentTheme.colors.primary}
                  onClick={handleNegative}
                >
                  {labelButtonNegative}
                </Button>

                <Button
                  color={currentTheme.colors.white}
                  borderRadius="0px 0px 9px 0px"
                  background={currentTheme.colors.primary}
                  onClick={handleConfirm}
                >
                  {labelButtonConfirm}
                </Button>
              </WrapperButtons>
            </ModalContentBox>

            <IconClose src="/assets/icons/icon-close.svg" onClick={onClose} />
          </Flex>
        </Modal>
      )}
    </>
  );
}

export default ConfirmDefault;
