import React from 'react';

import { Back, Description, DivideVertical, IconRoundedBack, TextBack, BtnBack } from './styled';

interface IButtonBackProps {
  text?: string;
  description?: string;
  onBack: () => void;
  padding?: string;
}
function ButtonBack({ text, description, onBack, padding }: IButtonBackProps) {
  return (
    <Back padding={padding} data-testid="button-back-container">
      <BtnBack onClick={onBack} data-testid="button-back">
        <IconRoundedBack src="/assets/icons/icon-rounded-back.svg" />
        <TextBack>{text ?? 'Voltar'}</TextBack>
      </BtnBack>

      {description && (
        <>
          <DivideVertical border="0.6px solid rgba(46, 43, 55, 0.36);" />

          <Description data-testid="button-back-description">{description}</Description>
        </>
      )}
    </Back>
  );
}

export default ButtonBack;
