import styled from 'styled-components';

interface IModalProps {
  display: string;
}
export const Modal = styled.div<IModalProps>`
  display: flex;
  position: relative;
  top: 50%;
  width: 100%;
  height: 0px;
  justify-content: center;
  align-items: center;
  display: ${({ display }) => display};
`;

export const Overlay = styled.div`
  background-color: rgb(241 241 241 / 52%);
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
  left: 0px;
`;

export const ModalContent = styled.section`
  width: 100%;
  background: ${(props) => props.theme.colors.success}; ;
  border-radius: 0.5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1.5rem;
  overflow: auto;
  position: relative;
  z-index: 2;
  animation: fadeIn linear 0.5s;
  -webkit-animation: fadeIn linear 0.5s;
  -moz-animation: fadeIn linear 0.5s;
  -o-animation: fadeIn linear 0.5s;
  -ms-animation: fadeIn linear 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Icon = styled.img`
  height: 19px;
  margin-bottom: 0.375rem;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 1rem;
  line-height: 120%;
  color: ${(props) => props.theme.colors.white}; 
  margin-bottom: 0.5rem;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.white}; 
`;
