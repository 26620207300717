import { RadioGroupForm } from 'components/atoms';
import { actionList } from 'helpers/enums';
import { useForm } from 'react-hook-form';

interface IChooseActionProps {
  form: any;
  label: string;
  nameFildLinkType?: string;
  nameFieldLink?: string;
  nameFieldInternalLink?: string;
  nameFieldActionLinkFile?: string;
  nameFieldActionFile?: string;
  nameFieldAction?: string;
  options?: any[];
}
export const ChooseAction: React.FC<IChooseActionProps> = ({
  form,
  label,
  nameFildLinkType = 'linkType',
  nameFieldLink = 'link',
  nameFieldInternalLink = 'internalLink',
  nameFieldActionLinkFile = 'actionLinkFile',
  nameFieldActionFile = 'actionFile',
  nameFieldAction = 'action',
  options = actionList,
}) => {
  const { setValue, control } = form;
  const onChange = (value: number) => {
    setValue(nameFildLinkType, value === 1 ? 1 : null);
    setValue(nameFieldLink, null);
    setValue(nameFieldInternalLink, null);
    setValue(nameFieldActionLinkFile, null);
    setValue(nameFieldActionFile, null);
  };
  return (
    <RadioGroupForm
      options={options}
      control={control}
      name={nameFieldAction}
      label={label}
      onChange={onChange}
      gap="0.3rem"
    />
  );
};
