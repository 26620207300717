import React from 'react';
import { Icon } from 'globalStyles';
import { removeCookie } from 'helpers/cookies';
import { useAuthentiction } from 'hooks/authentication';
import { useTheme } from 'hooks/useTheme';
import { device } from 'styles/breakpoint';

import Stack from '../Stack';
import { DivideVertical, StackModalHeader, TexGreeting, TextLogout, TextName } from './styled';

interface IHeaderModal {
  breakpointDown?: string;
  breakpointUp?: string;
}

export const HeaderModal: React.FC<IHeaderModal> = ({
  breakpointDown,
  breakpointUp,
}: IHeaderModal) => {
  const { user, signout } = useAuthentiction();
  const { currentTheme } = useTheme();

  const getWelcomeText = () => {
    const now = new Date();
    const hour = now.getHours();
    if (hour >= 0 && hour < 12) {
      return 'Bom dia';
    } else if (hour >= 12 && hour < 18) {
      return 'Boa tarde';
    } else {
      return 'Boa noite';
    }
  };

  const logout = () => {
    removeCookie('BACKOFFICE_VEICULOS_TOKEN');
    signout();
  };

  return (
    <StackModalHeader
      padding="1.875rem"
      background={currentTheme.colors.primary}
      backgroundImage="/assets/images/background-header-turbo.png" // tirar essa img que está fixa
      borderRadius="12px 12px 0 0"
      width="100%"
      breakpointDown={breakpointDown}
      breakpointUp={breakpointUp}
    >
      <Stack alignItems="center">
        <Stack breakpointDown={device.tablet}>
          <Icon src="/assets/icons/profile_backoffice.png" width="25px" />
        </Stack>

        <Stack direction="column" marginLeft="0.875rem">
          <TexGreeting>{getWelcomeText()}</TexGreeting>
          <TextName>{user.name}</TextName>
        </Stack>

        <Stack marginLeft="0.875rem">
          <DivideVertical />
        </Stack>

        <Stack marginLeft="0.875rem" gap="0.25rem" onClick={() => logout()} cursor="pointer">
          <Icon src="/assets/icons/icon-logout.svg" width="14px" />
          <TextLogout>Sair</TextLogout>
        </Stack>
      </Stack>

      <Stack marginLeft="0.875rem" flex="1" justifyContent="flex-end" gap="6px">
        <Icon src="/assets/images/logo-backoffice.svg" width="50px" />
        <Icon src="/assets/images/logo-fidelidade-turbo-white.svg" width="141px" />
      </Stack>
    </StackModalHeader>
  );
};
