import { useEffect } from 'react';

import ContentStep1 from './ContentStep1';
import ContentStep2 from './ContentStep2';
import ContentStep3 from './ContentStep3';
import ContentStep4 from './ContentStep4';
import ContentStep5 from './ContentStep5';

interface IContentCreateBannerProps {
  step: number;
}

const ContentCreateBanner: React.FC<IContentCreateBannerProps> = ({ step }) => {
  useEffect(() => {
    // define title page
    document.title = 'Banners | Turbo | Backoffice Lojas';
  }, []);

  return (
    <>
      {step === 1 ? (
        <ContentStep1 />
      ) : step === 2 ? (
        <ContentStep2 />
      ) : step === 3 ? (
        <ContentStep3 />
      ) : step === 4 ? (
        <ContentStep4 />
      ) : (
        <ContentStep5 />
      )}
    </>
  );
};

export default ContentCreateBanner;
