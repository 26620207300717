import { Check, ItemRow, Row } from './styled';

interface ITopicSummaryItemArrayRowProps {
  text: string | JSX.Element;
}
export const TopicSummaryItemArrayRow: React.FC<ITopicSummaryItemArrayRowProps> = ({ text }) => {
  return (
    <Row gap="0.5rem" marginBottom="0.25rem">
      <Check src="/assets/icons/icon-circle-moon.svg" alt="check" height="4px" />
      <ItemRow>{text}</ItemRow>
    </Row>
  );
};
