import styled from 'styled-components';

export const Description = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 109%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.secondary}; 
`;
