import { TargetAudienceForm } from 'components/molecules';
import { useCreateBanner } from 'hooks/banner';

function ContentStep4() {
  const { list, createFormBannerStepper } = useCreateBanner();

  return <TargetAudienceForm form={createFormBannerStepper} label="banner" list={list} />;
}

export default ContentStep4;
