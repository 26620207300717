import { limitLenghtString } from 'helpers/functions';
import { useMemo, useState } from 'react';

import { TopicSummaryItemArrayRow } from './row';
import { Check, Item, Link, Row, StackItems } from './styled';
import { useTheme } from 'hooks/useTheme';
import useIconThemes from 'hooks/iconThemes';

interface ITopicSummaryItemArrayProps {
  title: string;
  arrayItems: string[];
  columns?: number;
  lines?: number;
  customClickMaisItens?: () => void;
}

const TopicSummaryItemArray: React.FC<ITopicSummaryItemArrayProps> = ({
  title,
  arrayItems,
  columns = 3,
  lines = 3,
  customClickMaisItens,
}) => {
  const { currentTheme } = useTheme();
  const iconThemes = useIconThemes();
  const [showAll, setShowAll] = useState(false);
  const textMostrarMais = useMemo(
    () => (
      <>
        Mais {arrayItems.length - (columns === 3 ? 8 : 5)}.
        <Link
          onClick={() => {
            if (customClickMaisItens) {
              customClickMaisItens();
            } else {
              setShowAll(true);
            }
          }}
        >
          {'Ver mais >'}
        </Link>
      </>
    ),
    [arrayItems.length, customClickMaisItens]
  );

  const textMostrarMenos = useMemo(
    () => <Link onClick={() => setShowAll(false)}>{'Ver menos <'}</Link>,
    []
  );

  const randomId = useMemo(() => (Math.random() * Math.random()).toString(), []);

  const arrayItemsToShow = useMemo(() => columns * lines - 1, [columns, lines]);

  return (
    <>
      <Row key={title} gap="0.5rem" marginBottom="0.25rem">
        <Check src={iconThemes[currentTheme.theme]?.IconCheck} alt="check" height="8px" />
        <Item>{title}</Item>
      </Row>
      <StackItems columnsLogicPadding={columns} columns={`repeat(${columns}, 1fr)`}>
        {arrayItems.map((item, index) => {
          if (index === arrayItemsToShow && !showAll && arrayItems.length - 1 > arrayItemsToShow) {
            return <TopicSummaryItemArrayRow key={index} text={textMostrarMais} />;
          } else if (
            index > arrayItemsToShow &&
            !showAll &&
            arrayItems.length - 1 > arrayItemsToShow
          ) {
            return null;
          } else {
            return (
              <TopicSummaryItemArrayRow
                key={index}
                text={limitLenghtString(item, columns > 2 ? 17 : 14)}
              />
            );
          }
        })}
        {showAll && <TopicSummaryItemArrayRow key={randomId} text={textMostrarMenos} />}
      </StackItems>
    </>
  );
};

export default TopicSummaryItemArray;
