import { CustomErrorAnauthorized } from 'helpers/customErrorApi';
import { createContext, useContext, useState } from 'react';

export interface INotification {
  type: string;
  title: string;
  message: string | JSX.Element;
  icon: string;
  bgColor: string;
}

interface INotifyContext {
  // eslint-disable-next-line no-unused-vars
  success: (_title: string, _message: string | JSX.Element, _timeout?: number) => void;
  // eslint-disable-next-line no-unused-vars
  error: (_title: string, _message: string | JSX.Element, _timeout?: number, error?: any) => void;
  handleClearNotification: () => void;
  showNotification: boolean;
  notification: INotification;
}

const DEFAULT_NOTIFICATION: INotification = {
  type: '',
  title: '',
  message: '',
  icon: '',
  bgColor: '',
};

export const NotifyContext = createContext({} as INotifyContext);

export const NotifyProvider = ({ children }: { children: React.ReactNode }) => {
  const [notification, setNotification] = useState<INotification>(DEFAULT_NOTIFICATION);
  const [showNotification, setShowNotification] = useState(false);

  const handleClearNotification = () => {
    setShowNotification(false);
    setNotification(DEFAULT_NOTIFICATION);
  };

  const _handleNotification = (
    type: string,
    title: string,
    message: string | JSX.Element,
    icon: string,
    bgColor: string,
    timeout: number
  ) => {
    setNotification({
      type,
      title,
      message,
      icon,
      bgColor,
    });
    setShowNotification(true);
    setTimeout(() => handleClearNotification(), timeout);
  };

  const success = (title: string, message: string | JSX.Element, timeout = 3000) => {
    _handleNotification(
      'success',
      title,
      message,
      '/assets/icons/icon-check-circle.svg',
      '#00D217',
      timeout
    );
  };
  const error = (title: string, message: string | JSX.Element, timeout = 70000, error?: any) => {
    if (error instanceof CustomErrorAnauthorized) return;
    _handleNotification('error', title, message, '/assets/icons/alerta.svg', '#FF8332', timeout);
  };

  return (
    <NotifyContext.Provider
      value={{
        handleClearNotification,
        success,
        error,
        showNotification,
        notification,
      }}
    >
      {children}
    </NotifyContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotifyContext);

  return context;
};
