/* eslint-disable eqeqeq */
import { useNotification } from 'hooks/notification';
import { IListsModel, IPopupButtonButtonsModel, IPopupsModel } from 'models';
import moment from 'moment';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EditPopup } from 'requests/PopupManagement';
import { getImage } from 'requests/Image';
import { ModuloEnum } from 'helpers/enums';

interface IPopupListProps {
  popupsList: { name: string; value: number }[];
  viewOrder: string;
}

interface IPopupEditContext {
  hidenPreview: boolean;
  setHidenPreview: (value: boolean) => void;
  updatePopup: () => Promise<boolean>;
  setInitialStateForm: (data: IPopupsModel, lists: IListsModel) => void;
  formPopup: ReturnType<typeof useForm<IPopupsModel>>;
  popupsList: IPopupListProps;
}

const PopupEditContext = createContext({} as IPopupEditContext);

const mapFilter = (
  _list: { value: number; label: string }[],
  _values?: number[]
): { value: number; label: string }[] => {
  if (!_list) return [];

  return (
    _values?.map(
      (itemValue) =>
        _list?.find((itemList) => itemList.value.toString() === itemValue.toString()) ??
        ({} as { value: number; label: string })
    ) ?? []
  );
};

export const CreatePopupEditProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [hidenPreview, setHidenPreview] = useState(false);
  const [popupsList, setPopupsList] = useState<IPopupListProps>({} as IPopupListProps);
  const notify = useNotification();

  const formPopup = useForm<IPopupsModel>();

  const setInitialStateForm = async (data: IPopupsModel, lists: IListsModel) => {
    const getFiliais = () => {
      const filiais = data.IdsWhichBranches?.filter((n) => n);
      if (filiais?.length === 0) {
        return [{ value: 0, label: 'Todas' }];
      } else {
        return mapFilter(lists?.branchs, data.IdsWhichBranches);
      }
    };

    const getLink = () => {
      if (data.IdInternalLink && lists.links) {
        return lists.links.find((link) => link.value == data.IdInternalLink);
      }
      return undefined;
    };

    const getClassificationsSelected = () => {
      const whichClassifications = data.IdsWhichClassifications?.filter((n) => n);
      if (whichClassifications?.length === 0) {
        return [{ value: 0, label: 'Todas' }];
      } else {
        return mapFilter(lists?.categories, data?.IdsWhichClassifications);
      }
    };

    const getSegmentsSelected = (): { value: number; label: string }[] => {
      const segments = data.IdsSegments?.filter((n) => n);
      if (segments?.length === 0) {
        return [{ value: 0, label: 'Todos' }];
      } else {
        return mapFilter(lists?.segments, data.IdsSegments);
      }
    };

    const popupButtons: IPopupButtonButtonsModel[] = data.popupButtons.map((item) => ({
      text: item.text,
      idColor: item.idColor,
      action: item.action,
      link: item.link,
      internalLink: item.idLinkInternal
        ? lists.links.filter((link) => link.value == item.idLinkInternal)[0]
        : undefined,
      linkArquivo: item.nameFileBucket ?? item.linkFile,
      nameFileBucket: item.nameFileBucket ?? undefined,
      actionLinkFile: !item.nameFileBucket ? item.linkFile : undefined,
      linkFileBucket: item.linkFile,
      linkType: item.link ? 1 : 2,
    }));

    const { image } = await getImage({ moduloId: ModuloEnum.POPUP, id: data.id });

    const newData: IPopupsModel = {
      type: data.type,
      active: data.active,
      image: image, 
      viewOrder: data.viewOrder,
      popupButtonName: data.name,
      popupButtonIcon: data.popupButtonIcon,
      popupButtonTitle: data.popupButtonTitle,
      popupButtonSubTitle: data.popupButtonSubTitle,
      popupButtonCount: data.popupButtons.length > 0 ? data.popupButtons.length : 3,
      id: data.id,
      popupButtons,
      startDate: data.startDate || new Date(),
      endDate: data.endDate,
      endDateUndefined: data.endDateUndefined,
      defineDate: data.defineDate,
      name: data.name,
      action: data.action,
      linkType: data.linkType,
      link: data.link,
      internalLink: getLink(),
      nameFileBucket: data.nameFileBucket,
      actionLinkFile: !data.nameFileBucket ? data.actionLinkFile : undefined,
      linkFileBucket: data.actionLinkFile,
      whoCanSee: data.nameFileClient ? 2 : 1,
      csvFile: data.nameFileClient ? { name: data.nameFileClient } : undefined,
      whichBranches: getFiliais(),
      classificationPeriod: data.classificationPeriod,
      whichClassifications: getClassificationsSelected(),
      segments: getSegmentsSelected(),
      planCondition: data.planCondition,
      planPoints: data.planPoints ?? undefined,
      customers: data.customers,
      popups: lists?.popups ?? [],
      activeInitial: data.active,
      status: data.status,
    };

    setPopupsList({
      popupsList: lists?.popups ?? [],
      viewOrder: data.viewOrder,
    });
    formPopup.reset(newData);
  };

  const updatePopup = async (): Promise<boolean> => {
    const data = formPopup.getValues();
    if (
      data.endDate &&
      moment(new Date()).format('YYYY-MM-DD') > moment(data.endDate).format('YYYY-MM-DD')
    ) {
      notify.error(
        'Período de vigência vencido!',
        'Por favor, selecione um novo período de vigência para o pop-up.',
        3000
      );
      return false;
    }
    await EditPopup(data)
      .then((_) => {
        notify.success('Pop-up atualizado com sucesso.', `O ${data.name} foi alterado.`);
      })
      .catch((error) => {
        notify.error('Erro ao atualizar o pop-up', error.message, 5000);
        throw error;
      });

    return true;
  };

  return (
    <PopupEditContext.Provider
      value={{
        hidenPreview,
        setHidenPreview,
        updatePopup,
        setInitialStateForm,
        formPopup,
        popupsList,
      }}
    >
      {children}
    </PopupEditContext.Provider>
  );
};

export const usePopupEdit = () => {
  const context = useContext(PopupEditContext);
  return context;
};
