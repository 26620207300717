import { IStackStyled } from '../Stack';
import { Wrap } from './styled';

interface IModalHeaderProps extends IStackStyled {
  background?: string;
  height?: string;
  children: React.ReactNode;
}
export const ModalHeader: React.FC<IModalHeaderProps> = ({
  background,
  height,
  children,
  ...props
}) => (
  <Wrap background={background} height={height} {...props}>
    {children}
  </Wrap>
);
