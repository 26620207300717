import { Stack } from 'components/atoms';
import { classificationPeriodList, planConditionList } from 'helpers/enums';
import { useForm } from 'react-hook-form';

import { ClassificationSee } from './ClassificationSee';
import { CNPJSee } from './CNPJSee';
import { FiliaisSee } from './FiliaisSee';
import { PlanCondition } from './PlanCondition';
import { SegmentsSee } from './SegmentsSee';

interface ITargetAudienceFormProps {
  form: any;
  label: string;
  list: any;
}
export const TargetAudienceForm: React.FC<ITargetAudienceFormProps> = ({ form, label, list }) => {
  return (
    <>
      <Stack direction="column">
        <CNPJSee label={label} form={form} />
        <FiliaisSee label={label} form={form} branchs={list.branchs} />
        <ClassificationSee
          label={label}
          form={form}
          classificationPeriodList={classificationPeriodList}
          categories={list.categories}
        />
        <SegmentsSee label={label} form={form} segments={list.segments} />
        <PlanCondition form={form} planConditionList={planConditionList} />
      </Stack>
    </>
  );
};
