import { Stack } from 'components/atoms';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

interface IStackModalProps {
  heightModalDown: {
    tablet: string;
    laptopM: string;
    desktop: string;
  };
  widthModalDown: {
    tablet: string;
    laptopM: string;
    desktop: string;
  };
}
export const StackModal = styled(Stack)<IStackModalProps>`
  flex-direction: column;
  background: #f1f1f1;
  width: ${({ widthModalDown }) => widthModalDown?.desktop};
  height: ${({ heightModalDown }) => heightModalDown?.desktop};
  padding-top: 2.063rem;
  border-radius: 0.5rem;
  justify-content: space-between;
  /* overflow: hidden; */
  max-height: 1000px;

  @media (max-width: ${device.laptopM}) {
    height: ${({ heightModalDown }) => heightModalDown?.laptopM};
    width: ${({ widthModalDown }) => widthModalDown?.laptopM};
  }

  @media (max-width: ${device.tablet}) {
    width: ${({ widthModalDown }) => widthModalDown?.tablet};
    height: ${({ heightModalDown }) => heightModalDown?.tablet};
    padding-top: 0;
  }
`;

export const StackModalWrapper = styled(Stack)`
  flex-direction: column;
  padding: 0 1.25rem 0 1.875rem;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StackHeaderButtonBackAndTitle = styled(Stack)`
  @media (max-width: ${device.tablet}) {
    padding-top: 2.063rem;
  }
`;

export const ButtonClosePopup = styled.button`
  background-color: transparent;
  position: absolute;
  right: -40px;
  top: 0;
  img {
    width: 24px;
  }

  @media (max-width: ${device.laptopM}) {
    right: -35px;
  }

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;
