import { Paragraph, Stack } from 'components/atoms';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { Controller } from 'react-hook-form';
import { StylesConfig } from 'react-select';
import { DownChevron, ReactSelectElement } from './styled';

interface IOptionProps {
  value: number;
  label: string;
}

interface ISelectProps {
  options: IOptionProps[];
  control: any;
  name: string;
  onChange?: (val: any) => void;
  width?: string;
}

const colourStyles: StylesConfig = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f1f1f1' : undefined,
    };
  },
};

export const SearchWithSelectSelect: React.FC<ISelectProps> = ({
  options,
  control,
  name,
  onChange,
  width = '178px',
}) => {
  const internalOptions = options || [];
  const iconThemes = useIconThemes();
  const { currentTheme } = useTheme();

  const _onChange = (value: IOptionProps | IOptionProps[], localOnChange: any) => {
    localOnChange(value);
  };

  const formatOptionLabel = (prop: any, value: any) => {
    return (
      <Stack gap="6px">
        {prop.value !== 0 && <Paragraph label={'Pesquise por:'} fontSize="9px" />}
        <Paragraph label={prop.label} fontWeight={prop.value > 0 ? 'bold' : '400'} fontSize="9px" />
      </Stack>
    );
  };

  const arrowRenderer = () => {
    return (
      <DownChevron alt="chevron" src={iconThemes[currentTheme.theme]?.IconDownChevronSecondary} />
    );
  };

  const handleChange = (newValue: any, localOnChange: any) => {
    _onChange(newValue, localOnChange);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Stack direction="column" height="50px">
          <ReactSelectElement
            {...field}
            classNamePrefix="mySelect"
            isSearchable={false}
            width={width}
            options={internalOptions}
            formatOptionLabel={(props) => formatOptionLabel(props, field.value)}
            components={{ DropdownIndicator: arrowRenderer }}
            value={field.value}
            onChange={(e) => {
              handleChange(e, field.onChange);
              if (onChange) onChange(e);
            }}
            noOptionsMessage={() => 'Sem dados'}
            closeMenuOnSelect
            styles={colourStyles}
          />
        </Stack>
      )}
    />
  );
};
