import { Stack } from 'components/atoms';
import { MenuHorizontal } from 'components/molecules';
import { menuHorizontalItems } from 'constants/menuHorizontalItems';
import { ReactNode } from 'react';
import themeTurbo from 'styles/theme/themeTurbo';
import { ContainerLayoutPage, LayoutPageStyled } from './styled';

interface ILayoutPageProps {
  children: ReactNode;
  height: string;
  width: string;
}

export const LayoutPage = ({ children, height, width }: ILayoutPageProps) => {
  return (
    <LayoutPageStyled>
      <MenuHorizontal menus={menuHorizontalItems} />
      <ContainerLayoutPage width={width} height={height} data-testid="stack-content">
        {children}
      </ContainerLayoutPage>
    </LayoutPageStyled>
  );
};
