import { api } from 'services/api';

export interface IHistoricModel {
  data: string;
  horario: string;
  tipo_relatorio: number;
  status: number;
  hash_link: string | null;
}

interface IAPIResponse<T> {
  HttpStatus: number;
  Dado: T;
  Notificacoes: any[];
}

export const getHistoric = async (): Promise<IHistoricModel[]> => {
  try {
    const response = await api.get<IAPIResponse<IHistoricModel[]>>('/analitico/chamas/historico');
    return response.data.Dado;
  } catch (error) {
    console.error('Erro ao buscar o histórico:', error);
    throw error;
  }
};
