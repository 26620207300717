import { IStackStyled, StyledStack } from 'components/atoms/Stack';
import styled from 'styled-components';

export const WrapTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2.25rem;
  margin-top: 1.625rem;
  h1 {
    font-weight: 700;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 0.375rem;
    margin-left: 10px;
  }
`;

export const WrapToogle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  #name {
    width: 24.5rem;
  }
  margin-top: 2rem;
  gap: 12px;
`;

export const WrapSummary = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 1rem;
`;
export const WrapModal = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000000b0;
  position: absolute;
`;

export const StackActions = styled(StyledStack) <IStackStyled>`
  padding-top: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  @media (max-width: 1040px) {
    padding-top: 0.188rem;
  }
`;
