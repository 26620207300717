import { LoginState, useAuthentiction } from 'hooks/authentication';
import { Routes, BrowserRouter, Route, Navigate, useLocation } from 'react-router-dom';
import { router } from './router';
import { useTheme } from 'hooks/useTheme';
import { backofficeHomeURL } from './routes';
import { useEffect } from 'react';
import { isEmpty } from 'helpers/functions';

interface IContainerRoutersProps {
  Component: any;
  path: string;
  isPrivate?: boolean;
  exact: boolean;
  theme: string;
}

export const ContainerRouters = (props: IContainerRoutersProps) => {
  const { Component, path, isPrivate } = props;
  const { token, signout, user } = useAuthentiction();
  const location = useLocation();
  const { currentTheme, selectTheme } = useTheme();

  useEffect(() => {
    if (currentTheme.theme !== props.theme) {
      selectTheme(props.theme);
    }
  }, [props.theme]);

  if (isEmpty(currentTheme)) return <></>;

  if (location.pathname.includes('/senhatrocar')) {
    signout();
  } else {
    if (
      location.pathname === '/senha-expirada' &&
      (!token || user?.status !== LoginState.EXPIRE_PASSWORD)
    ) {
      return <Navigate to={backofficeHomeURL} />;
    }
  }

  if (
    token &&
    user?.status === LoginState.EXPIRE_PASSWORD &&
    location.pathname !== '/senha-expirada'
  ) {
    return <Navigate to="/senha-expirada" />;
  }

  if (user && user.profile && user?.status === LoginState.SUCCESS) {
    return <Navigate to={backofficeHomeURL} />;
  }

  if (!token && isPrivate) {
    return <Navigate to="/login" />;
  }

  if (token && !isPrivate) {
    return <Navigate to={backofficeHomeURL} />;
  }

  return <Component {...props} />;
};

export const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        {router &&
          router.map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={
                <ContainerRouters
                  path={item.path}
                  Component={item.component}
                  isPrivate={item.private}
                  exact={item.exact}
                  theme={item.theme}
                />
              }
            />
          ))}
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
};
