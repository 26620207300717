import styled from 'styled-components';

interface IWrapperProps {
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  gap?: string;
  marginBottom?: string;
}
export const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'row')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${(props) => props.justifyContent};
  gap: ${(props) => (props.gap ? props.gap : '0')};
  margin-bottom: ${(props) => props.marginBottom};
`;

export const WrapperInformation = styled.div`
  margin-bottom: 1rem;
`;

interface IGroupProps {
  marginBottom?: string;
}
export const Group = styled.div<IGroupProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '20px')};
`;

interface IFlexProps {
  gap?: string;
  marginBottom?: string;
  marginTop?: string;
}
export const Flex = styled.div<IFlexProps>`
  display: flex;
  gap: ${(props) => (props.gap ? props.gap : '9px')};
  margin-bottom: ${(props) => props.marginBottom};
  margin-top: ${(props) => props.marginTop};
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #2e2b37;
`;
