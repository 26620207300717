import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { IPictureDictionaryModel } from 'models/entities/picture-dictionary.model';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PostPictureDictioary } from 'requests/PictureDictionaryManagement';
import * as yup from 'yup';

import { useLoading } from './loading';
import { useNotification } from './notification';
import { useConfirm } from './useConfirm';

const Schema = yup.object({
  name: yup.string().required(''),
  dictinaryFile: yup.mixed().required(''),
  idTypeAward: yup.string().required(''),
});

interface ICarouselPictureDictionaryContextType {
  created: boolean;
  setCreated: (val: boolean) => void;
  createdData: IPictureDictionaryModel;
  loading: boolean;
  isValid: () => boolean;
  handleCreateCarouselPictureDictionary: () => Promise<void>;
  setLoading: (val: boolean) => void;
  resetForm: () => void;
  createFormPicture: ReturnType<typeof useForm<IPictureDictionaryModel>>;
  handleClose: (url: string) => void;
}

const CarouselPictureDictionaryContext = createContext({} as ICarouselPictureDictionaryContextType);

const defaultData: IPictureDictionaryModel = {
  idPictureDicionary: 0,
  idTypeAward: 1,
  active: true,
  name: '',
};

export const CarouselPictureDictionaryProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [createdData, setCreatedData] = useState<IPictureDictionaryModel>(
    {} as IPictureDictionaryModel
  );
  const [created, setCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const notify = useNotification();

  const createFormPicture = useForm<IPictureDictionaryModel>({
    defaultValues: defaultData,
    resolver: yupResolver(Schema),
  });

  const { handleHideLoading, handleShowLoading } = useLoading();

  const handleCreateCarouselPictureDictionary = async () => {
    const data = createFormPicture.getValues();
    handleShowLoading();
    setLoading(true);
    setCreated(false);
    setCreatedData({} as IPictureDictionaryModel);

    await PostPictureDictioary(data)
      .then((response) => {
        setCreated(true);
        setCreatedData(response);
      })
      .catch((error) => {
        setCreated(false);
        notify.error('Houve um problema', error?.message);
      })
      .finally(() => {
        setLoading(false);
        handleHideLoading();
      });
  };

  const isValid = (): boolean => {
    return createFormPicture.formState.isValid && isEmpty(createFormPicture.formState.errors);
  };

  const resetForm = () => {
    createFormPicture.reset(defaultData);
    setCreatedData({} as IPictureDictionaryModel);
    setCreated(false);
  };

  const handleClose = (url: string) => {
    void confirm()
      .then(() => {
        navigate(url);
      })
      .catch(() => {});
  };

  return (
    <CarouselPictureDictionaryContext.Provider
      value={{
        created,
        setCreated,
        createdData,
        loading,
        isValid,
        handleCreateCarouselPictureDictionary,
        setLoading,
        resetForm,
        createFormPicture,
        handleClose,
      }}
    >
      {children}
    </CarouselPictureDictionaryContext.Provider>
  );
};

export const useCarouselPictureDictionary = () => {
  const context = useContext(CarouselPictureDictionaryContext);

  return context;
};
