import { validatePassowrd } from 'helpers/functions';

import { Container, ContainerItem, Text, Circle } from './styles';

interface IItemPasswordValidationProps {
  text: string;
  isValid: boolean;
}

const ItemPasswordValidation: React.FC<IItemPasswordValidationProps> = ({ text, isValid }) => (
  <ContainerItem>
    {isValid ? <img src="/assets/icons/icon-check-around.svg" alt="check" /> : <Circle />}
    <Text isValid={isValid}>{text}</Text>
  </ContainerItem>
);

const ItemPasswordWarning: React.FC<IItemPasswordValidationProps> = ({ text, isValid }) => (
  <ContainerItem>
    {isValid ? (
      <img src="/assets/icons/icon-information-red.svg" alt="warning" />
    ) : (
      <img src="/assets/icons/icon-information.svg" alt="warning" />
    )}
    <Text isValid={isValid}>{text}</Text>
  </ContainerItem>
);

interface IPasswordValidationProps {
  value: string;
  passwordUsed: boolean;
}
export const PasswordValidation: React.FC<IPasswordValidationProps> = ({ value, passwordUsed }) => {
  const passwordValidated = validatePassowrd(value);
  return (
    <Container>
      <ItemPasswordValidation
        text="Pelo menos uma letra minúscula"
        isValid={passwordValidated.lower ?? false}
      />
      <ItemPasswordValidation
        text="Pelo menos uma letra maiúscula"
        isValid={passwordValidated.upper ?? false}
      />
      <ItemPasswordValidation
        text="Pelo menos um caractere especial"
        isValid={passwordValidated.special ?? false}
      />
      <ItemPasswordValidation
        text="Pelo menos um número"
        isValid={passwordValidated.number ?? false}
      />
      <ItemPasswordValidation
        text="Possuir entre 6 e 12 caracteres"
        isValid={passwordValidated.length ?? false}
      />
      <ItemPasswordWarning
        text="Diferente das últimas 5 senhas utilizadas"
        isValid={passwordUsed}
      />
    </Container>
  );
};
