import styled from 'styled-components';

interface ITag {
  backgroundColor: string;
}
export const Tag = styled.div<ITag>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 4px;
  border-radius: 1rem;
  font-size: 8px;
  font-weight: 700;
  color: #ffffff;
`;
