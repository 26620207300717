import { IMenuItem, Menu } from 'components/atoms/Menu';
import { removeCookie } from 'helpers/cookies';
import { useAuthentiction } from 'hooks/authentication';
import { ContainerHeader, StackNameUser } from './styled';
import { Paragraph, Stack } from 'components/atoms';
import { Icon } from 'globalStyles';
import { DivideVertical } from 'components/atoms/HeaderModal/styled';
import { useNavigate } from 'react-router-dom';
import { turboHomeURL, backofficeHomeURL, chamasHomeURL } from 'routers/routes';
import { useTheme } from 'hooks/useTheme';

interface IHeader {
  subMenu?: IMenuItem[];
  confirmOnClick?: boolean;
}

export const LayoutHeader = ({ subMenu, confirmOnClick }: IHeader) => {
  const { user, signout } = useAuthentiction();
  const navigate = useNavigate();
  const { name } = user;
  const { currentTheme } = useTheme();

  const logout = () => {
    removeCookie('BACKOFFICE_VEICULOS_TOKEN');
    signout();
  };

  const isHome = currentTheme.theme === 'themeBackoffice';
  const isChamas = currentTheme.theme === 'themeChamas';

  const getRoutePush = (): string => {
    if (isHome) return backofficeHomeURL;
    if (isChamas) return chamasHomeURL;
    return turboHomeURL;
  };

  return (
    <ContainerHeader height={isHome ? '89px' : '105px'}>
      <Stack height="34px" justifyContent="space-between" alignItems="center" width="100%">
        <Stack
          gap="6px"
          alignItems="center"
          cursor="pointer"
          onClick={() => navigate(getRoutePush())}
        >
          <Icon
            src="/assets/icons/icon-flag-backoffice-color-transparent.svg"
            width="58px"
            height="14px"
            cursor="pointer"
          />
          <Icon src={`/assets/images/logo-fidelidade-${currentTheme.theme}.svg`} cursor="pointer" />
        </Stack>
        <Stack gap="4px" alignItems="center">
          <StackNameUser>
            <Paragraph color="#fff" fontSize="12px" lineHeight="12px" label={'Olá, '} />
            <Paragraph
              color="#fff"
              fontSize="12px"
              lineHeight="12px"
              fontWeight="bold"
              label={`${name}`}
            />
            <Stack marginLeft="0.375rem">
              <DivideVertical />
            </Stack>
          </StackNameUser>

          <Stack marginLeft="0.375rem" gap="0.25rem" onClick={() => logout()} cursor="pointer">
            <Icon src="/assets/icons/icon-logout.svg" width={'14px'} cursor="pointer" />
            <Paragraph fontSize="10px" lineHeight="12px" color="#fff" textDecoration="underline">
              Sair
            </Paragraph>
          </Stack>
        </Stack>
      </Stack>
      {subMenu && (
        <Stack height="34px" width="100%">
          <Menu menuList={subMenu} confirmOnClick={confirmOnClick} />
        </Stack>
      )}
    </ContainerHeader>
  );
};
