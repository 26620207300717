import { Stack } from 'components/atoms';
import { IMenuItem } from 'components/atoms/Menu';
import { MenuHorizontal } from 'components/molecules';
import { menuHorizontalItems } from 'constants/menuHorizontalItems';
import { ReactNode } from 'react';
import themeTurbo from 'styles/theme/themeTurbo';
import { Layout } from '../Layout';
import { LayoutStackRegister, StackModalContent } from './styled';
import ButtonBack from 'components/atoms/ButtonBack';
import { useNavigate } from 'react-router-dom';

interface ILayoutRegisterCardProps {
  children: ReactNode;
  subMenu?: IMenuItem[];
  onBack?: () => void;
  noBack?: boolean;
  routerBack?: string;
  backText?: string;
  description?: string;
  confirmOnClick?: boolean;
}

export const LayoutRegisterCard = ({
  children,
  subMenu,
  onBack,
  noBack,
  routerBack,
  backText,
  description,
  confirmOnClick = true,
}: ILayoutRegisterCardProps) => {
  const navigate = useNavigate();

  const navigateBack = () => {
    if (routerBack) navigate(routerBack);
  };

  return (
    <>
      <MenuHorizontal menus={menuHorizontalItems} />
      <LayoutStackRegister>
        <Layout.Header subMenu={subMenu} confirmOnClick={confirmOnClick} />
        <StackModalContent>
          {!noBack && (
            <ButtonBack
              description={description}
              text={backText}
              onBack={() => {
                if (onBack) onBack();
                if (routerBack) navigateBack();
              }}
            />
          )}

          <Stack direction="column" height="100%" width="100%" flex="1" overflow="hidden">
            {children}
          </Stack>
        </StackModalContent>
      </LayoutStackRegister>
    </>
  );
};
