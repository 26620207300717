import { useTheme } from 'hooks/useTheme';
import { Btn } from './styled';
import useIconThemes from 'hooks/iconThemes';

interface IButtonEdit {
  onClick: () => void;
}
export const ButtonEdit: React.FC<IButtonEdit> = ({ onClick }): JSX.Element => {
  const { currentTheme } = useTheme();
  const iconThemes = useIconThemes();

  return (
    <Btn onClick={onClick}>
      <img src={iconThemes[currentTheme.theme]?.IconButtonEdit} alt="button-edit" />
    </Btn>
  );
};
