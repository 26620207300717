import { Icon } from '../../../globalStyles';
import { Input, Stack } from '../../atoms';
import { ButtonExport } from '../../atoms/ButtonExport';
import RadioCustom from '../../atoms/Radio';
import Title from '../../atoms/Title';
import { FilerText, IconFilter, ImageList, StackFilter, Subtitle } from './styled';
import { useEffect } from 'react';

interface IHeaderListPictureDictinaryManagementProps {
  search: string;
  setSearch: any;
  typeFilter: any;
  setTypeFilter: any;
  handleClickExport: any;
}

export const HeaderListPictureDictinaryManagement: React.FC<
  IHeaderListPictureDictinaryManagementProps
> = ({ search, setSearch, typeFilter, setTypeFilter, handleClickExport }) => {
  useEffect(() => {
    // define title page
    document.title = 'Gerenciador de Dicionário de imagens | Turbo |  Backoffice Lojas';
  }, []);

  return (
    <Stack justifyContent="space-between" marginBottom="1.25rem" direction="column">
      <Stack direction="column" width="100%">
        <Stack>
          <ImageList
            src={'/assets/icons/icon-carousel-turbo-red.svg'}
            className="icon"
            alt={'dicionario-icon'}
          />
          <Title label={'Gerenciar Dicionário'} />
        </Stack>
        <Subtitle>{'Configure um prêmio já publicado.'}</Subtitle>
      </Stack>
      <StackFilter>
        <Stack gap="12px">
          <Stack alignItems="center" justifyContent="center" gap="3px">
            <IconFilter src="/assets/icons/icon-filter.png" />
            <FilerText>Filtrar</FilerText>
          </Stack>
          <Stack alignItems="center" justifyContent="center" gap="0.5rem">
            <RadioCustom
              title="Normal"
              value={1}
              currentSelect={typeFilter}
              onClick={() => {
                setTypeFilter(typeFilter === 1 ? null : 1);
              }}
            />
            <RadioCustom
              title="Campanha"
              value={3}
              currentSelect={typeFilter}
              onClick={() => {
                setTypeFilter(typeFilter === 3 ? null : 3);
              }}
            />
          </Stack>
        </Stack>
        <Stack>
          <Stack>
            <Input
              placeholder={'Procurar um prêmio...'}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              customMinWidth="192px"
              justLabel
              leadingIcon={() =>
                search ? (
                  <Icon
                    onClick={() => setSearch('')}
                    src="/assets/icons/icon-close-black.svg"
                    width={'19px'}
                    data-testid="icon-search"
                  />
                ) : (
                  <Icon src="/assets/icons/icon-search.svg" width={'19px'} />
                )
              }
            />
          </Stack>

          <Stack marginLeft="12px">
            <ButtonExport handleClick={handleClickExport} />
          </Stack>
        </Stack>
      </StackFilter>
    </Stack>
  );
};
