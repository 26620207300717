import React from 'react';
import { ITextTitle, TextTitle } from './styled';
import { useTheme } from 'hooks/useTheme';

interface ITitleProps extends ITextTitle {
  label?: string;
  color?: string;
  children?: React.ReactNode;
  className?: string;
}

function Title(props: ITitleProps) {
  const { currentTheme } = useTheme();

  return (
    <TextTitle {...props} theme={currentTheme}>
      {props.label ?? props.children}
    </TextTitle>
  );
}

export default Title;
