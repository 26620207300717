import { Stack } from 'components/atoms';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

export const Wrapper = styled.div<{ edit: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: '100%';
  flex: 1;
  @media (max-width: ${device.laptopM}) {
    background: ${({ theme }) => theme.colors.secondary}; 
    border-radius: ${({ theme }) => theme.borderRadius.lg};
    border: ${({ theme }) => `10px solid ${theme.colors.secondary}`}; 
    box-sizing: border-box;
  }
`;

export const RoundIconContainer = styled.div`
  position: absolute;
  left: 50;
  bottom: -20px;
  width: 42px;
  display: flex;
  flex-direction: row;
`;

export const RoundIcon = styled.div`
  bottom: -16px;
  margin: auto;
  width: 10px;
  height: 10px;
  background-color: ${({ theme }) => theme.colors.backgroundWhite};
  border-radius: 50%;
`;

export const ArrowBackIcon = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: -16px;
  margin: auto;
  width: 15px;
  height: 15px;
  background-image: url('/assets/icons/arrow-rounded-previous.svg');
  background-repeat: no-repeat;
  background-size: contain;
`;
export const ArrowForwardIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: -16px;
  margin: auto;
  width: 15px;
  height: 15px;
  background-image: url('/assets/icons/arrow-rounded-next.svg');
  background-repeat: no-repeat;
  background-size: contain;
`;

export const SectionUpload = styled.div<{ base64: string; edit: boolean }>`
  height: 291px;
  width: 481px;
  position: relative;
  background: ${({ theme }) => theme.colors.disabled};
  background-image: ${({ base64 }) => base64 && `url(${base64})`};
  background-size: cover;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing[14]} ${({ theme }) => theme.spacing[7]} ${({ theme }) => theme.spacing[22]} ${({ theme }) => theme.spacing[7]};

  @media (max-width: ${device.laptopM}) {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-height: ${({ theme }) => theme.spacing[14]};
    width: 100%;
    padding: 0;
    border: ${({ edit, theme }) => (edit ? `4px solid ${theme.colors.secondary}` : '')};
  }
`;

export const IconEdit = styled.img`
  width: 18px;
  height: 18px;
`;

export const SubWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const IconUpload = styled.img`
  width: ${({ theme }) => theme.spacing[24]};
  height: ${({ theme }) => theme.spacing[24]};
  margin-bottom: ${({ theme }) => theme.spacing[15]};
`;

export const Title = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.xmd};
  line-height: 109%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.backgroundWhite};
  background: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: ${({ theme }) => theme.spacing[3]} ${({ theme }) => theme.spacing[8]};
  margin: ${({ theme }) => theme.spacing[14]} 0;
  cursor: pointer;
`;

export const Description = styled.small<{ fontWeight?: string }>`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 120%;
  text-align: center;
  color: ${({ theme }) => theme.colors.backgroundWhite};
  font-weight: ${({ fontWeight, theme }) => fontWeight ?? theme.fontWeights.normal};
`;

export const ImageLink = styled.img<{ edit: boolean }>`
  width: 496px;
  height: 313px;
  margin-top: ${({ theme }) => theme.spacing[15]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${({ edit, theme }) => (edit ? `5px solid ${theme.colors.backgroundWhite}` : `4px solid ${theme.colors.primary}`)}; //verificar aqui
  @media (max-width: ${device.laptopM}) {
    border: ${({ theme }) => `4px solid ${theme.colors.primary}`};
    width: 100%;
    height: ${({ theme }) => theme.spacing[14]};
    margin-top: 0;
  }
  @media (max-width: ${device.tablet}) {
    width: auto;
  }
`;

export const Text = styled.image`
  color: ${({ theme }) => theme.colors.backgroundWhite};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

interface IStackPreviewProps {
  edit: boolean;
  marginLeft?: string;
}

export const StackPreview = styled(Stack) <IStackPreviewProps>`
  margin-left: ${({ marginLeft }) => marginLeft ?? '0px'};
  @media (max-width: ${device.laptopM}) {
    margin: 0px;
  }
`;

export const StackPreviewImage = styled(Stack)`
  @media (max-width: ${device.laptopM}) {
    width: 100%;
  }
`;

export const StackPreviewText = styled(Stack)`
  @media (max-width: ${device.laptopM}) {
    display: none;
  }
`;

export const StackSectionUpload = styled(Stack) <{ disableEdit: boolean }>`
  background: ${({ theme }) => theme.colors.backgroundWhite};
  padding: ${({ theme }) => theme.spacing[5]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  position: relative;
  cursor: ${({ disableEdit }) => (disableEdit ? 'auto' : 'pointer')};
  @media (max-width: ${device.laptopM}) {
    padding: 0;
  }
`;

export const StackSectionUploadImageBorder = styled(Stack)`
  @media (max-width: ${device.laptopM}) {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: ${({ theme }) => theme.borderRadius.lg};
    padding: ${({ theme }) => theme.spacing[5]};
  }
`;
