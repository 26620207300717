import styled from 'styled-components';

// export const Label = styled.label`
//   font-weight: 400;
//   font-size: 0.75rem;
//   line-height: 120%;
//   letter-spacing: -0.02em;
//   color: #2e2b37;
//   display: flex;
//   margin-bottom: 0.25rem;
//   color: ${({ color }) => color};
// `;

export const InputStyled = styled.input`
  background: none;
  border: none;
  border-radius: none;
  height: 30px;
  width: 100%;
  padding: 0 10px;
  position: relative;
  color: ${({ color }) => color};

  &:focus {
    outline: none !important;
    border-color: none !important;
  }

  &::placeholder {
    color: #2e2b37;
    opacity: 0.35;
  }

  /*when disabled change color text with opacity 0.35 in text*/
  &:disabled {
    color: rgba(46, 43, 52, 0.35);
    cursor: not-allowed;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

interface IBackgroundIcon {
  backgroundColor?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  success?: boolean;
}

export const BackgroundIcon = styled.div<IBackgroundIcon>`
  padding-right: 6px;
  width: 46px;
  height: 30px;
  background-color: ${(props) =>
    props.disabled
      ? '#AEAEBA'
      : props.isInvalid
      ? props.theme.colors.error
      : props.success
      ? props.theme.colors.success
      : props.backgroundColor && !props.disabled
      ? props.backgroundColor
      : props.theme.colors.primary};
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconInput = styled.img`
  height: 19px;
`;

interface IFlex {
  alignItems?: string;
  justifyContent?: string;
  gap?: string;
  margin?: string;
}

export const Flex = styled.div<IFlex>`
  display: flex;
  align-items: initial;
`;

export const LinkLabel = styled.a`
  margin-bottom: 0.375rem;
  display: flex;
  min-height: 14px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;
