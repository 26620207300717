import { ButtonRounded, Stack, TipsTurbo } from 'components/atoms';
import { useTheme } from 'hooks/useTheme';

interface IFooterTip {
  tip?: string;
  cancel: () => void;
  advance: () => void;
  disabledAdvance?: boolean;
  buttonFinalize?: number | boolean;
  buttonResume?: boolean;
  buttonLabel?: string;
}

export const FooterTip: React.FC<IFooterTip> = ({
  tip,
  cancel,
  advance,
  disabledAdvance,
  buttonFinalize,
  buttonResume,
  buttonLabel,
}): JSX.Element => {
  const { currentTheme } = useTheme();
  return (
    <Stack
      padding="0px 30px 0px 30px"
      boxShadow="0px -2px 18px rgba(0, 0, 0, 0.28);"
      zIndex="2"
      justifyContent="space-between"
      height="90px"
      minHeight="90px"
      alignItems="center"
      position="sticky"
      bottom="0"
    >
      <Stack direction="column" marginBottom={'0.25rem'} maxWidth="276px">
        {tip && <TipsTurbo tip={tip} />}
      </Stack>

      <Stack alignItems="center" gap="4px">
        <ButtonRounded
          id="cancel"
          outline={true}
          color={'#F3123C'}
          label="Cancelar"
          iconSrc="/assets/icons/icon-close-red.svg"
          labelHover
          click={cancel}
        />

        <ButtonRounded
          id="advance_button"
          background={
            buttonFinalize === 5 ? currentTheme.colors.success : currentTheme.colors.primary
          }
          color={currentTheme.colors.white}
          label={
            buttonLabel ?? (buttonFinalize ? 'Finalizar' : buttonResume ? 'Resumo' : 'Avançar')
          }
          iconSrc="/assets/icons/icon-enter-white.svg"
          disabled={disabledAdvance}
          click={() => advance()}
        />
      </Stack>
    </Stack>
  );
};
