import styled from 'styled-components';

export const InputCheckboxStyled = styled.input`
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 1px solid #555555;
    border-radius: 3px;
    background-color: white;
  }

  &:checked:after {
    content: '';
    display: block;
    width: 3px;
    height: 6px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 4px;
    left: 6px;
  }
`;

export const InputCheckboxLabel = styled.label`
  white-space: nowrap;
  font-size: 12px;
  line-height: 14px;
  margin-top: 2px;
  cursor: pointer;
`;
