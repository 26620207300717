import { useState } from 'react';

interface IconThemes {
  [theme: string]: {
    [icon: string]: string;
  };
}

const useIconThemes = (): IconThemes => {
  const [iconThemes] = useState<IconThemes>({
    themeTurbo: {
      iconBanner: '/assets/icons/icon-banner-turbo-red.svg',
      iconBannerHover: '/assets/icons/icon-banner-turbo-red.svg',
      iconPopup: '/assets/icons/icon-popup-turbo-red.svg',
      iconPopupHover: '/assets/icons/icon-popup-turbo-red.svg',
      iconCarousel: '/assets/icons/icon-carousel-turbo-red.svg',
      iconCarouselHover: '/assets/icons/icon-carousel-turbo-red.svg',
      iconUserbo: '/assets/icons/icon-userbo-turbo-red.svg',
      iconUserboHover: '/assets/icons/icon-userbo-turbo-red.svg',
      iconPlus: '/assets/icons/icon-plus-turbo-red.svg',
      iconPlusHover: '/assets/icons/icon-plus-turbo-red.svg',
      iconGear: '/assets/icons/icon-gear-red.svg',
      iconGearHover: '/assets/icons/icon-gear-red.svg',
      iconBrush: '/assets/icons/icon-brush-red.svg',
      iconBrushHover: '/assets/icons/icon-brush-red.svg',
      iconTrophy: '/assets/icons/icon-trophy-red.svg',
      iconTrophyHover: '/assets/icons/icon-trophy-red.svg',
      iconDownload: '/assets/icons/icon-trophy-red.svg',
      iconDownloadHover: '/assets/icons/icon-trophy-red.svg',
      iconCardUser: '/assets/icons/icon-card-user-red.svg',
      iconCardUserHover: '/assets/icons/icon-card-user-red.svg',
      iconPopupImg: '/assets/icons/icon-popup-img-red.svg',
      iconPopupImgHover: '/assets/icons/icon-popup-img-red.svg',
      IconInformation: '/assets/icons/icon-information-red.svg',
      IconInformationHover: '/assets/icons/icon-information-red.svg',
      IconEnter: '/assets/icons/icon-enter-turbo.svg',
      IconEnterHover: '/assets/icons/icon-enter-red.svg',
      IconEnterHoverWhite: '/assets/icons/icon-enter-white.svg',
      IconButtonEdit: '/assets/icons/icon-edit-rounded-red.svg',
      IconEditWhite: '/assets/icons/icon-edit.svg',
      IconDownChevron: '/assets/icons/down-chevron-red.svg',
      IconDownChevronSecondary: '/assets/icons/down-chevron-white.svg',
      IconDoneOutlined: '/assets/icons/icon-done-outline-red.svg',
      IconCheck: '/assets/icons/icon-check-red.svg',
      IconCheckAround: '/assets/icons/icon-check-around-red.svg',
      loadingImgSrc: '/assets/gifs/loading-themeTurbo.gif',
      iconAnalitico: '/assets/icons/icon-analitico-chamas.svg',
      iconAnaliticoHover: '/assets/icons/icon-analitico-chamas.svg',
    },
    themeBackoffice: {
      iconBanner: '/assets/icons/icon-gear-blue.svg',
      iconBannerHover: '/assets/icons/icon-gear-blue.svg',
      iconPopup: '/assets/icons/icon-settings-blue.svg',
      iconPopupHover: '/assets/icons/icon-settings-blue.svg',
      iconCarousel: '/assets/icons/icon-lock-blue.svg',
      iconCarouselHover: '/assets/icons/icon-lock-blue.svg',
      iconUserbo: '/assets/icons/icon-key-blue.svg',
      iconUserboHover: '/assets/icons/icon-userbo-white.svg',
      iconPlus: '/assets/icons/icon-plus-turbo-blue.svg',
      iconPlusHover: '/assets/icons/icon-plus-turbo-white.svg',
      iconGear: '/assets/icons/icon-gear-blue.svg',
      iconGearHover: '/assets/icons/icon-gear-white.svg',
      iconBrush: '/assets/icons/icon-envelope-blue.svg',
      iconBrushHover: '/assets/icons/icon-envelope-blue.svg',
      iconTrophy: '/assets/icons/icon-bell-blue.svg',
      iconTrophyHover: '/assets/icons/icon-bell-blue.svg',
      iconDownload: '/assets/icons/icon-bell-blue.svg',
      iconDownloadHover: '/assets/icons/icon-bell-blue.svg',
      iconCardUser: '/assets/icons/icon-card-user-blue.svg',
      iconCardUserHover: '/assets/icons/icon-card-user-red.svg',
      iconPopupImg: '/assets/icons/icon-popup-img-red.svg',
      iconPopupImgHover: '/assets/icons/icon-popup-img-red.svg',
      IconInformation: '/assets/icons/icon-popup-img-red.svg',
      IconInformationHover: '/assets/icons/icon-popup-img-red.svg',
      IconEnter: '/assets/icons/icon-enter-blue.svg',
      IconEnterHover: '/assets/icons/icon-enter-white.svg',
      IconButtonEdit: '/assets/icons/icon-edit-blue-rounded.svg',
      IconDownChevron: '/assets/icons/down-chevron-red.svg',
      IconDownChevronSecondary: '/assets/icons/down-chevron-white.svg',
      iconButtonEditHover: '/assets/icons/icon-edit-blue-rounded.svg',
      iconCheck: '/assets/icons/icon-edit-blue-rounded.svg',
      iconCheckHover: '/assets/icons/icon-edit-blue-rounded.svg',
      iconBackoffice: '/assets/icons/icon-backoffice-blue.svg',
      iconBackofficeHover: '/assets/icons/icon-userbo-blue.svg',
      loadingImgSrc: '/assets/gifs/loading-themeBackoffice.gif',
      iconAnalitico: '/assets/icons/icon-analitico-chamas.svg',
      iconAnaliticoHover: '/assets/icons/icon-analitico-chamas.svg',
      IconDoneOutlined: '/assets/icons/icon-done-outline-blue.svg',
    },
    themeChamas: {
      iconBanner: '/assets/icons/icon-gear-blue.svg',
      iconBannerHover: '/assets/icons/icon-gear-blue.svg',
      iconPopup: '/assets/icons/icon-settings-blue.svg',
      iconPopupHover: '/assets/icons/icon-settings-blue.svg',
      iconCarousel: '/assets/icons/icon-lock-blue.svg',
      iconCarouselHover: '/assets/icons/icon-lock-blue.svg',
      iconUserbo: '/assets/icons/icon-key-blue.svg',
      iconUserboHover: '/assets/icons/icon-key-blue.svg',
      iconPlus: '/assets/icons/icon-home-blue.svg',
      iconPlusHover: '/assets/icons/icon-home-blue.svg',
      iconGear: '/assets/icons/icon-search-blue.svg',
      iconGearHover: '/assets/icons/icon-search-blue.svg',
      iconBrush: '/assets/icons/icon-envelope-blue.svg',
      iconBrushHover: '/assets/icons/icon-envelope-blue.svg',
      iconTrophy: '/assets/icons/icon-bell-blue.svg',
      iconTrophyHover: '/assets/icons/icon-bell-blue.svg',
      iconDownload: '/assets/icons/icon-bell-blue.svg',
      iconDownloadHover: '/assets/icons/icon-bell-blue.svg',
      iconCardUser: '/assets/icons/icon-card-user-red.svg',
      iconCardUserHover: '/assets/icons/icon-card-user-red.svg',
      iconPopupImg: '/assets/icons/icon-popup-img-red.svg',
      iconPopupImgHover: '/assets/icons/icon-popup-img-red.svg',
      IconInformation: '/assets/icons/icon-popup-img-red.svg',
      IconInformationHover: '/assets/icons/icon-popup-img-red.svg',
      IconEnter: '/assets/icons/icon-enter-turbo.svg',
      IconEnterHover: '/assets/icons/icon-enter-red.svg',
      iconButtonEdit: '/assets/icons/icon-edit-blue-rounded.svg',
      IconDownChevron: '/assets/icons/down-chevron-red.svg',
      IconDownChevronSecondary: '/assets/icons/down-chevron-white.svg',
      iconButtonEditHover: '/assets/icons/icon-edit-blue-rounded.svg',
      iconCheck: '/assets/icons/icon-edit-blue-rounded.svg',
      iconCheckHover: '/assets/icons/icon-edit-blue-rounded.svg',
      iconAnalitico: '/assets/icons/icon-analitico-chamas.svg',
      iconAnaliticoHover: '/assets/icons/icon-analitico-chamas.svg',
      loadingImgSrc: '/assets/gifs/loading-themeChamas.gif',
      IconDoneOutlined: '/assets/icons/icon-done-outline-red.svg',
    },
  });

  return iconThemes;
};

export default useIconThemes;
