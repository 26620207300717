import { ButtonEdit } from 'components/atoms';
import { IMenuItem } from 'components/atoms/Menu';
import StatusUser from 'components/atoms/StatusUser';
import { ListDefault } from 'components/molecules';
import PopupEditUser from 'components/molecules/PopupEditUser';
import { Layout } from 'components/templates/Layout';
import { generateKeyId } from 'helpers/functions';
import useIconThemes from 'hooks/iconThemes';
import { useNotification } from 'hooks/notification';
import { useTheme } from 'hooks/useTheme';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetAllUsers } from 'requests/UserManagement';

interface IUserManagementProps {
  subMenu?: IMenuItem[];
  routerBack: string;
}

export const UserManagement = ({ subMenu, routerBack }: IUserManagementProps) => {
  const pageLimit = 5;
  const columnsHeader = ['Nome', 'Email', 'Status', 'Editar'];

  const [dataUsers, setDataUsers] = useState([]);
  const [totalRecordsUsers, setTotalRecordsUsers] = useState(0);
  const [dataUserSelected, setDataUserSelected] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenEditUser, setIsOpenEditUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const prevSearchRef = useRef('');
  const navigate = useNavigate();

  const { currentTheme } = useTheme();

  const iconThemes = useIconThemes();

  const notify = useNotification();

  const getUsersData = async (pageInitSearch?: number) => {
    setLoading(true);
    setDataUsers([]);
    await GetAllUsers(pageInitSearch ?? currentPage, pageLimit, search)
      .then((response) => {
        setDataUsers(response?.Dado.Usuarios);
        setTotalRecordsUsers(response?.Dado.Total);
      })
      .catch((_) => {
        notify.error('Erro ao buscar dados.', 'Tente novamente mais tarde.');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (prevSearchRef.current !== search) {
      // Verifica se é a primeira vez que o search muda
      setCurrentPage(1); // Define currentPage como 1 somente na primeira vez
    }

    prevSearchRef.current = search; // Atualiza o prevSearchRef com o valor atual do search

    const delayDebounce = setTimeout(() => {
      void getUsersData();
    }, 300);
    return () => clearTimeout(delayDebounce);
  }, [search, currentPage]);

  useEffect(() => {
    // define title page
    document.title = 'Gerenciador de Usuários | Turbo | Backoffice Lojas';
  }, []);

  const handleClick = (obj: any) => {
    setIsOpenEditUser(true);
    setDataUserSelected(obj);
  };

  const rows = dataUsers?.map((obj: any) => {
    return [
      obj?.nome,
      obj?.email,
      <StatusUser type="User" status={obj?.status} key={generateKeyId()} />,
      <ButtonEdit onClick={() => handleClick(obj)} key={generateKeyId()} />,
    ];
  });

  return (
    <>
      <Layout.Container>
        <Layout.Page width="740px" height="547px">
          <Layout.Header subMenu={subMenu} />
          <Layout.Body.Root>
            <Layout.Body.Back onBack={() => navigate(routerBack, { replace: true })} />
            <ListDefault
              name="usuário"
              pageLimit={pageLimit}
              columnsHeader={columnsHeader}
              totalRecords={totalRecordsUsers}
              loading={loading}
              list={rows}
              setCurrentPage={setCurrentPage}
              showHeader
              search={search}
              setSearch={setSearch}
              img={iconThemes[currentTheme.theme]?.iconCardUser || ''}
              title="Gerenciar usuários do Backoffice"
              subTitleHeader="Edite, reenvie um convite ou exclua um usuário."
            />
          </Layout.Body.Root>
        </Layout.Page>
      </Layout.Container>

      {isOpenEditUser ? (
        <PopupEditUser
          data={dataUserSelected}
          onRefreshTable={() => {
            getUsersData();
          }}
          open={isOpenEditUser}
          onClose={() => {
            setIsOpenEditUser(false);
            getUsersData();
          }}
        />
      ) : null}
    </>
  );
};
