import { ChamasAnalitycalPage } from 'pages/Chamas/Analytical';
import { TurboBanners } from 'pages/Turbo/Banners';
import { TurboBannerCreate } from 'pages/Turbo/Banners/Create';
import { TurboBannersManagement } from 'pages/Turbo/Banners/Management';
import { TurboCarrossel } from 'pages/Turbo/Carrossel';
import { TurboCarrosselBackground } from 'pages/Turbo/Carrossel/Background';
import { TurboCarrosselBackgroundCreate } from 'pages/Turbo/Carrossel/Background/Create';
import { TurboCarrosselBackgroundManagement } from 'pages/Turbo/Carrossel/Background/Management';
import { TurboCarroselPictureDictionary } from 'pages/Turbo/Carrossel/PictureDictionary';
import { TurboCarroselPictureDictionaryCreate } from 'pages/Turbo/Carrossel/PictureDictionary/Create';
import { TurboPictureDictionaryManagement } from 'pages/Turbo/Carrossel/PictureDictionary/Management';
import { TurboHome } from 'pages/Turbo/Home';
import { TurboPopups } from 'pages/Turbo/Popups';
import { TurboPopupButtonCreate } from 'pages/Turbo/Popups/Create/TurboPopupButtonCreate';
import { TurboPopupImageCreate } from 'pages/Turbo/Popups/Create/TurboPopupImageCreate';
import { TurboPopupsManagement } from 'pages/Turbo/Popups/Management';
import { PopupsSelectType } from 'pages/Turbo/Popups/SelectType';
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage';
import { LoginPage } from '../pages/LoginPage';
import { PasswordExperied } from '../pages/PasswordExperied';
import { PasswordRegistration } from '../pages/PasswordRegistration';
import {
  backofficeHomeURL,
  backofficeUsersCreateURL,
  backofficeUsersMangementURL,
  backofficeUsersURL,
  chamasAnalyticalURL,
  chamasHomeURL,
  turboBannerCreateURL,
  turboBannerManagementURL,
  turboBannersURL,
  turboCarroselPictureDicionaryCreateURL,
  turboCarroselPictureDicionaryManagementURL,
  turboCarroselPictureDicionaryURL,
  turboCarrosselBackgroundCreateURL,
  turboCarrosselBackgroundManagementURL,
  turboCarrosselBackgroundURL,
  turboCarrosselURL,
  turboHomeURL,
  turboPopupsButtonCreateURL,
  turboPopupsCreateURL,
  turboPopupsImageCreateURL,
  turboPopupsManagementURL,
  turboPopupsURL,
} from './routes';

import { BackofficeHome } from 'pages/Backoffice/Home';
import { BackofficeUsers } from 'pages/Backoffice/User';
import { BackofficeUsersManagement } from 'pages/Backoffice/User/Management';
import { ChamasHome } from 'pages/Chamas/Home';
import { BackofficeUserCreate } from 'pages/Backoffice/User/Create';

interface IRouteProps {
  path: string;
  name: string;
  component: any;
  exact: boolean;
  private?: boolean;
  theme: string;
}

export const router: IRouteProps[] = [
  {
    path: '/login/:page?/:id?',
    name: 'Login',
    component: LoginPage,
    exact: true,
    theme: 'themeBackOffice',
  },
  {
    path: '/404',
    name: 'Login',
    component: LoginPage,
    exact: true,
    theme: 'themeBackOffice',
  },
  {
    path: '/senha/:id?',
    name: 'Cadastro Senha',
    component: PasswordRegistration,
    exact: true,
    theme: 'themeBackOffice',
  },
  {
    path: '/senha-expirada',
    name: 'Senha Expirada',
    component: PasswordExperied,
    exact: true,
    private: true,
    theme: 'themeBackOffice',
  },
  {
    path: '/senha-esquecida',
    name: 'Senha Esquecida',
    component: ForgotPasswordPage,
    exact: true,
    theme: 'themeBackOffice',
  },
  {
    path: turboHomeURL,
    name: 'turboHomeURL',
    component: TurboHome,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboBannersURL,
    name: 'turboBannersURL',
    component: TurboBanners,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboPopupsURL,
    name: 'turboBannersURL',
    component: TurboPopups,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboCarrosselURL,
    name: 'turboCarrosselURL',
    component: TurboCarrossel,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  // {
  //   path: turboUsersURL,
  //   name: 'turboUsersURL',
  //   component: TurboUsers,
  //   private: true,
  //   exact: true,
  //   theme: 'themeTurbo',
  // },
  {
    path: turboBannerManagementURL,
    name: 'turboBannerManagementURL',
    component: TurboBannersManagement,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboBannerCreateURL,
    name: 'turboBannerCreateURL',
    component: TurboBannerCreate,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboPopupsURL,
    name: 'turboPopupsURL',
    component: TurboPopups,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboPopupsManagementURL,
    name: 'turboPopupsManagementURL',
    component: TurboPopupsManagement,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboPopupsCreateURL,
    name: 'turboPopupsCreateURL',
    component: PopupsSelectType,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboPopupsImageCreateURL,
    name: 'turboPopupsImageCreateURL',
    component: TurboPopupImageCreate,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboPopupsButtonCreateURL,
    name: 'turboPopupsButtonCreateURL',
    component: TurboPopupButtonCreate,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboCarrosselURL,
    name: 'turboCarrosselURL',
    component: TurboCarrossel,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboCarrosselBackgroundURL,
    name: 'turboCarrosselBackgroundURL',
    component: TurboCarrosselBackground,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboCarrosselBackgroundManagementURL,
    name: 'turboCarrosselBackgroundManagementURL',
    component: TurboCarrosselBackgroundManagement,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboCarrosselBackgroundCreateURL,
    name: 'TurboCarrosselBackgroundCreate',
    component: TurboCarrosselBackgroundCreate,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboCarroselPictureDicionaryURL,
    name: 'turboCarroselImageURL',
    component: TurboCarroselPictureDictionary,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboCarroselPictureDicionaryManagementURL,
    name: 'turboCarroselPictureDicionaryManagementURL',
    component: TurboPictureDictionaryManagement,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: turboCarroselPictureDicionaryCreateURL,
    name: 'TurboCarroselPictureDictionaryCreate',
    component: TurboCarroselPictureDictionaryCreate,
    private: true,
    exact: true,
    theme: 'themeTurbo',
  },
  {
    path: chamasAnalyticalURL,
    name: 'ChamasAnalitycal',
    component: ChamasAnalitycalPage,
    private: true,
    exact: true,
    theme: 'themeChamas',
  },
  {
    path: chamasHomeURL,
    name: 'chamasHomeURL',
    component: ChamasHome,
    private: true,
    exact: true,
    theme: 'themeChamas',
  },
  {
    path: backofficeHomeURL,
    name: 'backofficeHomeURL',
    component: BackofficeHome,
    private: true,
    exact: true,
    theme: 'themeBackoffice',
  },
  {
    path: backofficeUsersURL,
    name: 'backofficeUsersURL',
    component: BackofficeUsers,
    private: true,
    exact: true,
    theme: 'themeBackoffice',
  },
  {
    path: backofficeUsersMangementURL,
    name: 'BackofficeUsersManagement',
    component: BackofficeUsersManagement,
    private: true,
    exact: true,
    theme: 'themeBackoffice',
  },
  {
    path: backofficeUsersCreateURL,
    name: 'backofficeUsersCreateURL',
    component: BackofficeUserCreate,
    private: true,
    exact: true,
    theme: 'themeBackoffice',
  },
];
