import { InputDragAndDropForm, InputForm, Stack } from 'components/atoms';
import { useForm } from 'react-hook-form';

interface IChooseFileProps {
  form: any;
  maxSize: number;
  accept: string[];
  errorMessage: string;
  nameFieldActionFile?: string;
  nameFieldActionLinkFile?: string;
  nameFieldNameFileBucket?: string;
}
export function ChooseFile({
  form,
  maxSize,
  accept,
  errorMessage,
  nameFieldActionFile = 'actionFile',
  nameFieldActionLinkFile = 'actionLinkFile',
  nameFieldNameFileBucket = 'nomeArquivoBucket',
}: IChooseFileProps) {
  const { control, setValue } = form;
  return (
    <Stack direction="column">
      <InputDragAndDropForm
        buttonTitle="Escolher arquivo"
        titleChange="ou arraste ele para cá."
        title="Faça upload do arquivo"
        accept={accept}
        errorMessage={errorMessage}
        maxSize={maxSize}
        name={nameFieldActionFile}
        control={control}
        onChange={() => {
          setValue(nameFieldActionLinkFile, '');
          setValue(nameFieldNameFileBucket, '');
        }}
      />
      <p className="ou">ou</p>
      <InputForm
        type="text"
        name={nameFieldActionLinkFile}
        label="Insira aqui o link do arquivo"
        placeholder="Digite aqui..."
        control={control}
        toLowerCase
        onChange={() => {
          setValue(nameFieldActionFile, '');
          setValue(nameFieldNameFileBucket, '');
        }}
      />
    </Stack>
  );
}
