import { FooterTip, Stack, StepCreated } from 'components/atoms';
import { IMenuItem } from 'components/atoms/Menu';
import { PreviewPictureDictinary } from 'components/molecules';
import { LayoutRegister } from 'components/templates';
import { useCarouselPictureDictionary } from 'hooks/carouselPictureDictionary';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateCarouselPictureContent } from './CreateCarouselPictureContent';

interface IStepperBannerProps {
  subMenu?: IMenuItem[];
  routerBack: string;
}

export const PictureDictionaryCreate = ({ subMenu, routerBack }: IStepperBannerProps) => {
  useEffect(() => {
    document.title = 'Criar Dicionário de Imagem | Turbo | Backoffice Lojas';
  }, []);

  const navigate = useNavigate();
  const {
    createdData,
    loading,
    created,
    handleCreateCarouselPictureDictionary,
    isValid,
    resetForm,
    createFormPicture,
    handleClose,
  } = useCarouselPictureDictionary();
  const { name, idTypeAward } = createFormPicture.watch();

  if (created) {
    return (
      <LayoutRegister.Container>
        <LayoutRegister.Card
          backText={'Voltar a tela de Carrossel'}
          subMenu={subMenu}
          onBack={() => navigate(routerBack, { replace: true })}
          confirmOnClick={false}
        >
          <StepCreated
            title={name}
            route={`${routerBack}/imagem/gerenciador`}
            name="Dicionário de Imagem"
            custonSubtitle="Você pode alterar detalhes deste e outros prêmios acessando o"
            custonLinkText="Gerenciador do Dicionário."
            custonLabelbuttonAccess="Adicionar Nova Imagem"
            justifyContent="space-between"
            height="inherit"
            onClickFirstButton={() => resetForm()}
          >
            <PreviewPictureDictinary
              marginTop="14px"
              id={String(createdData.idPictureDicionary)}
              src={createdData.dictinaryURL ?? ''}
              label={createdData.name}
            />
          </StepCreated>
        </LayoutRegister.Card>
      </LayoutRegister.Container>
    );
  }

  const isNormal = idTypeAward === 1;
  const footerTipText = `O tamanho de imagem apropriado para um prêmio ${
    isNormal ? 'normal' : 'de campanha'
  } é de ${isNormal ? '500px x 500px.' : '260px x 202px.'}`;

  const create = () => {
    handleCreateCarouselPictureDictionary();
  };

  return (
    <LayoutRegister.Container>
      <LayoutRegister.Card
        description="Criando uma imagem de prêmio"
        subMenu={subMenu}
        onBack={() => handleClose(`${routerBack}/imagem`)}
      >
        <>
          <Stack direction="column" flex="1" padding="0.875rem 1.625rem 0 1.875rem">
            <Stack direction="column">
              <Stack
                direction="column"
                height="380px"
                paddingBottom="12px"
                overflow="auto"
                paddingRight="1.25rem"
                scrollbarborder="#f1f1f1"
              >
                <CreateCarouselPictureContent />
              </Stack>
            </Stack>
          </Stack>
          <FooterTip
            tip={footerTipText}
            advance={() => create()}
            disabledAdvance={!isValid() || loading}
            cancel={() => handleClose(`${routerBack}/imagem`)}
            buttonFinalize
            buttonLabel="Publicar"
          />
        </>
      </LayoutRegister.Card>
    </LayoutRegister.Container>
  );
};
