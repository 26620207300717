import React, { useState } from 'react';
import Stack from '../Stack';
import Title from '../Title';
import { IconNext, StackCard, Subtitle } from './styled';
import { useTheme } from 'hooks/useTheme';
import useIconThemes from 'hooks/iconThemes';
import { Icon } from 'globalStyles';

interface IMenuCardProps {
  title?: string;
  subtitle?: string;
  icon?: string;
  iconHover?: string;
  onClick?: () => void;
  height?: string;
  iconSize?: string;
  className?: string;
  mode?: 'landscape' | 'portrait';
}

const MenuCard: React.FC<IMenuCardProps> = (props) => {
  const {
    title,
    subtitle,
    icon,
    iconHover,
    onClick,
    height = '200px',
    iconSize,
    className,
    mode,
  } = props;

  const { currentTheme } = useTheme();
  const iconThemes = useIconThemes();
  const [isHover, setHover] = useState(false);

  return (
    <div className={className}>
      <StackCard
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onClick}
        hover={isHover}
        height={height}
        background={isHover ? currentTheme.colors.secondary : currentTheme.colors.silver}
        data-testid="menu-card"
        className="menu-card"
        mode={mode || 'portrait'}
      >
        <Stack>
          <Icon src={isHover ? iconHover : icon} size={iconSize} width="32px" />
          <Stack direction="column" marginTop="0.75rem" marginBottom="0.75rem">
            <Title
              color={isHover ? currentTheme.colors.backgroundWhite : currentTheme.colors.secondary} // mudar aqui
              label={title}
            />
            <Subtitle hover={isHover}>{subtitle}</Subtitle>
          </Stack>
        </Stack>
        <IconNext
          src={
            isHover
              ? iconThemes?.[currentTheme.theme].IconEnterHover
              : iconThemes?.[currentTheme.theme].IconEnter
          }
          className={className}
        />
      </StackCard>
    </div>
  );
};

export default MenuCard;
