import { ColDef, GridApi } from 'ag-grid-community';
import { ChamasAnalyticalExportType, Months } from 'helpers/enums';
import { IAnalyticalColumnEntity } from 'models/entities/AnalyticalColumn.entity';
import { IReportAnalyticalTableEntity } from 'models/entities/ReportAnalyticalTable.entity';
import { createContext, useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  GetAnalyticalColumns,
  IConsultReportAnalyticalProps,
  IExportChamasAnalytical,
  consultReportAnalytical,
  exportChamasAnalytical,
} from 'requests/Chamas/Analytical/Analytical';
import { useNotification } from './notification';
import { transformarYearAndMonthInFullDataMonth } from 'helpers/functions';

type OptionType = { value: number; label: string };

interface IFormFilterAnalitycalProps {
  type: number;
  typeSearch: OptionType;
  search: string;
  startMonth: OptionType;
  startYear: OptionType;
  endMonth: OptionType;
  endYear: OptionType;
  provider: OptionType;
}
interface IChamasAnalyticalContext {
  formFilterAnalitycal: ReturnType<typeof useForm<IFormFilterAnalitycalProps>>;
  getDataTableAnalytical: (newPage?: boolean) => Promise<IRowsReport | undefined>;
  dataReport: IRowsReport;
  exportReport: (type: ChamasAnalyticalExportType) => Promise<boolean | undefined>;
  getDataTableAnalyticalAndSet: () => void;
  loading: boolean;
  columns: { [key: string]: IAnalyticalColumnEntity[] };
  columnsGroup: string[];
  getColumns: (typeReport: number) => Promise<void>;
  years: OptionType[];
  getColumnsSelected: () => ColDef<IReportAnalyticalTableEntity>[];
  handleSetColumns: (columns: { [key: string]: IAnalyticalColumnEntity[] }) => void;
  showMore: boolean;
  gridApiRef: React.MutableRefObject<GridApi | null>;
  resetForm: (type: number) => void;
}

const ChamasAnalyticalContext = createContext({} as IChamasAnalyticalContext);

const currentMonth = new Date().getMonth();
const currentYear = new Date().getFullYear();
const defaultValues: IFormFilterAnalitycalProps = {
  type: 1,
  typeSearch: { value: 0, label: 'Selecione...' },
  search: '',
  startMonth: { value: currentMonth + 1, label: Months[currentMonth].label },
  startYear: { value: currentYear, label: currentYear.toString() },
  endMonth: { value: currentMonth + 1, label: Months[currentMonth].label },
  endYear: { value: currentYear, label: currentYear.toString() },
  provider: { value: 0, label: 'Todos' },
};

type IRowsReport = {
  header?: ColDef[] | null;
  rows?: IReportAnalyticalTableEntity[][] | null;
  loading?: boolean;
};

export const ChamasAnalyticalProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const formFilterAnalitycal = useForm({
    defaultValues,
  });

  const [dataReport, setDataReport] = useState<IRowsReport>({} as IRowsReport);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState<{ [key: string]: IAnalyticalColumnEntity[] }>({});
  const [columnsGroup, setColumnsGroup] = useState<string[]>([]);
  const [years, setYears] = useState<OptionType[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [lastColumnsPineed, setLastColumnsPineed] = useState<number[]>([]);
  const gridApiRef = useRef<GridApi | null>(null);

  const notify = useNotification();

  const handleSetColumns = (columns: { [key: string]: IAnalyticalColumnEntity[] }) => {
    setColumns(columns);
  };

  const getColumnsSelected = (): ColDef<IReportAnalyticalTableEntity>[] => {
    var columnsSelected: IAnalyticalColumnEntity[] = [];
    columnsGroup.map((group) => {
      let qualquer = columns[group].filter((column) => column.checked);

      columnsSelected = [...columnsSelected, ...qualquer];
    });
    // const hasColumnsPinned = getPinedColumns().length > 0;
    var columnsSelectedHeaderTable: ColDef[] = columnsSelected
      .sort((a, b) => (a.order > b.order ? 1 : -1)) // para ordenar as colunas conforme vem do backend
      .map((column, index) => ({
        headerName: column.label,
        field: column.id.toString(),
        pinned: undefined,
        order: column.order,
        sortable: false,
      }));
    return columnsSelectedHeaderTable;
  };

  const resetForm = (type: number) => {
    formFilterAnalitycal.reset({ ...defaultValues, type });
  };

  const getColumns = async (typeReport: number) => {
    setLoading(true);
    await GetAnalyticalColumns(typeReport)
      .then((response) => {
        const { columns, yearStart } = response;
        const groupedColumnsData = columns
          .sort((a, b) => a.order - b.order)
          .reduce((acc: any, item) => {
            if (!acc[item.groupName]) {
              acc[item.groupName] = [];
            }
            acc[item.groupName].push(item);
            return acc;
          }, {});
        const newYearts = getYears(yearStart);
        setYears(newYearts);
        handleSetColumns(groupedColumnsData);
        setColumnsGroup(Object.keys(groupedColumnsData));
      })
      .catch((error) => {
        setDataReport({ header: [], rows: [], loading: false });
        notify.error('Erro ao buscar colunas.', 'Tente novamente mais tarde.', 5000, error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getYears = (startYear: number | null): OptionType[] => {
    try {
      if (!startYear) {
        return [];
      }
      const currentYear = new Date().getFullYear();
      const yearsAvailable = Array.from({ length: currentYear - startYear + 1 }, (_, i) => {
        const newYear = startYear + i;
        return { value: newYear, label: newYear.toString() };
      });
      return yearsAvailable;
    } catch (e) {
      return [];
    }
  };

  const getPinedColumns = (): number[] => {
    const currentGridApi = gridApiRef.current;
    if (currentGridApi && currentGridApi?.getColumnState()) {
      const pinnedColumns = currentGridApi.getColumnState().filter((column) => column.pinned);
      const pinnedColumnsIds = pinnedColumns.map((column) => Number(column.colId));
      setLastColumnsPineed(pinnedColumnsIds);
      return pinnedColumnsIds;
    }
    if (lastColumnsPineed.length) {
      return lastColumnsPineed;
    }
    return [];
  };

  function getParams<T>(): T {
    formFilterAnalitycal.clearErrors();
    const { startYear, startMonth, endYear, endMonth, search, typeSearch, provider, type } =
      formFilterAnalitycal.getValues();

    const params = {
      pesquisa: search ? search : undefined,
      tipoFiltro: search ? typeSearch.value : undefined,
      dataInicio: `${startYear.value}-${startMonth.value}-01`,
      dataFim: transformarYearAndMonthInFullDataMonth(endYear.value, endMonth.label).dataFim,
      //dataFim: `${endYear.value}-${endMonth.value}-01`,
      fornecedor: provider.value || 0,
      pagina: 1,
      tipoRelatorio: type,
    };
    return params as T;
  }

  const getColumnsIds = (): number[] => {
    const header = getColumnsSelected();
    return header.map((column) => Number(column.field));
  };

  const getDataTableAnalytical = async (newPage?: boolean): Promise<IRowsReport> => {
    try {
      setLoading(true);
      const wrapNewPage = newPage ? activePage + 1 : 1;
      setActivePage(wrapNewPage);

      let params = getParams<IConsultReportAnalyticalProps>();

      const header = getColumnsSelected();
      const columnsIds = getColumnsIds();
      params.colunas = columnsIds;
      params.pagina = wrapNewPage;
      const response = await consultReportAnalytical(params);
      const { totalLines, rows } = response;
      const validShowMore = Math.ceil(totalLines / 100) > wrapNewPage;
      setShowMore(validShowMore);
      return { header, rows };
    } catch (e) {
      notify.error('Erro ao buscar Relatório.', 'Tente novamente mais tarde.', 5000);
      return { header: [], rows: [] };
    } finally {
      setLoading(false);
    }
  };

  const getDataTableAnalyticalAndSet = async () => {
    try {
      const pinnedColumns = getPinedColumns();
      setDataReport({ header: [], rows: [], loading: true });

      const response = await getDataTableAnalytical();
      const { header, rows } = response;

      let newHeader = header;
      // setar colunas fixas
      if (pinnedColumns.length) {
        newHeader?.forEach((column) => {
          if (pinnedColumns.includes(Number(column.field))) {
            column.pinned = 'left';
          }
        });
      }
      setDataReport({ header: newHeader, rows, loading: false });
    } catch (_) {
      notify.error('Erro ao buscar Relatório.', 'Tente novamente mais tarde.', 5000);
      setDataReport({ header: [], rows: [], loading: false });
    }
  };

  const exportReport = async (typeExport: ChamasAnalyticalExportType): Promise<any | undefined> => {
    let params = getParams<IExportChamasAnalytical>();

    params.colunas = [];
    if (typeExport === ChamasAnalyticalExportType.PARTIAL) {
      params.colunas = getColumnsIds();
    }

    params.tipoExportacao = typeExport;
    return exportChamasAnalytical(params);
  };

  return (
    <ChamasAnalyticalContext.Provider
      value={{
        formFilterAnalitycal,
        getDataTableAnalytical,
        dataReport,
        exportReport,
        getDataTableAnalyticalAndSet,
        loading,
        columns,
        columnsGroup,
        getColumns,
        years,
        getColumnsSelected,
        handleSetColumns,
        showMore,
        gridApiRef,
        resetForm,
      }}
    >
      {children}
    </ChamasAnalyticalContext.Provider>
  );
};

export const useChamasAnalytical = () => {
  const context = useContext(ChamasAnalyticalContext);

  if (!context) {
    throw new Error('useChamasAnalitycal must be used within an ChamasAnalyticalProvider');
  }

  return context;
};
