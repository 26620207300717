import styled from 'styled-components';

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primary}; 
  margin-left: 0.5rem;
`;

export const Subtitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  margin-top: 0.75rem;
`;

export const LinkText = styled.p`
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
`;
