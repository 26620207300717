import { Stack } from 'components/atoms';
import PreviewPopupButton from 'components/atoms/PreviewPopupButton';
import { ModalEditPopupButton } from 'components/molecules/ModalEditPopupButton';
import { popupButtonIcons } from 'helpers/enums';
import { usePopupButton } from 'hooks/popupButton';
import { device } from 'styles/breakpoint';

export const PopupButtonStep1 = () => {
  const { createFormPopupButtonStepper } = usePopupButton();
  const { popupButtonIcon, popupButtonTitle, popupButtonSubTitle, popupButtons } =
    createFormPopupButtonStepper.watch();
  const currentIcon = popupButtonIcons[popupButtonIcon - 1];

  return (
    <Stack direction="column">
      <ModalEditPopupButton form={createFormPopupButtonStepper} />
      <Stack
        flex="1"
        justifyContent="center"
        alignItems="center"
        breakpointDownInvert={device.laptopM}
        marginBottom="10px"
      >
        <PreviewPopupButton
          icon={currentIcon?.src}
          title={popupButtonTitle}
          subTitle={popupButtonSubTitle}
          buttons={popupButtons.map((button: { text: string; idColor: number }) => {
            return {
              text: button.text,
              color: button.idColor,
            };
          })}
        />
      </Stack>
    </Stack>
  );
};
