import { Control, Controller } from 'react-hook-form';
import Stack from '../Stack';
import { RadioButtonText } from './styled';

interface IRadioGroupTextForm {
  control: Control<any>;
  name: string;
  options: { label: string; value: string | number | boolean }[];
  disabled?: boolean;
}

export const RadioGroupTextForm: React.FC<IRadioGroupTextForm> = ({
  control,
  name,
  options,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack gap="12px">
          {options.map((option, index) => (
            <RadioButtonText
              checked={field.value === option.value}
              key={`RadioGroupTextForm-${index}-${option.value}`}
              onClick={() => {
                if (disabled) return;
                field.onChange(option.value);
              }}
            >
              {option.label}
            </RadioButtonText>
          ))}
        </Stack>
      )}
    />
  );
};
