import { Icon } from 'globalStyles';
import { useTheme } from 'hooks/useTheme';

interface ILoadingProps {
  height?: number;
  width?: number;
}

export const Loading: React.FC<ILoadingProps> = ({ height = 47, width = 47 }) => {
  const { currentTheme } = useTheme();

  let loadingImgSrc = '';
  switch (currentTheme.theme) {
    case 'themeTurbo':
      loadingImgSrc = '/assets/gifs/loading-themeTurbo.gif';
      break;
    case 'themeBackoffice':
      loadingImgSrc = '/assets/gifs/loading-themeBackoffice.gif';
      break;
    case 'themeChamas':
      loadingImgSrc = '/assets/gifs/loading-themeChamas.gif';
      break;
    default:
      loadingImgSrc = '/assets/gifs/loading-default.gif';
  }

  return (
    <Icon
      src={loadingImgSrc}
      alt="loading..."
      height={height}
      width={width}
      data-testid="loading-spinner"
    />
  );
};
