import { StyledStack } from 'components/atoms/Stack';
import styled from 'styled-components';

export const Modal = styled(StyledStack)`
  /* The Modal (background) */
  z-index: 99999;
  display: ${(props) => (props.display ? props.display : 'none')};
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.8);
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  /* Style the horizontal ruler */
  hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 1.5rem;
  }
`;

export const ModalContentBox = styled(StyledStack)`
  width: ${(props) => (props.width ? props.width : '537px')};
  justify-content: center;
`;

export const ModalContent = styled.section<{
  hasMessageInside?: boolean;
  height?: string;
  borderRadius?: string;
  alignItems?: string;
  width?: string;
}>`
  width: ${(props) => props.width};
  position: relative;
  overflow: hidden;
  overflow: ${(props) => (props.hasMessageInside ? 'hidden' : 'initial')};
  width: 100%;
  height: ${(props) => (props.height ? props.height : '308px')};
  background: #f1f1f1;
  box-shadow: 0px 0px 16.9476px rgba(0, 0, 0, 0.13);
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '9px')};
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: ${(props) => props.alignItems};
  padding: 1.875rem;
  animation: fadeIn linear 0.5s;
  -webkit-animation: fadeIn linear 0.5s;
  -moz-animation: fadeIn linear 0.5s;
  -o-animation: fadeIn linear 0.5s;
  -ms-animation: fadeIn linear 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
  width: 100%;
`;

export const IconClose = styled.img`
  width: 24px;
  cursor: pointer;
`;
