import { InputDragAndDropForm, Stack } from 'components/atoms';
import { IBackgroundModel } from 'models';
import { useForm } from 'react-hook-form';

interface ISelectFileCarouselBackground {
  form: ReturnType<typeof useForm<IBackgroundModel>>;
}

export function SelectFileCarouselBackground(props: ISelectFileCarouselBackground) {
  const { form } = props;
  return (
    <Stack direction="column" marginTop="0.75rem" marginBottom="0.75rem">
      <InputDragAndDropForm
        name="backgroundFile"
        control={form?.control}
        errorMessage="O arquivo deve ser no formato PNG e de até 1MB."
        buttonTitle="Escolher arquivo"
        titleChange="ou arraste ele para cá."
        title="Faça o upload do fundo*"
        accept={['image/png']}
        imageConfig={{ width: 1100, height: 650 }}
      />
    </Stack>
  );
}
