import { DefineDateValidityForm } from 'components/molecules/DefineDateValidityForm';
import { usePopupImage } from 'hooks/popupImage';

const labelsDefineDateValidity = {
  label1:
    'Com esta opção selecionada, seu pop-up será exibido apenas durante o período de resgate vigente na plataforma por tempo indeterminado.',
  label2: 'Você poderá desativar este pop-up acessando o Gerenciador de Pop-ups.',
};

export function StepTrheeDate() {
  const { createFormPopupImageStepper } = usePopupImage();
  return (
    <DefineDateValidityForm form={createFormPopupImageStepper} {...labelsDefineDateValidity} />
  );
}
