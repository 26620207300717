import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, ModalHeader, Input, InputForm } from 'components/atoms';
import { Modal } from 'components/molecules';
import { ButtonEyes } from 'globalStyles';
import { useAuthentiction } from 'hooks/authentication';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ButtonSubmit, ButtonLink } from './styled';
import { useTheme } from 'hooks/useTheme';

export const LoginPage = () => {
  const { signin, error } = useAuthentiction();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Login | Backoffice Lojas';
  }, []);

  interface IFormInputs {
    email: string;
    password: string;
  }
  const schema = yup.object({
    email: yup.string().email('Insira um email válido.').required('O email é obrigatório.'),
    password: yup
      .string()
      .required('A senha é obrigatório.')
      .min(6, 'A senha deve ter pelo menos 6 caracteres')
      .max(12, 'A senha não pode ter mais que 12 caracteres'),
  });
  const { control, handleSubmit, setError } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    signin(data.email, data.password);
  };

  useEffect(() => {
    if (error) {
      setError('email', { type: 'custom', message: '' });
      setError('password', { type: 'custom', message: '' });
    }
  }, [error]);

  const { currentTheme } = useTheme();

  return (
    <Stack width="100%" justifyContent="center">
      <Stack justifyContent="center" alignItems="center">
        <Modal iconTurbo height="457px" width="345px" background="#2F80ED">
          <ModalHeader display="flex" height="200px" background="#2F80ED">
            <img
              style={{ margin: '0 auto', display: 'flex', width: '216px' }}
              src="/assets/images/logo-fidelidade-lojas.svg"
              alt="logo"
            />
          </ModalHeader>
          <Stack
            borderRadius="0.5rem"
            direction="column"
            height="272px"
            background="#f1f1f1"
            padding="1.875rem"
            justifyContent="space-bettween"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputForm
                id="email"
                name="email"
                color={currentTheme.colors.darkGray}
                label="E-mail"
                placeholder="turbo@santander.com.br"
                iconSrc="/assets/icons/icon-email-outline.svg"
                trailing
                control={control}
              />

              <InputForm
                id="senha"
                name="password"
                label="Senha"
                color={currentTheme.colors.darkGray}
                placeholder="*****"
                iconSrc="/assets/icons/icon-password-outline.svg"
                type={showPassword ? 'text' : 'password'}
                control={control}
                trailing
                leadingIcon={() => (
                  <ButtonEyes type="button" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <img
                        src="/assets/icons/icon-eyes-open.svg"
                        alt="Ver senha"
                        title="Ver senha"
                      />
                    ) : (
                      <img
                        src="/assets/icons/icon-eyes-close.svg"
                        alt="Esconder senha"
                        title="Esconder senha"
                      />
                    )}
                  </ButtonEyes>
                )}
              />

              <ButtonSubmit type="submit" id="submit">
                <p>Entrar</p> <img src="/assets/icons/icon-arrow-rigth.svg" alt="Submit" />
              </ButtonSubmit>
            </form>

            <ButtonLink>
              <button onClick={() => navigate('/senha-esquecida')}>
                Esqueci minha senha {'>'}
              </button>
            </ButtonLink>
          </Stack>
        </Modal>
      </Stack>
    </Stack>
  );
};

export default LoginPage;
