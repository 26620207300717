import { Stack } from 'components/atoms';
import StatusUser from 'components/atoms/StatusUser';
import { limitLenghtString } from 'helpers/functions';
import React from 'react';

import { TitleEditPopup } from './styled';

interface ITitlePopupProps {
  state: boolean;
  name: string;
  isSchedule: boolean;
}
export const TitlePopup: React.FC<ITitlePopupProps> = ({ state, name, isSchedule }) => {
  return (
    <Stack
      direction="row"
      marginBottom="1.5rem"
      marginRight="1.625rem"
      marginTop="1.5rem"
      gap="1rem"
    >
      <TitleEditPopup>Editando: {limitLenghtString(name)}</TitleEditPopup>
      <StatusUser type="Popup" status={state ? (isSchedule ? 'Agendado' : state) : state} />
    </Stack>
  );
};
