import React, { useEffect, useState } from 'react';
import { ButtonRounded, Paragraph, Stack } from 'components/atoms';
import { ContainerTable, ModalContent, ModalOverlay, NotFound } from './styled';
import ButtonBack from 'components/atoms/ButtonBack';
import Table from 'components/atoms/Table';
import { IHistoricModel, getHistoric } from 'requests/Historic';
import { useTheme } from 'hooks/useTheme';
import { useNotification } from 'hooks/notification';
import { exportChamasAnalyticalComplete } from 'requests/Chamas/Analytical/Analytical';

interface IModalDownloadReportProps {
  opened: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}

const getReportType = (tipoRelatorio: number) => {
  switch (tipoRelatorio) {
    case 1:
      return 'Relatório de Resgates';
    case 2:
      return 'Relatório Chamas';
    default:
      return 'Desconhecido';
  }
};

const getStatus = (status: number, hashLink: string | null) => {
  switch (status) {
    case 1:
      return <span style={{ cursor: 'default' }}>Em andamento...</span>;
    case 2:
      return <span style={{ cursor: 'default' }}>Em andamento...</span>;
    case 3:
      return hashLink ? (
        <a href={hashLink} target="_self" rel="noopener noreferrer" download>
          Baixar
        </a>
      ) : (
        'Concluído'
      );
    case 4:
      return 'Erro';
    case 5:
      return <span style={{ color: '#c4c4c4', cursor: 'not-allowed' }}>Indisponível</span>;
    default:
      return 'Desconhecido';
  }
};

export const ModalDownloadReport: React.FC<IModalDownloadReportProps> = ({ opened, onClose }) => {
  const [rowData, setRowData] = useState<any[][]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const columnData: string[] = ['Relatório', 'Data', 'Horário', 'Download'];
  const { currentTheme } = useTheme();
  const notification = useNotification();

  const fetchData = async () => {
    setLoading(true);
    try {
      const data: IHistoricModel[] = await getHistoric();
      const formattedData = data
        .filter((item) => item.status !== 4) // Filtrar itens com status "Erro"
        .map((item) => [
          getReportType(item.tipo_relatorio),
          item.data,
          item.horario,
          getStatus(item.status, item.hash_link),
        ]);
      setRowData(formattedData);
    } catch (error) {
      notification.error('Erro ao buscar Histórico.', 'Tente novamente mais tarde.', 5000, error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  const handleExportAll = () => {
    setLoadingExport(true);
    exportChamasAnalyticalComplete()
      .then((response) => {
        window.open(response.hashLink, '_self');
      })
      .catch((error) => {
        notification.error('Erro ao baixar relatório.', 'Tente novamente mais tarde.', 5000, error);
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  useEffect(() => {
    if (opened) {
      fetchData();
    }
  }, [opened]);

  if (!opened) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <Stack direction="column" justifyContent="flex-start" gap="24px">
          <Stack direction="row" justifyContent="flex-start" alignItems="center">
            <ButtonBack onBack={onClose} padding="0px" />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Paragraph
              label="Relatórios Exportados"
              fontSize="16px"
              fontWeight="700"
              lineHeight="19px"
            />
            <Stack direction="row" justifyContent="flex-start" gap="8px">
              <ButtonRounded
                click={fetchData}
                label="Atualizar"
                startIconSrc="/assets/icons/icon-reload-red.svg"
                fontSize="9px"
                lineHeight="12px"
                outline
                sizeIcon="12px"
              />
              {rowData.length > 0 && (
                <ButtonRounded
                  click={handleExportAll}
                  label="Baixar todos os relatórios disponíveis"
                  color={currentTheme.colors.white}
                  startIconSrc="/assets/icons/icon-arrow-down-white.svg"
                  fontSize="9px"
                  sizeIcon="12px"
                  isLoading={loadingExport}
                />
              )}
            </Stack>
          </Stack>
          <ContainerTable>
            <Stack direction="row" justifyContent="flex-start">
              {rowData.length === 0 && !loading ? (
                <Stack
                  width="100%"
                  height={'400px'}
                  justifyContent="center"
                  alignItems="center"
                  padding="10px"
                >
                  <NotFound data-testid="not-found">
                    Nenhum relatório disponível. Você pode solicitar um novo relatório no Painel
                    Analítico.
                  </NotFound>
                </Stack>
              ) : (
                <Table
                  key={0}
                  header={columnData}
                  body={rowData}
                  fixedLastColumn={false}
                  loading={loading}
                />
              )}
            </Stack>
          </ContainerTable>
        </Stack>
      </ModalContent>
    </ModalOverlay>
  );
};
