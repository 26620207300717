import { InputFormCustomValidation, RadioGroupForm, Stack } from 'components/atoms';
import UploadBanner from 'components/atoms/UploadBanner';
import { ChooseAction } from 'components/molecules/ChooseAction/ChooseAction';
import { ChooseFile } from 'components/molecules/ChooseFile/ChooseFile';
import { ChooseLink } from 'components/molecules/ChooseLink/ChooseLink';
import { bannerLocalList } from 'helpers/enums';
import { IBannerForm, useCreateBanner } from 'hooks/banner';
import { useForm } from 'react-hook-form';
import { ValidityName, screenName } from 'requests/ValidityName';
import { device } from 'styles/breakpoint';

interface IBannerNameProps {
  form: ReturnType<typeof useForm<IBannerForm>>;
}
export const BannerName: React.FC<IBannerNameProps> = ({ form }) => {
  const validate = (val: string): Promise<boolean> => {
    return ValidityName({ name: val, screen: screenName.banner });
  };
  return (
    <Stack>
      <InputFormCustomValidation
        tooltipLabel="Escolha um nome para seu banner. Ele será visível apenas no Gerenciador de Banners."
        type="text"
        // id="name"
        name="name"
        placeholder="Crie um título..."
        control={form.control}
        label="Nome do banner*"
        form={form}
        callbackValidate={validate}
      />

    </Stack>
  );
};

function ContentStep1() {
  const { createFormBannerStepper, list } = useCreateBanner();
  const action = createFormBannerStepper.watch('action');
  const bannerFile = createFormBannerStepper.watch('bannerFile');

  return (
    <Stack direction="column">
      <BannerName form={createFormBannerStepper} />
      <ChooseAction form={createFormBannerStepper} label="Ação do banner*" />
      {action === 1 && <ChooseLink form={createFormBannerStepper} links={list.links} />}
      {action === 2 && (
        <ChooseFile
          form={createFormBannerStepper}
          maxSize={5000000}
          accept={['application/pdf']}
          errorMessage="O arquivo deve ser no formato PDF e de até 5MB"
        />
      )}
      <RadioGroupForm
        options={bannerLocalList}
        control={createFormBannerStepper.control}
        name="localBanner"
        label="Local do banner*"
        gap="0.3rem"
      />
      <Stack
        flex="1"
        justifyContent="center"
        alignItems="center"
        marginTop="10px"
        breakpointDownInvert={device.laptopM}
      >
        <UploadBanner
          name="bannerFile"
          control={createFormBannerStepper.control}
          file={bannerFile}
        />
      </Stack>
    </Stack>
  );
}

export default ContentStep1;
