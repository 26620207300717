import { yupResolver } from '@hookform/resolvers/yup';
import {
  InputFormCustomValidation,
  InputLabel,
  Stack,
  StackModal,
  ToggleCustom,
} from 'components/atoms';
import StatusUser from 'components/atoms/StatusUser';
import TopicSummary from 'components/atoms/TopicSummary';
import { Modal, ModalEditDate } from 'components/molecules';
import { LayoutModalContent } from 'components/templates';
import { diffDaysLabel, limitLenghtString } from 'helpers/functions';
import { useLoading } from 'hooks/loading';
import { useNotification } from 'hooks/notification';
import { useConfirm } from 'hooks/useConfirm';
import { isEmpty } from 'lodash';
import { IBackgroundModel } from 'models';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EditBackground } from 'requests/BackgroundManagement';
import { ValidityName, screenName } from 'requests/ValidityName';
import * as yup from 'yup';
import { ModuloEnum, getImage } from 'requests/Image';
import { BackgroundImage } from './BackgroundImage';
import { WrapTitle, WrapToogle } from './styled';

const validationSchemaForm = yup.object({
  name: yup.string().required('Informe o nome do banner'),
});

interface IEditCarouselBackground {
  data: IBackgroundModel;
  setDataEdit: (data: IBackgroundModel | null) => void;
  getBackgroundData: () => void;
}

interface IBackgroundForm extends IBackgroundModel {
  background?: string;
  imageName?: string;
  statusType: boolean;
  activeForTitle: boolean;
  stateActiveOriginal: string;
}

export const EditCarouselBackground: React.FC<IEditCarouselBackground> = ({
  data,
  setDataEdit,
  getBackgroundData,
}): JSX.Element => {
  const { confirm } = useConfirm();
  const [showValidity, setShowValidity] = useState(false);
  const [ImagemAlterada, setImagemAlterada] = useState(false);
  const { handleHideLoading, handleShowLoading } = useLoading();
  const notify = useNotification();

  const editBackgroundForm = useForm<IBackgroundForm>({
    resolver: yupResolver(validationSchemaForm),
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      handleShowLoading();
      try {
        if (data.id !== undefined) {
          const { image } = await getImage({ moduloId: ModuloEnum.BACKGROUND, id: data.id });
          const defaultValues = {
            id: data.id,
            active: data.active,
            activeForTitle: data.active,
            stateActiveOriginal: data.status,
            status: data.status,
            startDate: data.startDate,
            endDate: data.endDate,
            defineDate: data.defineDate,
            background: image,
            endDateUndefined: data.endDateUndefined,
            name: data.name,
            imageName: undefined,
            statusType: data.status === 'Agendado' || data.status === 'Ativo',
          };
          editBackgroundForm.reset(defaultValues);
        }
      } catch (error) {
        console.error('Error fetching image:', error);
        notify.error('Erro ao buscar a imagem.', 'Tente novamente mais tarde.');
      } finally {
        handleHideLoading();
      }
    };

    if (data) {
      fetchInitialData();
    }
  }, [data]);

  const cancelEditModal = () => {
    if (editBackgroundForm.formState.isDirty) {
      void confirm().then(() => {
        setDataEdit(null);
      });
    } else {
      setDataEdit(null);
    }
  };

  const handleUploadCarouselBackground = async () => {
    try {
      handleShowLoading();

      const {
        id,
        startDate,
        endDate,
        defineDate,
        background,
        endDateUndefined,
        name,
        statusType,
        imageName,
        status,
      } = editBackgroundForm.getValues();
      const payload = {
        data: {
          id,
          name,
          defineDate: endDateUndefined ? 3 : defineDate,
          startDate: defineDate === 1 || endDateUndefined ? startDate : undefined,
          endDate: defineDate === 1 ? endDate : undefined,
          status,
          endDateUndefined,
          active: statusType,
        },
        nameImage: ImagemAlterada ? imageName : null,
        imageAltered: ImagemAlterada,
        imagebase64: ImagemAlterada ? background?.replace(/^data:image\/[a-z]+;base64,/, '') : null,
        typeValidity: endDateUndefined ? 3 : defineDate,
      };
      await EditBackground(payload)
        .then(() => {
          notify.success('Plano de fundo atualizado com sucesso.', `O ${name} foi alterado.`);
          getBackgroundData();
        })
        .catch((error) => {
          notify.error('Houve um problema.', error.message, 5000);
        });
    } catch (e) {
      notify.error('Erro ao atualizar o fundo.', 'Tente novamente mais tarde.');
      return false;
    } finally {
      handleHideLoading();
    }
  };

  const {
    name,
    activeForTitle,
    background,
    stateActiveOriginal,
    active,
    startDate,
    endDate,
    endDateUndefined,
    status,
    defineDate,
    imageName,
  } = editBackgroundForm.watch();

  const isSchedule = () => {
    return status === 'Agendado';
  };

  const getStartDate = (): Date => {
    return startDate ?? new Date();
  };

  const getEndDate = (): Date => {
    return endDate ?? new Date();
  };

  const inactiveBackground = () => {
    const options = {
      width: '33.563rem',
      height: '19.25rem',
      question: 'Inativar o Plano de Fundo',
      information: `Você está Inativando um Fundo que está Agendado para inicio em ${getStartDate().toLocaleDateString()}. Deseja continuar?`,
      labelButtonNegative: 'Não',
      labelButtonConfirm: 'Sim',
    };
    void confirm(options).then(() => {
      void handleUploadCarouselBackground();
    });
  };

  const validate = (val: string): Promise<boolean> => {
    return ValidityName({ name: val, screen: screenName.background, id: data.id });
  };
  if (data) {
    return (
      <StackModal>
        <Modal close={cancelEditModal} position="absolute" zIndex="3" fullScreenTabled>
          <Stack alignItems="center" direction="row-reverse" height="100%">
            <LayoutModalContent
              title="Editando um plano de fundo"
              handleCancel={cancelEditModal}
              footer={{
                tip: !active
                  ? 'Para editar este plano de fundo, você deve reativá-lo primeiro.'
                  : '',
                onSave: () => {
                  if (stateActiveOriginal === 'Agendado' && !active) {
                    inactiveBackground();
                  } else {
                    void handleUploadCarouselBackground();
                  }
                },
                enableButtonSave: () =>
                  editBackgroundForm.formState.isValid &&
                  editBackgroundForm.formState.isDirty &&
                  isEmpty(editBackgroundForm.formState.errors),
                onClose: cancelEditModal,
              }}
              showHeaderFullScreen
            >
              <WrapTitle>
                <h1>Editando: {limitLenghtString(name)}</h1>
                <StatusUser
                  type="Banner"
                  status={
                    activeForTitle ? (isSchedule() ? 'agendado' : activeForTitle) : activeForTitle
                  }
                />
              </WrapTitle>
              <Stack justifyContent="center">
                <BackgroundImage
                  background={background ?? ''}
                  setBackground={(image, nomeImagem) => {
                    editBackgroundForm.setValue('background', image, {
                      shouldDirty: true,
                    });
                    editBackgroundForm.setValue('imageName', nomeImagem, {
                      shouldDirty: true,
                    });
                    setImagemAlterada(true);
                  }}
                  disabled={!active}
                />
              </Stack>
              <WrapToogle>
                <InputFormCustomValidation
                  type="text"
                  name="name"
                  label="Nome do fundo*"
                  placeholder="Crie um título..."
                  tooltipLabel="Escolha um nome para seu fundo. Ele será visível apenas no Gerenciador de fundo."
                  control={editBackgroundForm.control}
                  onChange={() => {
                    editBackgroundForm.setValue('imageName', imageName, {
                      shouldDirty: true,
                    });
                  }}
                  disabled={!active}
                  form={editBackgroundForm}
                  callbackValidate={validate}
                />

                <InputLabel
                  type="text"
                  id="name"
                  title="Status*"
                  placeholder="Crie um título..."
                  information="Escolha um status para seu plano de fundo. Ele será visível apenas no Gerenciador de Plano de Fundo."
                >
                  <ToggleCustom
                    defaultChecked={!!(data && active)}
                    isSchedule={isSchedule()}
                    onChange={(e) => {
                      const value = e.target.checked;

                      editBackgroundForm.setValue('active', value, {
                        shouldDirty: true,
                      });
                      editBackgroundForm.setValue('statusType', value, {
                        shouldDirty: true,
                      });
                    }}
                  />
                </InputLabel>
              </WrapToogle>

              <TopicSummary
                disabled={!active}
                itens={
                  defineDate === 2
                    ? ['Exibir apenas durante período de resgate']
                    : [
                        `Data início: ${getStartDate().toLocaleDateString()} ${diffDaysLabel(
                          getStartDate()
                        )}`,
                        `Data fim: ${
                          endDateUndefined ? 'Indefinida' : getEndDate().toLocaleDateString()
                        }`,
                      ]
                }
                title="Vigência do fundo*"
                link="Editar >"
                click={() => setShowValidity(true)}
                stackCSS={{ gap: '2rem' }}
              />
              {showValidity && (
                <ModalEditDate
                  form={editBackgroundForm}
                  title={`Editando "${name}"`}
                  onClose={() => setShowValidity(false)}
                  hideValidity
                />
              )}
            </LayoutModalContent>
          </Stack>
        </Modal>
      </StackModal>
    );
  }
  return <></>;
};
