import { DateRange } from 'react-date-range';
import styled from 'styled-components';

export const DateRangeCustom = styled(DateRange)`
  * {
    font-family: 'Poppins', sans-serif !important;
  }

  .rdrMonthName {
    display: none;
  }

  &.rdrCalendarWrapper.rdrDateRangeWrapper {
    width: 100%;
    border-radius: 0.5rem;
  }

  & .rdrMonthAndYearWrapper {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 0.5rem 0.5rem 0 0;
    padding-top: 0;
  }

  & .rdrDateDisplayWrapper {
    display: none;
  }

  & .rdrMonthAndYearPickers select {
    color: ${(props) => props.theme.colors.white}; 
    font-weight: 600;
  }

  & .rdrDayToday .rdrDayNumber span:after{
    background: ${({ theme }) => theme.colors.primary};
  }

  & .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    background: ${({ theme }) => theme.colors.primary};
  }

  & .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    background: rgba(255, 255, 255, 0.09);
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    pointer-events: none;
    border: 0px solid ${({ theme }) => theme.colors.primary};
    z-index: 1;
  }

  & .rdrDayStartPreview{
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    left: 0px;
  }

  & .rdrDayInPreview{
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  
  & .rdrDayEndPreview{
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    right: 2px;
    right: 0px;
  }

  &.rdrPprevButton i {
    border-width: 0;
    border-color: transparent;
    /* define content < */
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 0;
    border-color: ${(props) => props.theme.colors.white}; 
    display: inline-block;
    padding: 0.125rem;
  }
`;
