export const EnumErrors = {
  Login:
    'Não foi possível fazer seu login. Seu e-mail ou senha estão incorretos. Verifique seus dados e tente novamente.',
};

// create enum object with color for status
export const StatusEnum = {
  ACTIVE: { description: 'Ativo', key: 'Ativo', color: '#00D217' },
  WAITING: {
    description: 'Aguardando',
    key: 'Aguardando confirmação',
    color: '#2F80ED',
  },
  INACTIVE: { description: 'Inativo', key: 'Inativo', color: '#FF8332' },
};

export const ObjectUserTypes = [
  { value: 5, label: 'Backoffice' },
  { value: 6, label: 'Administrador' },
];

export const ObjectUserStatusBackoffice = [{ value: 5, label: 'Backoffice' }];

export const ObjectUserStatus = [
  { value: 'ac', label: 'Aguardando confirmação' },
  { value: 'a', label: 'Ativo' },
  { value: 'i', label: 'Inativo' },
];

export const ObjectBannersStatus = {
  INACTIVE: { value: false, label: 'Inativo', color: '#FF8332' },
  ACTIVE: { value: true, label: 'Ativo', color: '#00D217' },
  SCHEDULE: { value: 'agendado', label: 'Agendado', color: '#2f80ed' },
};

export const ObjectCategories = [
  { value: 1, label: 'Bronze' },
  { value: 2, label: 'Silver' },
  { value: 3, label: 'Gold' },
  { value: 4, label: 'Platinum' },
];

export const ObjectSegments = [
  { value: 1, label: 'Revendas' },
  { value: 2, label: 'Intermediate Dealers' },
  { value: 3, label: 'Big dealers' },
  { value: 4, label: 'Revendas varejo' },
];

export function getStatus(status: boolean): { description: string; key: string; color: string } {
  if (status) return { description: 'Ativo', key: 'Ativo', color: '#00D217' };
  return { description: 'Inativo', key: 'Inativo', color: '#FF8332' };
}

export const popupButtonIcons = [
  { value: '1', src: '/assets/icons/icon-a.svg' },
  { value: '2', src: '/assets/icons/icon-b.svg' },
  { value: '3', src: '/assets/icons/icon-c.svg' },
  { value: '4', src: '/assets/icons/icon-d.svg' },
  { value: '5', src: '/assets/icons/icon-e.svg' },
  { value: '6', src: '/assets/icons/icon-f.svg' },
  { value: '7', src: '/assets/icons/icon-g.svg' },
  { value: '8', src: '/assets/icons/icon-h.svg' },
];

// export enum popupButtonColorsMap {
//   '#F3123C',
//   '#2E2B37',
//   '#00D217',
// }

export enum popupButtonColorsMap {
  '#F3123C' = 1,
  '#2E2B37' = 2,
  '#00D217' = 3,
}

export const popupButtonColors = [
  { value: 1, color: popupButtonColorsMap[1], label: 'Vermelho' },
  { value: 2, color: popupButtonColorsMap[2], label: 'Cinza' },
  { value: 3, color: popupButtonColorsMap[3], label: 'Verde' },
];

export const popupActionList = [
  { value: 1, label: 'Acessar um link ou página' },
  { value: 2, label: 'Abrir um arquivo' },
  { value: 4, label: 'Fechar pop-up' },
];

export const popupActionListPopUp = [
  { value: 1, label: 'Acessar um link ou página' },
  { value: 2, label: 'Abrir um arquivo' },
  { value: 3, label: 'Nenhuma ação' },
];

export const popupActionListAll = [
  { value: '1', label: 'Acessar um link ou página' },
  { value: '2', label: 'Abrir um arquivo' },
  { value: '3', label: 'Nenhuma ação' },
  { value: '4', label: 'Fechar pop-up' },
];

export const actionList = [
  { value: 1, label: 'Acessar um link ou página' },
  { value: 2, label: 'Abrir um arquivo' },
  { value: 3, label: 'Nenhuma ação' },
];

export const classificationPeriodList = [
  { value: 1, label: 'Classificação do período atual' },
  { value: 2, label: 'Classificação do período passado' },
];

export const planConditionList = [
  { value: 1, label: 'Maior que' },
  { value: 2, label: 'Igual a' },
];

export const popupButtonsCount = [
  { value: 1, label: '1 Botão' },
  { value: 2, label: '2 Botões' },
  { value: 3, label: 'Nenhum botão' },
];

export const defineDateList = [
  { value: 1, label: 'Definir datas' },
  { value: 2, label: 'Exibir apenas durante período de resgate' },
];

export const defineDateOnlyDates = [{ value: 1, label: 'Definir datas' }];

export const linkTypeList = [
  { value: 1, label: 'Link externo' },
  { value: 2, label: 'Página interna' },
];

export const CNPJWhoCanSee = [
  { value: 1, label: 'Todos' },
  { value: 2, label: 'Fazer upload de uma lista' },
];

export const carouselPictureType = [
  { value: 1, label: 'Normal' },
  { value: 3, label: 'Campanha' },
];

export const bannerLocalList = [
  { value: 1, label: 'Home +Fidelidade Turbo' },
  { value: 2, label: 'Chamas' },
  { value: 0, label: 'Ambos' },
];

export const reportsAnalyticList = [
  { value: 1, label: 'Relatório de Resgates' },
  { value: 2, label: 'Relatório Chamas' },
];

export const optionsSearchFilterAnalytical = [
  { value: 1, label: 'CNPJ' },
  { value: 2, label: 'TAB' },
  { value: 3, label: 'Nome Fantasia' },
];

export const Months = [
  { value: 1, label: 'Janeiro' },
  { value: 2, label: 'Fevereiro' },
  { value: 3, label: 'Março' },
  { value: 4, label: 'Abril' },
  { value: 5, label: 'Maio' },
  { value: 6, label: 'Junho' },
  { value: 7, label: 'Julho' },
  { value: 8, label: 'Agosto' },
  { value: 9, label: 'Setembro' },
  { value: 10, label: 'Outubro' },
  { value: 11, label: 'Novembro' },
  { value: 12, label: 'Dezembro' },
];

export const GroupSupplier = {
  1: 'Fornecedor',
  2: '+Fidelidade',
};

export const providerList = [
  { value: 1, label: 'Webmotors' },
  { value: 2, label: 'Incentivale' },
];

export enum ModuloEnum {
  BACKGROUND = 1,
  BANNER = 2,
  DICIONARIO = 3,
  POPUP = 4,
}

export enum ChamasAnalyticalExportType {
  PARTIAL = 1,
  COMPLETE = 2,
}
