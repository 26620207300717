import { Button, Stack } from 'components/atoms';
import { Control, Controller } from 'react-hook-form';
import { InputStyled } from './styled';
import { Icon } from 'globalStyles';
import { useTheme } from 'hooks/useTheme';
import { Search } from 'components/atoms/Icons/Search';

interface IInputFormProps {
  name: string;
  form: any;
  placeholder?: string;
  maxLength?: number;
  type?: string;
  handleSearch?: () => void;
  disabled?: boolean;
}

export const SearchWithSelectInput: React.FC<IInputFormProps> = ({
  name,
  form,
  placeholder = 'Digite aqui...',
  maxLength,
  type = 'text',
  handleSearch,
  disabled,
}) => {
  const { currentTheme } = useTheme();
  const something = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && handleSearch) {
      handleSearch();
    }
  };

  const buttonClick = () => {
    if (handleSearch && !disabled) handleSearch();
  };

  const justNumbersInput = (string: string, prevValue: string, maxLength: number): string => {
    const numsStr = string.replace(/[^0-9]/g, '');

    if (maxLength && numsStr.length > maxLength) {
      return prevValue;
    }
    return numsStr || '';
  };

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field, field: { value }, fieldState: { error } }) => {
        return (
          <Stack width="100%" direction="column">
            <Stack direction="column">
              <Stack
                background="white"
                borderRadius="0px 8px 8px 0px"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <InputStyled
                  {...field}
                  placeholder={placeholder}
                  value={field.value || ''}
                  data-testid="input-form"
                  maxLength={type === 'number' ? undefined : maxLength ?? 9999} // se for number, não limita o tamanho aqui
                  onKeyDown={(e) => something(e)}
                  disabled={disabled}
                  onChange={(e) => {
                    form.clearErrors(name);
                    let newValue = e.target.value;
                    const oldValue = field.value;
                    field.onChange(
                      type === 'number'
                        ? justNumbersInput(newValue, oldValue, maxLength ?? 9999)
                        : newValue
                    );
                  }}
                  isError={error?.message ? true : false}
                />
                <Button
                  display="flex"
                  alignItems="center"
                  width="29px"
                  height="30px"
                  onClick={buttonClick}
                  data-testid="set-serach-null"
                  background={currentTheme.colors.secondary}
                  borderRadius={`0 ${currentTheme.borderRadius.md} ${currentTheme.borderRadius.md} 0`}
                >
                  <Stack margin="0 auto">
                    <Search width={12} height={12} />
                  </Stack>
                </Button>
              </Stack>
              <Stack height="1.25rem">
                <p style={{ color: currentTheme.colors.error, fontSize: '9px' }}>
                  {error?.message ? error.message : null}
                </p>
              </Stack>
            </Stack>
          </Stack>
        );
      }}
    />
  );
};
