import styled from 'styled-components';

export const Check = styled.img`
  height: ${({ height }) => height};
`;

export const Item = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  color: ${(props) => props.theme.colors.secondary}; 
  word-break: break-all;
`;

export const ItemRow = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors.secondary}; 
  word-break: break-all;
  line-height: 1;
`;

interface IItemRowProps {
  gap?: string;
  marginBottom?: string;
}
export const Row = styled.div<IItemRowProps>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap ?? '0px'};
  margin-bottom: ${(props) => props.marginBottom ?? '0px'};
`;

interface IItemRowProps {
  columns?: string;
  columnsLogicPadding?: number;
}
export const StackItems = styled.div<IItemRowProps>`
  display: grid;
  grid-template-columns: ${(props) => props.columns ?? '1fr'};
  width: 100%;
  padding-left: 18px;
  ${({ columnsLogicPadding }) => columnsLogicPadding === 2 && 'padding-left: 0px;'}
`;

export const Link = styled.a`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: ${(props) => props.theme.colors.primary}; 
  cursor: pointer;
  margin-left: 0.375rem;
`;
