import React, { useState } from 'react';
import { Button, IconNext } from './styled';
import { useTheme } from 'hooks/useTheme';
import useIconThemes from 'hooks/iconThemes';

interface IButtonRounded {
  id?: string;
  click?: () => void;
  background?: string;
  disabled?: boolean;
  readView?: boolean;
  color?: string;
  label?: string;
  iconSrc?: string;
  margin?: string;
  marginRight?: string;
  width?: string;
  labelHover?: boolean;
  padding?: string;
  outline?: boolean;
  startIconSrc?: string;
  fontSize?: string;
  sizeIcon?: string;
  lineHeight?: string;
  border?: string;
  isLoading?: boolean;
}

export const ButtonRounded: React.FC<IButtonRounded> = ({
  id,
  click,
  background,
  disabled,
  readView,
  color,
  label,
  iconSrc,
  margin,
  marginRight,
  width,
  labelHover,
  padding,
  outline,
  startIconSrc,
  fontSize,
  sizeIcon,
  lineHeight,
  border,
  isLoading,
}): JSX.Element => {
  const [showLabel, setShowLabel] = useState(!labelHover);
  const iconThemes = useIconThemes();
  const { currentTheme } = useTheme();
  startIconSrc = isLoading ? iconThemes?.[currentTheme.theme].loadingImgSrc : startIconSrc;
  return (
    <Button
      id={id}
      width={width}
      background={background}
      disabled={disabled || isLoading}
      readView={readView}
      color={color}
      onClick={click}
      margin={margin}
      marginRight={marginRight}
      padding={padding}
      onMouseEnter={() => setShowLabel(true)}
      onMouseLeave={() => setShowLabel(!labelHover)}
      data-testid="button-rounded"
      outline={outline}
      fontSize={fontSize}
      lineHeight={lineHeight}
      border={border}
    >
      {startIconSrc ? <IconNext src={startIconSrc} alt="start-icon" sizeIcon={sizeIcon} /> : null}
      {showLabel && <p>{label}</p>}
      {iconSrc ? <IconNext src={iconSrc} alt="icon-enter" sizeIcon={sizeIcon} /> : null}
    </Button>
  );
};
