import {
  ButtonRounded,
  Input,
  InputCheckbox,
  Paragraph,
  Stack,
  StackModal,
} from 'components/atoms';
import ButtonBack from 'components/atoms/ButtonBack';
import { Modal } from 'components/molecules';
import { Icon } from 'globalStyles';
import { GroupSupplier } from 'helpers/enums';
import { useNotification } from 'hooks/notification';
import { useTheme } from 'hooks/useTheme';
import { IAnalyticalColumnEntity } from 'models/entities/AnalyticalColumn.entity';
import { useEffect, useMemo, useState } from 'react';

interface ISelectColumnsProps {
  handleClose: () => void;
  columns: { [key: string]: IAnalyticalColumnEntity[] };
  columnsGroup: string[];
  handleSetColumns: (columns: { [key: string]: IAnalyticalColumnEntity[] }) => void;
  type: number;
}

export const SelectColumns: React.FC<ISelectColumnsProps> = ({
  handleClose,
  columns,
  columnsGroup,
  handleSetColumns,
  type,
}) => {
  const [search, setSearch] = useState('');
  const [textFilter, setTextFilter] = useState('');
  const [columnsInternal, setColumnsInternal] = useState<{
    [key: string]: IAnalyticalColumnEntity[];
  }>(columns);

  const { currentTheme } = useTheme();
  const notify = useNotification();

  const handleCheck = (column: IAnalyticalColumnEntity) => {
    let newColumns = { ...columnsInternal };
    newColumns[column.groupName] = newColumns[column.groupName].map((item) => {
      if (item.id === column.id) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });
    setColumnsInternal(newColumns);
  };

  useEffect(() => {
    const delayDefounce = setTimeout(() => {
      setTextFilter(search);
    }, 300);
    return () => clearTimeout(delayDefounce);
  }, [search]);

  const columnsGroupFiltered = [GroupSupplier[1], GroupSupplier[2]].filter(
    (columnsGroup) =>
      columnsInternal[columnsGroup].filter((item) =>
        item.label.toLowerCase().includes(textFilter.toLowerCase())
      ).length > 0
  );

  const handleSearch = () => {
    handleSetColumns(columnsInternal);
    handleClose();
  };

  const titleButtonSearch = `Atualizar Relatório ${type === 1 ? 'de Resgates' : 'Chamas'}`;
  const amountColumnsSelected = columnsGroup.reduce((acc, group) => {
    return acc + columnsInternal[group].filter((column) => column.checked).length;
  }, 0);

  useEffect(() => {
    if (amountColumnsSelected < 2) {
      notify.error(
        'Dados insuficientes.',
        'Você deve selecionar pelo menos 2 colunas para o relatório.',
        3000
      );
    }
  }, [amountColumnsSelected]);

  return (
    <StackModal>
      <Modal
        minWidth={'710px'}
        height={'400px'}
        background={'#F1F1F1'}
        zIndex="3"
        display="flex"
        flexDirection="column"
        fullScreenTabled
        padding="24px"
      >
        <Stack width="100%" height="100%" direction="column" gap="24px">
          <Stack direction="column" width="100%">
            <ButtonBack onBack={handleClose} padding="0px" />
          </Stack>
          <Stack width="100%" justifyContent="space-between" alignItems="center">
            <Paragraph
              label="Escolha as colunas do relatório"
              fontSize="16px"
              lineHeight="19px"
              fontWeight="bold"
            />
            <Stack width="220px">
              <Input
                placeholder={'Procurar uma coluna...'}
                value={search}
                justLabel
                onChange={(e) => {
                  if (setSearch) setSearch(e.target.value);
                }}
                leadingIcon={() =>
                  search ? (
                    <Icon
                      onClick={() => {
                        if (setSearch) setSearch('');
                      }}
                      src="/assets/icons/icon-close-black.svg"
                      width={'22px'}
                      data-testid="set-serach-null"
                    />
                  ) : (
                    <Icon src="/assets/icons/icon-search.svg" width={'22px'} />
                  )
                }
              />
            </Stack>
          </Stack>
          <Stack direction="column" gap="24px">
            {columnsGroupFiltered.map((columnsGroup: string, index: number) => (
              <Stack key={`columns-${index}`} direction="column" gap="12px">
                <Paragraph
                  label={`Informações via ${columnsGroup}`}
                  fontSize="12px"
                  fontWeight="bold"
                  lineHeight="14px"
                />
                <Stack display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="14px">
                  {columnsInternal[columnsGroup]
                    .filter((item) => item.label.toLowerCase().includes(textFilter.toLowerCase()))
                    .map((item, index) => {
                      return (
                        <InputCheckbox
                          label={item.label}
                          value={item.id}
                          checked={item.checked}
                          onClick={() => handleCheck(item)}
                          key={`columns-${index}`}
                        />
                      );
                    })}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack width="100%" justifyContent="flex-end" marginTop="16px">
          <ButtonRounded
            label={titleButtonSearch}
            color={currentTheme.colors.white}
            iconSrc="/assets/icons/icon-arrow-rigth.svg"
            fontSize="9px"
            lineHeight="8px"
            sizeIcon="12px"
            disabled={amountColumnsSelected < 2}
            click={handleSearch}
          />
        </Stack>
      </Modal>
    </StackModal>
  );
};
