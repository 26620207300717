import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Stack from '../Stack';
import { useTheme } from 'hooks/useTheme'; // Importe o hook useTheme

interface ITooltipProps {
  children?: React.ReactNode;
  width?: string;
  direction?: 'top' | 'right' | 'bottom' | 'left';
  title?: string;
  content?: React.ReactNode;
  enableHover?: boolean;
  isOpenTootip?: boolean;
  delayHide?: number;
}

const Tooltip = ({
  children,
  width,
  direction,
  title,
  content,
  enableHover = true,
  isOpenTootip,
  delayHide = 200,
}: ITooltipProps) => {
  const stackRef = useRef<HTMLDivElement>(null);
  const { currentTheme } = useTheme();

  const randomId = useMemo(() => (Math.random() * Math.random()).toString(), []);

  const [active, setActive] = useState<boolean | undefined>(isOpenTootip);

  useEffect(() => {
    setActive(isOpenTootip);
    if (stackRef?.current) {
      if (active) {
        ReactTooltip.show(stackRef.current);
      } else {
        ReactTooltip.hide(stackRef.current);
      }
    }
  }, [active, isOpenTootip]);

  return (
    <>
      {children}
      <Stack data-tip="" data-for={randomId} ref={stackRef} data-testid="tooltip-container" />
      <ReactTooltip
        id={randomId}
        delayHide={delayHide}
        place={direction ?? 'right'}
        backgroundColor={currentTheme.colors.error}
        className="tooltip"
        getContent={() => {
          return (
            <Stack width={width} direction="column" textAlign="center">
              {title ? (
                <>
                  <p className="tooltipTitle">{title}</p>
                  <p>{content}</p>
                </>
              ) : (
                <>{content}</>
              )}
            </Stack>
          );
        }}
      />
    </>
  );
};

export default Tooltip;
