import styled from 'styled-components';

export interface IParagraphStyledProps {
  fontSize?: string;
  lineHeight?: string;
  textAlign?: string;
  color?: string;
  fontWeight?: string;
  marginBottom?: string;
  marginTop?: string;
  textDecoration?: string;
}
export const P = styled.p<IParagraphStyledProps>`
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  text-decoration: ${({ textDecoration }) => textDecoration};
`;
