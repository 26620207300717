import { Stack } from 'components/atoms';
import { AlertPreview } from 'globalStyles';
import React, { useEffect, useState } from 'react';
import { device } from 'styles/breakpoint';

import { StackSectionUploadImageBorder } from '../PreviewPopupImage/styled';
import { StackContainer, StackImage, StackImageContainer } from './styled';

interface IPreviewCarouselBackground {
  file?: File;
  url?: string;
}

export const PreviewCarouselBackground: React.FC<IPreviewCarouselBackground> = ({
  file,
  url,
}): JSX.Element => {
  const backgroundImage = () => {
    if (!file) return url ?? '/assets/background/bg-carousel-default.png';

    return window.URL.createObjectURL(file);
  };

  const [background, setBackground] = useState('');

  useEffect(() => {
    setBackground(backgroundImage());
  }, [file]);

  return (
    <StackContainer direction="column" justifyContent="center">
      <Stack flex="1">
        <Stack flex="1" alignItems="center" justifyContent="center">
          <Stack direction="column" flex="1">
            <Stack justifyContent="center" breakpointDown={device.laptopM}>
              <AlertPreview>Pré-visualização</AlertPreview>
            </Stack>
            <Stack paddingTop="5px" borderRadius="10px" justifyContent="center">
              <StackSectionUploadImageBorder>
                <StackImageContainer backgroundImage={background} borderTop="5px solid #F3123C;">
                  <StackImage backgroundImage="/assets/background/carousel-content.png"></StackImage>
                </StackImageContainer>
              </StackSectionUploadImageBorder>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </StackContainer>
  );
};

