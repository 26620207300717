import { ButtonRounded, Label, Paragraph, RadioGroupForm, Button, Stack } from 'components/atoms';
import ConfirmDefault from 'components/atoms/ConfirmDefault';
import { CNPJWhoCanSee } from 'helpers/enums';
import { useLoading } from 'hooks/loading';
import { useNotification } from 'hooks/notification';
import { useTheme } from 'hooks/useTheme';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ValidityTabList } from 'requests/Validities';

interface ICNPJSeeProps {
  label: string;
  form: any;
}

export const CNPJSee: React.FC<ICNPJSeeProps> = ({ label, form }) => {
  const { handleHideLoading, handleShowLoading } = useLoading();
  const [fileOfInvalidTabs, setFileOfInvalidTabs] = useState('');
  const [numberOfInvalidTabs, setNumberOfInvalidTabs] = useState(0);
  const [showModalTabsUndefined, setShowModalTabsUndefined] = useState(false);
  const [file, setFile] = useState<File>();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const notify = useNotification();
  const { currentTheme } = useTheme();

  const [whoCanSee, csvFile] = form.watch(['whoCanSee', 'csvFile']);

  const validationFileInternalFunction = async (fileImageBanner: File) => {
    handleShowLoading();
    await ValidityTabList({
      fileImageBanner,
    })
      .then((response) => {
        if (response.numberOfInvalidTabs > 0) {
          setFileOfInvalidTabs(response.fileOfInvalidTabs);
          setNumberOfInvalidTabs(response.numberOfInvalidTabs);
          setShowModalTabsUndefined(true);
        } else {
          form.setValue('csvFile', fileImageBanner, { shouldDirty: true });
          form.trigger('csvFile');
        }
      })
      .catch((error) => {
        notify.error('Erro ao atualizar banner.', error.message);
      });
    handleHideLoading();
  };

  const onFilechange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files) {
      const file = e.target.files[0];

      if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel') {
        setFile(file);
        validationFileInternalFunction(file);
      } else {
        notify.error('Arquivo no formato inválido.', 'O arquivo deve ser no formato CSV.');
      }
    }
  };

  const onBtnClick = () => {
    inputFileRef?.current?.click();
  };
  const handleNegativeConfirmDefault = () => {
    setShowModalTabsUndefined(false);

    if (inputFileRef?.current?.value) inputFileRef.current.value = '';
  };

  const exportTabsUndefined = () => {
    if (fileOfInvalidTabs) {
      const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${fileOfInvalidTabs}`;
      const link = document.createElement('a');
      link.href = file;
      link.target = '_blank';
      link.download = 'TabsInvalidas.xlsx';
      link.click();
    }
  };

  return (
    <>
      <ConfirmDefault
        width="537px"
        height="360px"
        isOpen={showModalTabsUndefined}
        question={`Este arquivo possui ${numberOfInvalidTabs} TABs não <br/> identificadas. `}
        information="Deseja continuar?"
        onClose={() => handleNegativeConfirmDefault()}
        onNegative={() => handleNegativeConfirmDefault()}
        onConfirm={() => {
          setShowModalTabsUndefined(false);
          form.setValue('csvFile', file, { shouldDirty: true });
          form.trigger('csvFile');
        }}
        labelButtonNegative={'< Cancelar'}
        labelButtonConfirm={'Continuar >'}
        title="Alerta:"
      >
        <Paragraph fontSize="12px" marginTop="20px" color="#FFFFFF">
          Estas TABs não conseguirão visualizar o pop-up publicado.
        </Paragraph>
        <Button
          fontSize="16px"
          background="#F3123C"
          borderRadius="25px"
          padding="10px"
          color="#fff"
          fontWeight="700"
          marginTop="20px"
          onClick={() => exportTabsUndefined()}
        >
          Baixar relatório de TABs não identificadas {'>'}
        </Button>
      </ConfirmDefault>
      <Stack direction="column">
        <RadioGroupForm
          control={form.control}
          options={CNPJWhoCanSee}
          name="whoCanSee"
          label={`Quais CNPJs poderão ver o ${label}?*`}
        />

        {whoCanSee === 2 ? (
          <Stack alignItems="center" marginBottom="0.75rem" gap="0.5rem">
            <ButtonRounded
              background={currentTheme.colors.primary}
              color="#ffffff"
              label="Escolher arquivo"
              click={onBtnClick}
            />

            <input hidden ref={inputFileRef} accept=".csv" type="file" onChange={onFilechange} />
            <Label title={csvFile?.name} letterSpacing="-0.02em" lineHeight="120%" />
          </Stack>
        ) : null}
      </Stack>
    </>
  );
};
