import { PictureDictionaryManagement } from 'components/organisms/PictureDictionaryManagement';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import { turboCarroselPictureDicionaryURL } from 'routers/routes';

export const TurboPictureDictionaryManagement = () => {
  return (
    <PictureDictionaryManagement
      subMenu={subMenuTurbo}
      routerBack={turboCarroselPictureDicionaryURL}
    />
  );
};
