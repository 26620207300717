import styled, { css } from 'styled-components';

interface IButtonProps {
  variant?: 'outlined' | 'contained';
  fontSize?: string;
}

export const Button = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize ?? '0.75rem'} ;
  background-color: ${(props) => props.theme.colors.primary}; 
  color: ${(props) => props.theme.colors.white}; 
  gap: 0.25rem;
  border-radius: 1rem;
  padding: 7px;

  ${({ variant }) => variant === 'outlined' && css`
    background-color: transparent;
    color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary};
  `}
`;

export const IconDownload = styled.img`
  height: 18px;
`;
