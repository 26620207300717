import { Loading, Stack } from 'components/atoms';
import Pagination from 'components/atoms/Pagination';
import Table from 'components/atoms/Table';
import { useMemo } from 'react';
import { useTheme } from 'hooks/useTheme'; // Importando o useTheme

import { HeaderListDefault } from './HeaderList';
import { NotFound, StackTableContent } from './styled';
import useIconThemes from 'hooks/iconThemes';

interface IListDefault {
  name: string;
  img?: string;
  pageLimit?: number;
  columnsHeader?: Array<string>;
  totalRecords?: number;
  search?: string;
  setSearch?: (value: string) => void;
  loading?: boolean;
  list?: Array<Array<string | number | JSX.Element | undefined>>;
  setCurrentPage: (value: number) => void;
  widthSearchBox?: string;
  subTitleHeader?: string;
  textSearch?: string;
  showHeader?: boolean;
  title?: string;
}

export const ListDefault: React.FC<IListDefault> = ({
  name,
  img,
  pageLimit = 5,
  columnsHeader = [],
  totalRecords = 0,
  search,
  setSearch,
  loading,
  list,
  setCurrentPage,
  subTitleHeader,
  textSearch,
  showHeader = true,
  title,
}): JSX.Element => {
  // Calculando o height da tabela, 53 é o height do header da tabela e 43 é o height de cada linha da tabela
  const heightTable = useMemo(() => 53 + 43 * pageLimit, [pageLimit]);

  return (
    <>
      {showHeader && (
        <HeaderListDefault
          setSearch={setSearch}
          search={search}
          img={img || ''}
          name={name}
          subTitleHeader={subTitleHeader}
          textSearch={textSearch}
          title={title}
        />
      )}
      <>
        <StackTableContent showShadow={!loading && totalRecords > 0}>
          <Stack width="676px">
            {loading || totalRecords <= 0 ? (
              <Stack
                width="100%"
                height={`${heightTable}px`}
                justifyContent="center"
                alignItems="center"
                padding="10px"
              >
                {loading ? (
                  <Loading data-testid="loading-spinner" />
                ) : (
                  <NotFound data-testid="not-found">Nenhum resultado encontrado.</NotFound>
                )}
              </Stack>
            ) : (
              <Table key={0} header={columnsHeader} body={list} />
            )}
          </Stack>
        </StackTableContent>
        <Pagination
          totalItems={totalRecords} // total de itens
          quantityItems={pageLimit} // quantidade de itens por pagina
          pagesAmount={5} // quantidade de paginas
          isSkipPaginations={true}
          disable={totalRecords <= pageLimit} // habilitar ou não a paginação
          selectPage={(page: number) => {
            setCurrentPage(page);
          }}
        />
      </>
    </>
  );
};
