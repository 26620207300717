import styled from 'styled-components';
import { device } from 'styles/breakpoint';

export const ContainerMenuHorizontal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  gap: 16px;
  width: 48px;

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;

interface IButtonMenuHorizontalProps {
  background: string;
  boxShadow: string;
}

export const ButtonMenuHorizontal = styled.div<IButtonMenuHorizontalProps>`
  width: 100%;
  height: 40px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  background: ${({ background }) => background};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  box-shadow: ${({ boxShadow }) => boxShadow};
  &:hover {
    cursor: pointer;
  }
`;
