import { ModuloEnum } from "helpers/enums";
import { IImageModel } from "models/dtos/Image.model";
import { IAPIResponse } from "models/dtos/api-response.model";
import { IImageEntity } from "models/entities/Image.entity";
import { api } from "services/api";

interface iGetImageProps {
  moduloId: ModuloEnum;
  id: number;
}

export const getImage = async ({ moduloId, id }: iGetImageProps): Promise<IImageEntity> => {
  try {
    const response = await api.get<IAPIResponse<IImageModel>>('/link/imagem', {
      params: { modulo: moduloId, id },
    });
    const { imagem } = response.data.Dado;
    return { image: imagem };
  } catch (error) {
    console.error('Erro ao buscar a lista:', error);
    throw error;
  }
}

export { ModuloEnum };