import styled from 'styled-components';

export const ContainerLayoutMenu = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
  width: max-content;
  height: max-content;
`;

export const Containerheader = styled.div`
  display: flex;
  height: max-content;
  width: 100%;
`;

export const ContainerBody = styled.div`
  display: flex;
  flex: 1;
  background-color: blue;
  border-radius: 8px;
  background-color: #fff;
  margin-top: -8px;
`;





