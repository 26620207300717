import styled from 'styled-components';

export const Active = styled.div`
  padding-top: 5px;
  padding-left: 5px;
`;
export const Inative = styled.div`
  margin-left: -2rem;
  margin-top: 0.3rem;
`;
