import { InputFormCustomValidation, ToggleCustom } from 'components/atoms';
import { usePopupEdit } from 'hooks/useEditPopup';
import { ValidityName, screenName } from 'requests/ValidityName';

import { StackStatus } from './styled';

export const NamePopup = () => {
  const { formPopup, popupsList } = usePopupEdit();
  const { active, popups, id, popupButtonName, status } = formPopup.watch();
  const changeActive = (e: any) => {
    if (e.target.checked) {
      // se for ativar um popup, verificar se ele já existe na lista de popups, se não existir, adicionar
      const popupExist = popups.find((p) => p.value === id);
      if (!popupExist) {
        // se o status original for Ativo, então adiciona o item na posição original
        if (status === 'Ativo' || status === 'Agendado') {
          formPopup.setValue('popups', popupsList.popupsList, { shouldDirty: true });
          formPopup.setValue('viewOrder', popupsList.viewOrder, { shouldDirty: true });
        } else {
          // se o status original for ative == false, então adiciona o item na ultima posição
          const newPopups = [
            ...popups,
            {
              value: id,
              name: popupButtonName ?? '',
            },
          ];
          formPopup.setValue('popups', newPopups, { shouldDirty: true });
          formPopup.setValue('viewOrder', String(popups.length + 1), {
            shouldDirty: true,
          });
        }
        formPopup.setValue('active', e.target.checked, { shouldDirty: true });
      } else {
        formPopup.setValue('active', e.target.checked, { shouldDirty: true });
      }
    } else {
      // se for desativar um popup, verificar se ele existe na lista de popups, se existir, remover
      const popupExist = popups.find((p) => p.value === id);
      if (popupExist) {
        if (status === 'Inativo') {
          formPopup.setValue('popups', popupsList.popupsList, { shouldDirty: true });
          formPopup.setValue('viewOrder', popupsList.viewOrder, { shouldDirty: true });
        } else {
          const newPopups = popups.filter((p) => p.value !== id);
          formPopup.setValue('popups', newPopups, { shouldDirty: true });
          formPopup.setValue('viewOrder', '0', { shouldDirty: true });
        }
        formPopup.setValue('active', e.target.checked, { shouldDirty: true });
      } else {
        formPopup.setValue('active', e.target.checked, { shouldDirty: true });
      }
    }
  };

  const validate = (val: string): Promise<boolean> => {
    return ValidityName({ name: val, screen: screenName.popup, id });
  };

  return (
    <StackStatus>
      <InputFormCustomValidation
        type="text"
        label="Nome do pop-up*"
        placeholder="Crie um título..."
        tooltipLabel="Escolha um nome para seu pop-up. Ele será visível apenas no Gerenciador de popup."
        name="popupButtonName"
        control={formPopup.control}
        disabled={!active}
        form={formPopup}
        callbackValidate={validate}
      />

      <ToggleCustom
        defaultChecked={active}
        onChange={changeActive}
        isSchedule={status === 'Agendado'}
      />
    </StackStatus>
  );
};
