import { Stack } from 'components/atoms';
import styled from 'styled-components';

export const StackModal = styled(Stack)`
  width: 100%;
  height: 100%;
  background-color: #000000b0;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
`;

export const Link = styled.a`
  font-size: 11px;
  line-height: 11px;
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
`;

export const StackContainer = styled(Stack)`
  flex-direction: column;
  width: 100%;
  height: 690px;
  overflow: hidden;

  @media (max-height: 768px) {
    max-height: 575px;
  }
`;
