import { pt } from 'date-fns/locale';
import React, { useEffect, useMemo, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; // theme css file

import Information from '../Information';
import Stack from '../Stack';
import { DateRangeCustom } from './styled';
import { useTheme } from 'hooks/useTheme';

interface IDuoCalendarProps {
  onChange: (val: any) => void;
  startDate?: Date;
  endDate?: Date;
  minDate: Date;
  hideEndDate?: boolean;
  label?: string;
  tooltipTitle?: string;
  tooltipLabel?: string;
}

const DuoCalendar: React.FC<IDuoCalendarProps> = ({
  onChange,
  startDate,
  endDate,
  minDate,
  hideEndDate,
  label,
  tooltipTitle,
  tooltipLabel,
}) => {
  const initialState: { startDate?: Date; endDate?: Date; key?: string } = useMemo(
    () => ({
      startDate: startDate ?? undefined,
      endDate: endDate ?? new Date(''),
      key: 'selection',
    }),
    [endDate, startDate]
  );

  // add 7 days with moment
  const [state, setState] = useState([initialState]);
  const { currentTheme } = useTheme();

  useEffect(() => {
    const _state = {
      ...initialState,
    };
    // remove a data final do payload quando usuario definir "data final indefinida"
    if (hideEndDate) {
      delete _state.endDate;
    }
    setState([_state]);
  }, [endDate, hideEndDate, initialState, startDate]);

  return (
    <Stack direction="column">
      <Information
        label={label}
        tooltipTitle={tooltipTitle}
        tooltipLabel={tooltipLabel}
        marginBottom="0.75rem"
        labelColor={currentTheme.colors.secondary}
        tooltipColor={currentTheme.colors.white}
        place='right'
      />
      <DateRangeCustom
        minDate={minDate}
        editableDateInputs={true}
        months={2}
        onChange={(item) => {
          setState([item.selection]);
          onChange(item.selection);
        }}
        moveRangeOnFirstSelection={false}
        ranges={state}
        direction="horizontal"
        locale={pt}
      />
    </Stack>
  );
};

export default DuoCalendar;
