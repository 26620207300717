import { Stack } from 'components/atoms';
import PreviewPopupButton from 'components/atoms/PreviewPopupButton';
import TopicSummary from 'components/atoms/TopicSummary';
import TopicSummaryItemArray from 'components/atoms/TopicSummaryItemArray';
import { classificationPeriodList, planConditionList, popupButtonIcons } from 'helpers/enums';
import { convertArrayStringInText, diffDaysLabel } from 'helpers/functions';
import { usePopupButton } from 'hooks/popupButton';
import { useMemo } from 'react';
import { device } from 'styles/breakpoint';

export const PopupButtonStep6 = () => {
  const { setActiveStep, setIsEditing, createFormPopupButtonStepper } = usePopupButton();

  const {
    popupButtonIcon,
    popupButtonTitle,
    popupButtonSubTitle,
    popupButtons,
    classificationPeriod,
    planCondition,
    popups,
    defineDate,
    startDate,
    endDateUndefined,
    endDate,
    whichBranches,
    whichClassifications,
    segments,
    planPoints,
    whoCanSee,
    csvFile,
  } = createFormPopupButtonStepper.watch();

  const contentPopUp = useMemo(() => {
    const content = [
      popupButtonIcon >= 0 ? 'ícone' : '',
      popupButtonTitle ? 'título' : '',
      popupButtonSubTitle ? 'conteúdo' : '',
    ];
    return convertArrayStringInText(content);
  }, [popupButtonIcon, popupButtonTitle, popupButtonSubTitle]);

  const popupButtonCount = useMemo(() => {
    return popupButtons?.length === 0 ? 'Nenhum Botão' : popupButtons?.length;
  }, [popupButtons]);

  const randomId = useMemo(() => (Math.random() * Math.random()).toString(), []);

  const onEditStep = (step: number) => {
    setIsEditing(true);
    setActiveStep(step);
  };

  const periodSelected = useMemo(() => {
    return classificationPeriodList.find((period) => period.value === classificationPeriod);
  }, [classificationPeriodList, classificationPeriod]);

  const planConditionSelected = useMemo(() => {
    return planConditionList.find((condition) => condition.value === planCondition);
  }, [planConditionList, planCondition]);

  const viewOrderNumber = (): string => {
    const popup: number = popups?.findIndex((e: { value: number }) => e.value === 0) ?? 0;
    return String(popup + 1);
  };

  const currentIcon = popupButtonIcons[popupButtonIcon - 1];

  return (
    <Stack direction="column" marginTop="0.75rem" marginBottom="0.75rem" gap="1.125rem">
      <Stack flex="1" breakpointDownInvert={device.laptopM} display={'flex'}>
        <PreviewPopupButton
          icon={currentIcon?.src}
          title={popupButtonTitle}
          subTitle={popupButtonSubTitle}
          buttons={popupButtons.map((button) => {
            return {
              text: button.text,
              color: button.idColor,
            };
          })}
        />
      </Stack>
      <TopicSummary
        title="Conteúdo do pop-up*"
        link="Editar >"
        itens={[contentPopUp]}
        click={() => onEditStep(1)}
      />
      <TopicSummary
        title="Botões do pop-up*"
        link="Editar >"
        itens={[String(popupButtonCount) ?? '']}
        click={() => onEditStep(2)}
      />
      <TopicSummary
        title="Ordem de aparição*"
        link="Editar >"
        itens={[viewOrderNumber()]}
        click={() => onEditStep(3)}
      />
      <TopicSummary
        title="Vigência do pop-up*"
        link="Editar >"
        itens={
          defineDate === 1
            ? [
                `Data início: ${startDate.toLocaleDateString('pt-BR', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                })} ${diffDaysLabel(startDate)}`,
                `Data fim: ${
                  endDateUndefined
                    ? 'Indefinida'
                    : endDate.toLocaleDateString('pt-BR', {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                      })
                }`,
              ]
            : ['Exibir apenas durante período de resgate']
        }
        click={() => onEditStep(4)}
      />
      <TopicSummary
        title="Público alvo*"
        link="Editar >"
        itens={[
          // removendo o item "todos" do array para cair no else e ficar na mesma linha
          whichBranches ? (
            whichBranches.filter((item) => item.value !== 0).length >= 1 ? (
              <TopicSummaryItemArray
                title="Filiais:"
                key={randomId}
                arrayItems={whichBranches?.map((item) => item.label)}
              />
            ) : (
              'Filiais: Todas'
            )
          ) : (
            'Filiais: Todas'
          ),
          `Classificação: ${
            whichClassifications && whichClassifications.length
              ? whichClassifications?.map((classification) => ' ' + classification.label).toString()
              : 'Todas'
          } - ${periodSelected?.label ?? ''}`,
          `Segmentos: ${
            segments && segments.length
              ? segments?.map((segment) => ' ' + segment.label).toString()
              : 'Todos'
          }`,
          `Pontuação Platinum: ${planConditionSelected?.label ?? ''} ${planPoints ?? '-'}`,
          `CNPJs: ${whoCanSee === 1 ? 'Todos' : csvFile?.name ?? ''}`,
        ]}
        click={() => onEditStep(5)}
      />
    </Stack>
  );
};
