import styled from 'styled-components';

export const EditorContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  margin-bottom: 10px;

  .container-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.375rem;
  }
`;

export const ToolbarContainer = styled.div`
  border: 0.2px solid rgba(255, 255, 255, 0.57);
  border-radius: 7px 7px 0 0;
  background-color: #a8a8a8;
  height: 27px;
  display: flex;

  .toolbar__controls {
    align-items: center;
    gap: 6px;
    display: flex;
    margin-left: 10px;

    img {
      cursor: pointer;
    }

    .icon-bold {
      width: 8.4px;
      height: 10.8px;
    }

    .icon-italic {
      width: 9.51px;
      height: 10.8px;
    }

    .icon-underline {
      width: 8.4px;
      height: 12px;
    }
  }
`;

export const EditorCotainer = styled.div`
  background-color: ${(props) => props.theme.colors.white}; 
  padding-left: 10px;
  border-radius: 0 0 7px 7px;
  font-size: 13.5px;
`;
