import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { InputForm } from '../InputForm';

interface IFormUserData {
  form: ReturnType<typeof useForm<{ name: string; email: string }>>;
  isDisabled?: boolean;
  emailEditable?: boolean;
  emailPlaceholder?: string;
}

function FormUserData(props: IFormUserData) {
  const { form, isDisabled, emailEditable, emailPlaceholder } = props;

  const [emailEditing, setEmailEditing] = useState(emailEditable);

  return (
    <>
      <InputForm
        id="name"
        type="text"
        label="Nome completo do usuário*"
        placeholder="Nome completo"
        control={form.control}
        name="name"
        showSuccess
        disabled={isDisabled}
        trailing
        iconSrc="/assets/icons/icon-user-outline.svg"
      />
      <InputForm
        id="email"
        control={form.control}
        type="email"
        name="email"
        tooltipLabel="Para editar o e-mail, deve ser inserido um novo e-mail do usuário."
        label="E-mail do usuário"
        placeholder={
          emailEditing ? 'turbo@santander.com.br' : emailPlaceholder ?? 'turbo@santander.com.br'
        }
        trailing
        iconSrc="/assets/icons/icon-email-outline.svg"
        labelLink={!emailEditing && !isDisabled ? 'Alterar e-mail do usuário >' : ''}
        onClickLink={() => {
          setEmailEditing(true);
        }}
        showSuccess
        disabled={!emailEditing || isDisabled}
      />
    </>
  );
}

export default FormUserData;
