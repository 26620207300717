import { StyledStack } from 'components/atoms/Stack';
import styled from 'styled-components';

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.silver};
  border-radius: 0.5rem;
  padding: 1.125rem 1.25rem 1.375rem 1.25rem;
  position: relative;
  top: -8px;
`;

export const HeaderTop = styled.div`
  width: 100%;
  height: 14px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 0.5rem 0.5rem 0 0;
`;

export const Back = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  margin-bottom: 1.25rem;
`;

export const IconRoundedBack = styled.img`
  margin-right: 0.375rem;
  width: 14px;
  height: 14px;
`;

export const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-bottom: 0.75rem;
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 1rem;
`;

export const IconNext = styled.img`
  width: 19px;
  height: 19px;
`;

export const WrapperButtons = styled(StyledStack)`
  margin-top: 0.875rem;
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
`;

export const WrapperInformations = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WarningDescription = styled.p`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 109%;
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
`;
