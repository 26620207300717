import { Stack } from 'components/atoms';
import { DefineDateValidityForm } from 'components/molecules/DefineDateValidityForm';
import { useCreateBanner } from 'hooks/banner';

const labelsDefineDateValidity = {
  label1:
    'Com esta opção selecionada, seu banner será exibido apenas durante o período de resgate vigente na plataforma por tempo indeterminado.',
  label2: 'Você poderá desativar este banner acessando o Gerenciador de Banners.',
};

function ContentStep3() {
  const { createFormBannerStepper } = useCreateBanner();
  return <DefineDateValidityForm form={createFormBannerStepper} {...labelsDefineDateValidity} />;
}

export default ContentStep3;
