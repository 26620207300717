import styled from 'styled-components';
import { device } from 'styles/breakpoint';
import { useTheme } from 'hooks/useTheme';

import Stack, { StyledStack } from '../Stack';

export const PopupTitle = styled.p`
  font-size: 18px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  margin-top: 0.125rem;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;
  word-break: break-word;
  padding: 0 42px 0;
`;

export const PopupSubTitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  margin-top: 0.75rem;
  color: ${({ theme }) => theme.colors.secondary};
  padding: 0 42px 0;
  text-align: center;
  word-break: break-word;
  font-size: 11px;
`;

export const PopupButtonText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: 11px;
`;

export const StackPreview = styled(StyledStack)`
  @media (max-width: ${device.laptopM}) {
    margin: 0px;
  }
`;

export const StackCard = styled(Stack)`
  @media (max-width: ${device.laptopM}) {
    background: ${({ theme }) => theme.colors.secondary}; 
    border-radius: 12px;
    border: 10px solid ${({ theme }) => theme.colors.secondary}; 
    box-sizing: border-box;
  }
`;
