import ConfirmDefault from 'components/atoms/ConfirmDefault';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

interface IConfirmOptions {
  width: string;
  height: string;
  question: string;
  information: string;
  labelButtonNegative: string;
  labelButtonConfirm: string;
}

const optionsDefaultConfrim: IConfirmOptions = {
  width: '33.563rem',
  height: '19.25rem',
  question: 'Você tem certeza que deseja sair da página?',
  information: 'Ao sair da página você irá perder as alterações realizadas',
  labelButtonNegative: '< Voltar',
  labelButtonConfirm: 'Sair >',
};

interface IConfirmContextInterface {
  confirm: (options?: IConfirmOptions) => Promise<void>;
}

const ConfirmContext = createContext<IConfirmContextInterface>({} as IConfirmContextInterface);

export const ConfirmProvider = ({ children }: { children: ReactNode }) => {
  const [resolveReject, setResolveReject] = useState<[() => void, () => void] | []>([]);
  const [resolve, reject] = resolveReject;
  const [options, setOption] = useState<IConfirmOptions>({} as IConfirmOptions);

  const confirm = useCallback(
    async (options?: {
      width?: string;
      height?: string;
      question?: string;
      information?: string;
      labelButtonNegative?: string;
      labelButtonConfirm?: string;
    }) => {
      await new Promise<void>((resolve, reject) => {
        setOption({ ...optionsDefaultConfrim, ...options });
        setResolveReject([resolve, reject]);
      });
    },
    []
  );

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    if (reject) {
      handleClose();
      reject();
    }
  }, [reject, handleClose]);

  const handleConfirm = useCallback(() => {
    if (resolve) {
      handleClose();
      resolve();
    }
  }, [resolve, handleClose]);

  return (
    <>
      <ConfirmContext.Provider value={{ confirm }}>{children}</ConfirmContext.Provider>
      <ConfirmDefault
        isOpen={resolveReject.length === 2}
        onClose={handleClose}
        onNegative={handleCancel}
        onConfirm={handleConfirm}
        closeOnConfirm
        {...options}
      />
    </>
  );
};

export function useConfirm(): IConfirmContextInterface {
  const context = useContext(ConfirmContext);
  return context;
}
