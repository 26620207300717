// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import { UpdateUser } from 'requests/UserManagement';

import { ButtonRounded } from '../ButtonRounded';
import { useTheme } from 'hooks/useTheme';

interface IButtonUpdateUserProps {
  nameUser: string;
  emailUser: string;
  idUser: string;
  isDisabled: boolean;
  onRefreshTable: () => void;
  messageSuccess: (props: { title: string; description: string }) => void;
  emailEnableEditing?: boolean;
  copyInputValue: { email?: string | undefined; name?: string | undefined };
  onError: (message: string) => void;
  emailRepeated: () => void;
}
function ButtonUpdateUser({
  nameUser,
  emailUser,
  idUser,
  isDisabled,
  onRefreshTable,
  messageSuccess,
  emailEnableEditing = false,
  copyInputValue,
  onError,
  emailRepeated,
}: IButtonUpdateUserProps) {
  const [hasSucceed, setHasSucceed] = useState(false);

  const handleClick = async () => {
    const response = await UpdateUser(nameUser, emailUser, idUser, true);

    if (response.HttpStatus === 200) {
      setHasSucceed(true);
      if (!emailEnableEditing) {
        messageSuccess({
          title: 'Nome atualizado com sucesso.',
          description: 'O nome do usuário foi atualizado com sucesso.',
        });
      }

      const emailChanged = emailUser !== copyInputValue.email;
      const nameChanged = nameUser !== copyInputValue.name;
      // verify if email is different from the original one
      if (emailChanged) {
        messageSuccess({
          title: 'E-mail pendente de definição de senha.',
          description: 'Enviamos instruções para definição de senha no e-mail cadastrado.',
        });
      } else if (nameChanged) {
        messageSuccess({
          title: 'Nome atualizado com sucesso.',
          description: 'O nome do usuário foi atualizado com sucesso.',
        });
      }

      onRefreshTable();
    } else if (response.HttpStatus === 400) {
      onError('Erro ao atualizar usuário!');
      emailRepeated();
    } else {
      onError('Erro ao atualizar usuário!');
    }
  };

  const { currentTheme } = useTheme();
  
  return (
    <>
      {!hasSucceed ? (
        <ButtonRounded
          background={currentTheme.colors.primary}
          color={currentTheme.colors.white}
          label="Avançar"
          disabled={isDisabled}
          iconSrc="/assets/icons/icon-enter-white.svg"
          click={handleClick}
        />
      ) : (
        <ButtonRounded
          background={currentTheme.colors.success}
          color={currentTheme.colors.white}
          label="Cadastro atualizado!"
          iconSrc="/assets/icons/icon-done-outline-white.svg"
          readView
        />
      )}
    </>
  );
}

export default ButtonUpdateUser;
