import { AuthProvider } from './hooks/authentication';
import { LoadingProvider } from './hooks/loading';
import { NotifyProvider } from './hooks/notification';
import { ConfirmProvider } from './hooks/useConfirm';

const AppProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <NotifyProvider>
      <LoadingProvider>
        <ConfirmProvider>
          <AuthProvider>{children}</AuthProvider>
        </ConfirmProvider>
      </LoadingProvider>
    </NotifyProvider>
  );
};

export default AppProviders;
