import Stack from '../Stack';
import { Wrap, Itens, Title, SubTitle, ItemStack } from './styled';

interface IStepsProps {
  currentValue: number;
  title: string[];
  subTitle?: string[];
}
export const Steps: React.FC<IStepsProps> = ({ currentValue, title, subTitle }) => {
  return (
    <Stack direction="column" padding="0.875rem 1.875rem 0 1.875rem">
      <Wrap>
        {title.map((_, index) => {
          return (
            <ItemStack key={index} height="18px" alignItems="center" disabled={true}>
              <Itens key={index} active={currentValue >= index} disabled={true} />
            </ItemStack>
          );
        })}
      </Wrap>
      <Title>{title[currentValue]}</Title>
      <SubTitle>{subTitle && subTitle[currentValue]}</SubTitle>
    </Stack>
  );
};
