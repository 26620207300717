import styled from "styled-components";

interface ContainerProps {
  columns: number;
}

export const ContainerLayoutMenuContent = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: ${({ columns }) => (columns === 1 ? '1fr' : `repeat(${columns}, 1fr)`)};
  gap: 16px;
  padding: 16px;
  
  /* Estilo adicional para garantir que o item ocupe 100% da largura */
  & > * {
    width: ${({ columns }) => (columns === 1 ? '100%' : 'auto')};
  }
`;
