import { ButtonRounded, Image, Paragraph, SelectCustomForm, Stack } from 'components/atoms';
import ButtonBack from 'components/atoms/ButtonBack';
import { Modal } from 'components/molecules';
import { differenceInYears } from 'date-fns';
import { Months, providerList } from 'helpers/enums';
import { useNotification } from 'hooks/notification';
import { useChamasAnalytical } from 'hooks/useChamasAnalytical';
import { useTheme } from 'hooks/useTheme';
import { useForm } from 'react-hook-form';
import { StackModal } from './styled';

interface IModalChamasAnalyticalFilterProps {
  handleClose: () => void;
  open: boolean;
}

export const ModalChamasAnalyticalFilter = ({
  handleClose,
  open,
}: IModalChamasAnalyticalFilterProps) => {
  if (!open) return null;

  const { formFilterAnalitycal, years } = useChamasAnalytical();

  const { currentTheme } = useTheme();
  const notify = useNotification();

  const { getValues } = formFilterAnalitycal;
  const defaultValues = getValues();

  const formFilterAnalitycalInternal = useForm({
    defaultValues,
  });
  const { control } = formFilterAnalitycalInternal;

  const handleSearch = () => {
    const valuesInternal = formFilterAnalitycalInternal.getValues();
    const { startMonth, startYear, endMonth, endYear } = valuesInternal;
    const newDateStart = new Date(startYear.value, startMonth.value - 1, 1);
    const newDateEnd = new Date(endYear.value, endMonth.value - 1, 1);
    const currentDate = new Date();

    if (newDateStart > newDateEnd) {
      notify.error(
        'Intervalo não permitido.',
        'Data Início deve ser menor ou igual a Data Fim.',
        5000
      );
      formFilterAnalitycal.setError('endMonth', { type: 'custom', message: '' });
      formFilterAnalitycal.setError('endYear', { type: 'custom', message: '' });
      return;
    }

    if (currentDate < newDateEnd) {
      notify.error(
        'Intervalo não permitido.',
        'Data Fim deve ser menor ou igual a Data Atual.',
        5000
      );
      formFilterAnalitycal.setError('endMonth', { type: 'custom', message: '' });
      formFilterAnalitycal.setError('endYear', { type: 'custom', message: '' });
      return;
    }

    const diffYears = differenceInYears(newDateEnd, newDateStart);
    if (diffYears >= 1) {
      notify.error(
        'Intervalo não permitido.',
        'Intervalo do período não pode ser maior que 1 ano.',
        5000
      );
      formFilterAnalitycal.setError('endMonth', { type: 'custom', message: '' });
      formFilterAnalitycal.setError('endYear', { type: 'custom', message: '' });
      return;
    }

    formFilterAnalitycal.reset(valuesInternal);
    handleClose();
  };

  return (
    <StackModal>
      <Modal
        width={'600px'}
        background={'#F1F1F1'}
        zIndex="3"
        display="flex"
        flexDirection="column"
        fullScreenTabled
        padding="24px"
      >
        <Stack width="100%" height="100%" direction="column" gap="24px">
          <ButtonBack onBack={handleClose} padding="0px" />
          <Stack gap="6px">
            <Image src="/assets/icons/icon-filter-black.svg" alt="Filtros" />
            <Paragraph fontSize="16px" label="Filtros" lineHeight="19px" fontWeight="bold" />
          </Stack>
          <Stack direction="column" justifyContent="space-between" height="100%">
            <Stack direction="row" gap="8px" alignItems="center">
              <Paragraph fontSize="12px" label="Período dos dados:" />
              <Stack width="102px">
                <SelectCustomForm
                  control={control}
                  name="startMonth"
                  options={Months}
                  placeholder="Mês início"
                  notMessageError
                />
              </Stack>
              <Stack width="70px">
                <SelectCustomForm
                  control={control}
                  name="startYear"
                  options={years}
                  placeholder="Ano início"
                  notMessageError
                />
              </Stack>
              <Paragraph fontSize="9px" label="até" />
              <Stack width="102px">
                <SelectCustomForm
                  control={control}
                  name="endMonth"
                  options={Months}
                  placeholder="Mês início"
                  notMessageError
                />
              </Stack>
              <Stack width="70px">
                <SelectCustomForm
                  control={control}
                  name="endYear"
                  options={years}
                  placeholder="Ano início"
                  notMessageError
                />
              </Stack>
            </Stack>
            <Stack alignItems="center" gap="8px" marginTop="22px">
              <Paragraph fontSize="12px" label="Fornecedor:" />
              <Stack width="110px">
                <SelectCustomForm
                  control={control}
                  name="provider"
                  options={[{ value: 0, label: 'Todos' }, ...providerList]}
                  placeholder="Ambos"
                  notMessageError
                />
              </Stack>
            </Stack>
            <Stack width="100%" justifyContent="flex-end" marginTop="16px">
              <ButtonRounded
                label="Filtrar"
                color={currentTheme.colors.white}
                iconSrc="/assets/icons/icon-arrow-rigth.svg"
                fontSize="9px"
                lineHeight="undefined"
                sizeIcon="12px"
                click={handleSearch}
              />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </StackModal>
  );
};
