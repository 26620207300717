import { Stack } from 'components/atoms';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

export const Text = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #2e2b37;
`;

export const StackContainer = styled(Stack)`
  flex-direction: column;
  /* height: 340px; */
  margin-top: 0.75rem;
  padding: 0px 20px 0 0;
  overflow: auto;
  margin-bottom: 0.5rem;

  @media (max-width: ${device.tablet}) {
    height: calc(100vh - 14rem);
    max-height: calc(1000px - 14rem);
  }
`;
