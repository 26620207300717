import { StyledStack } from 'components/atoms/Stack';
import styled, { createGlobalStyle } from 'styled-components';
import { device } from 'styles/breakpoint';

export const GlobalStyle = createGlobalStyle`

.tooltip {
  padding: 0.25rem !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  z-index: 1000;
}

.tooltipTitle {
  font-weight: bold !important;
}


  .two-column{
    display: flex;
    flex-direction: row;
    margin-left: 54px;
    align-items: center;
  }
  .wrap-modal{
    width: 100%;
    height: 100%;
    background-color: #000000b0;
    position: absolute;
    position: fixed;
    top: 0;
    left: 00;
  }

  /* normal toggle */
  .custom-classname.react-toggle .react-toggle-track{
    width: 91px;
    height: 30px;
    background-color: #FF8332 !important;
    :hover{
      background-color: #FF8332;
    }
  }
  .custom-classname.react-toggle--checked .react-toggle-track{
    width: 91px;
    height: 30px;
    background-color: #00D217 !important;
    :hover{
      background-color: #00D217;
    }
  }

   .custom-classname.react-toggle .react-toggle-track {
    width: 91px;
    height: 30px;
    left: 5px;
  }

  /* schedule toggle */
  .custom-classname-schedule.react-toggle .react-toggle-track{
    width: 91px;
    height: 30px;
    background-color: #FF8332 !important;
    :hover{
      background-color: #FF8332;
    }
  }
  .custom-classname-schedule.react-toggle--checked .react-toggle-track{
    width: 91px;
    height: 30px;
    background-color:#2f80ed !important;
    :hover{
      background-color:#2f80ed;
    }
    .react-toggle-track-check{
      left: 5px;
    }
  }

   .custom-classname-schedule.react-toggle .react-toggle-track {
    width: 91px;
    height: 30px;
    left: 5px;
  }

  .react-toggle--checked .react-toggle-thumb{
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    left: 58px;
    top: 2px;
  }
  
  .react-toggle-thumb{
    height: 26px;
    border: 1px solid #FFFFFF;
    top: 2px;
    left: 2px;
    width: 31px;
    border-radius: 13px;
    box-shadow: none !important;
    outline: none !important;
  }
  
  :root {
    --red: #F3123C;
    --blue: #2F80ED;
    --gray: #AEAEBA;
    --dark: #2E2B37;
    --white: #FFFFFF;
    --background: #2E2D37;
    --dark-text: #272829;
    --gray-light: #C5C6D0;
    --gray-200: #F9F9F9;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .ag-cell-label-container {
    cursor: move !important;
  }

  
  html {
    @media (max-width: 1440px) {
      font-size: 93.75%;
    }

    @media (max-width: 1040px) {
      font-size: 87.5%;
    }
  }

  body {
    background-color: #2E2B37;
    background-image: url("/assets/background/bg-plus.svg");
    background-repeat: no-repeat;
    background-position:center;
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }

  body, textarea, input, button, option, select {
    font-family: 'Poppins', sans-serif;
    color: var(--dark);
  }

  button {
    border: 0;
    padding: 0;
  }

  main{
    overflow: hidden;
    min-height: 540px;
    height: 100vh;
  }

  button {
    cursor: pointer;
  }

  .g2-html-annotation {
    z-index: 2 !important;
  }

  .ou {
    font-weight: 700;
    font-size: 0.75rem;
    color: #F3123C;
    /* margin-top: 0.5rem; */
    margin-bottom: 0.5rem;
    line-height: 109%;
  }
`;

export const MainContainer = styled.div`
  position: absolute;
  left: 17rem;
  width: calc(100% - 17rem);
`;

export const MainRouters = styled.div`
  padding: 1.5rem;
  overflow-y: auto;
  height: calc(100vh - 72px);
  z-index: 0;
  position: relative;
`;

export const Icon = styled.img<{
  minHeight?: string;
  maxWidth?: string;
  margin?: string;
  marginLeft?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  size?: string;
  cursor?: string;
}>`
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-top: ${({ marginTop }) => marginTop};
  margin-right: ${({ marginRight }) => marginRight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  cursor: ${({ cursor }) => cursor};
`;

export const ButtonEyes = styled.button`
  background-color: transparent;
  justify-content: center;
  display: flex;
`;

export const AlertPreview = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 109%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 0.5rem;
  padding: 0.125rem 0.375rem;
  margin-bottom: 1.25rem;
`;

export const ButtonClear = styled.button`
  background-color: transparent;
  padding-right: 0.75rem;
  justify-content: center;
  display: flex;
`;
export const TextLink = styled.p<{ textDecoration: string; fontSize?: string }>`
  color: white;
  text-decoration: ${({ textDecoration }) => textDecoration ?? 'underline'};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize ?? '6px'};
`;

export const ButtonEdit = styled.label<{
  position?: string;
  right?: string;
  bottom?: string;
  borderRadius?: string;
  padding?: string;
  borderLeft?: string;
  borderTop?: string;
}>`
  position: ${({ position }) => position ?? 'absolute'};
  cursor: pointer;
  right: ${({ right }) => right ?? 0};
  bottom: ${({ bottom }) => bottom ?? 0};
  background: ${(props) => props.theme.colors.primary};
  border-radius: ${({ borderRadius }) => borderRadius ?? '0.625rem 0px 0.25rem'};
  padding: ${({ padding }) => padding ?? '0.75rem 0.625rem'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: ${({ borderLeft }) => borderLeft ?? '1px solid white'};
  border-top: ${({ borderTop }) => borderTop ?? '1px solid white'};
`;

export const StackEditUpload = styled(StyledStack)<{ right?: string; bottom?: string }>`
  position: absolute;
  cursor: pointer;
  right: ${({ right }) => right ?? '-10px'};
  bottom: ${({ bottom }) => bottom ?? '-10px'};
  background: ${(props) => props.theme.colors.primary};
  border-radius: 0.625rem 0px 0.625rem;
  padding: 0.75rem 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid white;
  border-top: 1px solid white;
`;

export const MainLayoutCardContent = styled(StyledStack)`
  width: 100%;
  height: 100%;
  gap: 32px;
  max-width: 1600px;
  padding: 2rem;
  max-height: 1000px;
  margin: auto;

  @media (max-width: ${device.tablet}) {
    width: 100%;
    min-width: 100%;
    padding: 0px;
  }

  .DraftEditor-root {
    min-height: 60px;
  }
`;
