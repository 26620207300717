import { yupResolver } from '@hookform/resolvers/yup';
import { DefineDateValidityForm, ModalEditPopup } from 'components/molecules';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { StackDateValidity } from './styled';

const labelsDefineDateValidity = {
  label1:
    'Com esta opção selecionada, seu pop-up será exibido apenas durante o período de resgate vigente na plataforma por tempo indeterminado.',
  label2: 'Você poderá desativar este pop-up acessando o Gerenciador de Pop-ups.',
};

const Schema = yup.object({
  startDate: yup.mixed().when(['defineDate'], {
    is: (defineDate: number) => defineDate === 1,
    then: yup.mixed().required('Informe a data de início'),
  }),
  endDate: yup.mixed().when(['defineDate'], {
    is: (defineDate: number) => defineDate === 1,
    then: yup.mixed().required('Informe a data de Final'),
  }),
});

interface IEditDateValidityProps {
  onClose: () => void;
  defaultValues: any;
  handleSave: (val: any) => void;
}

export const EditDateValidity: React.FC<IEditDateValidityProps> = ({
  onClose,
  defaultValues,
  handleSave,
}) => {
  const formPopupDate = useForm({
    defaultValues,
    resolver: yupResolver(Schema),
  });

  const onSave = () => {
    const values = formPopupDate.getValues();
    handleSave(values);
  };

  const enableButton = () => {
    return formPopupDate.formState.isValid && formPopupDate.formState.isDirty;
  };

  return (
    <ModalEditPopup
      onClose={onClose}
      onSave={onSave}
      enableButtonSave={enableButton}
      height={'33.25rem'}
      name={defaultValues?.name}
    >
      <StackDateValidity>
        <DefineDateValidityForm form={formPopupDate} {...labelsDefineDateValidity} />
      </StackDateValidity>
    </ModalEditPopup>
  );
};
