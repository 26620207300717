import { InputFormCustomValidation } from 'components/atoms';
import { useForm } from 'react-hook-form';
import { ValidityName, screenName } from 'requests/ValidityName';

interface ISelectNamePopupImageProps {
  form: any;
}
export const SelectNamePopupImage: React.FC<ISelectNamePopupImageProps> = ({ form }) => {
  const validate = (val: string): Promise<boolean> => {
    return ValidityName({ name: val, screen: screenName.popup });
  };
  return (
    <InputFormCustomValidation
      tooltipTitle="Obrigatório"
      tooltipLabel="Escolha um nome para seu pop-up. Ele será visível apenas no Gerenciador de Pop-up."
      type="text"
      id="name"
      name="name"
      placeholder="Crie um nome..."
      control={form.control}
      label="Nome do Pop-up"
      form={form}
      callbackValidate={validate}
    />
  );
};
