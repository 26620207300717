import { FooterTip, Stack, StepCreated, Steps } from 'components/atoms';
import { usePopupButton } from 'hooks/popupButton';
import { useEffect, useRef } from 'react';

import { PopupButtonStep1 } from './PopupButtonStep1';
import { PopupButtonStep2 } from './PopupButtonStep2';
import { PopupButtonStep3 } from './PopupButtonStep3';
import { PopupButtonStep4 } from './PopupButtonStep4';
import { PopupButtonStep5 } from './PopupButtonStep5';
import { PopupButtonStep6 } from './PopupButtonStep6';

const title = ['Etapa 01', 'Etapa 02', 'Etapa 03', 'Etapa 04', 'Etapa 05', 'Resumo'];

const tip = [
  'Você pode ver um preview de como ficará o pop-up no sistema na pré-visualização ao lado.',
  'Você pode ver um preview de como ficará o pop-up no sistema na pré-visualização ao lado.',
  'Lembre-se que se houver uma campanha com pop-up ativo, ele pode afetar a ordem de visualização do(s) pop-up(s) cadastrado(s).',
];

interface ICreatePopupButtonProps {
  handleChangeStep: (step: number) => void;
  routerBack: string;
}
const CreatePopupButton: React.FC<ICreatePopupButtonProps> = ({ handleChangeStep, routerBack }) => {
  const stepStack = useRef<HTMLDivElement | null>(null);
  const {
    stepIsValid,
    created,
    activeStep,
    handleNext,
    isEditing,
    handleCloseConfirm,
    createFormPopupButtonStepper,
  } = usePopupButton();

  const popupButtonName = createFormPopupButtonStepper.watch('popupButtonName');

  const subTitle: string[] = [
    'Conteúdo do pop-up',
    'Ação dos botões do pop-up',
    'Prioridade do Pop-up',
    'Vigência do pop-up',
    'Público-alvo',
    popupButtonName ?? '',
  ];

  useEffect(() => {
    if (stepStack && stepStack?.current && stepStack?.current?.scrollTo) {
      stepStack?.current?.scrollTo({
        top: 0,
      });
    }
  }, [activeStep]);

  if (created)
    return (
      <Stack padding="30px 10px 30px 30px">
        <StepCreated title={popupButtonName ?? ''} name="Pop-ups" route="/popup/gerenciador" />
      </Stack>
    );

  return (
    <>
      <Steps title={title} subTitle={subTitle} currentValue={activeStep - 1} />
      <Stack direction="column" padding="0.4375rem 0.9375rem 0 0.9375rem" flex="1" overflow="auto">
        <Stack
          direction="column"
          padding="0.4375rem 0.9375rem 0 0.9375rem"
          flex="1"
          overflow="auto"
          ref={stepStack}
        >
          <Stack direction="column" paddingBottom="12px" scrollbarborder="#f1f1f1" marginTop="1rem">
            {activeStep === 1 && <PopupButtonStep1 />}
            {activeStep === 2 && <PopupButtonStep2 />}
            {activeStep === 3 && <PopupButtonStep3 />}
            {activeStep === 4 && <PopupButtonStep4 />}
            {activeStep === 5 && <PopupButtonStep5 />}
            {activeStep === 6 && <PopupButtonStep6 />}
          </Stack>
        </Stack>
      </Stack>
      <FooterTip
        tip={tip[activeStep - 1]}
        advance={() => handleNext()}
        disabledAdvance={!stepIsValid(activeStep)}
        cancel={() => handleCloseConfirm(routerBack)}
        buttonFinalize={activeStep === 6}
        buttonResume={isEditing}
      />
    </>
  );
};

export default CreatePopupButton;
