import { InputForm, RadioGroupForm, Stack } from 'components/atoms';
import { useForm } from 'react-hook-form';

interface IPlanConditionProps {
  form: any;
  planConditionList: { value: number; label: string }[];
}

export const PlanCondition: React.FC<IPlanConditionProps> = ({ form, planConditionList }) => {
  const [planCondition] = form.watch(['planCondition']);

  return (
    <Stack direction="row">
      <Stack width="320px">
        <RadioGroupForm
          control={form.control}
          label="Pontuação Platinum"
          tooltipLabel="Considerando o valor da Pontuação mínima Platinum vinda no último arquivo de processamento no programa."
          name="planCondition"
          options={planConditionList}
          isToggle
          onChange={(value, prevValue) => {
            if (!value || value === prevValue) {
              form.setValue('planCondition', null, { shouldDirty: true });
              form.setValue('planPoints', null, { shouldDirty: true });
            }
          }}
        />
      </Stack>

      {planCondition && (
        <InputForm
          type="number"
          maxLength={6}
          control={form.control}
          // id="planPoints"
          name="planPoints"
          placeholder="Digite uma pontuação"
          showClear
        />
      )}
    </Stack>
  );
};
