import styled from 'styled-components';

export const TableComponent = styled.table`
  width: 100%;
  box-shadow: 0px 0px 16.9476px rgba(0, 0, 0, 0.13);
  border-radius: 0.875rem;
  overflow: hidden;
`;

export const TableHeader = styled.thead`
  background: #2e2b37;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 109%;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 100%;
  height: 56px;
`;

export const TableBody = styled.tbody`
  border-radius: 0px 0px 0.5rem 0.5rem;
  overflow: hidden;
`;

interface IHeaderProps {
  background?: string;
}
export const RowHeader = styled.tr<IHeaderProps>`
  width: 100%;
  display: flex;
  background: ${({ background }) => background};
`;

interface IRowProps {
  color?: string;
  background?: string;
  active?: boolean;
}
export const Row = styled.tr<IRowProps>`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 109%;
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  height: 43px;

  &:nth-child(even) {
    background-color: ${({ active }) => !active && '#ffffff'};

    & > td.action {
      th {
        border: none;
      }
    }
  }

  ${({ active, theme }) =>
    active &&
    `
      background: ${theme.colors.primary};
      font-weight: 700;
      color: #ffffff;

      & > td:first-child {
        border-right-color: #fff;
      }
    `};
`;

interface ICellProps {
  width?: string;
  padding?: string;
  borderRight?: string;
  active?: boolean;
}
export const TD = styled.td<ICellProps>`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  position: relative;
  border-right: ${({ borderRight }) => borderRight};
  max-width: ${(props) => (props.width ? props.width : '100%')};
  padding: ${(props) => (props.padding ? props.padding : '0 29px')};
  height: 33px;
  align-items: center;
  display: flex;

  &.heightFull {
    height: 100% !important;
  }

  ${({ active }) =>
    active &&
    `
    &:last-child::after {
      content: '';
      height: 12px;
      width: 12px;
      background-image: url(/assets/icons/icon-hamburguer.svg);
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 0.625rem;
      position: absolute;
      right: 18px;
    }
    `};
`;

interface ICellHeaderProps {
  width?: string;
  padding?: string;
  borderRight?: string;
}
export const TH = styled.th<ICellHeaderProps>`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  border-right: ${({ borderRight }) => borderRight};
  max-width: ${(props) => (props.width ? props.width : '100%')};
  padding: ${(props) => (props.padding ? props.padding : '0 29px')};
  height: 33px;
  align-items: center;
  display: flex;

  &.heightFull {
    height: 100% !important;
  }
`;
