import { Stack } from 'components/atoms';
import Information from 'components/atoms/Information';
import { DragAndDrop } from 'components/molecules';
import { usePopupImage } from 'hooks/popupImage';
import { useEffect } from 'react';

import { Text } from './styled';
import { useTheme } from 'hooks/useTheme';

export const StepTwoPriority = () => {
  const { createFormPopupImageStepper } = usePopupImage();
  const [popups, name] = createFormPopupImageStepper.watch(['popups', 'name']);

  const onDragEnd = (popups: any) => {
    createFormPopupImageStepper.setValue('popups', popups);
  };

  useEffect(() => {
    const index = popups?.findIndex((popup) => popup.value === 0) ?? -1;
    if (index > -1 && popups) {
      // se o popup existir, apenas alterar o nome
      popups[index].name = name;
      createFormPopupImageStepper.setValue('popups', popups);
    }
  }, [name]);

  const { currentTheme } = useTheme();

  return (
    <>
      <Stack direction="column" marginBottom="4px">
        <Information
          label="Ordem de aparição*"
          tooltipTitle="Obrigatório"
          tooltipLabel="Defina em que ordem aparecerão os pop-ups no sistema."
          labelColor={currentTheme.colors.secondary}
          tooltipColor={currentTheme.colors.white}
          place="right"
        />
        <Text>Clique e arraste para ajustar a priorização de pop-ups ativos.</Text>
      </Stack>

      <DragAndDrop title={'Nome do pop-up'} list={popups ?? []} onDragEnd={onDragEnd} />
    </>
  );
};
