import React from 'react';
import Stack from '../Stack';
import { TableWrapper, Header, Body, Title, Data, Tbody, SpinnerContainer } from './styled';
import { Loading } from 'components/atoms';

interface ITableProps {
  header: string[];
  body?: any[][];
  fixedLastColumn?: boolean;
  loading?: boolean;
}
const Table: React.FC<ITableProps> = ({ header, body, fixedLastColumn = true, loading = false }) => {
  const getHeader = (dataHeader?: string[], forceBorder?: boolean) =>
    dataHeader?.map((title, i) => (
      <Title showBorder={i + 1 !== dataHeader.length || forceBorder} key={i}>
        <div key={i} className={'data'}>
          {title}
        </div>
      </Title>
    ));

  const getBody = (dataBody?: any[][], forceBorder?: boolean) =>
    dataBody?.map((item, i) => (
      <Body key={i}>
        {item?.map((row, j) => {
          return (
            <Data showBorder={j + 1 !== item?.length || forceBorder} key={j}>
              <div key={j} className={row?.typeOf === 'object' ? '' : 'data'}>
                {row}
              </div>
            </Data>
          );
        })}
      </Body>
    ));

  const renderTable = (): JSX.Element => {
    if (loading) {
      return (
        <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
          <SpinnerContainer>
            <Loading data-testid="loading-spinner" />
          </SpinnerContainer>
        </Stack>
      );
    }

    if (fixedLastColumn) {
      // separando a ultima coluna para fixar ela
      const headerNotFixed = header.filter((_, index) => index !== header.length - 1);
      const headerFixed = header.filter((_, index) => index === header.length - 1);
      const columnsNotFixed = body?.map((item) =>
        item.filter((_, index) => index !== item.length - 1)
      );
      const columnsFixed = body?.map((item) =>
        item.filter((_, index) => index === item.length - 1)
      );

      // se tiver columa fixada então gera duas tabelas
      // uma com as colunas que não são fixadas e outra com as colunas fixadas
      // a tabela com as colunas fixadas fica em um stack com position sticky
      // para que ela fique fixada na tela
      return (
        <>
          <TableWrapper width="600px">
            <thead>
              <Header>{getHeader(headerNotFixed, true)}</Header>
            </thead>
            <tbody>{getBody(columnsNotFixed, true)}</tbody>
          </TableWrapper>

          <Stack width="90px" position="sticky" right="0px">
            <TableWrapper>
              <thead>
                <Header>{getHeader(headerFixed)}</Header>
              </thead>
              <Tbody>{getBody(columnsFixed)}</Tbody>
            </TableWrapper>
          </Stack>
        </>
      );
    }
    return (
      <>
        <TableWrapper>
          <thead>
            <Header>{getHeader(header)}</Header>
          </thead>
          <tbody>{getBody(body)}</tbody>
        </TableWrapper>
      </>
    );
  };

  return <>{renderTable()}</>;
};

export default Table;
