import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

interface IDotProps {
  color?: string;
}
export const Dot = styled.div<IDotProps>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

export const Description = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 109%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.colorText};
`;
