import React from 'react';
import { Button, IconDownload } from './styled';
import { useTheme } from 'hooks/useTheme';

interface IButtonExportProps {
  label?: string;
  handleClick: () => void;
  variant?: 'outlined' | 'contained';
  srcIcon?: string;
  fontSize?: string;
}

export const ButtonExport: React.FC<IButtonExportProps> = ({
  label = 'Exportar Dicionário',
  handleClick,
  variant = 'contained',
  srcIcon = '/assets/icons/icon-download.svg',
  fontSize,
}) => {
  const { currentTheme } = useTheme();

  return (
    <Button theme={currentTheme} onClick={handleClick} variant={variant} fontSize={fontSize}>
      <IconDownload src={srcIcon} alt="icon-download" />
      <p>{label}</p>
    </Button>
  );
};
