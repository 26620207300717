import { ButtonClear } from 'globalStyles';
import { justNumbers } from 'helpers/functions';
import { FC } from 'react';
import { Controller, Control } from 'react-hook-form';

import Information from '../Information';
import Stack from '../Stack';
import { TextCount } from '../TextCount';
import { BackgroundIcon, Flex, IconInput, InputStyled, LinkLabel } from './styled';
import { useTheme } from 'hooks/useTheme';

export interface IInputFormProps {
  id?: string;
  name: string;
  control: Control<any>;
  labelLink?: string;
  onClickLink?: () => void;
  label?: string;
  tooltipTitle?: string;
  tooltipLabel?: string;
  trailing?: boolean;
  showSuccess?: boolean;
  iconSrc?: string;
  customWidth?: string;
  click?: () => void;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  leadingIcon?: () => JSX.Element;
  type?: string;
  hidden?: boolean;
  accept?: string;
  onChange?: (newValue: string, oldValue: string) => void;
  processingValueOnChange?: (newValue: string, oldValue: string) => string;
  showClear?: boolean;
  toLowerCase?: boolean;
  showCurrentCount?: boolean;
  onBlur?: () => void;
  isValidating?: boolean;
  autocomplete?: 'on' | 'off';
  color?: string;
}
export const InputForm: FC<IInputFormProps> = ({
  id,
  name,
  color,
  control,
  labelLink,
  onClickLink,
  label,
  tooltipTitle,
  tooltipLabel,
  trailing,
  showSuccess,
  iconSrc,
  customWidth,
  click,
  placeholder,
  disabled,
  maxLength,
  leadingIcon,
  type = 'text',
  hidden,
  accept,
  onChange,
  processingValueOnChange,
  showClear,
  toLowerCase,
  showCurrentCount,
  onBlur,
  isValidating,
  autocomplete = 'on',
}): JSX.Element => {
  const { currentTheme } = useTheme();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, field: { value }, fieldState: { error } }) => {
        return (
          <Stack width="100%" direction="column">
            <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
              {labelLink ? (
                <LinkLabel onClick={onClickLink}>{labelLink}</LinkLabel>
              ) : (
                <Information
                  label={label}
                  tooltipLabel={tooltipLabel}
                  marginBottom="0.375rem"
                  width="250px"
                  labelColor={color || currentTheme.colors.colorText}
                  tooltipColor={currentTheme.colors.white}
                />
              )}
              {showCurrentCount && (
                <TextCount count={value?.length ?? 0} maxLength={maxLength ?? 0} />
              )}
            </Stack>
            <Flex alignItems="initial">
              {trailing && (
                <BackgroundIcon
                  isInvalid={!!error}
                  success={showSuccess && value?.trim()}
                  disabled={disabled}
                >
                  <IconInput src={iconSrc} />
                </BackgroundIcon>
              )}

              <Stack
                width={customWidth ?? '100%'}
                background="white"
                borderRadius="7px"
                alignItems="center"
                justifyContent="center"
                marginLeft={trailing ? '-9px' : ''}
              >
                <InputStyled
                  id={id}
                  {...field}
                  hidden={hidden}
                  onClick={click}
                  type={type === 'number' ? 'text' : type}
                  placeholder={placeholder}
                  disabled={disabled}
                  maxLength={maxLength}
                  colorText={
                    error && error?.message
                      ? currentTheme.colors.error
                      : color || currentTheme.colors.colorText
                  }
                  value={field.value || ''}
                  accept={accept}
                  autoComplete={autocomplete}
                  data-testid="input-form"
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (toLowerCase) {
                      newValue = newValue.toLowerCase();
                    }
                    const oldValue = field.value;
                    if (processingValueOnChange) {
                      newValue = processingValueOnChange(newValue, oldValue);
                    }
                    field.onChange(
                      type === 'number'
                        ? justNumbers(newValue, oldValue, maxLength ?? 9999)
                        : newValue
                    );
                    if (onChange) onChange(newValue, oldValue);
                  }}
                  onBlur={onBlur}
                />
                {showClear && (
                  <Stack justifyContent="center" paddingRight="0.75rem">
                    <ButtonClear
                      onClick={() => {
                        field.onChange('');
                      }}
                    >
                      <img
                        src="/assets/icons/icon-close-black.svg"
                        alt="Limpar pontuação"
                        title="Limpar pontuação"
                      />
                    </ButtonClear>
                  </Stack>
                )}

                {leadingIcon && (
                  <Stack justifyContent="center" paddingRight="0.75rem">
                    {leadingIcon()}
                  </Stack>
                )}
              </Stack>
            </Flex>
            <Stack height="1.25rem">
              <p style={{ color: currentTheme.colors.error, fontSize: '12px' }}>
                {error?.message && !isValidating ? error.message : null}
              </p>
              {isValidating && (
                <p style={{ fontSize: '12px', fontStyle: 'italic' }}>{'Validando nome...'}</p>
              )}
            </Stack>
          </Stack>
        );
      }}
    />
  );
};
