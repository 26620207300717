import { Wrap, Divider } from './styled';
interface IHeaderButtonBackAndTitleProps {
  title: string;
  onClick: () => void;
}
export const HeaderButtonBackAndTitle: React.FC<IHeaderButtonBackAndTitleProps> = ({
  title,
  onClick,
}) => (
  <Wrap>
    <button onClick={onClick}>
      <img src="/assets/icons/icon-rounded-back.svg" alt="back" />
      <p>Voltar</p>
    </button>
    <Divider />
    <p>{title}</p>
  </Wrap>
);
