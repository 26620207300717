import { AlertPreview, Icon, TextLink } from 'globalStyles';
import { popupButtonColorsMap } from 'helpers/enums';
import parse from 'html-react-parser';
import { device } from 'styles/breakpoint';

import Stack from '../Stack';
import { PopupButtonText, PopupSubTitle, PopupTitle, StackCard, StackPreview } from './styled';

interface IPreviewPopupButtonProps {
  icon: string;
  title: string;
  subTitle: string;
  buttons: { text?: string; color: popupButtonColorsMap }[];
  hidenCloseButton?: boolean;
}

function PreviewPopupButton(props: IPreviewPopupButtonProps) {
  const { icon, title, subTitle, buttons, hidenCloseButton } = props;
  const calculateBorder = (index: number) => {
    if (buttons?.length === 1) {
      return '0 0 12px 12px';
    }
    if (index === 0) {
      return '0 0 0 12px';
    }
    if (index === 1) {
      return '0 0 12px 0';
    }
    return '';
  };

  const getDefaultText = (index: number) => {
    if (index === 0) {
      return '< Texto botão 1';
    } else if (index === 1) {
      return 'Texto botão 2 >';
    }
  };

  return (
    <StackPreview
      direction="column"
      marginLeft="70px"
      justifyContent="center"
      flex="1"
      alignItems="center"
    >
      <Stack justifyContent="center" breakpointDown={device.laptopM}>
        <AlertPreview>Pré-visualização</AlertPreview>
      </Stack>
      <StackCard direction="row" width="100%" maxWidth="504px" height="280px">
        <Stack direction="column" background="white" width="100%" height="100%" borderRadius="12px">
          <Stack direction="column" alignItems="center" flex="1" justifyContent="center">
            {icon && <Icon src={icon} height="60px" data-testid="icon-preview-popup-button" />}
            <PopupTitle>{title || 'Título do pop-up'}</PopupTitle>
            <PopupSubTitle>{parse(subTitle || 'Conteúdo do pop-up')}</PopupSubTitle>
          </Stack>

          {buttons && (
            <Stack direction="row">
              {buttons?.map((button, index) => (
                <Stack
                  key={index}
                  background={popupButtonColorsMap[button.color]}
                  flex="1"
                  justifyContent="center"
                  alignItems="center"
                  height="68px"
                  borderRadius={calculateBorder(index)}
                >
                  <PopupButtonText>{button.text || getDefaultText(index)}</PopupButtonText>
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>
        {!hidenCloseButton && (
          <Stack
            marginLeft="0.75rem"
            alignItems="flex-start"
            breakpointDown={device.laptopM}
            data-testid="icon-close-popup"
          >
            <Icon src="/assets/icons/icon-close.svg" width={'22px'} />
          </Stack>
        )}
      </StackCard>
      <Stack justifyContent="center" marginTop="1.255rem" breakpointDown={device.laptopM}>
        <TextLink textDecoration="underline" fontSize="10px">
          Não lembrar mais &gt;
        </TextLink>
      </Stack>
    </StackPreview>
  );
}
export default PreviewPopupButton;
