import TopicSummary from 'components/atoms/TopicSummary';
import { EditActionPopup } from 'components/molecules';
import { popupActionListAll } from 'helpers/enums';
import { usePopupEdit } from 'hooks/useEditPopup';
import { useMemo, useState } from 'react';

interface IResumeActionPopup {
  links: any[];
}

export const ResumeActionPopup: React.FC<IResumeActionPopup> = ({ links }) => {
  const { setHidenPreview, formPopup } = usePopupEdit();
  const { watch, setValue } = formPopup;
  const {
    action,
    active,
    name,
    linkType,
    link,
    internalLink,
    actionFile,
    actionLinkFile,
    nameFileBucket,
  } = watch();
  const [openEdit, setOpenEdit] = useState(false);
  const actionSelected = useMemo(() => {
    return popupActionListAll.find((actionItem) => actionItem.value === action.toString());
  }, [action]);

  const handleOpen = (val: any) => {
    setOpenEdit(val);
    setHidenPreview(val);
  };

  const save = (data: any) => {
    setValue('action', data.action, { shouldDirty: true });
    setValue('linkType', data.linkType, { shouldDirty: true });
    setValue('link', data.link, { shouldDirty: true });
    setValue('internalLink', data.internalLink, { shouldDirty: true });
    setValue('actionFile', data.actionFile, { shouldDirty: true });
    setValue('actionLinkFile', data.actionLinkFile, { shouldDirty: true });
    setValue('nameFileBucket', undefined, { shouldDirty: true });
    handleOpen(false);
  };

  const defaultValues = {
    name,
    action,
    linkType,
    link,
    internalLink,
    actionFile: actionFile ?? (nameFileBucket && !actionFile ? { name: nameFileBucket } : null),
    actionLinkFile,
  };

  return (
    <>
      <TopicSummary
        title="Ação do pop-up*"
        link="Editar >"
        itens={[actionSelected?.label ?? '']}
        click={() => handleOpen(true)}
        disabled={!active}
      />
      {openEdit && (
        <EditActionPopup
          data-testid="edit-action-popup"
          onClose={() => handleOpen(false)}
          handleSave={save}
          links={links}
          defaultValues={defaultValues}
          showPreview={false}
        />
      )}
    </>
  );
};
