import { StyledStack } from 'components/atoms/Stack';
import styled from 'styled-components';

export const SectionInformation = styled.section`
  margin-bottom: 1rem;
`;

export const WrapperBetween = styled.div`
  margin-top: 0.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WrapperClue = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25rem;
`;

export const TitleClue = styled.span`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 0.125rem;
  display: block;
`;

export const ParagraphClue = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  width: 240px;
`;

export const Flex = styled(StyledStack)`
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${(props) => props.justifyContent};
  gap: ${(props) => (props.gap ? props.gap : '0')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
`;

export const IconTurbo = styled.img`
  height: 7px;
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const IconClose = styled.img`
  height: 15px;
`;

export const ButtonRoundedNext = styled.button`
  border: 0;
  padding: 0;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 109%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  padding: 8px 11px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 0.875rem;
`;

export const IconSuccess = styled.img`
  width: 19px;
  display: flex;
  margin-bottom: 0.375rem;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black};
`;
