import TopicSummary from 'components/atoms/TopicSummary';
import { EditPopupButton } from 'components/molecules';
import { convertArrayStringInText } from 'helpers/functions';
import { usePopupEdit } from 'hooks/useEditPopup';
import { useMemo, useState } from 'react';

export const ResumePopup = () => {
  const { setHidenPreview, formPopup } = usePopupEdit();
  const { watch, setValue } = formPopup;
  const [openEdit, setOpenEdit] = useState(false);
  const { popupButtonIcon, popupButtonTitle, popupButtonSubTitle, popupButtons, active } = watch();

  const contentPopUp = useMemo(() => {
    const content = [
      popupButtonIcon >= 0 ? 'ícone' : '',
      popupButtonTitle ? 'título' : '',
      popupButtonSubTitle ? 'conteúdo' : '',
    ];
    return convertArrayStringInText(content);
  }, [popupButtonIcon, popupButtonTitle, popupButtonSubTitle]);

  const data = useMemo(
    () => ({
      popupButtonIcon,
      popupButtonTitle,
      popupButtonSubTitle,
      popupButtons: [...popupButtons],
    }),
    [popupButtonIcon, popupButtonTitle, popupButtonSubTitle, popupButtons]
  );

  const handleSave = (popupButtonsData: any) => {
    setValue('popupButtonIcon', popupButtonsData.popupButtonIcon, {
      shouldDirty: true,
    });
    setValue('popupButtonTitle', popupButtonsData.popupButtonTitle, {
      shouldDirty: true,
    });
    setValue('popupButtonSubTitle', popupButtonsData.popupButtonSubTitle, {
      shouldDirty: true,
    });
    setOpenEdit(false);
    setHidenPreview(false);
  };

  const handleOpen = (val: any) => {
    setOpenEdit(val);
    setHidenPreview(val);
  };

  return (
    <>
      <TopicSummary
        title="Conteúdo do pop-up*"
        link="Editar >"
        itens={[contentPopUp]}
        disabled={!active}
        click={() => setOpenEdit(true)}
      />
      {openEdit && (
        <EditPopupButton
          data={data}
          handleClose={() => handleOpen(false)}
          handleSave={handleSave}
        />
      )}
    </>
  );
};
