import { ContentState, Editor, EditorState, Modifier, RichUtils, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useState } from 'react';

import { ButtonInputStyle } from '../ButtonInputStyle';
import Information from '../Information';
import { TextCount } from '../TextCount';
import { EditorContainer, EditorCotainer, ToolbarContainer } from './styled';

import 'draft-js/dist/Draft.css';
import { useTheme } from 'hooks/useTheme';

const INLINE_STYLES: {
  label: string;
  style: string;
  name: 'bold' | 'italic' | 'underline';
}[] = [
    { label: 'Bold', style: 'BOLD', name: 'bold' },
    { label: 'Italic', style: 'ITALIC', name: 'italic' },
    { label: 'Underline', style: 'UNDERLINE', name: 'underline' },
  ];

interface IInlineStyleControlsProps {
  editorState: EditorState;
  onToggle: (inlineStyle: string) => void;
}

const InlineStyleControls: React.FC<IInlineStyleControlsProps> = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="toolbar__controls">
      {INLINE_STYLES.map((type) => (
        <ButtonInputStyle
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          name={type.name}
        />
      ))}
    </div>
  );
};

interface ITextEditorProps {
  label?: string;
  maxLength?: number;
  showCurrentCount?: boolean;
  tooltipTitle?: string;
  tooltipLabel?: string;
  placeholder?: string;
  onChangeHTML?: (value: string) => void;
  removeTags?: boolean;
  defaultValue?: string;
}

export const TextEditor: React.FC<ITextEditorProps> = ({
  label,
  maxLength = 99999,
  showCurrentCount = false,
  tooltipTitle,
  tooltipLabel,
  placeholder,
  onChangeHTML,
  defaultValue = '',
}) => {
  const [editorState, setEditorState] = useState(() => {
    const blocksFromHTML = convertFromHTML(defaultValue);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(contentState);
  });

  const toggleInlineStyle = (inlineStyle: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    if (onChangeHTML)
      onChangeHTML(
        stateToHTML(RichUtils.toggleInlineStyle(editorState, inlineStyle).getCurrentContent())
      );
  };

  const currentCount = editorState.getCurrentContent().getPlainText().length;

  const __handlePastedText = (pastedText: string) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength + pastedText.length - selectedTextLength > maxLength) {
      const selection = editorState.getSelection();
      const isCollapsed = selection.isCollapsed();
      const tempEditorState = !isCollapsed ? _removeSelection() : editorState;
      _addPastedContent(pastedText, tempEditorState);

      return 'handled';
    }
    return 'not-handled';
  };

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }
          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }
    return length;
  };

  const _addPastedContent = (input: string, editorState: EditorState) => {
    const inputLength = editorState.getCurrentContent().getPlainText().length;
    const remainingLength = maxLength - inputLength;

    const newContent = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      input.slice(0, remainingLength)
    );
    setEditorState(EditorState.push(editorState, newContent, 'insert-characters'));
  };

  const _removeSelection = () => {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endKey = selection.getEndKey();
    const endOffset = selection.getEndOffset();
    if (startKey !== endKey || startOffset !== endOffset) {
      const newContent = Modifier.removeRange(
        editorState.getCurrentContent(),
        selection,
        'forward'
      );
      const tempEditorState = EditorState.push(editorState, newContent, 'remove-range');
      setEditorState(tempEditorState);
      return tempEditorState;
    }
    return editorState;
  };

  const __handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > maxLength - 1) {
      return 'handled';
    }
    return 'not-handled';
  };

  const __onChange = (editorState: EditorState) => {
    setEditorState(editorState);
    if (onChangeHTML) {
      let contentStateHTML = stateToHTML(editorState.getCurrentContent());
      if (editorState.getCurrentContent().getPlainText().length === 0) {
        contentStateHTML = '';
      } else {
        contentStateHTML = contentStateHTML.substring(3);
        contentStateHTML = contentStateHTML.slice(0, -4);
      }
      onChangeHTML(contentStateHTML);
    }
  };

  const { currentTheme } = useTheme();

  return (
    <EditorContainer>
      <div className="container-label">
        <Information label={label} tooltipTitle={tooltipTitle} tooltipLabel={tooltipLabel}
          labelColor={currentTheme.colors.secondary}
          tooltipColor={currentTheme.colors.white} />
        {showCurrentCount && <TextCount count={currentCount} maxLength={maxLength} />}
      </div>
      <ToolbarContainer>
        <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
      </ToolbarContainer>
      <EditorCotainer>
        <Editor
          editorState={editorState}
          onChange={__onChange}
          placeholder={placeholder}
          handleBeforeInput={__handleBeforeInput}
          handlePastedText={__handlePastedText}
        />
      </EditorCotainer>
    </EditorContainer>
  );
};
