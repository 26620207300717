import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, StackModal } from 'components/atoms';
import { LayoutModalContent } from 'components/templates';
import { compareValues } from 'helpers/functions';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { DefineDateValidityForm } from '../DefineDateValidityForm';
import { Modal, ModalEdit } from '../index';

const labelsDefineDateValidity = {
  label1:
    'Com esta opção selecionada, seu banner será exibido apenas durante o período de resgate vigente na plataforma por tempo indeterminado.',
  label2: 'Você poderá desativar este banner acessando o Gerenciador de Banners.',
};

const validationSchemaForm = yup.object({
  startDate: yup.date().required('Informe a data de início'),
  endDate: yup.date().required('Informe a data de Final'),
});

interface IModalEditDateProps {
  form: any;
  title: string;
  onClose: () => void;
  hideValidity?: boolean;
}

export const ModalEditDate: React.FC<IModalEditDateProps> = ({
  form,
  title,
  onClose,
  hideValidity,
}) => {
  const values = form.getValues();
  const defaultValues = {
    defineDate: values.defineDate,
    startDate: values.startDate,
    endDate: values.endDate,
    endDateUndefined: values.endDateUndefined,
  };

  const modalEditDateForm = useForm({
    defaultValues,
    resolver: yupResolver(validationSchemaForm),
  });

  const enableButton = () => {
    const { defineDate, endDateUndefined, startDate, endDate } = modalEditDateForm.getValues();
    const { isValid } = modalEditDateForm.formState;

    const equals = compareValues(
      [defineDate, endDateUndefined, startDate, endDate],
      [
        defaultValues.defineDate,
        defaultValues.endDateUndefined,
        defaultValues.startDate,
        defaultValues.endDate,
      ]
    );
    return isValid && !equals;
  };

  const onSave = () => {
    const { defineDate, endDateUndefined, startDate, endDate } = modalEditDateForm.getValues();
    form.setValue('startDate', startDate, { shouldDirty: true });
    form.setValue('endDate', endDate, { shouldDirty: true });
    form.setValue('endDateUndefined', endDateUndefined, { shouldDirty: true });
    form.setValue('defineDate', defineDate, { shouldDirty: true });

    onClose();
  };

  const { startDate } = modalEditDateForm.getValues();
  return (
    <ModalEdit
      onClose={onClose}
      enableButtonSave={() => enableButton()}
      onSave={onSave}
      title={title}
      width="491px"
      height="532px"
    >
      <Stack marginTop="1rem">
        <DefineDateValidityForm
          form={modalEditDateForm}
          {...labelsDefineDateValidity}
          hideValidity={hideValidity}
        />
      </Stack>
    </ModalEdit>
  );
};
