import { ForwardedRef, forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface StackProps extends IStackStyled {
  children?: ReactNode;
  onClick?: () => void;
  onKeyDown?: () => void;
  classname?: string;
}

type direction = 'column' | 'column-reversed' | 'row' | 'row-reverse';

// Stack styles
const DEFAULT_STACK_DIRECTION = 'row';
export interface IStackStyled {
  htmlFor?: string;
  direction?: direction;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  position?: string;
  zIndex?: string;
  flex?: string;
  flexWrap?: string;
  justifyContent?: string;
  alignItems?: string;
  textAlign?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  margin?: string;
  marginLeft?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  padding?: string;
  paddingLeft?: string;
  paddingTop?: string;
  paddingRight?: string;
  paddingBottom?: string;
  background?: string;
  backgroundColor?: string;
  border?: string;
  borderRadius?: string;
  borderBottomLeftRadius?: string;
  borderBottomRightRadius?: string;
  borderTopLeftRadius?: string;
  borderTopRightRadius?: string;
  overflow?: string;
  overflowX?: string;
  overflowY?: string;
  cursor?: string;
  boxShadow?: string;
  borderTop?: string;
  gap?: string;
  scrollbarWidth?: string;
  scrollbarHeight?: string;
  scrollbarborder?: string;
  backgroundImage?: string;
  backgroundRepeat?: string;
  backgroundSize?: string;
  display?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  hover?: boolean;
  breakpointDown?: string;
  breakpointUp?: string;
  breakpointDownInvert?: string; // só mostra o componente se a tela for menor que o breakpoint
  gridTemplateColumns?: string;
  backgroundPosition?: string;
  textDecoration?: string;
  fontSize?: string;
  color?: string;
  whiteSpace?: string;
}

export const StyledStack = styled.div<IStackStyled>`
  display: ${({ display }) => display ?? 'flex'};
  color: ${({ color }) => color};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  position: ${({ position }) => position};
  z-index: ${({ zIndex }) => zIndex};
  flex-direction: ${({ direction }) => direction ?? DEFAULT_STACK_DIRECTION};
  flex: ${({ flex }) => flex};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  text-align: ${({ textAlign }) => textAlign};
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};
  min-height: ${({ minHeight }) => minHeight};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-top: ${({ marginTop }) => marginTop};
  margin-right: ${({ marginRight }) => marginRight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding: ${({ padding }) => padding};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-right: ${({ paddingRight }) => paddingRight};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  background: ${({ background }) => background};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  border-bottom-left-radius: ${({ borderBottomLeftRadius }) => borderBottomLeftRadius};
  border-bottom-right-radius: ${({ borderBottomRightRadius }) => borderBottomRightRadius};
  border-top-right-radius: ${({ borderTopRightRadius }) => borderTopRightRadius};
  border-top-left-radius: ${({ borderTopLeftRadius }) => borderTopLeftRadius};
  overflow: ${({ overflow }) => overflow};
  overflow-x: ${({ overflowX }) => overflowX};
  overflow-y: ${({ overflowY }) => overflowY};
  cursor: ${({ cursor }) => cursor};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border-top: ${({ borderTop }) => borderTop};
  gap: ${({ gap }) => gap};
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : '')};
  background-repeat: ${({ backgroundRepeat }) => backgroundRepeat};
  background-size: ${({ backgroundSize }) => backgroundSize};
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  background-position: ${({ backgroundPosition }) => backgroundPosition};
  text-decoration: ${({ textDecoration }) => textDecoration};
  font-size: ${({ fontSize }) => fontSize};
  white-space: ${({ whiteSpace }) => whiteSpace};
  // scroll in firefox
  scrollbar-color: ${({ theme }) => `${theme.colors.secondary} ${theme.colors.backgroundGray}`};

  /* width */
  ::-webkit-scrollbar {
    width: ${({ scrollbarWidth }) => scrollbarWidth ?? '4px'};
    height: ${({ scrollbarHeight }) => scrollbarHeight ?? '4px'};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.silverDark};
    border: 1px solid
      ${({ scrollbarborder, theme }) => scrollbarborder ?? theme.colors.backgroundGray};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 1.5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.primary};
  }

  ${({ breakpointDown }) => `
    @media (max-width: ${breakpointDown}) {
      display: none;
    }
  `};

  ${({ breakpointUp }) =>
    `
    @media (min-width: ${breakpointUp}) {
      display: none;
    }
  `};

  ${({ breakpointDownInvert }) =>
    breakpointDownInvert &&
    `
    display: none;
    @media (max-width: ${breakpointDownInvert}) {
      display: block;
    }
  `};
`;
// end stack styles

function Stack(props: StackProps, ref?: ForwardedRef<HTMLDivElement>) {
  return (
    <StyledStack {...props} ref={ref}>
      {props.children}
    </StyledStack>
  );
}

export default forwardRef(Stack);
