import { Label, Stack } from 'components/atoms';
import Information from 'components/atoms/Information';
import { DragAndDrop } from 'components/molecules';
import { usePopupButton } from 'hooks/popupButton';
import { useTheme } from 'hooks/useTheme';
import { useEffect } from 'react';

export const PopupButtonStep3 = () => {
  const { createFormPopupButtonStepper } = usePopupButton();
  const [popups, popupButtonName] = createFormPopupButtonStepper.watch([
    'popups',
    'popupButtonName',
  ]);

  const onDragEnd = (
    popups: {
      name: string;
      value: number;
    }[]
  ) => {
    createFormPopupButtonStepper.setValue('popups', popups);
  };

  useEffect(() => {
    const index = popups?.findIndex((popup) => popup.value === 0) ?? -1;
    if (index > -1 && popups) {
      // se o popup existir, apenas alterar o nome
      popups[index].name = popupButtonName ?? '';
      createFormPopupButtonStepper.setValue('popups', popups);
    }
  }, [popupButtonName]);

  const { currentTheme } = useTheme();

  return (
    <Stack direction="column" marginTop="0.75rem" marginBottom="0.75rem">
      <Stack direction="column" marginBottom="0.75rem">
        <Information
          label="Ordem de aparição*"
          tooltipTitle="Obrigatório"
          tooltipLabel="Defina em que ordem aparecerão os pop-ups no sistema."
          labelColor={currentTheme.colors.secondary}
          tooltipColor={currentTheme.colors.white}
          place="right"
        />
        <Label
          fontSize="10px"
          title={' Clique e arraste para ajustar a priorização de pop-ups ativos.'}
        />
      </Stack>

      <DragAndDrop title={'Nome do pop-up'} list={popups ?? []} onDragEnd={onDragEnd} />
    </Stack>
  );
};
