import React from 'react';

import { Background } from './styled';

interface ILayoutSystemProps {
  children?: React.ReactNode;
}
function LayoutSystem({ children }: ILayoutSystemProps) {
  return <Background>{children}</Background>;
}

export default LayoutSystem;
