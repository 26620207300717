import { Stack } from 'components/atoms';
import { device } from 'styles/breakpoint';

import { ButtonClose, Img, Wrap } from './styled';

interface IModal {
  iconTurbo?: boolean;
  close?: () => void;
  width?: string;
  minWidth?: string;
  height?: string;
  background?: string;
  display?: string;
  position?: string;
  zIndex?: string;
  positionCenter?: boolean;
  padding?: string;
  flexDirection?: string;
  justifyContent?: string;
  children: React.ReactNode;
  fullScreenTabled?: boolean;
  displayButtonCloseOnTable?: string;
}

export const Modal: React.FC<IModal> = ({
  iconTurbo,
  close,
  width,
  minWidth,
  height,
  background,
  display,
  position,
  zIndex,
  positionCenter,
  padding,
  flexDirection,
  justifyContent,
  children,
  fullScreenTabled = false,
  displayButtonCloseOnTable = 'none',
}): JSX.Element => {
  return (
    <Wrap
      width={width}
      minWidth={minWidth}
      height={height}
      background={background}
      display={display}
      position={position}
      zIndex={zIndex}
      positionCenter={positionCenter}
      padding={padding}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      fullScreenTabled={fullScreenTabled}
      data-testid="modal"
    >
      {iconTurbo && (
        <Stack breakpointDown={device.laptop}>
          <Img src="/assets/images/logo-backoffice-fidelidade-blue.svg" alt="turbo" />
        </Stack>
      )}
      {close && (
        <ButtonClose
          id="close"
          onClick={() => close()}
          displayButtonCloseOnTable={displayButtonCloseOnTable}
        >
          <img src="/assets/icons/icon-close.svg" alt="close" />
        </ButtonClose>
      )}
      {children}
    </Wrap>
  );
};
