import { StatusEnum, ObjectBannersStatus } from 'helpers/enums';
import React from 'react';

import { Description, Dot, Wrapper } from './styled';

interface IStatusUserProps {
  status: boolean | string;
  type: string;
}
function StatusUser({ status, type }: IStatusUserProps) {
  const getType = () => {
    switch (type) {
      case 'User':
        switch (status) {
          case StatusEnum.ACTIVE.key:
            return {
              description: StatusEnum.ACTIVE.description,
              color: StatusEnum.ACTIVE.color,
            };
          case StatusEnum.WAITING.key:
            return {
              description: StatusEnum.WAITING.description,
              color: StatusEnum.WAITING.color,
            };
          case StatusEnum.INACTIVE.key:
            return {
              description: StatusEnum.INACTIVE.description,
              color: StatusEnum.INACTIVE.color,
            };
          default:
            return {
              description: StatusEnum.ACTIVE.description,
              color: StatusEnum.ACTIVE.color,
            };
        }
      case 'Banner':
      case 'Popup':
        switch (status) {
          case ObjectBannersStatus.ACTIVE.label:
            return {
              description: ObjectBannersStatus.ACTIVE.label,
              color: ObjectBannersStatus.ACTIVE.color,
            };
          case ObjectBannersStatus.INACTIVE.label:
            return {
              description: ObjectBannersStatus.INACTIVE.label,
              color: ObjectBannersStatus.INACTIVE.color,
            };
          case ObjectBannersStatus.SCHEDULE.label:
            return {
              description: ObjectBannersStatus.SCHEDULE.label,
              color: ObjectBannersStatus.SCHEDULE.color,
            };
        }
    }
  };

  return (
    <Wrapper>
      <Dot color={getType()?.color} data-testid="status-user-dot" />
      <Description data-testid="status-user-description">{getType()?.description}</Description>
    </Wrapper>
  );
}

export default StatusUser;
