import { StyledStack } from 'components/atoms/Stack';
import styled from 'styled-components';

export const StyledAgGridContainer = styled.div`
  /* Row */
  --ag-grid-size: 5px;
  --ag-list-item-height: 32px;
  --ag-range-selection-border-color: ${({ theme }) => theme.colors.backgroundWhite};
  .ag-row-odd {
    background: #f9f9f9;
  }
  .ag-row-hover {
    background: #00000005;
  }

  /* Font */
  --ag-foreground-color: ${({ theme }) => theme.colors.secondary};
  --ag-font-size: 9px !important;
  --ag-font-family: 'Poppins';
  .ag-root-wrapper {
    font-size: 12px;
  }

  /* Header */
  --ag-header-height: 44px;
  --ag-header-foreground-color: ${({ theme }) => theme.colors.backgroundWhite};
  --ag-header-background-color: ${({ theme }) => theme.colors.secondary};
  --ag-header-column-resize-handle-color: ${({ theme }) => theme.colors.backgroundWhite};
  --ag-header-cell-moving-background-color: ${({ theme }) => theme.colors.secondary};

  height: 400px;
  --ag-border-radius: 16px;
  --ag-background-color: ${({ theme }) => theme.colors.backgroundGray};

  @media (max-height: 768px) {
    height: 280px;
  }
`;
