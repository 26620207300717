import { AlertPreview, ButtonEdit } from 'globalStyles';
import { fileToBase64 } from 'helpers/functions';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import Stack from '../Stack';
import Tooltip from '../Tooltip';
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  Description,
  IconEdit,
  IconUpload,
  ImageLink,
  RoundIcon,
  RoundIconContainer,
  SectionUpload,
  StackAlertPreview,
  StackUpaloadBanner,
  SubWrapper,
  Title,
} from './styled';

interface IUploadBannerProps {
  edit?: boolean;
  imageLink?: string;
  disabled?: boolean;
  name: string;
  control: Control<any>;
  file?: File;
}

const UploadBanner: React.FC<IUploadBannerProps> = ({
  edit,
  imageLink,
  disabled,
  name,
  control,
  file,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const randomId = useMemo(() => (Math.random() * Math.random()).toString(), []);

  const label = 'O arquivo deve ser em PNG, de no máximo 1MB e nas proporções 1100px x 216px.';

  const [tooltipError, setTooltipError] = useState(false);
  const [base64, setBase64] = useState('');
  const [showLabel, setShowLabel] = useState(false);

  const showTooltipError = () => {
    // if (inputRef) inputRef.value = '';

    if (tooltipError) return;
    setTooltipError(true);

    setTimeout(() => setTooltipError(false), 5000);
  };

  // validate file size and type  (max 2MB, only image)
  const validateFile = (file: File, cb?: (file: File) => void) => {
    setTooltipError(false);
    if (!file) return;

    const validTypes = ['image/png'];
    const isLessThan1MB = file.size <= 2000000;

    // validate dimensions
    if (!validTypes.includes(file.type) || !isLessThan1MB) {
      return showTooltipError();
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      window.URL.revokeObjectURL(img.src);

      if (width !== 1100 || height !== 216) {
        showTooltipError();
      } else {
        setTooltipError(false);
        fileToBase64(file).then((result) => {
          const base64 = `data:${file.type};base64,${result}`;
          setBase64(base64);
          if (cb) cb(file);
        });
      }
    };
  };

  useEffect(() => {
    if (file) validateFile(file);
  }, [file]);

  return (
    <StackUpaloadBanner direction="column" alignItems="center" justifyContent="center" edit={edit}>
      {!edit && <StackAlertPreview>Pré-visualização</StackAlertPreview>}
      <Tooltip
        width={'197px'}
        content={'Arquivo no formato inválido. O arquivo deve ser no formato PNG e de até 1MB.'}
        direction={'bottom'}
        isOpenTootip={tooltipError}
      >
        <SectionUpload base64={base64 === '' && imageLink ? imageLink : base64} edit={edit}>
          <SubWrapper>
            {!edit && <ArrowBackIcon />}
            {!edit && (
              <RoundIconContainer>
                <RoundIcon />
                <RoundIcon />
                <RoundIcon />
              </RoundIconContainer>
            )}

            {!base64 && !imageLink ? (
              <>
                <IconUpload src="/assets/icons/icon-upload.svg" />

                <Description>{label}</Description>
              </>
            ) : null}

            {!base64 && !imageLink && (
              <Title htmlFor={randomId}>Escolher arquivo do computador</Title>
            )}

            {!edit && <ArrowForwardIcon />}
          </SubWrapper>

          {(base64 || imageLink) && !disabled && (
            <ButtonEdit
              htmlFor={randomId}
              bottom={edit ? '-4px' : '0'}
              right={edit ? '-4px' : '0'}
              onMouseEnter={() => setShowLabel(true)}
              onMouseLeave={() => setShowLabel(false)}
            >
              <Stack gap="4px" alignItems="center">
                <IconEdit src="/assets/icons/icon-edit.svg" />

                {showLabel && (
                  <>
                    <Description fontWeight="bold">Editar</Description>
                    <Description>{label}</Description>
                  </>
                )}
              </Stack>
            </ButtonEdit>
          )}
        </SectionUpload>
      </Tooltip>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <input
            ref={inputRef}
            type="file"
            id={randomId}
            hidden
            accept="image/png"
            onChange={(e) => {
              if (e.target.files?.length) validateFile(e.target.files[0], field.onChange);
            }}
          />
        )}
      />
    </StackUpaloadBanner>
  );
};

export default UploadBanner;
