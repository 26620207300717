import styled, { css } from 'styled-components';

interface IButtonProps {
  variant?: 'outlined' | 'contained';
}

export const Button = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 9px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  gap: 0.25rem;
  border-radius: 1rem;
  padding: 8px;

  ${({ variant }) =>
    variant === 'outlined' &&
    css`
      background-color: transparent;
      color: ${(props) => props.theme.colors.primary};
      border: 1px solid ${(props) => props.theme.colors.primary};
    `}
`;

export const IconDownload = styled.img`
  height: 12px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
`;
