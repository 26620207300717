import { HeaderButtonBackAndTitle, Stack, StackModal } from 'components/atoms';
import { FooterModalEdit, Modal } from 'components/molecules';
import { device } from 'styles/breakpoint';

interface IModalEditPopupProps {
  children?: React.ReactNode;
  onClose: (val: boolean) => void;
  onSave: () => void;
  enableButtonSave: () => boolean;
  height?: string;
  width?: string;
  background?: string;
  hidenFooter?: boolean;
  name?: string;
  Preview?: JSX.Element;
}

export const ModalEditPopup: React.FC<IModalEditPopupProps> = ({
  children,
  onClose,
  onSave,
  enableButtonSave,
  height = '30.625rem',
  width = '33.25rem',
  background = '#F1F1F1',
  hidenFooter = false,
  name,
  Preview,
}) => {
  return (
    <StackModal>
      <Modal
        width={width}
        height={height}
        background={background}
        zIndex="4"
        display="flex"
        flexDirection="column"
        fullScreenTabled
      >
        <Stack direction="column" justifyContent="space-between" height="100%">
          <Stack direction="column" gap="4px" padding="33px 10px 0 30px">
            <HeaderButtonBackAndTitle
              onClick={() => onClose(false)}
              title={name ? `Editando "${name}"` : 'Editando um popup'}
            />
            {children}
          </Stack>
          {!hidenFooter && (
            <FooterModalEdit
              enableButtonSave={enableButtonSave}
              onSave={onSave}
              onClose={onClose}
              theme="confirmToUpdate"
            />
          )}
        </Stack>
      </Modal>
      {Preview && (
        <Stack breakpointDown={device.laptopM} minWidth="550px">
          {Preview}
        </Stack>
      )}
    </StackModal>
  );
};
