export const newHomeURL = '/newHome';
export const turboURL = '/turbo';
export const turboHomeURL = `${turboURL}/home`;
export const turboBannersURL = `${turboURL}/banners`;
export const turboBannerManagementURL = `${turboBannersURL}/gerenciador`;
export const turboBannerCreateURL = `${turboBannersURL}/criar`;
export const turboPopupsURL = `${turboURL}/popups`;
export const turboPopupsManagementURL = `${turboPopupsURL}/gerenciador`;
export const turboPopupsCreateURL = `${turboPopupsURL}/criar`;
export const turboPopupsImageCreateURL = `${turboPopupsCreateURL}/image`;
export const turboPopupsButtonCreateURL = `${turboPopupsCreateURL}/button`;
export const turboCarrosselURL = `${turboURL}/carrossel`;
export const turboCarrosselBackgroundURL = `${turboCarrosselURL}/background`;
export const turboCarrosselBackgroundManagementURL = `${turboCarrosselBackgroundURL}/gerenciador`;
export const turboCarrosselBackgroundCreateURL = `${turboCarrosselBackgroundURL}/criar`;
export const turboCarroselPictureDicionaryURL = `${turboCarrosselURL}/imagem`;
export const turboCarroselPictureDicionaryManagementURL = `${turboCarroselPictureDicionaryURL}/gerenciador`;
export const turboCarroselPictureDicionaryCreateURL = `${turboCarroselPictureDicionaryURL}/criar`;
export const chamasURL = '/chamas';
export const chamasHomeURL = '/chamas/home';
export const chamasAnalyticalURL = `${chamasURL}/analitico`;
export const backofficeURL = `/backoffice`;
export const backofficeHomeURL = `${backofficeURL}/home`;
export const backofficeUsersURL = `${backofficeURL}/users`;
export const backofficeUsersMangementURL = `${backofficeUsersURL}/gerenciador`;
export const backofficeUsersCreateURL = `${backofficeUsersURL}/criar`;
