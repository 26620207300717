import { Stack, StackModal } from 'components/atoms';
import PreviewPopupButton from 'components/atoms/PreviewPopupButton';
import {
  Modal,
  NamePopup,
  ResumeActionPopup,
  ResumeButtonsPopup,
  ResumeDateValidity,
  ResumeFourtargetAudience,
  ResumePopup,
  ResumePriority,
  TitlePopup,
} from 'components/molecules';
import { LayoutModalContent } from 'components/templates';
import { popupButtonIcons } from 'helpers/enums';
import { useLoading } from 'hooks/loading';
import { useConfirm } from 'hooks/useConfirm';
import { usePopupEdit } from 'hooks/useEditPopup';
import { isEmpty } from 'lodash';
import { IListsModel } from 'models';
import { IPopupsModel, PopupType } from 'models/entities/popup-base.model';
import { useEffect, useState } from 'react';
import { GetAllLists } from 'requests/Lists';
import { device } from 'styles/breakpoint';

import { PreviewPopupImage } from '../PreviewPopupImage';
import { StackModalContent, StackResumo } from './styled';

interface IEditPopupButtonProps {
  data: IPopupsModel;
  onClose: (atualizar: boolean) => void;
}
export const EditPopupButton: React.FC<IEditPopupButtonProps> = ({ data, onClose }) => {
  const { hidenPreview, setInitialStateForm, formPopup, updatePopup } = usePopupEdit();
  const [lists, setLists] = useState<IListsModel>();
  const [boot, setBoot] = useState(true);
  const { handleHideLoading, handleShowLoading } = useLoading();
  const { confirm } = useConfirm();
  const {
    type,
    popupButtonIcon,
    popupButtonTitle,
    popupButtonSubTitle,
    popupButtons,
    image,
    active,
    popupButtonName,
    activeInitial,
    popupFile,
    status,
    startDate,
  } = formPopup.watch();

  const enableButton = () => {
    return (
      formPopup.formState.isValid &&
      formPopup.formState.isDirty &&
      isEmpty(formPopup.formState.errors)
    );
  };

  const cancelEditModal = () => {
    if (!enableButton()) {
      return onClose(false);
    }
    confirm().then(() => {
      onClose(false);
    });
  };

  useEffect(() => {
    handleShowLoading();
    setBoot(true);
    GetAllLists(4)
      .then((result) => {
        setLists(result);
        // setInitialState(data, result);
        setInitialStateForm(data, result);
        handleHideLoading();
        setBoot(false);
      })
      .catch(() => {
        handleHideLoading();
        setBoot(false);
      });
  }, []);

  const currentIcon = popupButtonIcons[popupButtonIcon - 1];

  const update = async () => {
    try {
      handleShowLoading();
      const res = await updatePopup();
      if (res) {
        onClose(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleHideLoading();
    }
  };
  const links = lists?.links ?? [];

  const getStartDate = (): Date => {
    return startDate ?? new Date();
  };

  const inactivePopup = () => {
    const options = {
      width: '33.563rem',
      height: '19.25rem',
      question: 'Inativar o Pop-up',
      information: `Você está Inativando um Pop-up que está Agendado para inicio em ${getStartDate().toLocaleDateString()}. Deseja continuar?`,
      labelButtonNegative: 'Não',
      labelButtonConfirm: 'Sim',
    };
    void confirm(options).then(() => {
      update();
    });
  };

  return (
    <StackModal>
      <Modal background="transparent" position="absolute" zIndex="3" fullScreenTabled>
        <Stack alignItems="center" direction="row-reverse" height="100%">
          <Stack breakpointDown={device.laptopM} minWidth="540px">
            {type === PopupType.BUTTON && (
              <PreviewPopupButton
                icon={currentIcon?.src}
                title={popupButtonTitle}
                subTitle={popupButtonSubTitle}
                hidenCloseButton
                buttons={popupButtons.map((button) => ({
                  text: button.text,
                  color: button.idColor,
                }))}
              />
            )}
            {type === PopupType.IMAGE && (
              <PreviewPopupImage
                disableEdit={!active}
                imageLink={image}
                onChange={(image) => formPopup.setValue('popupFile', image, { shouldDirty: true })}
                edit
                marginLeft="70px"
                popupFile={popupFile}
              />
            )}
          </Stack>

          <LayoutModalContent
            title="Editando um pop-up"
            handleCancel={cancelEditModal}
            footer={{
              tip: !active ? 'Para editar este pop-up, você deve reativá-lo primeiro.' : '',
              onSave: () => {
                if (status === 'Agendado' && !active) {
                  inactivePopup();
                } else {
                  update();
                }
              },
              enableButtonSave: () => enableButton(),
              onClose: () => cancelEditModal(),
            }}
            showHeaderFullScreen
            onClose={() => cancelEditModal()}
          >
            <StackModalContent
              direction="column"
              overflowY={hidenPreview ? 'hidden' : 'auto'}
              overflowX="hidden"
              paddingRight="1.25rem"
              position="relative"
            >
              {!boot && (
                <>
                  <TitlePopup
                    state={activeInitial ?? false}
                    name={popupButtonName ?? ''}
                    isSchedule={status === 'Agendado'}
                  />
                  <Stack breakpointDownInvert={device.laptopM} marginBottom="1rem">
                    {type === PopupType.BUTTON && (
                      <PreviewPopupButton
                        icon={currentIcon?.src}
                        title={popupButtonTitle}
                        subTitle={popupButtonSubTitle}
                        hidenCloseButton
                        buttons={popupButtons.map((button) => ({
                          text: button.text,
                          color: button.idColor,
                        }))}
                      />
                    )}
                    {type === PopupType.IMAGE && (
                      <PreviewPopupImage
                        disableEdit={!active}
                        imageLink={image}
                        onChange={(image) =>
                          formPopup.setValue('popupFile', image, { shouldDirty: true })
                        }
                        edit
                        marginLeft="70px"
                        popupFile={popupFile}
                      />
                    )}
                  </Stack>

                  <NamePopup />
                  <StackResumo>
                    <Stack>
                      {type === PopupType.BUTTON && <ResumePopup />}
                      {type === PopupType.IMAGE && <ResumeActionPopup links={links} />}

                      {type === PopupType.BUTTON && <ResumeButtonsPopup links={links} />}

                      {type === PopupType.IMAGE && <ResumePriority />}
                      <ResumeDateValidity />
                    </Stack>

                    <Stack gap="1.125rem">
                      {type === PopupType.BUTTON && <ResumePriority />}
                      <ResumeFourtargetAudience lists={lists} />
                    </Stack>
                  </StackResumo>
                </>
              )}
            </StackModalContent>
          </LayoutModalContent>
        </Stack>
      </Modal>
    </StackModal>
  );
};
