import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from 'components/atoms';
import { ModalEditPopup, StepOneUploadImage } from 'components/molecules';
import { validateLink } from 'helpers/functions';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const Schema = yup
  .object()
  .test(
    'Informe uma opção de ação',
    ({ link, internalLink, actionFile, actionLinkFile, action }) =>
      action === 3 ||
      validateLink(link) ||
      internalLink ||
      actionFile ||
      validateLink(actionLinkFile)
  );

interface IEditActionPopupProps {
  defaultValues: any;
  onClose: () => void;
  handleSave: (val: any) => void;
  links: any[];
  showPreview?: boolean;
}

export const EditActionPopup: React.FC<IEditActionPopupProps> = ({
  defaultValues,
  onClose,
  handleSave,
  links,
  showPreview = true,
}) => {
  const formActionButton = useForm({
    defaultValues,
    resolver: yupResolver(Schema),
  });

  const enableButton = () => {
    return formActionButton.formState.isValid && formActionButton.formState.isDirty;
  };

  const onSave = () => {
    const values = formActionButton.getValues();
    handleSave(values);
  };

  return (
    <ModalEditPopup
      onClose={onClose}
      onSave={() => onSave()}
      enableButtonSave={enableButton}
      height={'24.625rem'}
      name={defaultValues?.name}
    >
      <Stack marginRight="20px" overflowY="auto" minHeight="240px">
        <StepOneUploadImage
          form={formActionButton}
          links={links}
          disabledNameEdit
          nameFieldNameFileBucket="nameFileBucket"
          showPreview={showPreview}
        />
      </Stack>
    </ModalEditPopup>
  );
};
