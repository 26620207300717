import { Stack, StepCreated } from 'components/atoms';
import { IMenuItem } from 'components/atoms/Menu';
import UploadBanner from 'components/atoms/UploadBanner';
import { LayoutRegister } from 'components/templates';
import { useCreateBanner } from 'hooks/banner';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetAllLists } from 'requests/Lists';
import { turboBannerManagementURL, turboBannersURL } from 'routers/routes';
import { device } from 'styles/breakpoint';
import StepperBanner from '../StepperBanner';

interface IStepperBannerProps {
  subMenu?: IMenuItem[];
}

export const BannerCreate = ({ subMenu }: IStepperBannerProps) => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const { setList, created, setCreated, handleCloseConfirm, createFormBannerStepper } =
    useCreateBanner();

  const name = createFormBannerStepper.watch('name');
  const bannerFile = createFormBannerStepper.watch('bannerFile');
  useEffect(() => {
    document.title = 'Criar Banners | Turbo | Backoffice Lojas';
  }, []);

  useEffect(() => {
    setCreated(false);
    return () => {
      setCreated(false);
    };
  }, [setCreated]);

  useEffect(() => {
    GetAllLists(2).then((result) => {
      setList(result);
      const banners = result?.banners ?? [];
      createFormBannerStepper.setValue('banners', [{ value: 0, name }, ...banners]);
    });
  }, [setList]);

  return (
    <LayoutRegister.Container>
      {!created ? (
        <LayoutRegister.Card
          description="Criando um novo banner"
          subMenu={subMenu}
          onBack={() => {
            if (step === 1) {
              handleCloseConfirm(turboBannersURL);
            } else {
              setStep(step - 1);
            }
          }}
        >
          <StepperBanner
            changeStep={(step) => {
              setStep(step);
            }}
            step={step}
          />
        </LayoutRegister.Card>
      ) : (
        <LayoutRegister.Card
          onBack={() => navigate(turboBannersURL, { replace: true })}
          backText={'Voltar para tela de banner'}
          subMenu={subMenu}
          confirmOnClick={false}
        >
          <StepCreated title={name} name="banner" route={turboBannerManagementURL} height="100%">
            <Stack
              flex="1"
              justifyContent="center"
              alignItems="center"
              breakpointDownInvert={device.laptopM}
            >
              <UploadBanner
                name="bannerFile"
                control={createFormBannerStepper.control}
                file={bannerFile}
                disabled={true}
              />
            </Stack>
          </StepCreated>
        </LayoutRegister.Card>
      )}
      <Stack
        flex="1"
        justifyContent="center"
        alignItems="center"
        breakpointDown={device.laptopM}
        marginLeft="52px"
      >
        <UploadBanner
          name="bannerFile"
          control={createFormBannerStepper.control}
          file={bannerFile}
          disabled={created}
        />
      </Stack>
    </LayoutRegister.Container>
  );
};
