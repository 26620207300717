import styled from 'styled-components';
import { useTheme } from 'hooks/useTheme';

export const TitleClue = styled.span`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.secondary}; 
  margin-right: 0.125rem;
  display: block;
`;

export const ParagraphClue = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
`;

export const IconTurbo = styled.img`
  height: 7px;
`;

