import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';

interface IReactSelectElementProps {
  fixedHeight?: boolean;
  selectColor?: boolean;
  isError?: boolean;
}
export const ReactSelectElement = styled(ReactSelect) <IReactSelectElementProps>`
  .css-yt9ioa-option {
    background: ${(props) => props.theme.colors.backgroundGray}; 

    &:nth-child(even) {
      background-color: ${(props) => props.theme.colors.white}; 
    }
  }
  font-size: 12px;

  .css-1s2u09g-control {
    background: ${(props) => props.theme.colors.white}; 
    border: 0.2px solid rgba(0, 0, 0, 0.34);
    border-radius: 6px;
    min-height: 30px !important;
  }

  ${({ isError }) =>
    isError && css`
    .mySelect__control {
      border: 1px solid #ff8332;
    }
    
  `}

  ${({ fixedHeight }) =>
    fixedHeight &&
    `
  .css-1s2u09g-control {
    min-height: 30px !important;
  }

  .css-1pahdxg-control:hover {
    min-height: 30px !important;
    height: 30px !important;
  }

  .css-1pahdxg-control {
    min-height: 30px !important;
    height: 30px !important;
  }
  .mySelect__input-container {
    margin: 0;
    padding: 0;
  }


  `}

  .css-319lph-ValueContainer {
    padding: 0 8px;
  }

  .css-1d8n9bt {
    padding: 0 8px !important;
  }
  .css-6j8wv5-Input {
    margin: 0;
  }

  .mySelect__control {
    min-height: 30px !important;
  }

  .mySelect__indicator-separator {
    display: none;
  }


  .css-1v5bs2s-control {
    background: ${(props) => props.theme.colors.white}; 
    border-radius: 12px;
    cursor: pointer;
    flex-wrap: inherit;
  }

  .css-9gakcf-option {
    background-color: ${(props) => props.theme.colors.primary}; 
  }

  .fMzeIc {
    height: 0;
  }

  .css-14el2xx-placeholder {
    font-size: 12px;
  }

  ${({ selectColor }) =>
    selectColor &&
    `  
    .css-1fdsijx-ValueContainer {
      padding: 0px 2px !important;
    
  `}

  .mySelect__option--is-selected {
    background: #696977; 
    > div > span {
      color: #fff;
    }

  }

`;

export const DownChevron = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 0.625rem;
`;

export const Flex = styled.div`
  display: flex;
  gap: 0.375rem;
  align-items: center;
`;

export const IconCheck = styled.img`
  width: 19px;
  height: 19px;
`;

export const Label = styled.span`
  font-weight: 400;
  /* font-size: 12px; */
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.secondary}; 
`;
