import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from 'components/atoms';
import { ModalEditPopup, TargetAudienceForm } from 'components/molecules';
import { IListsModel } from 'models';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { StackEditAudience } from './styled';

const validationSchemaForm = yup.object({
  csvFile: yup.mixed().when(['whoCanSee'], {
    is: (whoCanSee: number) => whoCanSee === 2,
    then: yup.mixed().required('Informe o arquivo'),
  }),
  whichBranches: yup.array().min(1).required('Informe a filial'),
  whichClassifications: yup.array().min(1).required('Informe a classificação'),
  segments: yup.array().min(1).required('Informe o banner'),
  planPoints: yup.mixed().when(['planCondition'], {
    is: (planCondition: number) => planCondition,
    then: yup.string().nullable().required('Informe a pontuação'),
  }),
});

interface IEditAudienceProps {
  popup: any;
  onClose: () => void;
  handleSave: (val: any) => void;
  list?: IListsModel;
}

export const EditAudience: React.FC<IEditAudienceProps> = ({
  popup,
  onClose,
  handleSave,
  list,
}) => {
  const formPopupButton = useForm({
    defaultValues: popup,
    resolver: yupResolver(validationSchemaForm),
    // mode: 'onTouched',
  });

  const enableButton = () => {
    return formPopupButton.formState.isValid && formPopupButton.formState.isDirty;
  };

  const onSave = () => {
    const data = formPopupButton.getValues();
    handleSave(data);
  };

  return (
    <ModalEditPopup
      onClose={onClose}
      onSave={() => onSave()}
      enableButtonSave={enableButton}
      height={'33.25rem'}
      name={popup?.name}
    >
      <StackEditAudience>
        <TargetAudienceForm form={formPopupButton} label="pop-up" list={list} />
      </StackEditAudience>
    </ModalEditPopup>
  );
};
