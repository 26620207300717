import React from 'react';
import Information from '../Information';
import { LabelCustom } from './styled';
import { useTheme } from 'hooks/useTheme';

interface ILabelProps {
  title: string;
  fontWeight?: string;
  fontSize?: string;
  color?: string;
  marginTop?: string;
  marginBottom?: string;
  information?: string;
  label?: string;
  letterSpacing?: string;
  lineHeight?: string;
}

export const Label: React.FC<ILabelProps> = ({
  title,
  fontWeight,
  fontSize,
  color,
  marginTop,
  marginBottom,
  information,
  label,
  letterSpacing,
  lineHeight,
}) => {
  const { currentTheme } = useTheme();

  return (
    <LabelCustom
      fontWeight={fontWeight}
      fontSize={fontSize}
      color={color}
      marginTop={marginTop}
      marginBottom={marginBottom}
      letterSpacing={letterSpacing}
      lineHeight={lineHeight}
    >
      {title}
      {information && (
        <Information
          label={label}
          tooltipTitle="Obrigatório"
          tooltipLabel={information}
          labelColor={currentTheme.colors.secondary}
          tooltipColor={currentTheme.colors.white}
        />
      )}
    </LabelCustom>
  );
};
