// eslint-disable-next-line no-unused-vars
import { FooterTip, Stack, Steps } from 'components/atoms';
import { useCreateBanner } from 'hooks/banner';
import React, { useEffect, useRef } from 'react';
import { turboBannersURL } from 'routers/routes';
import ContentCreateBanner from '../ContentCreateBanner';

interface IStepperBannerProps {
  changeStep: (step: number) => void;
  step: number;
}

const tip = [
  'Prepare seu banner para o sistema! Baixe aqui o modelo com as margens de segurança do componente..',
  'Lembre-se que se houver uma campanha com banner ativo, ele pode afetar a ordem de visualização do(s) banner(s) cadastrado(s).',
];

const StepperBanner: React.FC<IStepperBannerProps> = ({ changeStep, step }) => {
  const stepStack = useRef<HTMLDivElement | null>(null);
  const {
    isEditing,
    stepIsValid,
    handleNext,
    activeStep,
    setActiveStep,
    setSteps,
    handleCloseConfirm,
    createFormBannerStepper,
  } = useCreateBanner();

  const name = createFormBannerStepper.watch('name');

  const titles = [
    'Upload e ajustes do banner',
    'Priorização do banner',
    'Vigência do banner',
    'Defina o público alvo',
    name,
  ];

  const title = ['Etapa 01', 'Etapa 02', 'Etapa 03', 'Etapa 04', 'Resumo'];

  // create array with 4 positions to render the steps
  const getSteps = () => {
    return Array.from({ length: 5 }, (_, i) => i + 1);
  };

  useEffect(() => {
    setSteps(getSteps());
  }, [setSteps]);

  useEffect(() => {
    setActiveStep(step);

    stepStack?.current?.scrollTo({
      top: 0,
    });
  }, [setActiveStep, step]);

  useEffect(() => {
    changeStep(activeStep);
  }, [activeStep]);

  const checkStep = (index: number) => {
    const selectedStep = index + 1;
    // para AVANÇAR um passo, é necessário validar se os campos obrigatórios foram preenchidos
    const canNexStep = selectedStep >= activeStep && stepIsValid();
    // para VOLTAR, NÃO é necessário validar
    const isPreviousStep = selectedStep < activeStep;

    // quando tentar avançar mais de um passo, precisa validar se os passos anteriores estão válidos
    if (selectedStep - activeStep > 1) {
      for (let i = activeStep; i <= selectedStep; i++) {
        if (!stepIsValid()) return false;
        // nao pode validar o passo selecionado, se for obrigatório estará inválido.
        if (i === selectedStep - 1) {
          return true;
        } else {
          continue;
        }
      }
      return false;
    }

    if (isPreviousStep || canNexStep) return true;

    return false;
  };

  return (
    <>
      <Steps title={title} subTitle={titles} currentValue={activeStep - 1} />
      <Stack direction="column" flex="1" padding="0.4375rem 0.9375rem 0 0.9375rem" overflow="auto">
        <Stack
          direction="column"
          padding="0.4375rem 0.9375rem 0 0.9375rem"
          flex="1"
          overflow="auto"
          ref={stepStack}
        >
          <Stack
            direction="column"
            paddingBottom="12px"
            scrollbarborder="#f1f1f1"
            paddingRight="1rem"
          >
            <ContentCreateBanner step={activeStep} />
          </Stack>
        </Stack>
      </Stack>

      <FooterTip
        tip={tip[activeStep - 1]}
        advance={() => handleNext()}
        disabledAdvance={!stepIsValid()}
        cancel={() => handleCloseConfirm(turboBannersURL)}
        buttonFinalize={activeStep === 5}
        buttonResume={isEditing}
      />
    </>
  );
};

export default StepperBanner;
