import styled from 'styled-components';

export interface IImgStyledProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  margin?: string;
}
export const Img = styled.img<IImgStyledProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};
  margin: ${({ margin }) => margin};
`;
