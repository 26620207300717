import { Stack } from 'components/atoms';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

export const WrapMenuCard = styled(Stack)`
  .menu-card {
    padding: 15px 21px 11px 23px;
    flex-direction: row;
    flex: auto;
  }
`;
export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 33px 32px;
  gap: 18px;
  flex: 1;
  border-radius: 8px;
  background-color: rgb(241, 241, 241);
  margin-top: -8px;
`;

export const ContainerHeader = styled.div<{ height?: string }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: ${({ height }) => height};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${({ theme }) => theme.colors.backgroundHeader};
  padding: 30px;
`;

export const ContainerLayoutPage = styled(Stack)`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  margin-left: -8px;
  z-index: 0;

  @media (max-width: ${device.tablet}) {
    height: 100%;
    width: 100%;
    margin-left: 0px;
    border-radius: 0px;
  }
`;

export const LayoutPageStyled = styled(Stack)`
  display: flex;
  width: max-content;
  height: max-content;
  @media (max-width: ${device.tablet}) {
    height: 100%;
    width: 100%;
  }
`;

export const StackNameUser = styled(Stack)`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: center;

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;

export const CenteredStack = styled(Stack)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
`;
