import Stack, { StyledStack } from 'components/atoms/Stack';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

export const StackResumo = styled(StyledStack)`
  margin-top: 1rem;
  width: 100%;
  gap: 2%;

  > div {
    width: 49%;
    flex-direction: column;
    gap: 1.125rem;
  }

  @media (max-width: ${device.tablet}) {
    gap: 1.125rem;
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`;

export const StackModal1 = styled(Stack)`
  flex-direction: column;
  background: #f1f1f1;
  width: 540px;
  height: 50rem;
  padding-top: 2.063rem;
  border-radius: 0.5rem;
  justify-content: space-between;

  @media (max-width: ${device.tablet}) {
    width: 100%;
    height: 100%;
    padding-top: 0;
    border-radius: 0;
  }
  @media (min-width: ${device.laptopM}) {
    height: 30rem;
  }
`;

export const StackModalContent = styled(Stack)`
  height: 43rem;

  @media (min-width: 1281px) {
    height: 22rem;
  }
  @media (max-width: ${device.tablet}) {
    height: 100%;
  }
`;

export const StackModalWrapper = styled(Stack)`
  @media (max-width: ${device.tablet}) {
    padding-top: 2.063rem;
  }
`;
