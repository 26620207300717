import { FooterTip, Stack, Steps } from 'components/atoms';
import { usePopupImage } from 'hooks/popupImage';
import { useEffect, useRef } from 'react';

import { StepFourtargetAudience } from './StepFourtargetAudience';
import { StepOneUpload } from './StepOneUpload';
import { StepPopupImageResume } from './StepPopupImageResume';
import { StepTrheeDate } from './StepTrheeDate';
import { StepTwoPriority } from './StepTwoPriority';

interface ICreatePopupImageProps {
  handleChangeStep: (step: number) => void;
  routerBack: string;
}
const CreatePopupImage: React.FC<ICreatePopupImageProps> = ({ handleChangeStep, routerBack }) => {
  const stepStack = useRef<HTMLDivElement | null>(null);
  const {
    stepIsValid,
    activeStep,
    handleNext,
    isEditing,
    handleCloseConfirm,
    createFormPopupImageStepper,
  } = usePopupImage();

  const name = createFormPopupImageStepper.watch('name');

  const title = ['Etapa 01', 'Etapa 02', 'Etapa 03', 'Etapa 04', 'Resumo'];
  const subTitle = [
    'Upload e ajustes do pop-up',
    'Prioridade do pop-up',
    'Vigência do pop-up',
    'Público-alvo',
    name,
  ];
  const tip = [
    'O tamanho de imagem apropriado para um pop-up é de 620px x 375px.',
    'Lembre-se que se houver uma campanha com pop-up ativo, ele pode afetar a ordem de visualização do(s) pop-up(s) cadastrado(s).',
  ];

  useEffect(() => {
    if (stepStack && stepStack?.current && stepStack?.current?.scrollTo) {
      stepStack?.current?.scrollTo({
        top: 0,
      });
    }
  }, [activeStep]);

  return (
    <>
      <Steps title={title} subTitle={subTitle} currentValue={activeStep - 1} />
      <Stack direction="column" padding="0.4375rem 0.9375rem 0 0.9375rem" flex="1" overflow="auto">
        <Stack
          direction="column"
          padding="0.4375rem 0.9375rem 0 0.9375rem"
          flex="1"
          overflow="auto"
          ref={stepStack}
        >
          <Stack direction="column" paddingBottom="12px" scrollbarborder="#f1f1f1" marginTop="1rem">
            {activeStep === 1 && <StepOneUpload />}
            {activeStep === 2 && <StepTwoPriority />}
            {activeStep === 3 && <StepTrheeDate />}
            {activeStep === 4 && <StepFourtargetAudience />}
            {activeStep === 5 && <StepPopupImageResume />}
          </Stack>
        </Stack>
      </Stack>
      <FooterTip
        tip={tip[activeStep - 1]}
        advance={() => handleNext()}
        disabledAdvance={!stepIsValid(activeStep)}
        cancel={() => handleCloseConfirm(routerBack)}
        buttonFinalize={activeStep === 6}
        buttonResume={isEditing}
      />
    </>
  );
};

export default CreatePopupImage;
