import styled from 'styled-components';

import Stack, { IStackStyled } from '../Stack';

interface IModalProps extends IStackStyled {
  display?: string;
  height?: string;
  background?: string;
}
export const Wrap = styled(Stack) <IModalProps>`
  width: 100%;
  height: ${({ height }) => height};
  background-color: ${({ background }) => background};
  border-radius: 0.5rem 9px 0 0;
  z-index: 0;
  ${({ display }) => `display:${display ?? 'block'}`};
`;
