import { Stack } from 'components/atoms';
import Information from 'components/atoms/Information';
import { compareValues } from 'helpers/functions';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { DragAndDrop } from '../DragAndDrop';
import { ModalEdit } from '../index';
import { StackCloseOrder, Text, WrapperInformation } from './styled';
import { useTheme } from 'hooks/useTheme';

interface IModalEditChaseOrderProps {
  form: any;
  onClose: () => void;
  title: string;
}

export const ModalEditChaseOrder: React.FC<IModalEditChaseOrderProps> = ({
  form,
  onClose,
  title,
}) => {
  const values = form.getValues();
  const defaultValues = {
    banners: values.banners,
    id: values.id,
  };

  const modalEditChaseOrderForm = useForm({
    defaultValues,
  });

  const [banners, id] = modalEditChaseOrderForm.watch(['banners', 'id']);

  let tableRef: any = useRef();

  const enableButton = () => {
    const { isValid } = modalEditChaseOrderForm.formState;
    const equals = compareValues(banners, defaultValues.banners);

    return isValid && !equals;
  };

  useEffect(() => {
    try {
      const position = banners?.findIndex((item: any) => item.value === id);
      const qtdItems = banners?.length || 0;
      tableRef?.scrollTo({
        top: (tableRef?.scrollHeight / qtdItems) * position,
      });
    } catch (e) { }
  }, [banners]);

  const onSave = () => {
    form.setValue('banners', banners, { shouldDirty: true });
    onClose();
  };

  const { currentTheme } = useTheme();

  return (
    <ModalEdit
      onClose={onClose}
      enableButtonSave={() => enableButton()}
      onSave={onSave}
      title={title}
      width="33.25rem"
      height="39.625rem"
    >
      <Stack direction="column" overflow="auto" marginTop="0.75rem">
        <WrapperInformation>
          <Information
            label="Ordem de aparição*"
            tooltipTitle="Obrigatório"
            tooltipLabel="Defina em que ordem aparecerão os banners"
            labelColor={currentTheme.colors.secondary}
            tooltipColor={currentTheme.colors.white}
            place="right"
          />
          <Text>Clique e arraste para ajustar a priorização de banners ativos</Text>
        </WrapperInformation>
      </Stack>
      <StackCloseOrder ref={(ref) => (tableRef = ref)}>
        <Stack padding="0 1px 0 1px">
          <DragAndDrop
            title={'Nome do banner'}
            list={banners}
            onDragEnd={(newOrderBanner) => {
              modalEditChaseOrderForm.setValue('banners', newOrderBanner);
              modalEditChaseOrderForm.trigger('banners');
            }}
            editKey={id}
          />
        </Stack>
      </StackCloseOrder>
    </ModalEdit>
  );
};
