import { yupResolver } from '@hookform/resolvers/yup';
import {
  InputForm,
  InputFormCustomValidation,
  InputLabel,
  Stack,
  StackModal,
  ToggleCustom,
} from 'components/atoms';
import TopicSummary from 'components/atoms/TopicSummary';
import { Modal, PreviewPictureDictinary, TitleAndStatusUser } from 'components/molecules';
import { LayoutModalContent } from 'components/templates';
import { useLoading } from 'hooks/loading';
import { useNotification } from 'hooks/notification';
import { useConfirm } from 'hooks/useConfirm';
import { isEmpty } from 'lodash';
import { IPictureDictionaryModel } from 'models';
import { useForm } from 'react-hook-form';
import { EditPictureDictionary } from 'requests/PictureDictionaryManagement';
import { ValidityName, screenName } from 'requests/ValidityName';
import { ModuloEnum, getImage } from 'requests/Image';
import * as yup from 'yup';
import { useEffect } from 'react';

const Schema = yup.object({
  name: yup.string().required(''),
  image: yup.mixed().required(''),
});

interface IPictureDictionaryFormModel extends IPictureDictionaryModel {
  image?: File | string;
  imageBase64?: string;
  base64ForImage?: string;
}

interface IEditPictureDictionaryManagement {
  data: IPictureDictionaryModel;
  setDataEdit: (data: IPictureDictionaryModel | null) => void;
  GetAllPictureDictionaryData: (
    _currentPage: number,
    _pageLimit: number,
    _searchText: string,
    _idTipoPremio: number | null
  ) => Promise<void>;
  dataGetAllPictureDictionaryData: {
    currentPage: number;
    pageLimit: number;
    search: string;
    typeFilter: number | null;
  };
}

export const EditPictureDictionaryManagement: React.FC<IEditPictureDictionaryManagement> = ({
  data,
  setDataEdit,
  GetAllPictureDictionaryData,
  dataGetAllPictureDictionaryData,
}) => {
  const { confirm } = useConfirm();
  const notify = useNotification();
  const { handleHideLoading, handleShowLoading } = useLoading();

  const editFormPicture = useForm<IPictureDictionaryFormModel>({
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      handleShowLoading();
      try {
        const { image } = await getImage({ moduloId: ModuloEnum.DICIONARIO, id: data.idPictureDicionary });
        const defaultValues = {
          id: data.idPictureDicionary,
          active: data.active,
          typeAward: data.typeAward,
          idTypeAward: data.idTypeAward,
          name: data.name,
          image: image,
          idPictureDicionary: data.idPictureDicionary,
          imageBase64: undefined,
          base64ForImage: undefined,
          dictinaryURL: image,
        };
        editFormPicture.reset(defaultValues);
      } catch (error) {
        console.error(error);
      }
      handleHideLoading();
    };

    fetchInitialData();
  }, [data, editFormPicture, handleHideLoading, handleShowLoading]);

  const {
    watch,
    setValue,
  } = editFormPicture;

  const {
    active,
    typeAward,
    idTypeAward,
    idPictureDicionary,
    name,
    imageBase64,
    image,
    base64ForImage,
    dictinaryURL,
  } = watch();

  const sendEdit = async () => {
    handleShowLoading();
    const payload = {
      data: {
        name,
        active,
        idTypeAward: data.idTypeAward,
        idPictureDicionary: data.idPictureDicionary,
      },
      imageChange: !!imageBase64,
      base64ForImage,
      imageName: typeof image === 'string' ? null : image?.name ?? null,
    };
    await EditPictureDictionary(payload)
      .then(() => {
        notify.success('Prêmio atualizado com sucesso.', `O ${name} foi alterado.`);
        GetAllPictureDictionaryData(
          dataGetAllPictureDictionaryData.currentPage,
          dataGetAllPictureDictionaryData.pageLimit,
          dataGetAllPictureDictionaryData.search,
          dataGetAllPictureDictionaryData.typeFilter
        );
      })
      .catch((error) => {
        notify.error('Erro ao atualizar o Prêmio.', error.message, 5000);
      })
      .finally(() => setDataEdit(null));
    handleHideLoading();
  };

  const enableButton = () => {
    return (
      editFormPicture.formState.isValid &&
      editFormPicture.formState.isDirty &&
      isEmpty(editFormPicture.formState.errors)
    );
  };

  const cancelEditModal = () => {
    if (enableButton()) {
      confirm().then(() => {
        setDataEdit(null);
      });
    } else {
      setDataEdit(null);
    }
  };

  const tip =
    active && idTypeAward === 1
      ? 'O tamanho de imagem apropriado para um prêmio normal é 500px x 500px.'
      : active && idTypeAward === 3
      ? 'O tamanho de imagem apropriado para um prêmio campanha é 260px x 202px.'
      : !active
      ? 'Para editar este prêmio, você deve reativá-lo primeiro.'
      : '';

  const save = () => {
    sendEdit();
  };

  const validate = (val: string): Promise<boolean> => {
    return ValidityName({ name: val, screen: screenName.dicionario, id: data.idPictureDicionary });
  };

  return (
    <StackModal>
      <Modal close={cancelEditModal} position="absolute" zIndex="3" fullScreenTabled>
        <Stack alignItems="center" direction="row-reverse" height="100%">
          <LayoutModalContent
            title="Editando uma imagem do dicionário"
            handleCancel={() => setDataEdit(null)}
            footer={{
              tip,
              onSave: save,
              enableButtonSave: enableButton,
              onClose: cancelEditModal,
            }}
            showHeaderFullScreen
            heightModalDown={{
              desktop: '32rem',
              laptopM: '33rem',
              tablet: '100%',
            }}
          >
            <TitleAndStatusUser title={data.name} active={data.active} />
            <PreviewPictureDictinary
              id={String(idPictureDicionary)}
              label={data.name}
              edit={active}
              form={editFormPicture}
              src={imageBase64 ?? dictinaryURL ?? ''}
              widthImageChange={idTypeAward === 1 ? 500 : 260}
              heightImageChange={idTypeAward === 1 ? 500 : 202}
            />
            <Stack marginTop="19px" gap="30px" marginBottom="16px">
              <InputFormCustomValidation
                disabled={!active}
                control={editFormPicture.control}
                name="name"
                type="text"
                label="Nome do prêmio*"
                placeholder="Crie um título..."
                tooltipLabel="Escolha um nome para seu prêmio. Ele será visível apenas no Gerenciador de prêmio."
                form={editFormPicture}
                callbackValidate={validate}
              />
              <InputLabel
                type="text"
                id="name"
                title="Status*"
                placeholder="Crie um título..."
                information="Escolha um nome para seu prêmio. Ele será visível apenas no Gerenciador de prêmio."
              >
                <ToggleCustom
                  defaultChecked={active}
                  onChange={(e) => setValue('active', e.target.checked, { shouldDirty: true })}
                />
              </InputLabel>
            </Stack>
            <TopicSummary title="Tipo de prêmio*" itens={[typeAward ?? '']} />
          </LayoutModalContent>
        </Stack>
      </Modal>
    </StackModal>
  );
};
