import styled from 'styled-components';
export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    display: flex;
    background-color: transparent;
    p {
      font-weight: 400;
      font-size: 0.625rem;
      color: ${(props) => props.theme.colors.secondary}; 
      margin-left: 0.25rem;
      text-decoration: underline;
    }
  }

  p {
    color: ${(props) => props.theme.colors.secondary}; 
    font-weight: 400;
    font-size: 0.625rem;
  }
`;

export const Divider = styled.div`
  height: 20px;
  width: 1px;
  background: rgb(46 43 55 / 36%);
  margin: 0 10px;
`;
