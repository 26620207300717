import TopicSummary from 'components/atoms/TopicSummary';
import { EditDateValidity } from 'components/molecules';
import { diffDaysLabel } from 'helpers/functions';
import { usePopupEdit } from 'hooks/useEditPopup';
import { useState } from 'react';

export const ResumeDateValidity = () => {
  const { setHidenPreview, formPopup } = usePopupEdit();
  const { startDate, defineDate, endDateUndefined, endDate, active, popupButtonName } =
    formPopup.watch();

  const defaultValues = {
    startDate,
    endDate,
    endDateUndefined,
    defineDate,
    name: popupButtonName,
  };

  const [open, setOpen] = useState(false);

  const handleOpenEdit = (val: any) => {
    setOpen(val);
    setHidenPreview(val);
  };

  const handleSave = (values: any) => {
    formPopup.setValue('startDate', values.startDate, { shouldDirty: true });
    formPopup.setValue('endDate', values.endDate, { shouldDirty: true });
    formPopup.setValue('endDateUndefined', values.endDateUndefined, {
      shouldDirty: true,
    });
    formPopup.setValue('defineDate', values.defineDate, {
      shouldDirty: true,
    });
    handleOpenEdit(false);
  };
  return (
    <>
      <TopicSummary
        title="Vigência do pop-up*"
        link="Editar >"
        itens={
          defineDate === 1
            ? [
                `Data início: ${startDate.toLocaleDateString('pt-BR', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                })} ${diffDaysLabel(startDate)}`,
                `Data fim: ${
                  endDateUndefined
                    ? 'Indefinida'
                    : endDate.toLocaleDateString('pt-BR', {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                      })
                }`,
              ]
            : ['Exibir apenas durante período de resgate']
        }
        disabled={!active}
        click={() => handleOpenEdit(true)}
      />
      {open && (
        <EditDateValidity
          defaultValues={defaultValues}
          onClose={() => handleOpenEdit(false)}
          handleSave={handleSave}
        />
      )}
    </>
  );
};
