import TopicSummary from 'components/atoms/TopicSummary';
import { EditPriorityPopup } from 'components/molecules';
import { usePopupEdit } from 'hooks/useEditPopup';
import { useState } from 'react';

export const ResumePriority = () => {
  const { setHidenPreview, formPopup } = usePopupEdit();
  const { popups, popupButtonName, id, active, viewOrder } = formPopup.watch();
  const [openEdit, setOpenEdit] = useState(false);

  const handleOpen = (val: any) => {
    setOpenEdit(val);
    setHidenPreview(val);
  };

  const onSave = (popupList: any[]) => {
    const popupListPosition = popupList.findIndex((item) => item.value === id) + 1;
    formPopup.setValue('viewOrder', popupListPosition.toString(), {
      shouldDirty: true,
    });
    formPopup.setValue('popups', popupList, {
      shouldDirty: true,
    });
    handleOpen(false);
  };

  return (
    <>
      <TopicSummary
        title="Ordem de aparição*"
        link="Editar >"
        itens={[active ? viewOrder : '-']}
        click={() => handleOpen(true)}
        disabled={!active}
      />
      {openEdit && (
        <EditPriorityPopup
          id_popup={id}
          data={popups}
          onSave={onSave}
          onClose={() => handleOpen(false)}
          name={popupButtonName ?? ''}
        />
      )}
    </>
  );
};
