import { useTheme } from 'hooks/useTheme';
import Stack from '../Stack';
import { CircleSelect, Description, Item, Wrapper } from './styled';

interface IRadioCustom {
  title: string;
  value: string | number | boolean;
  currentSelect: string | number | boolean;
  onClick: () => void;
  marginLeft?: string;
  marginRight?: string;
  isInvalid?: boolean;
  error?: string;
}
const RadioCustom: React.FC<IRadioCustom> = ({
  title,
  value,
  currentSelect,
  onClick,
  marginLeft,
  marginRight,
  isInvalid,
  error,
}): JSX.Element => {
  const selected = value === currentSelect;
  const { currentTheme } = useTheme();
  return (
    <>
      <Wrapper marginLeft={marginLeft} marginRight={marginRight} data-testid="wrapper-radio-custom">
        <Item onClick={onClick} selected={selected}>
          <CircleSelect />
          <Description>{title}</Description>
        </Item>
      </Wrapper>
      <Stack minHeight="1rem">
        <p style={{ color: currentTheme.colors.black, fontSize: '12px' }}>{isInvalid && error}</p>
      </Stack>
    </>
  );
};

export default RadioCustom;
