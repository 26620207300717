import { Input, Stack } from 'components/atoms';
import Title from 'components/atoms/Title';
import { Icon } from 'globalStyles';

import { ImageList, StackHeaderListDefault, Subtitle } from './styled';

interface IHeaderListDefault {
  name: string;
  img: string;
  search?: string;
  setSearch?: (value: string) => void;
  subTitleHeader?: string;
  textSearch?: string;
  title?: string;
}

export const HeaderListDefault: React.FC<IHeaderListDefault> = ({
  name,
  img,
  search,
  setSearch,
  subTitleHeader,
  textSearch,
  title,
}): JSX.Element => {
  return (
    <StackHeaderListDefault>
      <Stack direction="column" width="100%">
        <Stack>
          <ImageList src={img} className="icon" alt={`${name}-icon`} />
          <Title label={title ?? `Gerenciar ${name}s`} />
        </Stack>
        <Subtitle>{subTitleHeader ?? `Configure ou edite um ${name}.`}</Subtitle>
      </Stack>
      <Stack width="100%">
        <Input
          placeholder={textSearch ?? `Procurar um ${name.toLowerCase()}...`}
          value={search}
          justLabel
          onChange={(e) => {
            if (setSearch) setSearch(e.target.value);
          }}
          leadingIcon={() =>
            search ? (
              <Icon
                onClick={() => {
                  if (setSearch) setSearch('');
                }}
                src="/assets/icons/icon-close-black.svg"
                width={'22px'}
                data-testid="set-serach-null"
              />
            ) : (
              <Icon src="/assets/icons/icon-search.svg" width={'22px'} />
            )
          }
        />
      </Stack>
    </StackHeaderListDefault>
  );
};
