import styled from 'styled-components';

interface IButtonProps {
  background?: string;
  color?: string;
  padding?: string;
  margin?: string;
  marginRight?: string;
  width?: string;
  readView?: boolean;
  outline?: boolean;
  fontSize?: string;
  lineHeight?: string;
  border?: string;
}

export const Button = styled.button<IButtonProps>`
  padding: ${({ padding }) => padding ?? '0.5rem'};
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize ?? '0.75rem'};
  width: ${({ width }) => width};

  background: ${({ outline, theme }) => (outline ? 'transparent' : theme.colors.primary)};
  ${({ background }) => background && `background: ${background}`};
  color: ${({ color, theme }) => color ?? theme.colors.primary};
  gap: 0.25rem;
  border: ${({ outline, theme }) => (outline ? `1px solid #F3123C` : 'none')};
  ${({ border }) => border && `border: ${border}`};

  ${({ readView }) =>
    readView &&
    `
    cursor: not-allowed;
  `}

  ${({ margin }) => (margin ? `margin:${margin}` : '')};
  ${({ marginRight }) => (marginRight ? `margin-right:${marginRight}` : '')};

  &:disabled {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.silverDark};
    cursor: not-allowed;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0.25;
    }
    50% {
      opacity: 0.5;
    }
    75% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }

  p {
    animation: fade;
    animation-duration: 150ms;
    line-height: ${({ lineHeight }) => lineHeight ?? '109%'};
    white-space: nowrap;
  }
`;

interface IIconNextProps {
  sizeIcon?: string;
}

export const IconNext = styled.img<IIconNextProps>`
  height: ${({ sizeIcon }) => sizeIcon ?? '14px'};
`;
