import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from 'components/atoms';
import ButtonBack from 'components/atoms/ButtonBack';
import ButtonRecoveryPassword from 'components/atoms/ButtonRecoveryPassword';
import ButtonResendInviteRegister from 'components/atoms/ButtonResendInviteRegister';
import { ButtonRounded } from 'components/atoms/ButtonRounded';
import ButtonUpdateUser from 'components/atoms/ButtonUpdateUser';
import ConfirmDeactiveUser from 'components/atoms/ConfirmDeactiveUser';
import FormUserData from 'components/atoms/FormUserData';
import PopupMessage from 'components/atoms/PopupMessage';
import StatusUser from 'components/atoms/StatusUser';
import Title from 'components/atoms/Title';
import Popup from 'components/organisms/Popup';
import { StatusEnum } from 'helpers/enums';
import { useLoading } from 'hooks/loading';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UpdateUser } from 'requests/UserManagement';
import * as yup from 'yup';

import { WarningDescription, WrapperButtons, WrapperInformations } from './styled';
import { isEmpty } from 'helpers/functions';

const validationSchemaForm = yup.object({
  name: yup
    .string()
    .required('Informe o nome')
    .min(2, 'Nome incompleto')
    .test(
      'is-valid',
      (d) => 'Nome incompleto',
      (value) => {
        if (value) {
          const split = value.split(' ');
          return split.length >= 2 && split[0].length >= 2 && split[1].length >= 2;
        }
        return false;
      }
    ),
  email: yup.string().required('Informe o e-mail').email('E-mail inválido'),
});

interface IPopupEditUser {
  open: boolean;
  onClose: () => void;
  data: any;
  onRefreshTable: () => void;
}

const PopupEditUser: React.FC<IPopupEditUser> = ({ open, onClose, data, onRefreshTable }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [openConfirmInactive, setOpenConfirmInactive] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(data.status);
  const [showMessage, setShowMessage] = useState(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [descriptionMessage, setDescriptionMessage] = useState('');

  const { handleShowLoading, handleHideLoading } = useLoading();

  const editUserForm = useForm({
    defaultValues: {
      name: data.nome,
      email: data.email,
    },
    resolver: yupResolver(validationSchemaForm),
    mode: 'onChange',
  });

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const isInactive = () => {
    return currentStatus === StatusEnum.INACTIVE.key;
  };

  const isWaiting = () => {
    return currentStatus === StatusEnum.WAITING.key;
  };

  const showMessageSuccess = (title: string, description: string) => {
    setTitleMessage(title);
    setDescriptionMessage(description);
    setShowMessage(true);
  };

  const closeInactiveConfirm = () => {
    setOpenConfirmInactive(false);
  };

  const reactiveUser = async () => {
    handleShowLoading();
    const response = await UpdateUser(data.nome, data.email, data.id_usuario, true);

    if (response.HttpStatus === 200) {
      onRefreshTable();
      showMessageSuccess(
        'Usuário reativado com sucesso.',
        'Um convite foi enviado para o endereço de e-mail cadastrado.'
      );

      setCurrentStatus(StatusEnum.WAITING.key);
    }
    handleHideLoading();
  };

  const inactiveUser = async () => {
    handleShowLoading();
    const response = await UpdateUser(data.nome, data.email, data.id_usuario, false);

    if (response.HttpStatus === 200) {
      onRefreshTable();
      setCurrentStatus(StatusEnum.INACTIVE.key);
    }
    handleHideLoading();
  };

  const [name, email] = editUserForm.watch(['name', 'email']);

  return (
    <>
      <ConfirmDeactiveUser
        width="577px"
        nameUser={data.nome}
        height={isWaiting() ? '372px' : '308px'}
        isOpen={openConfirmInactive}
        onClose={closeInactiveConfirm}
        onConfirm={inactiveUser}
      />

      <Popup
        showButtonClose={!openConfirmInactive}
        hasMessageInside={showMessage}
        width="577px"
        height={isWaiting() ? '372px' : '308px'}
        isOpen={isOpen}
        onClose={onClose}
      >
        <PopupMessage
          isOpen={showMessage}
          closed={() => {
            setShowMessage(false);
          }}
          title={titleMessage}
          description={descriptionMessage}
        />
        <ButtonBack description="Editando um usuário" onBack={onClose} padding="0px" />
        <Stack gap="0.375rem" marginBottom="1rem" marginTop="1rem">
          <Title label={`Editando: ${name}`} />
          <StatusUser type="User" status={currentStatus} />
        </Stack>
        {isWaiting() ? (
          <Stack gap="1.375rem" marginBottom="1.25rem">
            <WrapperInformations>
              <WarningDescription color="#FF8332">Atenção:</WarningDescription>
              <WarningDescription color="#000">
                Este usuário ainda não finalizou o cadastro.
              </WarningDescription>
            </WrapperInformations>

            <ButtonResendInviteRegister
              idUser={data.id_usuario}
              disabled={!data.permitir_reenvio}
            />
          </Stack>
        ) : null}
        <FormUserData
          form={editUserForm}
          emailEditable={false}
          isDisabled={!!isInactive()}
          emailPlaceholder={data.email}
        />
        <WrapperButtons
          justifyContent={!isInactive() && !isWaiting() ? 'space-between' : 'flex-end'}
        >
          <Stack gap="0.625rem">
            {!isInactive() && !isWaiting() ? (
              <ButtonRecoveryPassword
                idUser={data.id_usuario}
                onSuccess={() => {
                  showMessageSuccess(
                    'Enviamos as instruções!',
                    'Enviamos instruções de redefinição de senha no e-mail cadastrado.'
                  );
                }}
              />
            ) : null}
            {!isInactive() ? (
              <ButtonRounded
                background="#F3123C"
                color="#ffffff"
                label="Desativar usuário"
                iconSrc="/assets/icons/icon-close.svg"
                click={() => {
                  setOpenConfirmInactive(true);
                }}
              />
            ) : null}

            {isInactive() ? (
              <ButtonRounded
                background="#00D217"
                color="#ffffff"
                label="Reativar usuário"
                iconSrc="/assets/icons/icon-reset.svg"
                click={reactiveUser}
              />
            ) : null}

            <ButtonUpdateUser
              nameUser={name}
              emailUser={email}
              idUser={data.id_usuario}
              onRefreshTable={onRefreshTable}
              isDisabled={
                isInactive() ||
                !isEmpty(editUserForm.formState.errors) ||
                !editUserForm.formState.isDirty
              }
              messageSuccess={(event: any) => {
                showMessageSuccess(event.title, event.description);
              }}
              copyInputValue={{
                name: editUserForm.formState.defaultValues?.name,
                email: editUserForm.formState.defaultValues?.email,
              }}
              emailRepeated={() => {
                editUserForm.setError('email', {
                  message: 'E-mail já cadastrado',
                  type: 'validate',
                });
              }}
              onError={(_) => {}}
            />
          </Stack>
        </WrapperButtons>
      </Popup>
    </>
  );
};

export default PopupEditUser;
