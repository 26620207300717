import { StyledStack } from 'components/atoms/Stack';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

export const StackDateValidity = styled(StyledStack)`
  display: block;
  padding-right: 20px;
  margin-top: 0.75rem;
  overflow-y: auto;
  max-height: 25rem;
  @media (max-width: ${device.tablet}) {
    max-height: max-content;
  }
`;
export const StackActions = styled(StyledStack)`
  padding-top: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  @media (max-width: 1040px) {
    padding-top: 0.188rem;
  }
`;

export const StackActionPriority = styled(StyledStack)`
  flex-direction: column;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  height: 22rem;
  @media (max-width: ${device.tablet}) {
    height: calc(100vh - 12rem);
    max-height: calc(1000px - 12rem);
  }
`;

export const StackEditAudience = styled(StyledStack)`
  padding-right: 20px;
  padding-top: 0.688rem;
  overflow-y: auto;
  height: 25rem;
  overflow-x: hidden;

  @media (max-width: ${device.tablet}) {
    height: calc(100vh - 12rem);
    max-height: calc(1000px - 12rem);
  }
`;

export const StackEditButton = styled(StyledStack)`
  flex-direction: column;
  height: 32.563rem;
  padding-right: 20px;
  overflow-y: auto;
  @media (max-width: ${device.tablet}) {
    max-height: calc(100vh - 12rem);
    max-height: calc(1000px - 12rem);
    height: calc(100vh - 12rem);
  }
`;

export const StackEditPopupButton = styled(StyledStack)`
  padding-right: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 19rem;
  @media (max-width: ${device.tablet}) {
    height: calc(100vh - 12rem);
    max-height: calc(1000px - 12rem);
  }

  @media (max-width: ${device.laptopM}) {
    height: 40rem;
    max-height: 40rem;
  }
`;
