import { RegisterDuplicateError } from 'helpers/registerDuplicateError';
import { useNotification } from 'hooks/notification';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { IInputFormProps, InputForm } from '../InputForm';

enum stateValidate {
  VALIDATING = 'VALIDATING',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

interface IInputFormCustomValidationProps extends IInputFormProps {
  form: any;
  callbackValidate: (value: string) => Promise<boolean>;
}
export const InputFormCustomValidation: React.FC<IInputFormCustomValidationProps> = ({
  form,
  callbackValidate,
  ...rest
}) => {
  const { name } = rest;
  const watchName: string = form.watch(name);
  const debaucedValue = useDebounce(watchName, 750)?.trim();
  const [validating, setValidating] = useState<stateValidate>(stateValidate.INVALID);
  const notify = useNotification();
  const isDirty = form.formState.dirtyFields[name];

  useEffect(() => {
    // para deixar o formulario no state inicial
    if (watchName === undefined || watchName === '') {
      form.setError(name, {
        type: 'isEmpty',
      });
    }
  }, []);

  useEffect(() => {
    setValidating(stateValidate.INVALID);
    if (debaucedValue !== undefined && debaucedValue !== '' && isDirty) {
      setValidating(stateValidate.VALIDATING);
      callbackValidate(debaucedValue)
        .then((isValid) => {
          setValidating(isValid ? stateValidate.VALID : stateValidate.INVALID);
          form.clearErrors(name);
          form.trigger(name);
        })
        .catch((err) => {
          if (err instanceof RegisterDuplicateError) {
            form.setError(name, {
              type: 'manual',
              message: err.message,
            });
          } else {
            form.setError(name, {
              type: 'manual',
            });
            notify.error('Houve um problema', 'Tente novamente mais tarde.', 3000, err);
          }
          setValidating(stateValidate.INVALID);
        });
    } else if (debaucedValue !== undefined && debaucedValue !== '') {
      form.clearErrors(name);
    }
  }, [debaucedValue]);

  return (
    <InputForm
      {...rest}
      isValidating={validating === stateValidate.VALIDATING}
      autocomplete={'off'}
      processingValueOnChange={(e) => {
        const regex = /[\\{}|#%^`]/g;
        const text = e.replace(regex, '');
        return text;
      }}
      onChange={(newValue, oldValue) => {
        if (rest.onChange) rest.onChange(newValue, oldValue);
        // para deixar o campo invalido quando o usuario digitar
        if (!form.formState.errors[name]) {
          form.setError(name, {
            type: 'validing',
          });
        }
        // para limpar o erro quando o usuário voltar com o valor inicial
        if (!form.getFieldState(name)?.isDirty) {
          form.clearErrors(name);
        }
      }}
    />
  );
};
