/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import moment from 'moment';

import { removeCookie } from './cookies';

export const validateLink = (link?: string) => {
  if (!link) return false;
  if (!link.startsWith('http://') && !link.startsWith('https://')) return false;
  if (!link.includes('.com') && !link.includes('.com.br')) return false;
  return true;
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*){2,}|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateName = (name: string, minLength = 3) => {
  let words = name.split(' ');
  words = words?.filter((word) => word.trim());
  const length = words.length;
  const validateLastName = words[length - 1]?.trim() ? words[length - 1].length >= minLength : true;

  if (words.length > 1 && words[0].length >= minLength && validateLastName) {
    return true;
  }
  return false;
};

export const validatePassowrd = (
  value?: string
): { lower?: boolean; upper?: boolean; number?: boolean; special?: boolean; length?: boolean } => {
  let validations = {};

  const upper = new RegExp(/[A-Z]/g);
  const lower = new RegExp(/[a-z]/g);
  const number = new RegExp(/[0-9]/g);
  const special = new RegExp(/[$-/:@#¨\\¹²³£¢¬ªº°\-?{-~!"^_`[\]´§;><=]/g);

  if (value) {
    if (upper.test(value)) {
      validations = { ...validations, upper: true };
    }

    if (lower.test(value)) {
      validations = { ...validations, lower: true };
    }

    if (number.test(value)) {
      validations = { ...validations, number: true };
    }

    if (special.test(value)) {
      validations = { ...validations, special: true };
    }

    if (value.length >= 6 && value.length <= 12) {
      validations = { ...validations, length: true };
    }
  }

  return validations;
};

export const emailMask = (email: string) => {
  if (email) {
    const index = email.indexOf('@');

    const user = email.slice(0, index);
    const provider = email.slice(index);

    const lastFive = user.substr(-5);
    const first = user.slice(0, -5);

    return `${first}${lastFive.replace(/([^@]){5}/, '*****')}${provider}`;
  }

  return email;
};

export const sizeVw = (size: number) => {
  const sizeDefault = (size * 100) / 1920;
  const sizeMin = size * 0.8;

  if (window.innerWidth < 1280) {
    return `${sizeMin}px`;
  }

  return `${sizeDefault}vw`;
};

export const fileToBase64 = async (file: any): Promise<string> =>
  await new Promise((resolve, reject) => {
    if (!file) {
      resolve('');
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const result: string = (reader.result as string) ?? '';

      result.includes('image')
        ? resolve(result.replace(/^data:image\/[a-z]+;base64,/, ''))
        : resolve(result.split(',')[1]);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });

export const convertOptionsInArray = (data: any[]) => {
  const arr: any[] = [];

  data.map((item) => arr.push(item.value));

  return arr;
};

/* eslint-disable  no-control-regex */
/* eslint-disable no-useless-escape */
export const validateUrls = (value: string) => {
  const regex = new RegExp(
    /https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=])/g
  );

  return regex.test(value);
};

export const compareDates = (dateOne: Date, dateTwo: Date) => {
  const formatOne = dateOne.setHours(0, 0, 0, 0);
  const formatTwo = dateTwo.setHours(0, 0, 0, 0);

  return new Date(formatOne) >= new Date(formatTwo);
};

export function formatDate(date?: Date) {
  if (!date) return '';

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const time = date.toLocaleTimeString();

  return `${year}-${month}-${day} ${time}`;
}

export const formatDateForInputString = (date: Date) => {
  const dateFormated = moment(date).format('DD/MM/YYYY');
  return dateFormated;
};

export const compareValues = (arrayInternal: any[], arrayExternal: any[]) => {
  const validateItemAsObject = (obj1: any, obj2: any): any => {
    const arr1 = Object.keys(obj1).map((key) => obj1[key]);
    const arr2 = Object.keys(obj2).map((key) => obj2[key]);

    return compareValues(arr1, arr2);
  };
  const validateItemAsArray = (arr1: any[], arr2: any[]): any => {
    if (arr1.length !== arr2.length) {
      return false;
    } else {
      return compareValues(arr1, arr2);
    }
  };
  if (Array.isArray(arrayInternal) && Array.isArray(arrayExternal)) {
    let equals = false;
    for (let i = 0; i <= arrayInternal.length; i++) {
      const itemForInternal = arrayInternal[i];
      const itemForExternal = arrayExternal[i];

      if (itemForInternal instanceof Date && itemForExternal instanceof Date) {
        equals = (itemForInternal || '').toString() === (itemForExternal || '').toString();
      } else if (Array.isArray(itemForInternal) && Array.isArray(itemForExternal)) {
        equals = validateItemAsArray(itemForInternal, itemForExternal);
      } else if (itemForInternal instanceof Object && itemForExternal instanceof Object) {
        equals = validateItemAsObject(itemForInternal, itemForExternal);
      } else {
        equals = (itemForInternal || '').toString() === (itemForExternal || '').toString();
      }
      if (!equals) break;
    }

    return equals;
  } else {
    return false;
  }
};

export const diffDaysLabel = (d1: Date): string => {
  if (!d1) return '';
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  const diffTime = d1.getTime() - today.getTime();

  if (diffTime < 0) {
    return '';
  }

  const result = Math.trunc(diffTime / (1000 * 3600 * 24));

  if (result >= 1) {
    return `(daqui ${result} dia${result > 1 ? 's' : ''})`;
  } else {
    return '(hoje)';
  }
};

export const limitLenghtString = (text: string, limit = 30) => {
  return text?.length > limit ? `${text.slice(0, limit)}...` : text;
};

/**
 * @param  {array<string>} array
 */
export const convertArrayStringInText = (array: any[]) => {
  const lastItem = ` e ${array[array.length - 1]}`;
  return (
    array
      .filter((_, index) => index !== array.length - 1)
      .filter((item) => item)
      .map((item, index) => (index === 0 ? item[0].toUpperCase() + item.substring(1) : ` ${item}`))
      .toString()
      .replace(/,/g, ', ') + lastItem
  );
};
/**
 * @param  {string} string - string to be converted
 */
export const justNumbers = (string: string, prevValue: string, maxLength: number) => {
  const numsStr = string.replace(/[^0-9]/g, '');

  if (maxLength && numsStr.length > maxLength) {
    return prevValue;
  }
  return parseInt(numsStr) || '';
};

export const changeImageAndReturnBase64 = async (
  file: File,
  size = 2000000,
  width: number,
  height: number
): Promise<{ image: any; error: boolean; imageBase64: any; base64ForImage: string }> => {
  const defaultError = {
    image: null,
    error: true,
    imageBase64: null,
    base64ForImage: null,
  };
  return await new Promise((resolve, reject) => {
    if (!file) reject(defaultError);

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = async () => {
      const validTypes = ['image/png'];
      const isLessThan1MB = file.size <= size;
      if (!validTypes.includes(file.type) || !isLessThan1MB) {
        reject(defaultError);
      }

      const internalWidth = img.naturalWidth;
      const internalHeight = img.naturalHeight;
      if (internalWidth !== width || internalHeight !== height) {
        reject(defaultError);
      } else {
        const result = await fileToBase64(file);
        const base64 = `data:${file.type};base64,${result}`;
        resolve({
          image: file,
          error: false,
          imageBase64: base64,
          base64ForImage: result,
        });
      }
    };
  });
};
export function removeTags(str: string) {
  if (str === null || str === '') return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '');
}

export function generateKeyId() {
  return Math.random().toString(36).substring(2, 15);
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isEmpty(obj: Object) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function transformarYearAndMonthInFullDataMonth(
  year: number,
  month: string
): { dataInicio: string; dataFim: string } {
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  // Obtém o índice do mês a partir do array monthNames
  const monthIndex = monthNames.indexOf(month);

  // Constrói a string no formato desejado: 'YYYY-MM'
  const transformedDate = `${year}-${(monthIndex + 1).toString().padStart(2, '0')}`;

  // Calcula o último dia do mês
  const lastDay = new Date(year, monthIndex + 1, 0).getDate();

  const dataReturn = {
    dataInicio: `${transformedDate}-01`,
    dataFim: `${transformedDate}-${lastDay}`,
  };
  return dataReturn;
}
