const endpoints = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'development': {
      return {
        urlApi: 'https://yfpc3a5779.execute-api.us-east-1.amazonaws.com/azl/api/',

        loginFront: 'https://yfpc3a5779.execute-api.us-east-1.amazonaws.com/azl/api/',
      };
    }
    case 'production': {
      return {
        urlApi: 'https://5bd8n7pmbh.execute-api.us-east-1.amazonaws.com/prd/api/',
        loginFront: 'https://css.santanderfinanc.com.br/api/autenticacao',
      };
    }
    case 'homologation': {
      return {
        urlApi: 'https://yfpc3a5779.execute-api.us-east-1.amazonaws.com/hml/api/',
        loginFront: 'https://05tlaoka67.execute-api.us-east-1.amazonaws.com/hml/api/autenticacao',
      };
    }
    default: {
      return {
        urlApi: 'https://yfpc3a5779.execute-api.us-east-1.amazonaws.com/hml/api/',
        loginFront: 'https://05tlaoka67.execute-api.us-east-1.amazonaws.com/hml/api/autenticacao',
      };
    }
  }
};

export const endpoint = endpoints();
