import {
  ButtonDropDown,
  ButtonRounded,
  Paragraph,
  RadioGroupTextForm,
  Stack,
} from 'components/atoms';
import { ModalDownloadReport, SearchWithSelect } from 'components/molecules';
import {
  ChamasAnalyticalExportType,
  optionsSearchFilterAnalytical,
  reportsAnalyticList,
} from 'helpers/enums';
import { isEmpty } from 'helpers/functions';
import { useLoading } from 'hooks/loading';
import { useNotification } from 'hooks/notification';
import { useChamasAnalytical } from 'hooks/useChamasAnalytical';
import { useTheme } from 'hooks/useTheme';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ModalChamasAnalyticalFilter } from './ModalChamasAnalyticalFilter';
import { NotifyExportSuccessMessage } from './NotifyExportSuccessMessage';
import { SelectColumns } from './SelectColumns';

const MonthsNumber = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const defaultValueFilter = {
  search: '',
  typeSearch: { value: 0, label: 'Selecione...' },
};

// const chamasAnalyticalReport = {
//   1: 'Relatório de Resgates',
//   2: 'Relatório Chamas',
// };

export const ChamasAnalyticalFilter = () => {
  const [openSelectColumn, setOpenSelectColumn] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    formFilterAnalitycal,
    getDataTableAnalyticalAndSet,
    exportReport,
    getColumns,
    loading,
    columns,
    resetForm,
    columnsGroup,
    handleSetColumns,
  } = useChamasAnalytical();

  const formSearch = useForm({
    defaultValues: defaultValueFilter,
  });

  const { handleShowLoading, handleHideLoading } = useLoading();
  const { currentTheme } = useTheme();
  const notification = useNotification();

  const { control, watch } = formFilterAnalitycal;
  const { type, endMonth, endYear, provider, startMonth, startYear, search } = watch();
  const { typeSearch } = formSearch.watch();

  const getMaxLength = (type: number) => {
    switch (type) {
      case 1:
        return 14;
      case 2:
        return 6;
      case 3:
        return 100;
      default:
        return 100;
    }
  };

  const maxLength = getMaxLength(typeSearch.value);
  const startDateLabel = `de ${MonthsNumber[startMonth.value - 1]}/${startYear.label}`;
  const endDateLabel = ` até ${MonthsNumber[endMonth.value - 1]}/${endYear.label}`;
  const providerLabel = provider.value > 0 ? `, ${provider.label}` : '';

  const openModalDownloadReport = () => {
    notification.handleClearNotification();
    setIsModalOpen(true);
  };
  const closeModalDownloadReport = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // tem dois forms por que o só deve aplicar o texto do filtro quando o usuário clicar em pesquisar ou der enter
    formSearch.setValue('search', '');
    formFilterAnalitycal.setValue('search', '');
    formSearch.clearErrors();
  }, [typeSearch.value]);

  useEffect(() => {
    if (!isEmpty(columns) && !loading) getDataTableAnalyticalAndSet();
  }, [columns, endMonth, endYear, provider, startMonth, startYear, search]);

  useEffect(() => {
    getColumns(type);
    resetForm(type);
    formSearch.reset(defaultValueFilter);
  }, [type]);

  const handleExportReport = async (typeExport: ChamasAnalyticalExportType) => {
    handleShowLoading();
    exportReport(typeExport)
      .then((_) => {
        const { type } = formFilterAnalitycal.getValues();
        notification.success(
          'A exportação foi solicitada com sucesso!',
          <NotifyExportSuccessMessage
            type={type}
            openModalDownloadReport={openModalDownloadReport}
          />,
          5000
        );
      })
      .catch(() => {
        notification.error('Erro ao exportar relatório.', 'Tente novamente mais tarde.', 5000);
      })
      .finally(() => {
        handleHideLoading();
      });
  };

  const options = [
    {
      title: 'Relatório selecionado',
      subtitle: 'Exporte o relatório de acordo com as colunas selecionadas',
      handleClick: () => handleExportReport(ChamasAnalyticalExportType.PARTIAL),
    },
    {
      title: 'Relatório completo',
      subtitle: 'Exporte o relatório com todas as colunas disponíveis',
      handleClick: () => handleExportReport(ChamasAnalyticalExportType.COMPLETE),
    },
  ];

  const handleSearch = () => {
    const { search, typeSearch } = formSearch.getValues();

    if (search.toString().length > 0 && search.toString().length < 3) {
      formSearch.setError('search', {
        type: 'custom',
        message: 'A pesquisa deve conter pelo menos 3 caracteres',
      });
      return;
    }

    formSearch.clearErrors();
    formFilterAnalitycal.setValue('typeSearch', typeSearch);
    formFilterAnalitycal.setValue('search', search);
  };

  return (
    <>
      <Stack width="100%" alignItems="center" direction="column" gap="12px" zIndex="3">
        <Stack width="100%" alignItems="flex-start" justifyContent="space-between">
          <Stack gap="16px" alignItems="center">
            <Paragraph
              label="Painel Analítico Chamas"
              fontSize="16px"
              fontWeight="700"
              lineHeight="19px"
            />
            <RadioGroupTextForm
              options={reportsAnalyticList}
              name="type"
              control={control}
              disabled={loading}
            />
          </Stack>
          <Stack gap="16px">
            <ButtonDropDown label="Exportar novo relatório" options={options} />
            <ButtonRounded
              label="Relatórios Exportados"
              startIconSrc="/assets/icons/icon-download-red.svg"
              fontSize="9px"
              lineHeight="12px"
              outline
              sizeIcon="12px"
              click={openModalDownloadReport}
            />
          </Stack>
        </Stack>
        <Stack
          width="100%"
          alignItems="flex-start"
          justifyContent="space-between"
          height="45px"
          gap="8px"
        >
          <Stack alignItems="center" gap="8px" width="100%">
            <SearchWithSelect.Root>
              <SearchWithSelect.Select
                options={[{ value: 0, label: 'Selecione...' }, ...optionsSearchFilterAnalytical]}
                control={formSearch.control}
                name="typeSearch"
              />
              <SearchWithSelect.Input
                form={formSearch}
                name="search"
                maxLength={maxLength}
                type={typeSearch.value === 3 ? 'text' : 'number'}
                handleSearch={handleSearch}
                disabled={typeSearch.value === 0 || loading}
              />
            </SearchWithSelect.Root>
          </Stack>

          <Stack gap="8px">
            {type === 1 && (
              <>
                <ButtonRounded
                  label="+ Mais Filtros"
                  color={currentTheme.colors.white}
                  background={currentTheme.colors.secondary}
                  startIconSrc="/assets/icons/icon-filter-white.svg"
                  fontSize="9px"
                  lineHeight="undefined"
                  sizeIcon="12px"
                  click={() => setOpenFilter(true)}
                  isLoading={loading}
                />
                <Stack gap="4px" alignItems="center" whiteSpace="noWrap">
                  <Paragraph fontSize="10px" label={'Filtrado:'} fontWeight="bold" />
                  <Paragraph fontSize="10px" label={startDateLabel} />
                  <Stack>
                    <Paragraph fontSize="10px" label={endDateLabel} />
                    <Paragraph fontSize="10px" label={providerLabel} />
                  </Stack>
                </Stack>
              </>
            )}
            <ButtonRounded
              label="Alterar Colunas"
              color={currentTheme.colors.secondary}
              startIconSrc="/assets/icons/icon-edit-simple-black.svg"
              border={`1px solid ${currentTheme.colors.secondary}`}
              fontSize="9px"
              lineHeight="undefined"
              sizeIcon="12px"
              click={() => setOpenSelectColumn(true)}
              outline
              isLoading={loading}
            />
          </Stack>
        </Stack>
      </Stack>
      {openSelectColumn && (
        <SelectColumns
          handleClose={() => setOpenSelectColumn(false)}
          columns={columns}
          columnsGroup={columnsGroup}
          handleSetColumns={handleSetColumns}
          type={type}
        />
      )}
      <ModalDownloadReport opened={isModalOpen} onClose={closeModalDownloadReport} />
      <ModalChamasAnalyticalFilter open={openFilter} handleClose={() => setOpenFilter(false)} />
    </>
  );
};
