import styled from 'styled-components';

export interface IButtonStyledProps {
  background?: string;
  borderRadius?: string;
  padding?: string;
  width?: string;
  height?: string;
  position?: string;
  right?: string;
  bottom?: string;
  fontWeight?: string;
  marginBottom?: string;
  marginTop?: string;
  fontSize?: string;
  border?: string;
  borderLeft?: string;
  borderTop?: string;
  borderBottom?: string;
  borderRight?: string;
  display?: string;
  alignItems?: string;
}
export const B = styled.button<IButtonStyledProps>`
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  background: ${({ background }) => background};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: ${({ position }) => position};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  font-size: ${({ fontSize }) => fontSize};
  border: ${({ border }) => border};
  border-top: ${({ borderTop }) => borderTop};
  border-right: ${({ borderRight }) => borderRight};
  border-bottom: ${({ borderBottom }) => borderBottom};
  border-left: ${({ borderLeft }) => borderLeft};
`;
