/* eslint-disable eqeqeq */
import { CustomErrorApi } from 'helpers/customErrorApi';
import { fileToBase64, formatDate } from 'helpers/functions';
import { IAPIResponse } from 'models/dtos/api-response.model';
import { IBannerRequestModel } from 'models/dtos/banner.model';
import { IPaginationModel } from 'models/dtos/pagination.model';
import { IBannerModel } from 'models/entities/banner.model';
import moment from 'moment';
import { api } from 'services/api';

export const GetAllBanners = async (filter: {
  page: number;
  quantity: number;
  name: string;
}): Promise<IPaginationModel<IBannerModel[]>> => {
  const params = {
    pagina: filter.page,
    quantidade: filter.quantity,
    nome: filter.name,
  };
  const response = await api.get<
    IAPIResponse<{ quantidade_total: number; banners: IBannerRequestModel[] }>
  >('/banner/listar', {
    params,
  });

  const { banners, quantidade_total } = response.data.Dado;
  const data: IBannerModel[] = banners.map((item) => {
    const _defineDate = item.tipo_vigencia === 2 ? 2 : 1;
    const _startDate = item.tipo_vigencia === 2 ? new Date() : new Date(item.data_inicio);
    const _endDate = _defineDate === 2 ? new Date() : new Date(item.data_fim);
    const dataItem: IBannerModel = {
      id: item.id_banner,
      active: item.status === 'Ativo' || item.status === 'Agendado',
      name: item.nome,
      action: item.tipo_acao,
      linkType: (item.link && 1) || (item.id_link_interno && 2),
      link: item.link,
      idLinkInterno: item.id_link_interno,
      startDate: _startDate,
      endDate: _endDate,
      defineDate: _defineDate,
      classificationPeriod: item.tipo_categoria,
      idsWhichClassifications: item.categorias,
      endDateUndefined: item.data_indefinida,
      customers: item.clientes,
      file: item.imagem,
      linkFile: item.link_arquivo,
      nameFileBucket: item.nome_arquivo_bucket,
      nameFileClient: item.nome_arquivo_cliente,
      planCondition: item.tipo_pontuacao,
      planPoints: item.pontuacao,
      idsSegments: item.segmentos,
      idsWhichBranches: item.filiais,
      whoCanSee: item.nome_arquivo_cliente ? 2 : 1,
      viewOrder: item.ordem_visualizacao,
      validity: item.vigencia,
      actionLinkFile: !item.nome_arquivo_bucket ? item.link_arquivo : undefined,
      status: item.status,
      localBanner: item.id_local_banner,
    };
    return dataItem;
  });

  return { data, total: quantidade_total };
};

export const PostEditBanner = async (payload: {
  data: IBannerModel;
  fileImageBanner: File;
  filiais: { value: number }[];
  classification: { value: number }[];
  segmentos: { value: number }[];
  cnpjFile: { size: number };
  dataAllBanner: { value: number }[];
  internalLink: { value: number };
  cnpjFileName: string | null;
  actionFile: File;
}): Promise<boolean> => {
  try {
    const {
      data,
      filiais,
      classification,
      segmentos,
      cnpjFile,
      fileImageBanner,
      dataAllBanner,
      internalLink,
      cnpjFileName,
      actionFile,
    } = payload;
    // INICIO
    // Se todas forem selecionadas, passar null
    // se nao pega da lista seleiconada pelo usuario
    const getFiliais = (): number[] | null => {
      if (filiais.find((item) => item.value === 0)) {
        return null;
      } else {
        return filiais.map((filiais) => filiais.value);
      }
    };
    const getCategorias = (): number[] | null => {
      if (classification.find((item) => item.value === 0)) {
        return null;
      } else {
        return classification.map((classificationItem) => classificationItem.value);
      }
    };
    const getSegmentos = (): number[] | null => {
      if (segmentos.find((item) => item.value === 0)) {
        return null;
      } else {
        return segmentos.map((segmentosItem) => segmentosItem.value);
      }
    };

    // FIM
    const ClientesBase64 = await fileToBase64(cnpjFile?.size ? cnpjFile : null);
    const internalData = {
      Id: data.id,
      Nome: data.name,
      Status: data.active,
      ImagemAlterada: !!fileImageBanner,
      NomeImagem: fileImageBanner?.name,
      ImagemBase64: await fileToBase64(fileImageBanner),
      OrdemVisualizacao: dataAllBanner.map((banner, index) => ({
        id: banner.value,
        ordem: index + 1,
      })),
      TipoAcao: data.action,
      TipoLink: data.linkType,
      LinkExterno: data.link,
      IdLinkInterno: internalLink?.value,

      TipoVigencia: data.endDateUndefined ? 3 : data.defineDate,
      DataInicio: data.startDate && moment(data.startDate)?.format('YYYY-MM-DD'),
      DataFim: data.endDate && moment(data.endDate)?.format('YYYY-MM-DD'),
      Filiais: getFiliais(),
      TipoClassificacao: data.classificationPeriod,
      Categorias: getCategorias(),
      Segmentos: getSegmentos(),
      TipoPontuacao: data.planCondition,
      ValorPontuacao: data.planPoints,
      Clientes: !ClientesBase64 ? (data.whoCanSee === 1 ? null : data.customers) : null,
      NomeArquivoCliente: cnpjFileName,
      ClientesBase64,

      // LinkArquivo: data.actionLinkFile,
      // NomeArquivo: data.actionFile?.name,
      // ArquivoBase64: await fileToBase64(data.actionFile),
      // Alterado para a FID-3220
      NomeArquivo: data.action == 2 ? actionFile?.name ?? null : null,
      NomeArquivoBucket: data.action == 2 && data.nameFileBucket ? data.nameFileBucket : null,
      ArquivoBase64: data.action == 2 ? await fileToBase64(actionFile) : null,
      localBanner: data.localBanner,
      LinkArquivo:
        data.action == 2
          ? data.actionLinkFile
            ? data.actionLinkFile
            : data.nameFileBucket && data.linkFile
              ? data.linkFile
              : null
          : null,

    };

    const response = await api.post('/banner/editar', internalData);
    const notifications = response.data.Notificacoes;
    if (response.data.HttpStatus !== 200) {
      if (notifications && notifications?.length > 0) {
        throw new CustomErrorApi(notifications[0].Mensagem);
      } else {
        throw new Error('Verifique sua conexão e tente novamente.');
      }
    }
    return true;
  } catch (error) {
    if (error instanceof CustomErrorApi) {
      throw new Error(error.message);
    } else throw new Error('Verifique sua conexão e tente novamente.');
  }
};

const getBranchesSelected = (whichBranches: { value: number; label: string }[]) => {
  if (whichBranches.find((item) => item.value === 0)) {
    return null;
  } else {
    return whichBranches.map((branch) => branch.value);
  }
};

const getClassificationsSelected = (whichClassifications: { value: number; label: string }[]) => {
  if (whichClassifications.find((item) => item.value === 0)) {
    return null;
  } else {
    return whichClassifications.map((category) => category.value);
  }
};

const getSegmentsSelected = (segments: { value: number; label: string }[]) => {
  if (segments.find((item) => item.value === 0)) {
    return null;
  } else {
    return segments.map((segment) => segment.value);
  }
};

export const PostBanner = async (payload: IBannerModel): Promise<boolean> => {
  try {
    const internalData = {
      // banner
      imagemarquivobase64: await fileToBase64(payload.bannerFile),
      nomeimagem: payload.bannerFile?.name,
      // step 1
      nome: payload.name,
      TipoAcao: payload.action,
      tipolink: payload.linkType,
      IdLinkInterno: payload.internalLink?.value,
      link: payload.link,
      LinkArquivo: payload.actionLinkFile,
      nomearquivolink: payload.actionFile?.name,
      linkarquivobase64: await fileToBase64(payload.actionFile),
      // step 2
      ordemvisualizacao: payload.banners?.map((banner, index) => ({
        id: banner.value,
        ordem: index + 1,
      })),
      // step 3
      // vigencia:
      // ComVigencia = 1,
      // PeriodoResgate = 2,
      // SemExpiracao = 3
      vigencia: payload.endDateUndefined ? 3 : payload.defineDate,
      // data inicio e data fim nao poderá ser passados quando periodo regaste for selecionado
      // data inicio deve ser passada quando tiver vigencia ou sem SemExpiracao
      datainicio:
        payload.defineDate === 1 || payload.endDateUndefined ? formatDate(payload.startDate) : null,
      // data final deverá ser passada apenas quando tiver vigencia e com expiração
      datafim: payload.defineDate === 1 ? formatDate(payload.endDate) : null,
      // step 4
      // passar arquivo csv dos CNPJS quando opção Fazer upload de uma lista for selecionado
      ClientesBase64: payload.whoCanSee === 2 ? await fileToBase64(payload.csvFile) : null,
      // passar nome do arquivo csv dos CNPJS quando opção Fazer upload de uma lista for selecionado
      nomearquivocliente: payload.whoCanSee === 2 ? payload.csvFile?.name : null,
      tipoclassificacao: payload.classificationPeriod,
      filiais: getBranchesSelected(payload?.whichBranches ?? []),
      categorias: getClassificationsSelected(payload?.whichClassifications ?? []),
      segmentos: getSegmentsSelected(payload?.segments ?? []),
      tipopontuacao: payload.planCondition,
      pontuacao: payload.planPoints,
      localBanner: payload?.localBanner,
    };

    const response = await api.post('/banner/incluir', internalData);
    const notifications = response.data.Notificacoes;
    if (response.data.HttpStatus !== 200) {
      if (notifications && notifications?.length > 0) {
        throw new CustomErrorApi(notifications[0].Mensagem);
      } else {
        throw new Error('Verifique sua conexão e tente novamente.');
      }
    }
    return true;
  } catch (error) {
    if (error instanceof CustomErrorApi) {
      throw new Error(error.message);
    } else throw new Error('Não foi possível criar a imagem.');
  }
};
