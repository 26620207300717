import Stack, { StyledStack } from 'components/atoms/Stack';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

export const StackLayoutCard = styled(StyledStack)`
  @media (max-width: ${device.tablet}) {
    width: 100%;
    min-width: 100%;
    height: 100%;
    max-height: 1000px;
  }
`;
interface IStackModalProps {
  cardSize: {
    width: { desktop: string; tablet: string; mobile: string };
    height: { mobile: string };
  };
}
export const StackModal = styled(Stack) <IStackModalProps>`
  width: ${(props) => (props.cardSize.width.desktop ? props.cardSize.width.desktop : '540px')};
  @media (max-width: ${device.laptopM}) {
    width: ${(props) => (props.cardSize.width.tablet ? props.cardSize.width.tablet : '740px')};
  }
  @media (max-width: ${device.tablet}) {
    width: ${(props) => (props.cardSize.width.mobile ? props.cardSize.width.mobile : '100%')};
    height: ${(props) => (props.cardSize.height.mobile ? props.cardSize.height.mobile : '100%')};
    max-width: 100vw;
  }
`;

export const StackLayoutMenu = styled(Stack)`
  justify-content: center;
  align-items: center;

  @media (max-width: ${device.tablet}) {
    width: 100%;
  }
`;

export const StackModalContent = styled(Stack)`
  flex-direction: column;
  flex: 1;
  margin-top: -8px;
  border-radius: 0.5rem;
  background: #f1f1f1;
  box-shadow: 0px 0px 16.9476px rgba(0, 0, 0, 0.13);
  overflow: auto;

  @media (max-width: ${device.tablet}) {
    border-radius: 0;
  }
`;
