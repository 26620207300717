import { useTheme } from 'hooks/useTheme';
import { useEffect, useRef, useState } from 'react';
import Stack from '../Stack';
import { Button, Divider, IconDownload } from './styled';
import { Paragraph } from '../Paragraph';

interface IButtonDropDownProps {
  label?: string;
  variant?: 'outlined' | 'contained';
  srcIcon?: string;
  options: { title: string; subtitle: string; handleClick: () => void }[];
}

export const ButtonDropDown: React.FC<IButtonDropDownProps> = ({
  label,
  variant = 'contained',
  srcIcon = '/assets/icons/icon-download.svg',
  options,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { currentTheme } = useTheme();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const isLastOption = (index: number) => options.length - 1 === index;

  return (
    <Stack position="relative">
      <Button onClick={() => setOpen((oldState) => !oldState)} variant={variant}>
        <IconDownload src={srcIcon} alt="icon-download" />
        <p>{label}</p>
      </Button>
      {open && (
        <Stack
          position="absolute"
          top="40px"
          left="0"
          width="218px"
          backgroundColor={currentTheme.colors.secondary}
          borderRadius="12px"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="16px"
          zIndex="100"
          key={`ButtonDropDown-Stack`}
          ref={modalRef}
        >
          <Stack gap="12px" direction="column">
            {options.map((option, index) => (
              <Stack key={`ButtonDropDown-${index}`} direction="column" gap="12px">
                <Stack
                  onClick={() => {
                    setOpen(false);
                    option.handleClick();
                  }}
                  direction="column"
                  gap="2px"
                  cursor="pointer"
                >
                  <Paragraph
                    label={option.title}
                    fontSize="10px"
                    lineHeight="12px"
                    fontWeight="bold"
                    color={currentTheme.colors.white}
                  />
                  <Paragraph
                    label={option.subtitle}
                    fontSize="10px"
                    lineHeight="12px"
                    fontWeight="400"
                    color={currentTheme.colors.white}
                  />
                </Stack>
                {!isLastOption(index) && <Divider />}
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
