import { HeaderButtonBackAndTitle, HeaderModal, Stack } from 'components/atoms';
import { FooterModalEdit } from 'components/molecules';
import { IFooterModalEditProps } from 'components/molecules/FooterModalEdit';
import { device } from 'styles/breakpoint';

import {
  ButtonClosePopup,
  StackHeaderButtonBackAndTitle,
  StackModal,
  StackModalWrapper,
} from './styled';

interface ILayoutModalContentProps {
  children?: React.ReactNode;
  title: string;
  handleCancel: () => void;
  footer?: IFooterModalEditProps;
  heightModalDown?: {
    tablet: string;
    laptopM: string;
    desktop: string;
  };
  widthModalDown?: {
    tablet: string;
    laptopM: string;
    desktop: string;
  };
  showHeaderFullScreen?: boolean;
  onClose?: () => void;
}
export const LayoutModalContent: React.FC<ILayoutModalContentProps> = ({
  children,
  title,
  handleCancel,
  footer,
  heightModalDown = {
    desktop: 'max-content',
    laptopM: 'max-content',
    tablet: '100%',
  },
  widthModalDown = {
    desktop: '540px',
    laptopM: '740px',
    tablet: '100%',
  },
  showHeaderFullScreen = false,
  onClose,
}) => {
  return (
    <StackModal
      heightModalDown={heightModalDown}
      widthModalDown={widthModalDown}
      position="relative"
    >
      {showHeaderFullScreen && <HeaderModal breakpointUp={device.tablet} />}
      <StackHeaderButtonBackAndTitle paddingLeft="1.875rem">
        <HeaderButtonBackAndTitle onClick={handleCancel} title={title} />
      </StackHeaderButtonBackAndTitle>
      <Stack overflow="auto" paddingRight="0.625rem" flex="1" paddingBottom="1rem">
        <StackModalWrapper>{children}</StackModalWrapper>
      </Stack>
      {footer && <FooterModalEdit {...footer} />}
      {onClose && (
        <ButtonClosePopup onClick={onClose}>
          <img src="/assets/icons/icon-close.svg" alt="close" />
        </ButtonClosePopup>
      )}
    </StackModal>
  );
};
