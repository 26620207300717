// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';

import { Description, Modal, Information, Button } from './styled';
import Stack from '../Stack';
import { useTheme } from 'hooks/useTheme';

// import { Container } from './styles';

interface IConfirmDeactiveUserProps {
  width?: string;
  height?: string;
  nameUser: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
function ConfirmDeactiveUser({
  width,
  nameUser,
  height,
  isOpen,
  onClose,
  onConfirm,
}: IConfirmDeactiveUserProps) {
  const [show, setShow] = useState(isOpen);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };
  
  const { currentTheme } = useTheme();

  return (
    <>
      {show && (
        <Modal display={show ? 'flex' : 'none'} data-testid="modal-confirm-deactive-user">
          <Stack
            width="577px"
            height={height}
            justifyContent="space-between"
            data-testid="stack-confirm-deactive-user"
          >
            <Stack direction="column" width="100%" height="100%" flex="1">
              <Stack
                direction="column"
                background="rgba(0, 0, 0, 0.9)"
                justifyContent="center"
                alignItems="center"
                padding=" 1.875rem"
                flex="1"
                width="577px"
                borderRadius="8px 8px 0px 0px"
              >
                <Description marginBottom="0.25rem">Atenção!</Description>
                <Description width="400px">
                  Você tem certeza que deseja desativar o usuário {nameUser}?
                </Description>
                <Information>
                  Este usuário não poderá acessar o Backoffice até que seja reativado.
                </Information>
              </Stack>
              <Stack>
                <Button
                  background={currentTheme.colors.white}
                  borderRadius="0px 0px 0px 9px"
                  color={currentTheme.colors.primary}
                  onClick={onClose}
                >
                  {' < Voltar'}
                </Button>

                <Button
                  color={currentTheme.colors.white}
                  borderRadius="0px 0px 9px 0px"
                  background={currentTheme.colors.primary}
                  onClick={handleConfirm}
                >
                  {' Desativar >'}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Modal>
      )}
    </>
  );
}

export default ConfirmDeactiveUser;
