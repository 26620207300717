import { Stack } from 'components/atoms';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

interface ILayoutRegisterProps {
  customHeight?: string;
}

export const LayoutRegisterContainerStyled = styled.div<ILayoutRegisterProps>`
  width: 100%;
  height: ${({ customHeight }) => customHeight ?? '100%'};
  gap: 32px;
  max-width: 1600px;
  padding: 2rem;
  max-height: 1000px;
  margin: auto;

  @media (max-width: ${device.tablet}) {
    padding: 0px;
    max-height: 100vh;
  }
`;

export const LayoutStackRegister = styled(Stack)`
  width: 540px;
  max-width: 540px;
  margin-left: -8px;
  flex: 1;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.boxShadow.md};
  flex-direction: column;

  @media (max-width: ${device.laptopM}) {
    width: 740px;
    max-width: 740px;
  }

  @media (max-width: ${device.tablet}) {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    border-radius: 0px;
  }
`;

export const StackModalContent = styled(Stack)`
  flex-direction: column;
  flex: 1;
  margin-top: -8px;
  border-radius: 0.5rem;
  background: #f1f1f1;
  box-shadow: 0px 0px 16.9476px rgba(0, 0, 0, 0.13);
  overflow: auto;

  @media (max-width: ${device.tablet}) {
    border-radius: 0;
  }
`;
