import { ReactNode } from 'react';
import { LayoutRegisterContainerStyled } from './styled';
import { Stack } from 'components/atoms';

interface ILayoutRegisterProps {
  children: ReactNode;
  customHeight?: string;
}

export const LayoutRegisterContainer = ({ children, customHeight }: ILayoutRegisterProps) => {
  return (
    <LayoutRegisterContainerStyled customHeight={customHeight}>
      <Stack overflowY="auto" flex="1" justifyContent="center" height="100%" width="100%">
        {children}
      </Stack>
    </LayoutRegisterContainerStyled>
  );
};
