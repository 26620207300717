/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { yupResolver } from '@hookform/resolvers/yup';
import { RadioGroupForm, Stack } from 'components/atoms';
import { validateLink } from 'helpers/functions';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ChooseAction } from '../ChooseAction/ChooseAction';
import { ChooseFile } from '../ChooseFile/ChooseFile';
import { ChooseLink } from '../ChooseLink/ChooseLink';
import { ModalEdit } from '../index';
import { bannerLocalList } from 'helpers/enums';

const validationSchemaForm = yup
  .object({
    action: yup.mixed().when('activeStep', {
      is: (activeStep: number) => activeStep === 1,
      then: yup.number().required('Informe a ação'),
    }),
    link: yup.mixed(),
    internalLink: yup.mixed(),
    actionFile: yup.mixed(),
    actionLinkFile: yup.mixed(),
    activeStep: yup.mixed(),
  })
  .test(
    'Informe uma opção de ação',
    ({ link, internalLink, actionFile, actionLinkFile, action }) =>
      action === 3 ||
      validateLink(link) ||
      internalLink ||
      actionFile ||
      validateLink(actionLinkFile)
  );

interface IModalEditActionBannerProps {
  form: any;
  title: string;
  links: any[];
  onClose: () => void;
}

export const ModalEditActionBanner: React.FC<IModalEditActionBannerProps> = ({
  form,
  title,
  links,
  onClose,
}) => {
  const values = form.getValues();
  const defaultValues = {
    linkType: values.linkType,
    link: values.link,
    internalLink: values.internalLink,
    actionFile: values.actionFile
      ? values.actionFile
      : values.nomeArquivoBucket && !values.actionFile
      ? { name: values.nomeArquivoBucket }
      : null,
    action: values.action,
    actionLinkFile: values.actionLinkFile,
    nomeArquivoBucket: '',
    localBanner: values.localBanner,
  };

  const modalEditActionForm = useForm({
    defaultValues,
    resolver: yupResolver(validationSchemaForm),
  });

  const [action] = modalEditActionForm.watch(['action']);

  const enableButton = () => {
    const { isValid, isDirty } = modalEditActionForm.formState;
    return isValid && isDirty;
  };

  const onSave = () => {
    const {
      linkType,
      link,
      internalLink,
      actionFile,
      action,
      actionLinkFile,
      nomeArquivoBucket,
      localBanner,
    } = modalEditActionForm.getValues();

    form.setValue('linkType', linkType, { shouldDirty: true });
    form.setValue('link', link, { shouldDirty: true });
    form.setValue('internalLink', internalLink, { shouldDirty: true });
    form.setValue('actionFile', actionFile, { shouldDirty: true });
    form.setValue('action', action, { shouldDirty: true });
    form.setValue('actionLinkFile', actionLinkFile, { shouldDirty: true });
    form.setValue('nomeArquivoBucket', nomeArquivoBucket, { shouldDirty: true });
    form.setValue('localBanner', localBanner, { shouldDirty: true });

    onClose();
  };

  return (
    <ModalEdit
      onClose={onClose}
      enableButtonSave={() => enableButton()}
      onSave={onSave}
      title={title}
      width="33.25rem"
      height="24.625rem"
    >
      <Stack direction="column" marginTop="0.75rem">
        <ChooseAction form={modalEditActionForm} label="Ação do banner*" />
        {action === 1 && <ChooseLink form={modalEditActionForm} links={links} />}
        {action === 2 && (
          <ChooseFile
            form={modalEditActionForm}
            accept={['application/pdf']}
            errorMessage="O arquivo deve ser no formato PDF e de até 5MB"
            maxSize={5000000}
            data-testid="choose-link"
          />
        )}
        <RadioGroupForm
          options={bannerLocalList}
          control={modalEditActionForm.control}
          name="localBanner"
          label="Local do banner*"
          gap="0.3rem"
        />
      </Stack>
    </ModalEdit>
  );
};
