import { SelectCustomForm } from 'components/atoms';
import { useForm } from 'react-hook-form';

interface ISegmentsSeeProps {
  form: any;
  label: string;
  segments: { value: number; label: string }[];
}
export const SegmentsSee: React.FC<ISegmentsSeeProps> = ({ form, label, segments }) => {
  return (
    <SelectCustomForm
      label={`Quais segmentos poderão ver o ${label}?*`}
      control={form.control}
      name="segments"
      showSelectAll
      isMulti
      options={segments}
    />
  );
};
