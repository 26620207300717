export const setCookie = (name: string, cookie: string, expire: string) => {
  document.cookie = `${name}=${cookie};expires=${expire};path=/`;
};

export const getCookie = (name: string) => {
  const arr = document.cookie.split('; ');

  const item = arr.filter((cookie) => cookie.includes(name))[0];

  if (item) {
    return item.split('=')[1];
  }
};

export const removeCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/usuarios`;
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
};

export const getData = (value: string) => {
  const token = value.replace(/_/g, '/').replace(/-/g, '+');

  const data = JSON.parse(decodeURIComponent(escape(atob(token.split('.')[1]))));

  return {
    Name: data.nameid,
    Email: data.email,
    Id: data.sid,
    Status: data.Status,
  };
};

export const getCookieData = () => {
  const cookie = getCookie('BACKOFFICE_VEICULOS_TOKEN');
  if (cookie) {
    const jwtPayload = cookie.replace(/_/g, '/').replace(/-/g, '+');
    const result = JSON.parse(decodeURIComponent(escape(atob(jwtPayload.split('.')[1]))));

    if (result.Perfil) {
      result.Perfil = JSON.parse(result.Perfil);
      result.Permissoes = result.Perfil.Permissoes;
    }

    if (result.Downloads) {
      result.Downloads = JSON.parse(result.Downloads);
    }
    return result;
  }
  return null;
};
