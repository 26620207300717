import { AlertPreview } from 'globalStyles';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

import Stack from '../Stack';

interface IWrapperProps {
  edit?: boolean;
}
export const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ edit }) => (edit ? '100%' : '672px')};
`;

export const RoundIconContainer = styled.div`
  position: absolute;
  left: 50;
  bottom: -20px;
  width: 42px;
  display: flex;
  flex-direction: row;
  @media (max-width: ${device.laptopM}) {
    display: none;
  }
`;

export const RoundIcon = styled.div`
  bottom: -16px;
  margin: auto;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
`;

export const ArrowBackIcon = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: -16px;
  margin: auto;
  width: 15px;
  height: 15px;
  background-image: url('/assets/icons/arrow-rounded-previous.svg');
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: ${device.laptopM}) {
    display: none;
  }
`;
export const ArrowForwardIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: -16px;
  margin: auto;
  width: 15px;
  height: 15px;
  background-image: url('/assets/icons/arrow-rounded-next.svg');
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: ${device.laptopM}) {
    display: none;
  }
`;

interface ISectionUploadProps {
  base64?: string;
  edit?: boolean;
}
export const SectionUpload = styled.div<ISectionUploadProps>`
  width: 100%;
  position: relative;
  background: ${(props) => props.theme.colors.disabled}; 
  background-image: ${({ base64 }) => base64 && `url(${base64})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.375rem;
  padding: 1.25rem 0.875rem 1.875rem 0.875rem;
  height: 132px;
  ${({ edit, theme }) => edit &&  `border: 4px solid ${theme.colors.secondary}; border-radius: 0.625rem;`};

  /* @media (max-width: ${device.laptopL}) {
    height: 116.7px;
  }
  @media (max-width: ${device.laptopM}) {
    height: 132px;
  } */

  @media (max-width: ${device.tablet}) {
    background-size: contain, cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const IconEdit = styled.img`
  width: 18px;
  height: 18px;
`;

export const SubWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const IconUpload = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 0.625rem;
`;

export const Title = styled.label`
  font-weight: 700;
  font-size: 1rem;
  line-height: 109%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.colors.white}; 
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 0.625rem;
  padding: 3px 8px;
  margin: 0.625rem 0;
  cursor: pointer;
`;

interface IDescriptionProps {
  fontWeight?: string;
}
export const Description = styled.small<IDescriptionProps>`
  font-size: 0.625rem;
  line-height: 120%;
  text-align: center;
  color: ${(props) => props.theme.colors.white}; 
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
`;

export const ImageLink = styled.img`
  width: 29.8rem;
  height: 98px;
  margin-top: 0.625rem;
  border-radius: 0.5rem;
  border: 4px solid ${({ theme }) => theme.colors.secondary};
`;

interface IStackUpaloadBannerProps {
  edit?: boolean;
}
export const StackUpaloadBanner = styled(Stack) <IStackUpaloadBannerProps>`
  width: ${({ edit }) => (edit ? '100%' : '672px')};

  /* @media (max-width: ${device.laptopL}) {
    width: 100%;
    width: 595px;
  } */

  @media (max-width: ${device.laptopM}) {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 12px;
    box-sizing: border-box;
    width: 100%;
  }
`;

export const StackAlertPreview = styled(AlertPreview)`
  @media (max-width: ${device.laptopM}) {
    display: none;
  }
`;
