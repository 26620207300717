import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

export const Text = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${({ color, theme }) => color ?? theme.colors.secondary};
`;

interface IIconInformation {
  cursor?: string;
}

export const CustomReactTooltip = styled(ReactTooltip)<{ color: string; lineHeight: string; backgroundColor: string; width: string }>`
  border-radius: 10px !important;
  color: ${({ color }) => color} !important;
  font-weight: 400;
  font-size: 10px !important;
  line-height: ${({ lineHeight }) => lineHeight};
  background-color: ${({ backgroundColor }) => backgroundColor} !important;
  opacity: 1 !important;
  width: ${({ width }) => width};
  padding: 10px !important;
`;

export const IconInformation = styled.div<IIconInformation>`
  cursor: ${({ cursor }) => cursor};
  height: 10px;
  width: 10px;
  background-image: url('/assets/icons/icon-information-red.svg'); 
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 0.25rem;
`;

interface ITooltipText {
  fontWeight?: string;
}

export const TooltipText = styled.p<ITooltipText>`
  font-weight: 400;
  font-size: 10.4968px;
  line-height: 109%;
  font-weight: ${({ fontWeight }) => fontWeight};
`;
