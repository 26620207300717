import { LayoutBack } from './LayoutBack';
import { LayoutBody } from './LayoutBody';
import { LayoutCardOptions } from './LayoutCardOptions';
import { LayoutCardOptionsNewHome } from './LayoutCardOptionsNewHome';
import { LayoutContainer } from './LayoutContainer';
import { LayoutHeader } from './LayoutHeader';
import { LayoutHeaderNewHome } from './LayoutHeaderNewHome';
import { LayoutPage } from './LayoutPage';
import { LayoutTitle } from './LayoutTitle';

export const Layout = {
  Container: LayoutContainer,
  Page: LayoutPage,
  Header: LayoutHeader,
  HeaderNewHome: LayoutHeaderNewHome,
  Body: {
    Root: LayoutBody,
    Back: LayoutBack,
    Title: LayoutTitle,
    CardsOptions: LayoutCardOptions,
    CardsOptionsNewHome: LayoutCardOptionsNewHome,
  },
};
