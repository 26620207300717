import { Paragraph, Stack } from 'components/atoms';
import { IPictureDictionaryModel } from 'models';
import { useForm } from 'react-hook-form';
import { useTheme } from 'hooks/useTheme';

import { ImageOnEdit } from '../ImageOnEdit';

interface IPreviewPictureDictinaryProps {
  id: string;
  label: string;
  edit?: boolean;
  src: string;
  setError?: (val: boolean) => void;
  widthImageChange?: number;
  heightImageChange?: number;
  marginTop?: string;
  form?: ReturnType<typeof useForm<IPictureDictionaryModel>>;
}

export const PreviewPictureDictinary: React.FC<IPreviewPictureDictinaryProps> = ({
  id,
  label,
  edit,
  src,
  setError,
  widthImageChange,
  heightImageChange,
  marginTop,
  form,
}) => {
  const { currentTheme } = useTheme(); 

  return (
    <Stack
      background={currentTheme.colors.secondary} 
      borderRadius="9px"
      padding="18px"
      width="100%"
      direction="row"
      alignItems="center"
      marginTop={marginTop}
    >
      <ImageOnEdit
        edit={edit}
        src={src}
        setError={setError}
        widthImageChange={widthImageChange}
        heightImageChange={heightImageChange}
        form={form}
      />
      <Stack direction="column" paddingLeft="20px">
        <Paragraph
          color={currentTheme.colors.white} 
          marginBottom="2px"
          fontSize="12px"
          fontWeight="400"
          lineHeight="120%"
        >
          Nos arquivos de processamento, utilize o ID
        </Paragraph>
        <Paragraph
          color={currentTheme.colors.white} 
          marginBottom="5px"
          fontSize="16px"
          fontWeight="700"
          lineHeight="120%"
        >
          {id}
        </Paragraph>
        <Paragraph
          color={currentTheme.colors.white} 
          marginBottom="2px"
          fontSize="12px"
          fontWeight="400"
          lineHeight="120%"
        >
          para a imagem do prêmio
        </Paragraph>
        <Paragraph
          color={currentTheme.colors.white} 
          fontSize="16px"
          fontWeight="700"
          lineHeight="120%"
        >
          {label}
        </Paragraph>
      </Stack>
    </Stack>
  );
};
