import { IBackgroundModel } from 'models';
import { useForm } from 'react-hook-form';
import { ValidityName, screenName } from 'requests/ValidityName';

import { InputFormCustomValidation } from '../../atoms';

interface ISelectFileCarouselBackground {
  form: ReturnType<typeof useForm<IBackgroundModel>>;
}

export function SelectNameCarouselBackground(props: ISelectFileCarouselBackground) {
  const { form } = props;
  const validate = (val: string): Promise<boolean> => {
    return ValidityName({ name: val, screen: screenName.background });
  };
  return (
    <InputFormCustomValidation
      control={form.control}
      type="text"
      name="name"
      label="Nome do fundo*"
      tooltipTitle="Obrigatório"
      tooltipLabel="Escolha um nome para seu fundo. Ele será visível apenas no Gerenciador de Fundo."
      placeholder="Crie um título..."
      form={form}
      callbackValidate={validate}
    />
  );
}
