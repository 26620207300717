import { IIconProps } from 'models/entities/Icon.entity';

export const Search: React.FC<IIconProps> = ({ fill = '#ffff', width = 11, height = 11 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4167 9.81667C17.4167 14.4131 13.8237 18.05 9.5 18.05C5.17628 18.05 1.58333 14.4131 1.58333 9.81667C1.58333 5.22018 5.17628 1.58333 9.5 1.58333C13.8237 1.58333 17.4167 5.22018 17.4167 9.81667ZM19 9.81667C19 15.2383 14.7467 19.6333 9.5 19.6333C4.25329 19.6333 0 15.2383 0 9.81667C0 4.39507 4.25329 0 9.5 0C14.7467 0 19 4.39507 19 9.81667ZM10.6874 10.6875C11.7319 10.6875 12.6666 9.79761 12.6666 8.58962C12.6666 7.38164 11.7319 6.49171 10.6874 6.49171C9.64289 6.49171 8.70825 7.38164 8.70825 8.58962C8.70825 9.79761 9.64289 10.6875 10.6874 10.6875ZM10.6874 12.2709C12.6549 12.2709 14.2499 10.6227 14.2499 8.58962C14.2499 6.55653 12.6549 4.90837 10.6874 4.90837C8.7199 4.90837 7.12492 6.55653 7.12492 8.58962C7.12492 9.31478 7.32783 9.99097 7.67818 10.5609L4.97277 13.3565C4.66872 13.6706 4.67693 14.1718 4.99113 14.4759C5.30532 14.7799 5.8065 14.7717 6.11056 14.4575L8.77988 11.6992C9.33137 12.0612 9.9857 12.2709 10.6874 12.2709Z"
        fill={fill}
      />
    </svg>
  );
};
