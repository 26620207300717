import styled from 'styled-components';

interface IWrapProps {
  marginTop?: string;
  marginLeft?: string;
  slin?: boolean;
  opacityWrap?: boolean;
}
export const Wrap = styled.div<IWrapProps>`
  display: grid;
  ${({ marginTop }) => marginTop && `margin-top:${marginTop}`};
  ${({ marginLeft }) => marginLeft && `margin-left:${marginLeft}`};

  p {
    font-weight: 400;
    font-size: 0.75rem;
    color: #2e2b37;
  }
  div {
    border-radius: 0.375rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    ${({ slin }) =>
    !slin &&
    'padding: 0.25rem;   background: #ffffff;   border: 0.2px solid rgba(0, 0, 0, 0.34);'};

    button {
      font-weight: 700;
      font-size: 0.75rem;
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 0.625rem;
      padding: 3.26px 5.49px;
      color: #ffffff;
    }
    p {
      color: ${({ theme }) => theme.colors.secondary};
      font-weight: 400;
      font-size: 0.75rem;
      margin-left: 0.375rem;
      ${({ opacityWrap }) => opacityWrap && 'opacity: 0.35'};
    }
  }
`;
