interface IButtonInputStyleProps {
  onClick?: () => void;
  onToggle?: (style: string) => void;
  active?: boolean;
  className?: string;
  style?: string;
  label: string;
  name: 'bold' | 'italic' | 'underline';
}

export const ButtonInputStyle: React.FC<IButtonInputStyleProps> = (props) => {
  const handleMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    if (props?.onToggle) props.onToggle(props.style ?? '');
  };

  const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();

    if (props?.onClick) {
      props.onClick();
    }
  };

  const src = `/assets/icons/editor/icon-${props.name}-${props.active ? 'active' : 'white'}.svg`;
  return (
    <img
      src={src}
      alt={props.label}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
      className={`icon-${props.name}`}
    />
  );
};
