import { Controller, Control } from 'react-hook-form';

import RadioCustom from '../Radio';
import { RadioCustomContainer } from '../RadioCustomContainer';

interface IRadioGroupForm {
  name: string;
  control: Control<any>;
  options: { label: string; value: string | number | boolean }[];
  label?: string;
  tooltipTitle?: string;
  tooltipLabel?: string;
  onChange?: (newValue: any, oldValue: any) => void;
  isToggle?: boolean;
  gap?: string;
}
export const RadioGroupForm: React.FC<IRadioGroupForm> = ({
  name,
  control,
  options,
  label,
  tooltipTitle,
  tooltipLabel,
  onChange,
  isToggle,
  gap = '0.5rem',
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <RadioCustomContainer
          label={label}
          tooltipTitle={tooltipTitle}
          tooltipLabel={tooltipLabel}
          error={error?.message}
          gap={gap}
          data-testid="radio-group-form"
        >
          {options.map((option, index) => (
            <RadioCustom
              key={index}
              title={option.label}
              value={option.value}
              currentSelect={field.value}
              onClick={() => {
                let newValue: string | number | boolean | null = option.value;
                if (isToggle) {
                  if (typeof field.value === 'boolean') {
                    newValue = !field.value;
                  } else if (field.value === newValue) {
                    newValue = null;
                  }
                }
                if (onChange) onChange(newValue, field.value);
                field.onChange(newValue);
              }}
            />
          ))}
        </RadioCustomContainer>
      )}
    />
  );
};
