import Information from 'components/atoms/Information';
import { DragAndDrop } from 'components/molecules';
import { useCreateBanner } from 'hooks/banner';
import { useEffect } from 'react';

import { Text, WrapperInformation } from '../styled';
import { useTheme } from 'hooks/useTheme';

function ContentStep2() {
  const { createFormBannerStepper } = useCreateBanner();

  const onDragEnd = (banners: { value: number; name: string }[]) =>
    createFormBannerStepper.setValue('banners', banners);

  const [banners, name] = createFormBannerStepper.watch(['banners', 'name']);

  useEffect(() => {
    const index = banners?.findIndex((banner) => banner.value === 0) ?? -1;
    if (index > -1 && banners) {
      // se o banner existir, apenas alterar o nome
      banners[index].name = name;
      createFormBannerStepper.setValue('banners', banners);
    }
  }, [name]);

  const { currentTheme } = useTheme();

  return (
    <>
      <WrapperInformation>
        <Information
          label="Ordem de aparição*"
          tooltipTitle="Obrigatório"
          tooltipLabel="Defina em que ordem aparecerão os banners"
          labelColor={currentTheme.colors.secondary}
          tooltipColor={currentTheme.colors.white}
          place="right"
        />
        <Text>Clique e arraste para ajustar a priorização de banners ativos</Text>
      </WrapperInformation>

      <DragAndDrop title={'Nome do banner'} list={banners ?? []} onDragEnd={onDragEnd} />
    </>
  );
}

export default ContentStep2;
