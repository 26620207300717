import { Stack } from 'components/atoms';
import TopicSummary from 'components/atoms/TopicSummary';
import TopicSummaryItemArray from 'components/atoms/TopicSummaryItemArray';
import UploadBanner from 'components/atoms/UploadBanner';
import {
  actionList,
  bannerLocalList,
  classificationPeriodList,
  planConditionList,
} from 'helpers/enums';
import { diffDaysLabel } from 'helpers/functions';
import { useCreateBanner } from 'hooks/banner';
import React, { useMemo } from 'react';
import { device } from 'styles/breakpoint';

import { Wrap } from './styled';

function ContentStep5() {
  const { setIsEditing, setActiveStep, createFormBannerStepper } = useCreateBanner();

  const [
    action,
    classificationPeriod,
    planCondition,
    banners,
    defineDate,
    startDate,
    endDateUndefined,
    endDate,
    whichBranches,
    whichClassifications,
    segments,
    planPoints,
    whoCanSee,
    csvFile,
    bannerFile,
    localBanner,
  ] = createFormBannerStepper.watch([
    'action',
    'classificationPeriod',
    'planCondition',
    'banners',
    'defineDate',
    'startDate',
    'endDateUndefined',
    'endDate',
    'whichBranches',
    'whichClassifications',
    'segments',
    'planPoints',
    'whoCanSee',
    'csvFile',
    'bannerFile',
    'localBanner',
  ]);

  const actionSelected = useMemo(() => {
    return actionList.find((item) => item.value === action);
  }, [actionList, action]);

  const localBannerSelected = useMemo(() => {
    return bannerLocalList.find((item) => item.value === localBanner);
  }, [localBanner, bannerLocalList]);

  const periodSelected = useMemo(() => {
    return classificationPeriodList.find((period) => period.value === classificationPeriod);
  }, [classificationPeriodList, classificationPeriod]);

  const planConditionSelected = useMemo(() => {
    return planConditionList.find((condition) => condition.value === planCondition);
  }, [planConditionList, planCondition]);

  const onEditStep = (step: number) => {
    setIsEditing(true);
    setActiveStep(step);
  };

  const randomId = useMemo(() => (Math.random() * Math.random()).toString(), []);

  const getItens = () => {
    const index = banners?.findIndex((banner) => banner.value === 0);
    return String(Number(index) + 1);
  };

  return (
    <Wrap>
      <Stack
        flex="1"
        justifyContent="center"
        alignItems="center"
        breakpointDownInvert={device.laptopM}
      >
        <UploadBanner
          name="bannerFile"
          control={createFormBannerStepper.control}
          file={bannerFile}
        />
      </Stack>
      <TopicSummary
        title="Opções do banner*"
        link="Editar >"
        itens={[
          `Ação: ${actionSelected?.label}` ?? '',
          `Local: ${localBannerSelected?.label}` ?? '',
        ]}
        click={() => onEditStep(1)}
      />

      <TopicSummary
        title="Ordem de aparição*"
        link="Editar >"
        itens={[getItens()]}
        click={() => onEditStep(2)}
      />

      <TopicSummary
        title="Vigência do banner*"
        link="Editar >"
        itens={
          defineDate === 1
            ? [
                `Data início: ${startDate.toLocaleDateString('pt-BR', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                })} ${diffDaysLabel(startDate)}`,
                `Data fim: ${
                  endDateUndefined
                    ? 'Indefinida'
                    : endDate.toLocaleDateString('pt-BR', {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                      })
                }`,
              ]
            : ['Exibir apenas durante período de resgate']
        }
        click={() => onEditStep(3)}
      />

      <TopicSummary
        title="Público alvo*"
        link="Editar >"
        itens={[
          whichBranches && whichBranches.filter((item) => item.value !== 0).length >= 1 ? (
            <TopicSummaryItemArray
              title="Filiais:"
              key={randomId}
              arrayItems={whichBranches?.map((item) => item.label) ?? []}
            />
          ) : (
            'Filiais: Todas'
          ),
          `Classificação: ${
            whichClassifications?.map((classification) => ' ' + classification.label).toString() ??
            ''
          } - ${periodSelected?.label ?? ''}`,
          `Segmentos: ${segments?.map((segment) => ' ' + segment.label).toString() ?? ''}`,
          `Pontuação Platinum: ${planConditionSelected?.label ?? ''} ${planPoints ?? '-'}`,
          `CNPJs: ${whoCanSee === 1 ? 'Todos' : csvFile?.name ?? ''}`,
        ]}
        click={() => onEditStep(4)}
      />
    </Wrap>
  );
}

export default ContentStep5;
