import styled from "styled-components";

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 105px;  
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 93.85%, #000 99.44%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.32%, rgba(0, 0, 0, 0.80) 69.36%);
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 93.85%, #000 99.44%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.32%, rgba(0, 0, 0, 0.80) 69.36%), url('/assets/background/bg_header_backoffice.png');
  padding: 20px;
`;