import Stack from '../Stack';
import { IconTurbo, ParagraphClue, TitleClue } from './styled';

interface ITipsTurboProps {
  tip: string;
}
export const TipsTurbo: React.FC<ITipsTurboProps> = ({ tip }) => (
  <Stack direction="column" marginBottom={'0.25rem'} maxWidth="276px">
    <Stack alignItems="center">
      <TitleClue>Dica</TitleClue>
      <IconTurbo src="/assets/icons/turbo-name.svg" alt="icon-turbo" />
    </Stack>
    <ParagraphClue>{tip}</ParagraphClue>
  </Stack>
);
