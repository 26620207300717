import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTheme } from 'hooks/useTheme';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Use a base theme for the initial setup
import { ColDef, GridApi, BodyScrollEvent } from 'ag-grid-community'; // Import the ColDef type
import { StyledAgGridContainer } from './styled';
import { ButtonRounded, Stack } from 'components/atoms';
// Row Data interface
interface TablePinProps<T> {
  columns?: ColDef<T>[] | null;
  rows?: T[] | null;
  getShowMore: () => Promise<any>;
  showMore?: boolean;
  loadingTable?: boolean;
  gridApiRef: React.MutableRefObject<GridApi | null>;
}

export const TablePin = <T,>({
  columns,
  rows,
  getShowMore,
  showMore,
  loadingTable,
  gridApiRef,
}: TablePinProps<T>) => {
  const [scrollTableBottom, setScrollTableBottom] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentTheme } = useTheme();

  useEffect(() => {
    if (loadingTable) {
      gridApiRef?.current?.showLoadingOverlay();
    } else {
      gridApiRef?.current?.hideOverlay();
    }
  }, [loadingTable]);

  useEffect(() => {
    if (rows?.length === 0 && !loadingTable) {
      gridApiRef?.current?.showNoRowsOverlay();
    } else {
      gridApiRef?.current?.hideOverlay();
    }
  }, [rows]);

  const handleShowMore = async () => {
    if (gridApiRef.current) {
      setLoading(true);
      const newRows = await getShowMore();
      gridApiRef.current.applyTransaction({
        add: newRows.rows,
      });
      setScrollTableBottom(false);
      setLoading(false);
    }
  };

  const onGridReady = useCallback((params: any) => {
    gridApiRef.current = params.api;
    params.api.addEventListener('bodyScroll', onBodyScroll);
  }, []);

  const onBodyScroll = useCallback(
    (event: BodyScrollEvent) => {
      if (event.direction === 'vertical') {
        const gridBody = document.querySelector('.ag-body-viewport');
        if (
          gridBody &&
          gridBody.scrollTop + gridBody.clientHeight >= gridBody.scrollHeight &&
          rows?.length
        ) {
          setScrollTableBottom(true);
        } else {
          setScrollTableBottom(false);
        }
      }
    },
    [rows]
  );

  useEffect(() => {
    const currentGridApi = gridApiRef.current;
    if (currentGridApi) {
      currentGridApi.addEventListener('bodyScroll', onBodyScroll);
    }

    return () => {
      if (currentGridApi) {
        currentGridApi.removeEventListener('bodyScroll', onBodyScroll);
      }
    };
  }, [onBodyScroll]);

  const showMoreButton = scrollTableBottom && showMore;

  const loadingOverlayTemplate = `
  <div class="custom-loading-overlay">
    <img src='/assets/gifs/loading-themeChamas.gif' alt="Loading..."  style="width: 40px; height: 40px;" />
  </div>
`;
  const noRowsOverlayTemplate = `
<div class="custom-no-rows-overlay">
  <p style="font-size: 16px; color: #888;">Nenhum movimento foi encontrado para os parâmetros selecionados.</p>
</div>
`;

  return (
    <StyledAgGridContainer className="ag-theme-alpine" theme={currentTheme}>
      <AgGridReact<T>
        rowData={rows}
        columnDefs={columns}
        onGridReady={onGridReady}
        suppressServerSideFullWidthLoadingRow
        overlayLoadingTemplate={loadingOverlayTemplate}
        overlayNoRowsTemplate={noRowsOverlayTemplate}
        suppressDragLeaveHidesColumns
      />
      {showMoreButton && (
        <Stack width="100%">
          <ButtonRounded
            margin="12px 0px"
            width="100%"
            isLoading={loading}
            label={loading ? 'Carregando...' : 'Carregar mais...'}
            background={currentTheme.colors.primary}
            fontSize="12px"
            color={currentTheme.colors.white}
            click={handleShowMore}
          />
        </Stack>
      )}
    </StyledAgGridContainer>
  );
};
