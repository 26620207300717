import styled, { css } from 'styled-components';

interface IWrapper {
  marginLeft?: string;
  marginRight?: string;
}
export const Wrapper = styled.div<IWrapper>`
  box-sizing: border-box;
  ${({ marginLeft }) => marginLeft && `margin-left:${marginLeft}`}
  ${({ marginRight }) => marginRight && `margin-right:${marginRight}`}
`;

export const Description = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const CircleSelect = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: transparent;
  border: 0.2px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const styleSelected = css`
  background: ${({ theme }) => theme.colors.primary};

  & > ${Description} {
    color: ${(props) => props.theme.colors.white}; 
  }

  & > ${CircleSelect} {
    position: relative;
    border: 0.2px solid ${(props) => props.theme.colors.white}; 

    &::after {
      content: url(/assets/icons/check.svg);
      position: absolute;
    }
  }
`;

interface IItem {
  selected?: boolean;
}
export const Item = styled.div<IItem>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${(props) => props.theme.colors.white}; 
  border: 0.2px solid rgba(0, 0, 0, 0.34);
  border-radius: 0.375rem;
  box-sizing: border-box;
  padding: 0.25rem;
  gap: 0.25rem;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s;
  cursor: pointer;

  ${({ selected }) => selected && styleSelected}
`;
