import { TextCountStack } from './styled';

interface ITextCountProps {
  count: number;
  maxLength: number;
}

export const TextCount = ({ count, maxLength }: ITextCountProps) => {
  return (
    <TextCountStack>
      <p>{`${count} / ${maxLength} caracteres`}</p>
    </TextCountStack>
  );
};
