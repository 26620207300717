import { StyledStack } from 'components/atoms/Stack';
import styled from 'styled-components';

export const StackActions = styled(StyledStack)`
  padding-top: 0;
  padding: 0 30px;
  height: 5rem;
  align-items: center;
  box-shadow: 0px -2px 18px rgba(0, 0, 0, 0.28);
`;
