import styled from 'styled-components';

export const ButtonSubmit = styled.button`
  background: ${(props) => props.theme.colors.primary}; 
  border-radius: 0.875rem;
  width: 182px;
  height: 29px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.875rem;
  margin-bottom: 2.25rem;
  :disabled {
    background-color: ${(props) => props.theme.colors.silverDark}; 
  }
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 109%;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.white}; 
  }
  img {
    margin-left: 0.25rem;
  }
`;

export const WrapperModal = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  img {
    width: 19px;
    margin-bottom: 0.375rem;
  }
  h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    color: ${(props) => props.theme.colors.white}; 
    margin-bottom: 0.5rem;
  }
  p {
    font-weight: 400;
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.white}; 
  }
`;
