import { CreateCarouselBackground } from 'components/organisms/CreateCarouselBackground';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import { CarouselBackgroundProvider } from 'hooks/carouselBackground';
import { turboCarrosselBackgroundURL, turboCarrosselURL } from 'routers/routes';

export const TurboCarrosselBackgroundCreate = () => {
  return (
    <CarouselBackgroundProvider>
      <CreateCarouselBackground subMenu={subMenuTurbo} routerBack={turboCarrosselBackgroundURL} />
    </CarouselBackgroundProvider>
  );
};
