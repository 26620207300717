import { GroupSupplier } from 'helpers/enums';
import { sleep } from 'helpers/functions';
import { consultReportAnalyticalMock } from 'mocks/chamas/consultReportAnalytical.mock';
import { IAnalyticalColumnsModel } from 'models/dtos/AnalyticalColumns.model';
import { IAPIResponse } from 'models/dtos/api-response.model';
import { IReportAnalitycalModel } from 'models/dtos/report-analitycal.model';
import { IReportChamasDownloadAllModel } from 'models/dtos/report-chamas-download-all.model';
import {
  IAnalyticalColumnEntity,
  IAnalyticalColumnReturn,
} from 'models/entities/AnalyticalColumn.entity';
import { IAnalitycalPeriodEntity } from 'models/entities/AnalyticalPeriod.entity';
import { IReportAnalyticalTableEntity } from 'models/entities/ReportAnalyticalTable.entity';
import { IReportAnalyticalEntity } from 'models/entities/report-analytical.entity';
import { IReportChamasDownloadAllEntity } from 'models/entities/report-chamas-download-all.entity';
import { api } from 'services/api';

export const GetAnalitycalPeriodStart = async (): Promise<IAnalitycalPeriodEntity> => {
  // const response = await api.get<IAPIResponse<IAnalitycalPeriodModel>>('/chamas/analytical/period');
  // const dado = response.data.Dado;

  // return {
  //   periodStart: dado.data_inicio,
  // };
  await sleep(1000);
  return {
    periodStart: '03/04/2019',
  };
};

export const GetAnalyticalColumns = async (
  typeReport: number
): Promise<IAnalyticalColumnReturn> => {
  const response = await api.get<IAPIResponse<IAnalyticalColumnsModel>>(
    '/analitico/chamas/colunas',
    {
      params: { tipoRelatorio: typeReport },
    }
  );
  const { colunas, data_inicio } = response.data.Dado;
  const columns: IAnalyticalColumnEntity[] = colunas.map((column) => {
    return {
      id: column.id,
      label: column.nome_coluna,
      groupId: column.grupo,
      groupName: GroupSupplier[column.grupo],
      checked: column.padrao,
      order: column.ordem_visualizacao,
    };
  });
  const year = data_inicio ? data_inicio.split('/')[1] : null;
  return { yearStart: Number(year), columns };
};

export interface IConsultReportAnalyticalProps {
  colunas?: number[];
  pesquisa?: string;
  tipoFiltro?: number;
  dataInicio: string;
  dataFim: string;
  pagina: number;
  fornecedor?: number;
  tipoRelatorio: number;
}
export const consultReportAnalytical = async ({
  colunas,
  pesquisa,
  tipoFiltro,
  dataInicio,
  dataFim,
  pagina,
  fornecedor,
  tipoRelatorio,
}: IConsultReportAnalyticalProps): Promise<IReportAnalyticalEntity> => {
  try {
    //https://yfpc3a5779.execute-api.us-east-1.amazonaws.com/azl/api/analitico/chamas/consultar?pesquisa=motos&tipoFiltro=3&dataInicio=2024-06-01&dataFim=2024-06-10&fornecedor=1&tipoRelatorio=1&colunas=[1,13,12,3,10,5,11,8]&pagina=1
    const response = await api.get<IAPIResponse<IReportAnalitycalModel>>(
      '/analitico/chamas/consultar',
      {
        params: {
          colunas: `[${colunas}]`,
          pesquisa,
          tipoFiltro,
          dataInicio,
          dataFim,
          fornecedor,
          pagina,
          tipoRelatorio,
        },
      }
    );
    // await sleep(1000);
    // const response = consultReportAnalyticalMock;
    const { linhas, totalLinhas } = response.data.Dado;

    const rows = linhas.map((linha) => {
      return linha.celulas.reduce((obj: any, item) => {
        obj[item.columnId] = item.value;
        return obj;
      }, {});
    });

    return {
      totalLines: totalLinhas,
      rows,
    };
  } catch (error) {
    console.error(error);
    throw new Error('Erro ao buscar relatório analítico');
  }
};

export interface IExportChamasAnalytical extends IConsultReportAnalyticalProps {
  tipoExportacao: number;
}

export const exportChamasAnalytical = async ({
  colunas,
  pesquisa,
  tipoFiltro,
  dataInicio,
  dataFim,
  fornecedor,
  tipoExportacao,
  tipoRelatorio,
}: IExportChamasAnalytical) => {
  try {
    const response = await api.get<IAPIResponse<any>>('/analitico/chamas/exportar', {
      params: {
        colunas: `[${colunas}]`,
        pesquisa,
        tipoFiltro,
        dataInicio,
        dataFim,
        fornecedor,
        tipoExportacao,
        tipoRelatorio,
      },
    });
    if (response.data.HttpStatus !== 200) {
      throw new Error('Erro ao exportar Relatório analítico.');
    }
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Erro ao exportar Relatório analítico.');
  }
};

export const exportChamasAnalyticalComplete = async (): Promise<IReportChamasDownloadAllEntity> => {
  try {
    const response = await api.get<IAPIResponse<IReportChamasDownloadAllModel>>(
      '/analitico/chamas/baixar'
    );
    const { hash_link } = response.data.Dado;
    return {
      hashLink: hash_link,
    };
  } catch (error) {
    throw new Error('Erro fazer download do relatório analítico.');
  }
};
