import { Label, RadioGroupForm, Stack } from 'components/atoms';
import DuoCalendar from 'components/atoms/DuoCalendar';
import Title from 'components/atoms/Title';
import { defineDateList, defineDateOnlyDates } from 'helpers/enums';
import { useEffect, useState } from 'react';

interface IDefineDateValidityFormProps {
  form: any;
  label1: string;
  label2: string;
  hideValidity?: boolean;
}
export const DefineDateValidityForm: React.FC<IDefineDateValidityFormProps> = ({
  form,
  label1,
  label2,
  hideValidity,
}) => {
  const { defineDate, startDate, endDate, endDateUndefined } = form.watch();

  const [fixedMinDate, setFixedMinDate] = useState(new Date());

  useEffect(() => {
    if (defineDate === 2) {
      form.setValue('endDateUndefined', false, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [defineDate]);

  useEffect(() => {
    const _startDate = startDate ?? new Date();
    setFixedMinDate(_startDate < new Date() ? _startDate : new Date());
  }, []);

  return (
    <Stack direction="column" overflowX="auto">
      <RadioGroupForm
        name="defineDate"
        control={form.control}
        options={hideValidity ? defineDateOnlyDates : defineDateList}
      />

      {defineDate === 1 ? (
        <Stack direction="column" gap="0.5rem">
          <DuoCalendar
            label="Defina data de Início e Fim"
            tooltipTitle="Obrigatório"
            tooltipLabel="Defina a data de Início e Fim"
            minDate={fixedMinDate}
            startDate={startDate}
            endDate={endDate}
            hideEndDate={endDateUndefined}
            onChange={(value: any) => {
              form.setValue('startDate', value.startDate, {
                shouldDirty: true,
              });
              form.setValue('endDate', value.endDate, { shouldDirty: true });
              form.trigger('startDate');
              form.trigger('endDate');
            }}
          />

          <RadioGroupForm
            name="endDateUndefined"
            control={form.control}
            isToggle
            options={[{ value: true, label: 'Data final indefinida' }]}
          />
        </Stack>
      ) : (
        <Stack direction="column" alignItems={'start'}>
          <Title label={'Atenção!'} color={'red'} />
          <Label title={label1} />
          <Label marginTop={'8px'} title={label2}></Label>
        </Stack>
      )}
    </Stack>
  );
};
