import { Stack } from 'components/atoms';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

export const Card = styled.section`
  background: #f1f1f1;
  box-shadow: 0px 0px 16.9476px rgba(0, 0, 0, 0.13);
  border-radius: 0.5rem;
  padding: 1.875rem;
  position: relative;
  top: -8px;
  cursor: pointer;
`;

export const WrapperTitle = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
`;

export const IconCardUser = styled.img`
  margin-right: 0.25rem;
  width: 21px;
  height: 12px;
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #2e2b37;
`;

export const SectionInformation = styled.section`
  /* margin-bottom: 1.5rem; */
`;

export const SectionOptions = styled.section`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  margin-top: 1rem;
`;

interface IStackOptionsProps {
  columnsTablet: string;
}
export const StackOptions = styled(Stack) <IStackOptionsProps>`
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: ${device.tablet}) {
    grid-template-columns: ${({ columnsTablet }) => columnsTablet};
  }
`;
