import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Stack from '../Stack';
import { IconInformation, Text, TooltipText, CustomReactTooltip } from './styled';
import { generateKeyId } from 'helpers/functions';

export interface IInformation {
  id?: string;
  label?: string;
  tooltipTitle?: string;
  tooltipLabel?: string;
  marginBottom?: string;
  marginLeft?: string;
  place?: 'top' | 'right' | 'bottom' | 'left';
  labelColor?: string;
  tooltipColor?: string;
  className?: string;
  width?: string;
  backgroundColor?: string;
  textTooltip?: string;
  lineHeight?: string;
}

const StyledTooltip = styled.div<{ backgroundColor?: string; color?: string; lineHeight?: string; width?: string }>`
.information-tooltip.__react_component_tooltip {
  border-radius: 10px !important;
  font-weight: 400;
  font-size: 10px !important;
  line-height: ${({ lineHeight }) => lineHeight};
  background-color: ${({ backgroundColor }) => backgroundColor} !important;
  opacity: 1 !important;
  max-width: 240px;
  padding: 10px !important;
  white-space: pre-wrap; 

  & > div {
    text-align: center; 
    color: ${({ color }) => color} !important;
    max-width: 100%; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    z-index: 99999 !important;
  }
}

`;

const Information: React.FC<IInformation> = ({
  id,
  label,
  tooltipTitle,
  tooltipLabel,
  marginBottom,
  marginLeft,
  place = 'bottom',
  labelColor = '#fff',
  tooltipColor = '#2e2b37',
  width,
  backgroundColor = '#2e2b37',
  lineHeight = '10.9px',
  textTooltip,
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tooltipLabel, tooltipTitle, textTooltip]);

  const key = id ?? `tooltip-label-${generateKeyId()}`;

  const tooltipContent = useMemo(
    () => (
      <div>
        {tooltipLabel && <div style={{ color: tooltipColor }}>{tooltipLabel}</div>}
        {textTooltip && <div style={{ color: tooltipColor }}>{textTooltip}</div>}
      </div>
    ),
    [tooltipTitle, tooltipLabel, textTooltip, tooltipColor]
  );

  return (
    <StyledTooltip backgroundColor={backgroundColor} color={labelColor} lineHeight={lineHeight} width={width}>
      <Stack alignItems="center" marginBottom={marginBottom} marginLeft={marginLeft} minHeight="14px">
        {label && <Text color={labelColor}>{label}</Text>}
        {(tooltipTitle || tooltipLabel || textTooltip) && (
          <>
            <IconInformation
              cursor={tooltipLabel || textTooltip ? 'pointer' : 'default'}
              data-tip
              data-for={key}
              data-testid="icon-information"
            />
            <ReactTooltip
              id={key}
              className="information-tooltip"
              delayShow={200}
              place={place}
              effect="solid"
              multiline={true}
              getContent={() => tooltipContent}
            />
          </>
        )}
      </Stack>
    </StyledTooltip>
  );
};

export default Information;
