import styled, { css } from 'styled-components';

interface IRadioButtonText {
  checked: boolean;
}

export const RadioButtonText = styled.div<IRadioButtonText>`
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  padding: 6px 8px;
  cursor: pointer;
  border-radius: 22px;
  border: 0.5px solid transparent;
  color: ${({ theme }) => theme.colors.black};

  ${({ checked }) =>
    checked &&
    css`
      border: 0.5px solid ${({ theme }) => theme.colors.black};
      font-weight: 700;
    `}
`;
