import styled from 'styled-components';
import { device } from 'styles/breakpoint';

interface IWrap {
  width?: string;
  minWidth?: string;
  height?: string;
  background?: string;
  display?: string;
  justifyContent?: string;
  flexDirection?: string;
  padding?: string;
  position?: string;
  zIndex?: string;
  positionCenter?: boolean;
  fullScreenTabled?: boolean;
}
export const Wrap = styled.div<IWrap>`
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};
  border-radius: 0.5rem;
  position: relative;
  background-color: ${({ background }) => background};
  max-height: 1000px;
  display: ${({ display }) => display ?? 'block'};
  ${({ justifyContent }) => (justifyContent ? `justify-content:${justifyContent}` : '')};
  ${({ flexDirection }) => (flexDirection ? `flex-direction:${flexDirection}` : '')};
  ${({ padding }) => (padding ? `padding:${padding}` : '')};
  ${({ position }) => (position ? `position:${position}` : '')};
  ${({ zIndex }) => (zIndex ? `z-index:${zIndex}` : '')};
  ${({ positionCenter }) =>
    positionCenter && 'top: 0;bottom: 0;left: 0;right: 0; margin: auto auto;'}
  ${({ fullScreenTabled }) =>
    `@media (max-width: ${device.tablet}) {${fullScreenTabled ? 'width: 100%;height: 100%;' : ''}}`}
`;

export const Img = styled.img`
  position: absolute;
  bottom: 26px;
  left: -26px;
`;

interface IButtonClose {
  displayButtonCloseOnTable: string;
}
export const ButtonClose = styled.button<IButtonClose>`
  background-color: transparent;
  position: absolute;
  top: 0;
  right: -40px;
  img {
    width: 24px;
  }

  @media (max-width: ${device.laptopM}) {
    right: -35px;
  }

  @media (max-width: ${device.tablet}) {
    display: ${({ displayButtonCloseOnTable }) => displayButtonCloseOnTable};
  }
`;
