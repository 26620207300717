import styled from "styled-components";


export const ContainerMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  padding: 10px 0;


  a {
    font-size: 10px;
    line-height: 12px;
    color: ${(props) => props.theme.colors.white}; 
    text-transform: uppercase;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-decoration: none;
    letter-spacing: 0.235px;

    &.button-menu-active {
      font-weight: bold;
    }
    /* :hover {
      font-weight: bold;
    } */

  }

`;