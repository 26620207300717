/* eslint-disable eqeqeq */
import { CustomErrorApi } from 'helpers/customErrorApi';
import { fileToBase64 } from 'helpers/functions';
import { IValidityTabModel } from 'models';
import { IAPIResponse } from 'models/dtos/api-response.model';

import { IValidityTabResponse } from 'models/dtos/validity-tab-response.model';

import { api } from 'services/api';

export const ValidityTabList = async (payload: {
  fileImageBanner: File;
}): Promise<IValidityTabModel> => {
  try {
    const { fileImageBanner } = payload;
    const internalData = {
      ClientesBase64: await fileToBase64(fileImageBanner),
    };

    const response = await api.post<IAPIResponse<IValidityTabResponse>>('/tab', internalData);
    const notifications = response.data.Notificacoes;
    if (response.data.HttpStatus !== 200) {
      if (notifications && notifications?.length > 0) {
        throw new CustomErrorApi(notifications[0].Mensagem);
      } else {
        throw new Error('Verifique sua conexão e tente novamente.');
      }
    }
    const data: IValidityTabModel = {
      fileOfInvalidTabs: response.data.Dado.ArquivoTabsInvalidas,
      numberOfInvalidTabs: response.data.Dado.QuantidadeTabsInvalidas,
    };
    return data;
  } catch (error) {
    if (error instanceof CustomErrorApi) {
      throw new Error(error.message);
    } else throw new Error('Verifique sua conexão e tente novamente.');
  }
};
