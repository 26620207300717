import React, { useEffect, useState } from 'react';

import { Flex, IconClose, Modal, ModalContent, ModalContentBox } from './styled';

interface IPopupProps {
  id?: string;
  width?: string;
  height?: string;
  showButtonClose?: boolean;
  hasMessageInside?: boolean;
  isOpen?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
}

const Popup = (props: IPopupProps) => {
  const { id, width, height, showButtonClose, hasMessageInside, isOpen, children, onClose } = props;
  const [show, setShow] = useState(isOpen);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  return (
    <>
      {show && (
        <Modal display={show ? 'flex' : 'none'}>
          <ModalContentBox width={width}>
            <Flex>
              <ModalContent id={id} height={height} hasMessageInside={hasMessageInside}>
                {children}
              </ModalContent>
              {showButtonClose ? (
                <IconClose
                  src="/assets/icons/icon-close.svg"
                  onClick={onClose}
                  data-testid="button-Close"
                />
              ) : null}
            </Flex>
          </ModalContentBox>
        </Modal>
      )}
    </>
  );
};

export default Popup;
