import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from 'components/atoms';
import {
  EditButtonsPopupButton,
  ModalEditPopup,
  PreviewButtonComponent,
} from 'components/molecules';
import { validateLink } from 'helpers/functions';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { StackEditButton } from './styled';

const validateButton = (button: any) =>
  button?.text &&
  button.idColor &&
  (button.action === 4 ||
    (button.action === 1 && (validateLink(button.link) || button.internalLink)) ||
    (button.action === 2 && (button.actionFile || validateLink(button.actionLinkFile))));

const Schema = yup.object().test('informe o botão', ({ popupButtons, popupButtonCount }) => {
  const buttonA = popupButtons[0];
  const buttonB = popupButtons[1];

  const buttonAValidated = validateButton(buttonA);
  const buttonBValidated = validateButton(buttonB);

  if (popupButtonCount === 1) return buttonAValidated;
  if (popupButtonCount === 2) return buttonAValidated && buttonBValidated;
  return true;
});

interface IEditButtonsPopupProps {
  onClose: () => void;
  links: any[];
  handleSave: (val: any) => void;
  defaultValues: any;
}

export const EditButtonsPopup: React.FC<IEditButtonsPopupProps> = ({
  onClose,
  links,
  handleSave,
  defaultValues,
}) => {
  const formButtonPopup = useForm({
    defaultValues,
    resolver: yupResolver(Schema),
  });

  const enableButton = () => {
    return formButtonPopup.formState.isValid && formButtonPopup.formState.isDirty;
  };

  const renderPreview = () => {
    return <PreviewButtonComponent form={formButtonPopup} />;
  };

  const onSave = () => {
    const values = formButtonPopup.getValues();
    handleSave(values);
  };

  return (
    <ModalEditPopup
      onClose={onClose}
      onSave={() => onSave()}
      enableButtonSave={enableButton}
      width="35.74rem"
      Preview={renderPreview()}
      height="40.563rem"
    >
      <StackEditButton>
        <EditButtonsPopupButton form={formButtonPopup} links={links} />
      </StackEditButton>
    </ModalEditPopup>
  );
};
