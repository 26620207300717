import { PictureDictionaryCreate } from 'components/organisms';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import { CarouselPictureDictionaryProvider } from 'hooks/carouselPictureDictionary';
import { turboCarrosselURL } from 'routers/routes';

export const TurboCarroselPictureDictionaryCreate = () => {
  return (
    <CarouselPictureDictionaryProvider>
      <PictureDictionaryCreate routerBack={turboCarrosselURL} subMenu={subMenuTurbo} />
    </CarouselPictureDictionaryProvider>
  );
};
