import styled, { css } from 'styled-components';

import Stack, { StyledStack } from '../Stack';

// export const WrapperCard = styled(StyledStack)`
//   width: ${(props) => props.width};
//   height: ${(props) => props.height};
// `;

// export const HeaderTop = styled.div`
//   width: 100%;
//   height: 14px;
//   background-color: #2f80ed;
//   border-radius: 0.5rem 0.5rem 0 0;
// `;

// export const Card = styled.div<{ height?: string; hover?: boolean }>`
//   border-radius: 0.5rem;
//   height: ${({ height }) => height ?? 'auto'};
//   padding: 1.125rem 1.25rem 1.375rem 1.25rem;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   top: -8px;
//   transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
//   cursor: pointer;
//   background-color: ${({ hover }) => (hover ? '#2f80ed' : '#e9e9e9')};
// `;

// export const Back = styled.div`
//   display: flex;
//   width: fit-content;
//   height: fit-content;
//   align-items: center;
//   margin-bottom: 1.25rem;
// `;

// export const IconRoundedBack = styled.img`
//   margin-right: 0.375rem;
//   width: 14px;
//   height: 14px;
// `;

// export const Icon = styled.img<{ size?: string }>`
//   width: ${({ size }) => size ?? '25px'};
//   height: ${({ size }) => size ?? '25px'};
//   margin-bottom: 0.75rem;
// `;

export const Subtitle = styled.p<{ hover?: boolean }>`
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: ${({ hover, theme }) => (hover ? `${theme.colors.white}` : `${theme.colors.darkGray}`)};
`;

export const IconNext = styled.img`
  width: 19px;
  height: 19px;
`;

interface IStackCardProps {
  mode: 'landscape' | 'portrait';
  className?: string;
}

export const StackCard = styled(Stack)<IStackCardProps>`
  width: 100%;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[11]};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border-top: 8px solid
    ${({ hover, theme }) => (hover ? theme.colors.secondary : theme.colors.primary)};
  height: 100%;
  justify-content: space-between;

  .layout-card-options-new-home {
    align-self: center !important;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  ${({ mode }) =>
    mode === 'landscape' &&
    css`
      height: auto;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      > div {
        gap: 18px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    `}
`;
