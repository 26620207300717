import { Stack } from 'components/atoms';
import { ReactNode } from 'react';

interface ILayoutContainerProps {
  children: ReactNode;
}

export const LayoutContainer = ({ children }: ILayoutContainerProps) => {
  return (
    <Stack width="100vw" height="100vh" justifyContent="center" alignItems="center">
      {children}
    </Stack>
  );
};
