import { PopupHome } from 'components/organisms/PopupHome';
import { Layout } from 'components/templates/Layout';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { backofficeUsersURL } from 'routers/routes';

export const BackofficeHome = () => {
  const { currentTheme } = useTheme();
  const iconThemes = useIconThemes();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Início | Backoffice Lojas';
  }, []);

  const optionsList = [
    {
      title: 'Usuários Backoffice',
      subtitle: 'Crie ou gerencie usuários que tem acesso ao Backoffice.',
      icon: iconThemes[currentTheme.theme]?.iconBackoffice || '',
      iconHover: iconThemes[currentTheme.theme]?.iconUserboHover || '',
      onClick: () => navigate(backofficeUsersURL),
    },
  ];

  return (
    <Layout.Container>
      <Layout.Page width={currentTheme.sizes[537]} height="613px">
        <Layout.Header />
        <Layout.Body.Root>
          <PopupHome
            item={[
              {
                title: '+Fidelidade Turbo',
                subtitle:
                  'Veja relatórios, gerencie as comunicações e programe suas postagens no +Fidelidade Turbo.',
                img: '/assets/images/icon-popup-home-turbo.svg',
                value: 'image',
              },
              {
                title: 'Chamas',
                subtitle: 'Consulte e baixe relatórios do Chamas.',
                img: '/assets/images/icon-popup-home-chamas.svg',
                value: 'button',
              },
            ]}
          />
          <Layout.Body.CardsOptionsNewHome options={optionsList} columns={1} />
        </Layout.Body.Root>
      </Layout.Page>
    </Layout.Container>
  );
};
