import { Layout } from 'components/templates/Layout';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  turboCarroselPictureDicionaryCreateURL,
  turboCarroselPictureDicionaryManagementURL,
  turboCarrosselURL,
} from 'routers/routes';

export const TurboCarroselPictureDictionary = () => {
  const { currentTheme } = useTheme();
  const iconThemes = useIconThemes();
  const navigate = useNavigate();

  useEffect(() => {
    // define title page
    document.title = 'Dicionário de imagens | Turbo |  Backoffice Lojas';
  }, []);

  const optionsList = [
    {
      title: 'Nova imagem',
      subtitle: 'Faça upload de uma nova imagem de prêmio para o carrossel.',
      icon: iconThemes[currentTheme.theme]?.iconPlus || '',
      iconHover: iconThemes[currentTheme.theme]?.iconPlusHover || '',
      onClick: () => navigate(turboCarroselPictureDicionaryCreateURL),
    },
    {
      title: 'Gerenciar Dicionário',
      subtitle: 'Gerencie as imagens do dicionário e exporte uma planilha.',
      icon: iconThemes[currentTheme.theme]?.iconGear || '',
      iconHover: iconThemes[currentTheme.theme]?.iconGearHover || '',
      onClick: () => navigate(turboCarroselPictureDicionaryManagementURL),
    },
  ];

  return (
    <Layout.Container>
      <Layout.Page width={currentTheme.sizes[537]} height={currentTheme.sizes[547]}>
        <Layout.Header subMenu={subMenuTurbo} />
        <Layout.Body.Root>
          <Layout.Body.Back routerBack={turboCarrosselURL} />
          <Layout.Body.Title
            title="Carrossel de Prêmios"
            subtitle="Crie e gerencie os prêmios turbo e personalize o carrossel."
            iconTitle={iconThemes[currentTheme.theme]?.iconCarousel}
          />
          <Layout.Body.CardsOptions options={optionsList} columns={1} />
        </Layout.Body.Root>
      </Layout.Page>
    </Layout.Container>
  );
};
