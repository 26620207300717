import styled from 'styled-components';

export const LabelTitle = styled.p`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 0.25rem;
`;
