import styled from 'styled-components';

export const LocalHeader = styled.div`
  display: flex;
  padding: 1.875rem;
  flex-direction: column;

  img {
    width: 12px;
    margin-bottom: 0.375rem;
  }
  h1 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 120%;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }
  p {
    font-weight: 400;
    font-size: 0.75rem;
    color: #ffffff;
  }
`;

export const WrapSusses = styled.div`
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  img {
    width: 15px;
    margin-bottom: 0.625rem;
  }
  h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 0.375rem;
  }
  p {
    font-weight: 400;
    font-size: 0.75rem;
    color: #ffffff;
  }
`;
