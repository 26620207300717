import PreviewPopupButton from 'components/atoms/PreviewPopupButton';
import { popupButtonIcons } from 'helpers/enums';
interface IPreviewButtonComponent {
  form: any;
}

export const PreviewButtonComponent: React.FC<IPreviewButtonComponent> = ({ form }) => {
  const { popupButtonIcon, popupButtonTitle, popupButtonSubTitle, popupButtons } = form.watch();
  const currentIcon = popupButtonIcons[popupButtonIcon - 1];
  return (
    <PreviewPopupButton
      icon={currentIcon.src}
      title={popupButtonTitle}
      subTitle={popupButtonSubTitle}
      hidenCloseButton
      buttons={popupButtons.map((button: { text: string; idColor: number }) => ({
        text: button.text,
        color: button.idColor,
      }))}
    />
  );
};
