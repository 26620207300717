import { InputForm, RadioGroupForm, SelectColorCustomForm, Stack } from 'components/atoms';
import PreviewPopupButton from 'components/atoms/PreviewPopupButton';
import {
  popupActionList,
  popupButtonColors,
  popupButtonIcons,
  popupButtonsCount,
} from 'helpers/enums';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { device } from 'styles/breakpoint';
import { ChooseAction } from '../ChooseAction/ChooseAction';
import { ChooseFile } from '../ChooseFile/ChooseFile';
import { ChooseLink } from '../ChooseLink/ChooseLink';

const intitialButtonsValues = {
  text: '',
  idColor: 1,
  action: 1,
  linkType: 1,
  link: '',
  internalLink: '',
  actionLinkFile: null,
  actionLinkFileBucket: null,
  linkFileBucket: false,
};

interface IEditButtonsPopupButtonProps {
  links: any[];
  form: any;
}

export const EditButtonsPopupButton: React.FC<IEditButtonsPopupButtonProps> = ({ links, form }) => {
  const { control, getValues } = form;
  const { popupButtonIcon, popupButtonTitle, popupButtonSubTitle, popupButtons } = form.watch();
  const currentIcon = popupButtonIcons[popupButtonIcon - 1];

  const { fields, append, remove } = useFieldArray({
    name: 'popupButtons',
    control,
  });

  const onChangeQtdButtons = (qtdButtonsNew: number, qtdButtonsOld: number) => {
    const qtdButtons = qtdButtonsNew === 3 ? 0 : qtdButtonsNew;
    const qtdButtonsInitial = qtdButtonsOld === 3 ? 0 : qtdButtonsOld;
    for (let i = qtdButtonsInitial; i > qtdButtons; i--) {
      remove(i - 1);
    }
    for (let i = qtdButtonsInitial; i < qtdButtons; i++) {
      append({
        ...intitialButtonsValues,
        idColor: i === 0 ? 1 : 3,
      });
    }
  };

  return (
    <Stack direction="column">
      <Stack
        flex="1"
        justifyContent="center"
        alignItems="center"
        breakpointDownInvert={device.laptopM}
        marginBottom="10px"
      >
        <PreviewPopupButton
          icon={currentIcon?.src}
          title={popupButtonTitle}
          subTitle={popupButtonSubTitle}
          buttons={popupButtons.map((button: { text: string; idColor: number }) => {
            return {
              text: button.text,
              color: button.idColor,
            };
          })}
        />
      </Stack>

      <RadioGroupForm
        options={popupButtonsCount}
        control={control}
        name="popupButtonCount"
        label="Quantidade de botões no pop-up*"
        tooltipTitle="Obrigatório"
        tooltipLabel={
          'Escolha quantos botões seu pop-up pode ter. No próximo passo, você poderá personalizar as ações dos botões e suas cores.'
        }
        onChange={onChangeQtdButtons}
      />
      {fields.map((_, index) => {
        const actionItem = getValues(`popupButtons[${index}].action`);
        return (
          <React.Fragment key={`EditButtonsPopupButton-${index}`}>
            <Stack
              direction="row"
              justifyContent="space-between"
              key={`stack_popupButtons[${index}].text`}
              marginTop={index === 1 ? '1rem' : ''}
            >
              <InputForm
                type="text"
                name={`popupButtons[${index}].text`}
                label={`Texto do botão ${index + 1}*`}
                placeholder={`Digite o texto do botão ${index + 1} aqui...`}
                control={control}
                maxLength={32}
                onChange={() => {
                  form.setValue('actionFile', '');
                  form.setValue('nameFileBucket', '');
                }}
              />
              <Stack minWidth="140px" marginLeft="0.5rem">
                <SelectColorCustomForm
                  label={`Cor do botão ${index + 1}*`}
                  name={`popupButtons[${index}].idColor`}
                  options={popupButtonColors}
                  control={control}
                />
              </Stack>
            </Stack>
            <ChooseAction
              form={form}
              label={`Ação do botão ${index + 1}*`}
              nameFildLinkType={`popupButtons[${index}].linkType`}
              nameFieldLink={`popupButtons[${index}].link`}
              nameFieldInternalLink={`popupButtons[${index}].internalLink`}
              nameFieldActionLinkFile={`popupButtons[${index}].actionLinkFile`}
              nameFieldActionFile={`popupButtons[${index}].actionFile`}
              nameFieldAction={`popupButtons[${index}].action`}
              options={popupActionList}
            />
            {actionItem === 1 && (
              <ChooseLink
                form={form}
                links={links}
                nameFieldLinkType={`popupButtons[${index}].linkType`}
                nameFiedLink={`popupButtons[${index}].link`}
                nameFieldInternalLink={`popupButtons[${index}].internalLink`}
              />
            )}
            {actionItem === 2 && (
              <ChooseFile
                form={form}
                nameFieldActionFile={`popupButtons[${index}].actionFile`}
                nameFieldActionLinkFile={`popupButtons[${index}].actionLinkFile`}
                nameFieldNameFileBucket={`popupButtons[${index}].nameFileBucket`}
                maxSize={5000000}
                accept={['application/pdf']}
                errorMessage="O arquivo deve ser no formato PDF e de até 5MB"
              />
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};
