import { InputDragAndDropForm, Stack } from 'components/atoms';
import { IPictureDictionaryModel } from 'models';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface ISelectFileCarouselPictureDicionaryProps {
  form: ReturnType<typeof useForm<IPictureDictionaryModel>>;
}

export const SelectFileCarouselPictureDicionary: React.FC<
  ISelectFileCarouselPictureDicionaryProps
> = ({ form }) => {
  const { control, watch } = form;
  const { idTypeAward, dictinaryFile } = watch();
  const imageConfig = idTypeAward === 1 ? { width: 500, height: 500 } : { width: 260, height: 202 };

  useEffect(() => {
    form.clearErrors('dictinaryFile');
  }, [dictinaryFile?.name]);

  return (
    <Stack direction="column">
      <InputDragAndDropForm
        errorMessage="O arquivo deve ser no formato PNG e de até 1MB."
        buttonTitle="Escolher arquivo"
        titleChange="ou arraste ele para cá."
        title="Faça o upload da imagem*"
        imageConfig={imageConfig}
        maxSize={5000000}
        accept={['image/png']}
        control={control}
        name="dictinaryFile"
      />
    </Stack>
  );
};
