import Information from 'components/atoms/Information';
import Stack from 'components/atoms/Stack';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { DownChevron, Flex, IconCheck, Label, ReactSelectElement } from '../styled';
import { useTheme } from 'hooks/useTheme';
import useIconThemes from 'hooks/iconThemes';
import { StylesConfig } from 'react-select';

interface IOptionProps {
  value: number;
  label: string;
}

interface ISelectCustomFormProps {
  label?: string;
  isMulti?: boolean;
  options: IOptionProps[];
  defaultValue?: IOptionProps;
  showSelectAll?: boolean;
  placeholder?: string;
  fixedHeight?: boolean;
  control: any;
  name: string;
  onChange?: (val: any) => void;
  menuOpen?: boolean;
  notMessageError?: boolean;
}

const colourStyles: StylesConfig = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f1f1f1' : undefined,
    };
  },
};

export const SelectCustomForm: React.FC<ISelectCustomFormProps> = ({
  label,
  isMulti,
  options,
  defaultValue = { value: 0, label: 'Todos' },
  showSelectAll,
  placeholder = 'Digite para pesquisar...',
  fixedHeight,
  control,
  name,
  onChange,
  menuOpen = false,
  notMessageError = false,
}) => {
  const [closeMenuOnSelect, setCloseMenuOnSelect] = useState(!isMulti);
  const [internalIsMulti, setInternalIsMulti] = useState(isMulti);
  const [menuIsOpen, setMenuIsOpen] = useState(menuOpen);
  const internalOptions = showSelectAll ? [defaultValue, ...options] : options || [];
  const iconThemes = useIconThemes();

  const forceClose = React.useCallback(() => {
    setCloseMenuOnSelect(false);
    setMenuIsOpen(false);
  }, []);

  const _onChange = (value: IOptionProps | IOptionProps[], localOnChange: any) => {
    if (isMulti && Array.isArray(value)) {
      const hasAll = value.findIndex((item) => item.value === 0);
      if (hasAll > -1) setInternalIsMulti(false);
      if (Array.isArray(value) && value.length === options.length) {
        forceClose();
        return localOnChange([defaultValue]);
      }
    }
    localOnChange(value);
  };

  const ValidIsMulti = (value: IOptionProps | IOptionProps[]) => {
    if (isMulti && Array.isArray(value)) {
      const hasAll = value.findIndex((item) => item.value === 0);
      if (hasAll > -1) setInternalIsMulti(false);
    }
  };

  const formatOptionLabel = (prop: any, value: any) => {
    return (
      <Flex>
        {value === prop.value ||
          (isMulti && value?.find((item: IOptionProps) => item.value === prop.value) && (
            <IconCheck src={iconThemes[currentTheme.theme]?.IconDoneOutlined} alt="check" />
          ))}
        <Label>{prop.label}</Label>
      </Flex>
    );
  };

  const arrowRenderer = () => {
    return <DownChevron alt="chevron" src={iconThemes[currentTheme.theme]?.IconDownChevron} />;
  };

  const handleChange = (newValue: any, localOnChange: any) => {
    if (isMulti) {
      if (internalIsMulti) {
        const hasAll = newValue.findIndex((item: IOptionProps) => item.value === 0);
        if (hasAll > -1) {
          forceClose();
          return _onChange([defaultValue], localOnChange);
        } else {
          return _onChange(newValue, localOnChange);
        }
      } else {
        if (newValue.value === 0) {
          setCloseMenuOnSelect(false);
          forceClose();
        }
        setInternalIsMulti(isMulti);
        return _onChange([newValue], localOnChange);
      }
    }
    _onChange(newValue, localOnChange);
  };

  const { currentTheme } = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        // eslint-disable-next-line no-sequences
        ValidIsMulti(field.value),
        (
          <Stack width="100%" direction="column">
            {label && (
              <Information
                label={label}
                marginBottom="0.375rem"
                labelColor={currentTheme.colors.secondary}
                tooltipColor={error ? currentTheme.colors.error : currentTheme.colors.white}
              />
            )}
            <ReactSelectElement
              {...field}
              isMulti={internalIsMulti}
              classNamePrefix="mySelect"
              options={internalOptions}
              formatOptionLabel={(props) => formatOptionLabel(props, field.value)}
              components={{ DropdownIndicator: arrowRenderer }}
              placeholder={placeholder}
              value={field.value}
              onChange={(e) => {
                handleChange(e, field.onChange);
                if (onChange) onChange(e);
              }}
              noOptionsMessage={() => 'Sem dados'}
              closeMenuOnSelect={closeMenuOnSelect}
              menuIsOpen={menuIsOpen}
              fixedHeight={fixedHeight}
              onMenuOpen={() => {
                setMenuIsOpen(true);
                setCloseMenuOnSelect(!isMulti);
              }}
              onMenuClose={() => setMenuIsOpen(false)}
              styles={colourStyles}
              isError={!!error}
            />
            {!notMessageError && (
              <Stack height="1rem">
                <p style={{ color: currentTheme.colors.error, fontSize: '12px' }}>
                  {error?.message}
                </p>
              </Stack>
            )}
          </Stack>
        )
      )}
    />
  );
};
