import styled from 'styled-components';

export const CardImage = styled.div<{ active?: boolean; hover?: boolean; customHover?: boolean }>`
  margin-top: 28px;
  width: 100%;
  border-radius: 0.5rem;
  transition: all 0.3s linear;
  cursor: pointer;
  background-color: ${({ active, hover, customHover, theme }) =>
    active || hover
      ? customHover
        ? theme.colors.primary
        : theme.colors.secondary
      : theme.colors.silver};
  color: ${({ active, theme }) => (active ? theme.colors.backgroundWhite : '')};

  span {
    font-weight: 700;
    font-size: 1rem;
    line-height: 120%;
    margin-bottom: 0.25rem;
  }

  .blue-space {
    height: 17px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 8.4738px 8.4738px 0px 0px;
    margin-bottom: -10px;
  }

  .img {
    width: 100%;
  }
  p {
    font-size: 0.625rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.backgroundWhite};
  }
`;

export const IconNext = styled.img`
  width: 19px;
  height: 19px;
`;
