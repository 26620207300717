import styled from 'styled-components';
import { device } from 'styles/breakpoint';

import { StyledStack } from '../Stack';

export const StackModalHeader = styled(StyledStack)`
  @media (max-width: ${device.tablet}) {
    border-radius: 0px;
  }
`;

export const TexGreeting = styled.span`
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.colors.white}; 
`;

export const TextName = styled.p`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 120%;
  color: ${(props) => props.theme.colors.white}; 
`;

interface IDivideVertical {
  height?: string;
  border?: string;
}
export const DivideVertical = styled.div<IDivideVertical>`
  width: 0.5px;
  height: ${(props) => (props.height ? props.height : '28px')};
  border: ${(props) => (props.border ? props.border : '0.6px solid rgba(255, 255, 255, 0.36)')};
`;

export const TextLogout = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.01em;
  text-decoration-line: underline;
  color: ${(props) => props.theme.colors.white}; 
`;
