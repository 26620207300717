import { Stack } from 'components/atoms';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';
export const WrapperInformation = styled.div`
  margin-bottom: 1rem;
`;
export const Text = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #2e2b37;
`;

export const StackCloseOrder = styled(Stack)`
  flex-direction: column;
  overflow: auto;
  padding: 0 20px 0 0;
  height: 340px;

  @media (max-width: ${device.tablet}) {
    height: calc(100vh - 14rem);
    max-height: calc(1000px - 14rem);
  }
  @media (min-width: ${device.laptop}) {
    height: 400px;
  }
`;
