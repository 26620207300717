import { popupButtonColorsMap } from 'helpers/enums';

export interface IPopupButtonButtonsModel {
  text: string;
  idColor: popupButtonColorsMap;
  action: number;
  link?: string;
  idLinkInternal?: number;
  nameFileBucket?: string;
  linkFileBucket?: string;
  linkFile?: string;
  internalLink?: { value: number };
  actionFile?: File;
  actionLinkFile?: string;
  linkType?: number;
}

export interface IPopupButtonModel extends IPopupBaseModel {
  popupButtonIcon: number;
  popupButtonTitle: string;
  popupButtonSubTitle: string;
  popupButtons: IPopupButtonButtonsModel[];
  popupButtonCount?: number;
  popupButtonName?: string;
}

export interface IPopupImagemModel extends IPopupBaseModel {
  action: number;
  image: string;
  linkType: number;
  link: string;
  IdInternalLink?: number;
  actionFile?: File;
  actionLinkFile?: string;
  nameFileBucket?: string;
  internalLink?: { value: number };
  popupFile?: File;
}

export interface IPopupBaseModel {
  id: number;
  active: boolean;
  viewOrder: string;
  name: string;
  startDate: Date;
  endDate: Date;
  endDateUndefined: boolean;
  typeValidity?: number;
  defineDate: number;
  IdsWhichBranches?: number[];
  IdsWhichClassifications?: number[];
  IdsSegments?: number[];
  classificationPeriod: number;
  planCondition: number;
  planPoints?: number;
  customers?: number[];
  validity?: string;
  nameFileClient?: string;
  whoCanSee?: number;
  popups: { name: string; value: number }[];
  whichBranches?: { value: number; label: string }[];
  segments?: { value: number; label: string }[];
  whichClassifications?: { value: number; label: string }[];
  csvFile?: File | { name: string; size?: number };
  status?: StatusType;
}

export interface IPopupsModel extends IPopupButtonModel, IPopupImagemModel {
  type: PopupType;
  linkFileBucket?: string;
  activeInitial?: boolean;
  popupFile?: File;
  ordem_visualizacao?: string;
}

export enum PopupType {
  BUTTON = 1,
  IMAGE = 2,
}

export enum StatusType {
  INACTIVE = 'Inativo',
  ACTIVE = 'Ativo',
  SCHEDULE = 'Agendado',
}
