import styled from 'styled-components';

interface IBackProps {
  padding?: string;
}
export const Back = styled.div<IBackProps>`
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  padding: ${(props) => props.padding ?? ' 1.875rem 1.875rem 0 1.875rem'};
  width: 100%;
`;
export const IconRoundedBack = styled.img`
  margin-right: 0.375rem;
  width: 14px;
  height: 14px;
`;

export const TextBack = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.01em;
  text-decoration-line: underline;
  color: #2e2b37;
  margin-right: 0.625rem;
`;

interface IDescriptionProps {
  height?: string;
}
export const Description = styled.p<IDescriptionProps>`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #2e2b37;
  margin-left: 0.625rem;
  height: ${(props) => props.height};
  cursor: initial;
`;

interface IDivideVerticalProps {
  height?: string;
  border?: string;
}
export const DivideVertical = styled.div<IDivideVerticalProps>`
  width: 0.5px;
  height: ${(props) => (props.height ? props.height : '28px')};
  border: ${(props) => (props.border ? props.border : '0.6px solid rgba(255, 255, 255, 0.36)')};
`;

export const BtnBack = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
`;
