import './App.css';
import { Loading, Stack } from './components/atoms';
import { useLoading } from './hooks/loading';

const AppLoading = () => {
  const { showLoading } = useLoading();

  return (
    <>
      {showLoading && (
        <Stack
          width="100vw"
          height="100vh"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          zIndex="999999"
          background="rgba(0,0,0,.52)"
        >
          <Loading />
        </Stack>
      )}
    </>
  );
};

export default AppLoading;
