import { Stack } from 'components/atoms';
import { DefineDateValidityForm } from 'components/molecules/DefineDateValidityForm';
import { usePopupButton } from 'hooks/popupButton';

const labelsDefineDateValidity = {
  label1:
    'Com esta opção selecionada, seu pop-up será exibido apenas durante o período de resgate vigente na plataforma por tempo indeterminado.',
  label2: 'Você poderá desativar este pop-up acessando o Gerenciador de Pop-ups.',
};

export const PopupButtonStep4 = () => {
  const { createFormPopupButtonStepper } = usePopupButton();

  return (
    <DefineDateValidityForm form={createFormPopupButtonStepper} {...labelsDefineDateValidity} />
  );
};
