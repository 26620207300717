import AppLoading from './AppLoading';
import AppNotify from './AppNotify';

const AppContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <AppNotify />
      <AppLoading />
      {children}
    </>
  );
};
export default AppContent;
