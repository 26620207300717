import React, { createContext, useContext, useState, useEffect } from 'react';
import themeBackOffice, { ITheme } from '../styles/theme/themeBackoffice';
import themeChamas from '../styles/theme/themeChamas';
import themeTurbo from '../styles/theme/themeTurbo';

export interface ThemeContextType {
  currentTheme: any;
  selectTheme: (themeName: string) => void;
}

const ThemeContext = createContext<ThemeContextType>({
  currentTheme: {},
  selectTheme: () => {},
});

export const useTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const CustomThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<ITheme>({} as ITheme);

  const selectTheme = (themeName: string) => {
    switch (themeName) {
      case 'themeBackOffice':
        setCurrentTheme(themeBackOffice);
        break;
      case 'themeChamas':
        setCurrentTheme(themeChamas);
        break;
      case 'themeTurbo':
        setCurrentTheme(themeTurbo);
        break;
      default:
        setCurrentTheme(themeBackOffice);
    }
  };

  return (
    <ThemeContext.Provider value={{ currentTheme, selectTheme }}>{children}</ThemeContext.Provider>
  );
};
