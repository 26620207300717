import { RadioGroupForm, Stack } from 'components/atoms';
import DuoCalendar from 'components/atoms/DuoCalendar';
import { IBackgroundModel } from 'models';
import { useForm } from 'react-hook-form';

interface ISelectFileCarouselBackground {
  form: ReturnType<typeof useForm<IBackgroundModel>>;
}

export function SelectPeriodCarouselBackground(props: ISelectFileCarouselBackground) {
  const { form } = props;
  const { startDate, endDate, endDateUndefined } = form.watch();

  return (
    <>
      <DuoCalendar
        data-testid="calendar"
        minDate={new Date()}
        startDate={startDate}
        endDate={endDate}
        hideEndDate={endDateUndefined}
        label="Defina data de Início e Fim"
        tooltipTitle="Obrigatório"
        tooltipLabel="Defina a data de Início e Fim"
        onChange={(value: any) => {
          form.setValue('startDate', value.startDate, {
            shouldDirty: true,
          });
          form.setValue('endDate', value.endDate, { shouldDirty: true });
          form.trigger('startDate');
          form.trigger('endDate');
        }}
      />
      <Stack marginTop={'12px'}>
        <RadioGroupForm
          name="endDateUndefined"
          control={form.control}
          isToggle
          options={[{ value: true, label: 'Data final indefinida' }]}
        />
      </Stack>
    </>
  );
}
