import { yupResolver } from '@hookform/resolvers/yup';
import { ModalEditPopup, ModalEditPopupButton, PreviewButtonComponent } from 'components/molecules';
import { useWindowSize } from 'hooks/useWindowSize';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { StackEditPopupButton } from './styled';

const Schema = yup.object({
  popupButtonIcon: yup.string().required('Informe o icon'),
  popupButtonTitle: yup.string().required('Informe o título'),
  popupButtonSubTitle: yup.string().required('Informe o subtitle'),
});

interface IEditPopupButtonProps {
  data: any;
  handleSave: (data: any) => void;
  handleClose: () => void;
}
export const EditPopupButton: React.FC<IEditPopupButtonProps> = ({
  data,
  handleSave,
  handleClose,
}) => {
  const defaultValues = {
    popupButtonIcon: data.popupButtonIcon,
    popupButtonTitle: data.popupButtonTitle,
    popupButtonSubTitle: data.popupButtonSubTitle,
    popupButtons: [...data.popupButtons],
  };
  const { width } = useWindowSize();

  const formPopupButton = useForm({
    defaultValues,
    resolver: yupResolver(Schema),
  });

  const enableButton = () => {
    return formPopupButton.formState.isValid && formPopupButton.formState.isDirty;
  };

  const onSave = () => {
    const values = formPopupButton.getValues();
    handleSave(values);
  };

  const renderPreview = () => {
    return <PreviewButtonComponent form={formPopupButton} />;
  };
  const isLaptop = (width ?? 0) < 1280;
  const isTabled = (width ?? 0) < 768;

  return (
    <ModalEditPopup
      onClose={handleClose}
      onSave={onSave}
      enableButtonSave={enableButton}
      name={data?.name}
      height={isTabled ? '48rem' : isLaptop ? '47.563rem' : '28rem'}
      width="540px"
      Preview={renderPreview()}
    >
      <StackEditPopupButton>
        <ModalEditPopupButton hiddenName form={formPopupButton} showPreview />
      </StackEditPopupButton>
    </ModalEditPopup>
  );
};
