import { Notify } from 'components/molecules';

import './App.css';
import { useNotification } from './hooks/notification';

const AppNotify = () => {
  const notify = useNotification();

  return (
    <>
      {notify.showNotification && (
        <Notify onClose={notify.handleClearNotification} notification={notify.notification} />
      )}
    </>
  );
};
export default AppNotify;
