import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ModalEdit, TargetAudienceForm } from '../index';
import { StackContainer } from './styled';

const validationSchemaForm = yup.object({
  csvFile: yup.mixed().when(['whoCanSee'], {
    is: (whoCanSee: number) => whoCanSee === 2,
    then: yup.mixed().required('Informe o arquivo'),
  }),
  whichBranches: yup.array().min(1).required('Informe a filial'),
  whichClassifications: yup.array().min(1).required('Informe a classificação'),
  segments: yup.array().min(1).required('Informe o banner'),
  planPoints: yup.mixed().when(['planCondition'], {
    is: (planCondition: number) => planCondition,
    then: yup.string().nullable().required('Informe a pontuação'),
  }),
});
interface IModalEditAudience {
  form: any;
  onClose: () => void;
  title: string;
  list?: any;
}

export const ModalEditAudience: React.FC<IModalEditAudience> = ({ form, onClose, title, list }) => {
  const values = form.getValues();
  const defaultValues = {
    whichBranches: values.whichBranches,
    csvFile: values.csvFile,
    whoCanSee: values.whoCanSee,
    classificationPeriod: values.classificationPeriod,
    whichClassifications: values.whichClassifications,
    segments: values.segments,
    planCondition: values.planCondition,
    planPoints: values.planPoints,
  };

  const modalEditAudienceForm = useForm({
    defaultValues,
    resolver: yupResolver(validationSchemaForm),
  });

  const enableButton = () => {
    const { isValid, isDirty } = modalEditAudienceForm.formState;
    return isValid && isDirty;
  };

  const onSave = () => {
    const {
      whichBranches,
      csvFile,
      whoCanSee,
      classificationPeriod,
      whichClassifications,
      segments,
      planCondition,
      planPoints,
    } = modalEditAudienceForm.getValues();

    if (csvFile) form.setValue('cnpjFileName', csvFile.name, { shouldDirty: true });

    if (whoCanSee === 1) form.setValue('cnpjFileName', null, { shouldDirty: true });

    form.setValue('whichBranches', whichBranches, { shouldDirty: true });
    form.setValue('csvFile', csvFile, { shouldDirty: true });
    form.setValue('cnpjFile', csvFile, { shouldDirty: true });
    form.setValue('whoCanSee', whoCanSee, { shouldDirty: true });
    form.setValue('classificationPeriod', classificationPeriod, {
      shouldDirty: true,
    });
    form.setValue('whichClassifications', whichClassifications, {
      shouldDirty: true,
    });
    form.setValue('segments', segments, { shouldDirty: true });
    form.setValue('planCondition', planCondition, { shouldDirty: true });
    form.setValue('planPoints', planPoints, { shouldDirty: true });

    onClose();
  };

  return (
    <ModalEdit
      onClose={onClose}
      enableButtonSave={() => enableButton()}
      onSave={onSave}
      title={title}
      width="491px"
      height="532px"
    >
      <StackContainer>
        <TargetAudienceForm form={modalEditAudienceForm} label="banner" list={list} />
      </StackContainer>
    </ModalEdit>
  );
};
