import styled from 'styled-components';

interface IWrap {
  marginBottom?: string;
}
export const Wrap = styled.div<IWrap>`
  ${({ marginBottom }) => marginBottom && `margin-bottom:${marginBottom}`}
`;
export const Title = styled.h4`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.secondary}; 
`;

export const Link = styled.button`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: ${(props) => props.theme.colors.primary}; 
  cursor: pointer;
  margin-left: 0.375rem;
  :disabled {
    color: rgba(105, 105, 119, 0.51);
    cursor: not-allowed;
  }
`;

export const Check = styled.img`
  height: 8px;
`;

export const Item = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  color: ${(props) => props.theme.colors.secondary}; 
  word-break: break-word;
`;

interface IRow {
  gap?: string;
  marginBottom?: string;
}
export const Row = styled.div<IRow>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap ?? '0px'};
  margin-bottom: ${(props) => props.marginBottom ?? '0px'};
`;
