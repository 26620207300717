import styled from 'styled-components';

interface IContainerProps {
  isValid: boolean;
}
export const Container = styled.div`
  width: 100%;

  text-align: left;

  margin-bottom: 0.5rem;
`;

export const Text = styled.p<IContainerProps>`
  font-size: 0.75rem;

  color: ${({ isValid }) => (isValid ? 'var(--green)' : 'var(--dark)')};
  font-weight: ${({ isValid }) => (isValid ? '600' : 'normal')};
`;

export const ContainerItem = styled.span`
  display: flex;
  align-items: center;

  line-height: 1.2rem;
  margin-bottom: 0.5rem;
  img {
    margin-right: 0.25rem;
  }
`;

export const Circle = styled.div`
  border: 2px solid #2e2b37;
  width: 19px;
  height: 19px;
  background-color: transparent;
  border-radius: 1.875rem;
  margin-right: 0.375rem;
`;
