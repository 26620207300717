import { ThemeProvider } from 'styled-components';
import './App.css';
import AppContent from './AppContent';
import AppProviders from './AppProviders';
import LayoutSystem from './components/organisms/LayoutSystem';
import { GlobalStyle } from './globalStyles';
import { Routers } from './routers';
import { CustomThemeProvider, useTheme } from 'hooks/useTheme';

function System() {
  const { currentTheme } = useTheme();

  return (
    <ThemeProvider theme={currentTheme}>
      <LayoutSystem>
        <GlobalStyle />
        <AppProviders>
          <AppContent>
            <Routers />
          </AppContent>
        </AppProviders>
      </LayoutSystem>
    </ThemeProvider>
  );
}

const App = () => {
  return (
    <CustomThemeProvider>
      <System />
    </CustomThemeProvider>
  );
};

export default App;
