// eslint-disable-next-line no-unused-vars
import { useLoading } from 'hooks/loading';
import React from 'react';
import { ResendInvite } from 'requests/UserManagement';

import { ButtonRounded } from '../ButtonRounded';
import { useTheme } from 'hooks/useTheme';

// import { Container } from './styles';

interface IButtonRecoveryPasswordProps {
  idUser: string;
  onSuccess: () => void;
}
function ButtonRecoveryPassword({ idUser, onSuccess }: IButtonRecoveryPasswordProps) {
  const { handleHideLoading, handleShowLoading } = useLoading();

  // request for resend invite
  const handleClick = async () => {
    handleShowLoading();
    const response = await ResendInvite(idUser, 2);
    if (response.HttpStatus === 200) {
      onSuccess();
    }
    handleHideLoading();
  };

  const { currentTheme } = useTheme();
  
  return (
    <ButtonRounded
      background={currentTheme.colors.error}
      color={currentTheme.colors.white}
      label="Redefinir senha"
      iconSrc="/assets/icons/icon-enter-white.svg"
      click={handleClick}
    />
  );
}

export default ButtonRecoveryPassword;
