import { ChamasAnalytical } from 'components/organisms';
import { ChamasAnalyticalProvider } from 'hooks/useChamasAnalytical';

export const ChamasAnalitycalPage = () => {
  return (
    <ChamasAnalyticalProvider>
      <ChamasAnalytical />
    </ChamasAnalyticalProvider>
  );
};
