import React from 'react';

import Information from '../Information';
import Stack, { StackProps } from '../Stack';
import { Wrap, Check, Item, Link, Row, Title } from './styled';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';

interface ITopicSummary {
  title: string;
  link?: string;
  click?: () => void;
  itens: Array<string | JSX.Element>;
  marginBottom?: string;
  tooltipLabel?: string;
  disabled?: boolean;
  stackCSS?: StackProps;
}

const TopicSummary: React.FC<ITopicSummary> = ({
  title,
  link,
  click,
  itens,
  marginBottom,
  tooltipLabel,
  disabled,
  stackCSS = { direction: 'column' },
}) => {
  const iconThemes = useIconThemes();
  const { currentTheme } = useTheme();
  return (
    <Wrap marginBottom={marginBottom}>
      <Row marginBottom="0.25rem">
        <Title>{title}</Title>
        {tooltipLabel && <Information tooltipLabel={tooltipLabel} />}
        <Link disabled={disabled} onClick={click}>
          {link}
        </Link>
      </Row>
      <Stack {...stackCSS}>
        {itens.map((item, index) => {
          if (typeof item === 'object') {
            return item;
          } else {
            return (
              <Row key={index} gap="0.5rem" marginBottom="0.25rem">
                <Check src={iconThemes[currentTheme.theme]?.IconCheck} alt="check" />
                <Item>{item}</Item>
              </Row>
            );
          }
        })}
      </Stack>
    </Wrap>
  );
};

export default TopicSummary;
