// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import { ResendInvite } from 'requests/UserManagement';

import { ButtonRounded } from '../ButtonRounded';

// import { Container } from './styles';

interface IButtonResendInviteRegisterProps {
  idUser: string;
  disabled?: boolean;
}
function ButtonResendInviteRegister({ idUser, disabled }: IButtonResendInviteRegisterProps) {
  const [stateButton, setStateButton] = useState({
    text: 'Reenviar convite',
    disabled: disabled ?? false,
    background: '#FF8332',
    color: '#fff',
    iconSrc: '/assets/icons/icon-enter-white.svg',
  });

  // request for resend invite
  const handleClick = async () => {
    setStateButton({
      ...stateButton,
      text: 'Reenviando...',
      disabled: true,
      iconSrc: '',
    });

    const response = await ResendInvite(idUser, 1);

    if (response.HttpStatus === 200) {
      setStateButton({
        ...stateButton,
        text: 'Convite reenviado',
        iconSrc: '/assets/icons/icon-enter-white.svg',
        disabled: true,
      });
    }
  };

  return (
    <ButtonRounded
      background={stateButton.background}
      color={stateButton.color}
      label={stateButton.text}
      iconSrc={stateButton.iconSrc}
      disabled={stateButton.disabled}
      click={handleClick}
    />
  );
}

export default ButtonResendInviteRegister;
