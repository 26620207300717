import Toggle from 'react-toggle';

import { Active, Inative, Schedule } from './styled';
import 'react-toggle/style.css';
import { useTheme } from 'hooks/useTheme';

interface IToggleCustom {
  defaultChecked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSchedule?: boolean;
}

export const ToggleCustom: React.FC<IToggleCustom> = ({ defaultChecked, onChange, isSchedule }) => {
  const className = isSchedule ? 'custom-classname-schedule' : 'custom-classname';
  const { currentTheme } = useTheme();

  const activeComponent = isSchedule ? (
    <Schedule>
      <p
        style={{
          fontWeight: 700,
          fontSize: '10px',
          color: currentTheme.colors.white,
        }}
      >
        Agendado
      </p>
    </Schedule>
  ) : (
    <Active>
      <p
        style={{
          fontWeight: 700,
          fontSize: '12px',
          color: currentTheme.colors.white,
        }}
      >
        Ativo
      </p>
    </Active>
  );
  return (
    <Toggle
      className={className}
      checked={defaultChecked}
      onChange={onChange}
      data-testid="toggle"
      icons={{
        checked: activeComponent,
        unchecked: (
          <Inative>
            <p
              style={{
                fontWeight: 700,
                fontSize: '12px',
                color: currentTheme.colors.white,
              }}
            >
              Inativo
            </p>
          </Inative>
        ),
      }}
    />
  );
};
