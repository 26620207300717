import { api } from '../services/api';
// import crypto from 'crypto';
// import moment from 'moment';

export const PostAuthentication = async (email: string, password: string): Promise<any> => {
  let status: { error?: boolean; data?: any } = {};
  await api
    .post('/autenticacao', {
      email,
      senha: password,
    })
    .then(function (response) {
      status = { error: false, data: response };
    })
    .catch(function (error) {
      console.error('error request', error);
      status = { error: true, data: null };
    });
  return status;
};

export const PostForgotPassword = async (email: string, type: number, id?: string) => {
  let status;
  await api
    .post('senha/solicitar', {
      tipoRequisicao: type,
      id,
      email,
    })
    .then(function (response) {
      status = { error: false, data: response };
    })
    .catch(function (error) {
      console.error('error request', error);
      status = { error: true, data: null };
    });
  return status;
};

export const PostResetPassword = async (id: string, type?: number, password?: string) => {
  const data = {
    idautenticacao: id,
    tiporequisicao: type,
    senha: password,
  };

  const response = await api.post('/senha/troca', data);

  return response.data;
};

export const PostExpiredPassword = async (email: string, type: string, password: string) => {
  const data = {
    Email: email,
    TipoRequisicao: type,
    Senha: password,
  };

  const response = await api.post('/senha/expirada', data);

  return response.data;
};

export const GetValidRequest = async (id: string) => {
  const params = { id };

  const response = await api.get('/requisicao/valida', { params });
  return response.data;
};

/*
export const PostLoginFront = ({ username, password, codGrupo }) => {
  return new Promise((resolve, reject) => {
    apiPostLoginFront
      .post('', { email: username, senha: password, codGrupo })
      .then((response) => {
        let domain;
        switch (process.env.REACT_APP_ENVIRONMENT) {
          case 'development':
            domain = 'http://local.santanderfinanc.com.br:3000/login';
            break;
          case 'homologation':
            domain = 'https://dzrq0nph4n7eb.cloudfront.net/login';
            break;
          case 'production':
            domain = 'https://css.santanderfinanc.com.br/login';
            break;
          default:
            domain = 'https://css.santanderfinanc.com.br/login';
        }
        
        if (response.data.Dado.Token) {
          const alg = 'aes-256-ctr';
          const pwd = moment()
            .set({ second: 0, millisecond: 0 })
            .add('day', 1)
            .valueOf()
            .toString();
          const text = response.data.Dado.Token;
          const cipher = crypto.createCipher(alg, pwd);
          const crypted = btoa(cipher.update(text, 'utf8', 'hex'));
          window.open(`${domain}?token=${crypted}`);
          resolve(true);
        } else {
          reject(new Error('Sem permissão'));
        } 

      })
      .catch((e) => {
        reject(e);
      });
  });
};
*/
