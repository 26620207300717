import React, { useEffect, useState } from 'react';

import {
  ArrowIcon,
  ButtonSectionPages,
  Grid,
  ItemList,
  ListPagination,
  SectionPaginator,
  Wrapper,
  WrapperPagination,
} from './styled';

interface IPagination {
  totalItems: number;
  pagesAmount: number;
  quantityItems: number;
  selectPage: (page: number) => void;
  visibility?: string;
  disable?: boolean;
  isSkipPaginations: boolean;
}

const Pagination: React.FC<IPagination> = ({
  totalItems,
  pagesAmount,
  quantityItems,
  selectPage,
  visibility,
  disable = false,
  isSkipPaginations = false,
}) => {
  const totalPages = Math.ceil(totalItems / quantityItems);
  const [pagesArray, setPagesArray] = useState<any[]>([]);
  const [indexPosition, setIndexPosition] = useState(0);
  const [indexPositionClicked, setIndexPositionClicked] = useState(0);
  const [pageClicked, setPageClicked] = useState<number>(1);

  const createPagination = (array: any[], quantityPages: number) => {
    return array.reduce((accumulator, value, index) => {
      const idx = Math.floor(index / quantityPages);
      const page = accumulator[idx] || (accumulator[idx] = []);
      page.push(value);
      return accumulator;
    }, []);
  };

  const getDivisionArray = (array: any[], quantityPages: number) => {
    const arrayWithDivision = createPagination(array, quantityPages);
    return arrayWithDivision;
  };

  const createNewArray = (currentValue: any, totalValue: number, numberPages: number) => {
    let pages = [];
    const array = [];
    for (let i = currentValue; i <= totalValue; i++) {
      array.push(i);
      if (i === totalValue) {
        const arr = getDivisionArray(array, numberPages);
        const concat: any = pages.concat(arr);
        pages = concat;
        setPagesArray(pages);
      }
    }
  };

  useEffect(() => {
    setPageClicked(1);
    setIndexPosition(0);
    const numberTotalPages = Math.floor(totalPages);
    if (numberTotalPages > 1000) {
      createNewArray(1, 1000, pagesAmount);
      createNewArray(1001, numberTotalPages, 4);
    } else {
      createNewArray(1, totalPages, pagesAmount);
    }
  }, [totalPages]);

  const clickPage = (page: number) => {
    selectPage(page);
  };

  const findIndexPositionPages = (page: number) => {
    const map = pagesArray.map((item: any[]) => item.findIndex((item) => item === page));
    const indexes = map.findIndex((item) => item !== -1);
    return indexes;
  };

  useEffect(() => {
    clickPage(pageClicked);
    const index = findIndexPositionPages(pageClicked);
    if (index !== -1) setIndexPositionClicked(index);
  }, [pageClicked]);

  // find index position of page clicked

  const previousPages = () => {
    const totalIndexes = pagesArray.length;
    if (totalPages > pagesAmount && indexPosition === 0) {
      setIndexPosition(totalIndexes - 1);
    } else if (totalPages > pagesAmount && indexPosition > 0) {
      setIndexPosition(indexPosition - 1);
    }
  };

  const nextPages = () => {
    // se o total de pagínas for maior que 5 e estiver na última pagina deverá retornar para a primeira
    const totalIndexes = pagesArray.length;
    if (totalPages > pagesAmount && indexPosition === totalIndexes - 1) {
      setIndexPosition(0);
    } else if (totalPages > pagesAmount && indexPosition < totalIndexes) {
      setIndexPosition(indexPosition + 1);
    }
  };

  const advanceForFirstPage = () => {
    if (indexPosition !== indexPositionClicked) {
      setPageClicked(pagesArray[indexPosition][0]);
    }
  };

  const previousPage = () => {
    if (pagesArray[indexPosition][0] === pageClicked) {
      setIndexPosition(indexPosition - 1);
    }
    setPageClicked(pageClicked - 1);

    advanceForFirstPage();
  };

  const nextPage = () => {
    setPageClicked(pageClicked + 1);
    if (pageClicked % pagesAmount === 0) {
      setIndexPosition(indexPosition + 1);
    }

    advanceForFirstPage();
  };

  const disablePreviousPages = () => {
    if (totalPages > pagesAmount) {
      return false;
    } else {
      return true;
    }
  };

  const disableNextPages = () => {
    if (totalPages > pagesAmount) {
      return false;
    } else {
      return true;
    }
  };

  const disableReturnPage = () => {
    if (disable) return true;

    const isFirstPage = pageClicked === 1;
    return isFirstPage;
  };

  const disableAdvancedPage = () => {
    if (disable) return true;
    const isLastPage = pageClicked === totalPages;
    return isLastPage;
  };

  const hasHundredPages = () => {
    let count = 0;
    const _array = pagesArray[indexPosition] || [];
    for (const page of _array) {
      if (page.length > 3) count++;
    }
    return count > 0;
  };

  const totalPagesToShow = pagesArray[indexPosition]?.length || 0;

  return (
    <>
      {totalItems ? (
        <>
          <SectionPaginator aria-label="Page navigation example" visibility={visibility}>
            <Wrapper>
              {disablePreviousPages() || isSkipPaginations ? null : (
                <ButtonSectionPages
                  left
                  onClick={previousPages}
                  data-testid="button-previous-pages"
                />
              )}

              <WrapperPagination className="pagination" biggerSize={hasHundredPages()}>
                {disableReturnPage() ? null : (
                  <ArrowIcon
                    aria-label="Previous"
                    onClick={previousPage}
                    data-testid="button-previous"
                  >
                    {'<'}
                  </ArrowIcon>
                )}
                <ListPagination>
                  <Grid>
                    {pagesArray.length ? (
                      pagesArray[indexPosition]?.map((x: any, i: number) => (
                        <ItemList
                          className={
                            pageClicked === x || pagesArray[0].length === 1 ? 'clicked' : ''
                          }
                          key={i}
                          onClick={() => setPageClicked(x)}
                          data-testid={`button-page`}
                        >
                          {x}
                        </ItemList>
                      ))
                    ) : (
                      <ItemList style={{ margin: '0 auto' }}>1</ItemList>
                    )}
                  </Grid>
                </ListPagination>

                {disableAdvancedPage() ? null : (
                  <ArrowIcon aria-label="Next" onClick={nextPage} data-testid="button-next">
                    {'>'}
                  </ArrowIcon>
                )}
              </WrapperPagination>

              {disableNextPages() || isSkipPaginations ? null : (
                <ButtonSectionPages right onClick={nextPages} data-testid="button-next-pages" />
              )}
            </Wrapper>
            {/* <Description>
              Página <strong>{pageClicked}</strong> de{' '}
              <strong>{totalPages}</strong>
            </Description> */}
          </SectionPaginator>
        </>
      ) : null}
    </>
  );
};

export default Pagination;
