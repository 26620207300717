import { generateKeyId } from 'helpers/functions';
import Stack from '../Stack';
import { InputCheckboxLabel, InputCheckboxStyled } from './styled';

interface IInputCheckboxProps {
  label: string;
  name?: string;
  checked?: boolean;
  value: number;
  onClick?: (e: any) => void;
}

export const InputCheckbox: React.FC<IInputCheckboxProps> = ({
  label,
  name,
  value,
  checked,
  onClick,
}) => {
  const id = `input-checkos-${generateKeyId()}`;
  return (
    <Stack gap="10px">
      <InputCheckboxStyled
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        value={value}
        onChange={onClick}
      />
      <InputCheckboxLabel htmlFor={id}>{label}</InputCheckboxLabel>
    </Stack>
  );
};
