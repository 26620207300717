import { Button, Image, Stack } from 'components/atoms';
import Tooltip from 'components/atoms/Tooltip';
import { changeImageAndReturnBase64 } from 'helpers/functions';
import { useTheme } from 'hooks/useTheme';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

interface IImageOnEditProps {
  edit?: boolean;
  src: string;
  setError?: (val: boolean) => void;
  widthImageChange?: number;
  heightImageChange?: number;
  form?: ReturnType<typeof useForm<any>>;
}
export const ImageOnEdit: React.FC<IImageOnEditProps> = ({
  edit,
  src,
  setError,
  widthImageChange = 0,
  heightImageChange = 0,
  form,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [tooltipError, setTooltipError] = useState(false);

  const showTooltipError = () => {
    // inputRef.value = '';
    if (tooltipError) return;
    setTooltipError(true);
    if (setError) setError(true);

    setTimeout(() => setTooltipError(false), 2000);
  };

  const wrapChangeImageAndReturnBase64 = async (file: File) => {
    if (form && form.setValue) {
      try {
        const internalData = await changeImageAndReturnBase64(
          file,
          2000000,
          widthImageChange,
          heightImageChange
        );
        form.setValue('image', internalData?.image, {
          shouldValidate: true,
          shouldDirty: true,
        });
        form.setValue('imageBase64', internalData?.imageBase64, {
          shouldValidate: true,
          shouldDirty: true,
        });
        form.setValue('base64ForImage', internalData?.base64ForImage, {
          shouldValidate: true,
          shouldDirty: true,
        });
        if (setError) setError(false);
      } catch (error) {
        form.setValue('image', src || null, {
          shouldValidate: true,
          shouldDirty: true,
        });
        form.setValue('imageBase64', null, {
          shouldValidate: true,
          shouldDirty: true,
        });
        form.setValue('base64ForImage', null, {
          shouldValidate: true,
          shouldDirty: true,
        });
        showTooltipError();
      }
    }
  };

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    wrapChangeImageAndReturnBase64(e.target.files[0]);
  };

  const { currentTheme } = useTheme();

  return (
    <Stack>
      <Tooltip
        width={'197px'}
        content={'Arquivo no formato inválido. O arquivo deve ser no formato PNG e de até 1MB.'}
        isOpenTootip={tooltipError}
        direction="right"
      >
        <Stack
          width="94px"
          height="94px"
          backgroundColor={currentTheme.colors.silver}
          borderRadius="8px"
          border="4px solid #ededed"
          position="relative"
        >
          <Image
            src={src || '/assets/images/image-default-ImageOnEdit.png'}
            alt="Imagem dicionario"
            width="100%"
            borderRadius="8px"
            margin="auto auto"
          />

          {edit && (
            <Button
              data-testid="edit-button"
              background={currentTheme.colors.primary}
              borderRadius="8px 0px"
              padding="7px"
              width="31px"
              height="31px"
              position="absolute"
              right="0"
              bottom="0"
              borderLeft="1px solid white"
              borderTop="1px solid white"
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              <Image width="18px" height="18px" src="/assets/icons/icon-edit.svg" />
            </Button>
          )}
          <input
            data-testid="file-input"
            ref={inputRef}
            type="file"
            hidden
            accept="image/png"
            onChange={handleChangeImage}
          />
        </Stack>
      </Tooltip>
    </Stack>
  );
};
