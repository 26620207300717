import React from 'react';

import Information, { IInformation } from '../Information';
import Stack from '../Stack';
import { BackgroundIcon, Flex, IconInput, InputStyled, LinkLabel } from './styled';
import { useTheme } from 'hooks/useTheme';

interface IInput extends IInformation {
  id?: string;
  name?: string;
  label?: string;
  labelLink?: string;
  placeholder?: string;
  type?: string;
  iconSrc?: string;
  error?: string;
  isInvalid?: boolean;
  showSuccess?: boolean;
  disabled?: boolean;
  value?: string;
  onClickLink?: () => void;
  click?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  trailing?: boolean;
  leadingIcon?: () => JSX.Element;
  maxLength?: number;
  customWidth?: string;
  customMinWidth?: string;
  justLabel?: boolean;
}

export const Input: React.FC<IInput> = ({
  id,
  name,
  label,
  labelLink,
  placeholder,
  type,
  iconSrc,
  error,
  isInvalid,
  showSuccess,
  disabled,
  value,
  onClickLink,
  click,
  onChange,
  trailing,
  leadingIcon,
  maxLength,
  customWidth,
  tooltipTitle,
  tooltipLabel,
  customMinWidth,
  justLabel,
}): JSX.Element => {
  const { currentTheme } = useTheme();
  return (
    <Stack width="100%" direction="column">
      {labelLink ? (
        <LinkLabel onClick={onClickLink}>{labelLink}</LinkLabel>
      ) : (
        !justLabel && (
          <Information
            label={label}
            tooltipTitle={tooltipTitle}
            tooltipLabel={tooltipLabel}
            marginBottom="0.375rem"
            labelColor={currentTheme.colors.colorText}
            tooltipColor={isInvalid ? currentTheme.colors.error : currentTheme.colors.white}
          />
        )
      )}

      <Flex>
        {trailing && (
          <BackgroundIcon
            isInvalid={isInvalid}
            success={showSuccess && !!value?.trim()}
            disabled={disabled}
            data-testid="background-icon"
          >
            <IconInput src={iconSrc} data-testid="icon-trailing" />
          </BackgroundIcon>
        )}

        <Stack
          background="white"
          borderRadius="7px"
          alignItems="center"
          justifyContent="center"
          minWidth={customMinWidth}
          marginLeft={trailing ? '-9px' : undefined}
          width={customWidth ?? '100%'}
        >
          <InputStyled
            onClick={click}
            type={type}
            id={id}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => {
              if (onChange) onChange(e);
            }}
            value={value}
            maxLength={maxLength}
            color={isInvalid ? currentTheme.colors.error : ''}
            data-testid="input"
          />

          {leadingIcon && (
            <Stack justifyContent="center" paddingRight="0.75rem">
              {leadingIcon()}
            </Stack>
          )}
        </Stack>
      </Flex>
      {!justLabel && (
        <Stack minHeight="1rem">
          <p style={{ color: currentTheme.colors.error, fontSize: '12px' }}>{isInvalid && error}</p>
        </Stack>
      )}
    </Stack>
  );
};
