import { Stack } from 'components/atoms';
import Information from 'components/atoms/Information';
import { Icon } from 'globalStyles';
import { popupButtonIcons } from 'helpers/enums';
import { useTheme } from 'hooks/useTheme';
import { useForm } from 'react-hook-form';

interface ISelectPopupButtonIconsProps {
  form: any;
}
export const SelectPopupButtonIcons: React.FC<ISelectPopupButtonIconsProps> = ({ form }) => {
  const popupButtonIcon = form.watch('popupButtonIcon');
  const { currentTheme } = useTheme();

  return (
    <Stack direction="column" marginTop="0.75rem" marginBottom="0.75rem">
      <Stack>
        <Information label={'Ícone do pop-up*'} labelColor={currentTheme.colors.colorText} />
      </Stack>
      <Stack marginTop="0.75rem" alignItems="baseline" marginBottom="0.75rem">
        {popupButtonIcons.map((item, index) => (
          <Stack
            marginRight="0.375rem"
            key={index}
            direction="column"
            height="3.5rem"
            cursor="pointer"
            data-testid="popupButtonIcons"
            onClick={() =>
              form.setValue('popupButtonIcon', item.value, {
                shouldDirty: true,
              })
            }
          >
            <Icon src={item.src} height="40px" style={{ cursor: 'pointer' }} />
            <Stack marginTop="0.75rem" justifyContent="center">
              <input
                style={{ cursor: 'pointer', accentColor: currentTheme.colors.primary }}
                type="radio"
                value={item.value}
                // eslint-disable-next-line eqeqeq
                checked={item.value == popupButtonIcon}
                placeholder="Crie um nome..."
                readOnly
              />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
