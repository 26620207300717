import { InputFormCustomValidation, Stack } from 'components/atoms';
import { IPictureDictionaryModel } from 'models';
import { useForm } from 'react-hook-form';
import { ValidityName, screenName } from 'requests/ValidityName';

interface ISelectNameCarouselPictureDicionaryProps {
  form: ReturnType<typeof useForm<IPictureDictionaryModel>>;
}
export const SelectNameCarouselPictureDicionary: React.FC<
  ISelectNameCarouselPictureDicionaryProps
> = ({ form }) => {
  const validate = (val: string): Promise<boolean> => {
    return ValidityName({ name: val, screen: screenName.dicionario });
  };
  return (
    <Stack direction="column">
      <InputFormCustomValidation
        type="text"
        // id="name"
        name="name"
        control={form.control}
        placeholder="Crie um nome..."
        label="Nome do prêmio*"
        tooltipTitle="Obrigatório"
        tooltipLabel="Escolha um nome para a imagem. Ele será visível apenas no Dicionário de Imagens."
        form={form}
        callbackValidate={validate}
      />
    </Stack>
  );
};
