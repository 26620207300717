import { Layout } from 'components/templates/Layout';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  turboCarrosselBackgroundCreateURL,
  turboCarrosselBackgroundManagementURL,
  turboCarrosselURL,
} from 'routers/routes';

export const TurboCarrosselBackground = () => {
  const navigate = useNavigate();

  const iconThemes = useIconThemes();
  const { currentTheme } = useTheme();

  useEffect(() => {
    // define title page
    document.title = 'Background | Turbo |  Backoffice Lojas';
  }, []);

  const optionsList = [
    {
      title: 'Novo Plano de Fundo',
      subtitle: 'Faça o upload de um novo background.',
      icon: iconThemes[currentTheme.theme]?.iconPlus || '',
      iconHover: iconThemes[currentTheme.theme]?.iconPlusHover || '',
      onClick: () => navigate(turboCarrosselBackgroundCreateURL),
    },
    {
      title: 'Gerenciar Fundos',
      subtitle: 'Configure um background já publicado.',
      icon: iconThemes[currentTheme.theme]?.iconGear || '',
      iconHover: iconThemes[currentTheme.theme]?.iconGearHover || '',
      onClick: () => navigate(turboCarrosselBackgroundManagementURL),
    },
  ];

  return (
    <Layout.Container>
      <Layout.Page width={currentTheme.sizes[537]} height={currentTheme.sizes[547]}>
        <Layout.Header subMenu={subMenuTurbo} />
        <Layout.Body.Root>
          <Layout.Body.Back routerBack={turboCarrosselURL} />
          <Layout.Body.Title
            title="Plano de Fundo | Carrossel de Prêmios"
            subtitle="Personalize o plano de fundo do Carrossel de Prêmios"
            iconTitle={iconThemes[currentTheme]?.iconCarousel}
          />
          <Layout.Body.CardsOptions options={optionsList} columns={1} />
        </Layout.Body.Root>
      </Layout.Page>
    </Layout.Container>
  );
};
