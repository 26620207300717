import mixpanel from 'mixpanel-browser';

if (process.env.REACT_APP_ENVIRONMENT_MIXPANEL) {
  mixpanel.init(process.env.REACT_APP_ENVIRONMENT_MIXPANEL, { debug: false });
}
const usingMixpanel = !!process.env.REACT_APP_ENVIRONMENT_MIXPANEL;

export const useMixpanel = {
  identify: (id: string) => {
    if (usingMixpanel) mixpanel.identify(id);
  },
  track: (name: string, props: any) => {
    if (usingMixpanel)
      mixpanel.track(name, {
        ...props,
      });
  },
  time: (name: string) => {
    if (usingMixpanel) mixpanel.time_event(name);
  },
  set_group: (id: string, data: any) => {
    if (usingMixpanel) mixpanel.set_group(id, data);
  },
  people: {
    set: (props: any) => {
      if (usingMixpanel) mixpanel.people.set(props);
    },
  },
};
