import { ModalHeader } from 'components/atoms';

import { LocalHeader } from './styled';

interface IHeaderIconTitleTextProps {
  srcIcon?: string;
  title?: string;
  text?: string;
  height?: string;
  display?: string;
  background?: string;
}

export const HeaderIconTitleText = (props: IHeaderIconTitleTextProps) => (
  <ModalHeader
    display={props.display ?? 'flex'}
    height={props.height}
    background={props.background}
  >
    <LocalHeader>
      <img src={props.srcIcon} alt="imagem" />
      <h1>{props.title}</h1>
      <p>{props.text}</p>
    </LocalHeader>
  </ModalHeader>
);
