import styled from 'styled-components';
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000b0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
export const ModalContent = styled.div`
  position: relative;
  width: 650px;
  height: 550px;
  padding: 24px;
  background-color: #f1f1f1;
  border-radius: 10px;
`;
export const ContainerTable = styled.div`
  width: 100%;
  max-height: 400px;
  height: 400px;
  overflow-y: auto;
  border-radius: 0.5rem;
`;

export const NotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgb(135, 135, 135);
  font-size: 12px;
`;
