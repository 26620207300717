import { useTheme } from 'hooks/useTheme';
import Information from '../Information';
import Stack from '../Stack';

interface IRadioCustomContainerProps {
  label?: string;
  tooltipTitle?: string;
  tooltipLabel?: string;
  error?: string;
  gap?: string;
  children: React.ReactNode;
}

export const RadioCustomContainer: React.FC<IRadioCustomContainerProps> = (props) => {
  const { currentTheme } = useTheme();
  return (
    <Stack direction="column" {...props} gap="0">
      {props.label && (
        <Information
          label={props.label}
          tooltipTitle={props.tooltipTitle}
          tooltipLabel={props.tooltipLabel}
          marginBottom="0.375rem"
          labelColor={currentTheme.colors.secondary}
          tooltipColor={props.error ? currentTheme.colors.error : currentTheme.colors.white}
        />
      )}
      <Stack gap={props.gap} flexWrap="wrap" data-testid="stack-children">
        {props.children}
      </Stack>
      <Stack minHeight="1rem">
        <p style={{ color: currentTheme.colors.error, fontSize: '12px' }}>{props.error}</p>
      </Stack>
    </Stack>
  );
};
