import React from 'react';
import { Paragraph, Stack } from 'components/atoms';
import { reportsAnalyticList } from 'helpers/enums';
import { useTheme } from 'hooks/useTheme';
import { Link } from './styled';

interface INotifyExportSuccessMessage {
  type: number;
  openModalDownloadReport: () => void;
}

export const NotifyExportSuccessMessage = ({
  type,
  openModalDownloadReport,
}: INotifyExportSuccessMessage) => {
  const message = reportsAnalyticList.filter((report) => report.value === type)[0].label;
  const { currentTheme } = useTheme();
  return (
    <Stack direction="column" marginTop="6px">
      <Paragraph
        label={`A exportação do relatório ${message} está em andamento.`}
        fontSize="11px"
        fontWeight="400"
        lineHeight="12px"
        color={currentTheme.colors.white}
      />
      <Paragraph
        label={`Enviaremos um e-mail ao fim do processo de exportação do arquivo, `}
        fontSize="11px"
        fontWeight="400"
        lineHeight="12px"
        color={currentTheme.colors.white}
      />
      <Stack gap="4px">
        <Paragraph
          label={`e ele estará disponível em `}
          fontSize="11px"
          fontWeight="400"
          lineHeight="12px"
          color={currentTheme.colors.white}
        />
        <Link onClick={openModalDownloadReport}>Relatórios Exportados.</Link>
      </Stack>
    </Stack>
  );
};
