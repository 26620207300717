import { IMenuItem } from 'components/atoms/Menu';
import StatusUser from 'components/atoms/StatusUser';
import { ListDefault } from 'components/molecules';
import { Layout } from 'components/templates/Layout';
import { generateKeyId, limitLenghtString } from 'helpers/functions';
import useIconThemes from 'hooks/iconThemes';
import { CreatePopupEditProvider } from 'hooks/useEditPopup';
import { useTheme } from 'hooks/useTheme';
import { IPopupsModel } from 'models';
import { useEffect, useState } from 'react';
import { GetAllPopups } from 'requests/PopupManagement';
import { EditPopupButton } from '../EditPopupButton';
import { ButtonEdit } from 'components/atoms';

const PAGE_LIMIT = 5;
const COLUMS_HEADER = ['Nº', 'Status', 'Nome do Pop-up', 'Vigência', 'Editar'];

interface IPopupManagementProps {
  subMenu: IMenuItem[];
}

export const PopupManagement = ({ subMenu }: IPopupManagementProps) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [popups, setPopups] = useState<IPopupsModel[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPopupId, setCurrentPopupId] = useState<number | null>(null);
  const [currentDataPopup, setCurrentDataPopup] = useState<IPopupsModel | null>(null);
  const [search, setSearch] = useState('');
  const iconThemes = useIconThemes();
  const { currentTheme } = useTheme();

  const getPopupsData = async (_currentPage: number, _searchText?: string) => {
    setLoading(true);
    await GetAllPopups(_currentPage, PAGE_LIMIT, _searchText ?? '').then(({ data, total }) => {
      setTotalRecords(total);
      setPopups(data);
    });
    setLoading(false);
  };

  useEffect(() => {
    document.title = 'Gerenciador de Pop-ups | Turbo | Backoffice Lojas';
  }, []);

  useEffect(() => {
    if (popups?.length && currentPopupId) {
      const data = popups?.filter((item) => item?.id === currentPopupId)[0];
      setCurrentDataPopup(data);
    }
  }, [currentPopupId, popups]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getPopupsData(currentPage, search);
    }, 300);
    return () => clearTimeout(delayDebounce);
  }, [search, currentPage]);

  const handleClick = (id: number) => {
    setCurrentPopupId(id);
  };

  const rows = popups?.map((obj) => {
    return [
      obj?.viewOrder,
      <StatusUser type="Banner" status={obj?.status ?? ''} key={generateKeyId()} />,
      limitLenghtString(obj?.name),
      obj?.validity,
      <ButtonEdit onClick={() => handleClick(obj.id)} key={generateKeyId()} />,
    ];
  });

  const handleClose = (atualizar: boolean) => {
    setCurrentPopupId(null);
    if (atualizar) {
      getPopupsData(currentPage, search);
    }
  };

  return (
    <>
      <Layout.Container>
        <Layout.Page width="740px" height="547px">
          <Layout.Header subMenu={subMenu} />
          <Layout.Body.Root>
            <Layout.Body.Back />
            <ListDefault
              name="Pop-up"
              pageLimit={PAGE_LIMIT}
              columnsHeader={COLUMS_HEADER}
              totalRecords={totalRecords}
              search={search}
              setSearch={setSearch}
              loading={loading}
              list={rows}
              setCurrentPage={setCurrentPage}
              img={iconThemes[currentTheme.theme]?.iconPopup}
            />
          </Layout.Body.Root>
        </Layout.Page>
      </Layout.Container>
      {currentPopupId && currentDataPopup ? (
        <CreatePopupEditProvider>
          <EditPopupButton data={currentDataPopup} onClose={handleClose} />
        </CreatePopupEditProvider>
      ) : null}
    </>
  );
};
