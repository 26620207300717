import { Layout } from 'components/templates/Layout';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { turboBannersURL, turboCarrosselURL, turboPopupsURL } from 'routers/routes';

export const TurboHome = () => {
  const { currentTheme } = useTheme();
  const iconThemes = useIconThemes();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Turbo | Backoffice Lojas';
  }, []);

  const optionsList = [
    {
      title: 'Banners',
      subtitle: 'Faça upload de um novo banner ou gerencie banners publicados.',
      icon: iconThemes[currentTheme.theme]?.iconBanner || '',
      iconHover: iconThemes[currentTheme.theme]?.iconBannerHover || '',
      onClick: () => navigate(turboBannersURL),
    },
    {
      title: 'Pop-ups',
      subtitle: 'Crie um novo pop-up ou gerencie pop-ups publicados.',
      icon: iconThemes[currentTheme.theme]?.iconPopup || '',
      iconHover: iconThemes[currentTheme.theme]?.iconPopupHover || '',
      onClick: () => navigate(turboPopupsURL),
    },
    {
      title: 'Carrossel de Prêmios',
      subtitle: 'Crie e gerencie os prêmios turbo e personalize o carrossel.',
      icon: iconThemes[currentTheme.theme]?.iconCarousel || '',
      iconHover: iconThemes[currentTheme.theme]?.iconCarouselHover || '',
      onClick: () => navigate(turboCarrosselURL),
    },
    // {
    //   title: 'Usuários Backoffice',
    //   subtitle: 'Crie ou gerencie usuários que tem acesso ao Backoffice.',
    //   icon: iconThemes[currentTheme.theme]?.iconUserbo || '',
    //   iconHover: iconThemes[currentTheme.theme]?.iconUserboHover || '',
    //   onClick: () => navigate(turboUsersURL),
    // },
  ];

  return (
    <Layout.Container>
      <Layout.Page width={currentTheme.sizes[537]} height={currentTheme.sizes[547]}>
        <Layout.Header subMenu={subMenuTurbo} />
        <Layout.Body.Root>
          <Layout.Body.CardsOptions options={optionsList} columns={3} />
        </Layout.Body.Root>
      </Layout.Page>
    </Layout.Container>
  );
};
