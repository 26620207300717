import { Stack } from 'components/atoms';
import { PreviewPopupImage } from 'components/organisms';
import { useForm } from 'react-hook-form';
import { device } from 'styles/breakpoint';

import { ChooseAction } from '../ChooseAction/ChooseAction';
import { ChooseFile } from '../ChooseFile/ChooseFile';
import { ChooseLink } from '../ChooseLink/ChooseLink';
import { SelectNamePopupImage } from './SelectNamePopupImage';

interface IStepOneUploadImageProps {
  links: { value: number; label: string }[];
  disabledNameEdit?: boolean;
  form: any;
  nameFieldNameFileBucket?: string;
  showPreview?: boolean;
}

export const StepOneUploadImage: React.FC<IStepOneUploadImageProps> = ({
  links,
  disabledNameEdit,
  form,
  nameFieldNameFileBucket = 'nomeArquivoBucket',
  showPreview,
}) => {
  const action = form.watch('action');
  const popupFile = form.watch('popupFile');

  return (
    <Stack direction="column">
      {!disabledNameEdit && <SelectNamePopupImage form={form} />}
      <ChooseAction form={form} label="Ação do Pop-up*" />
      {action === 1 && <ChooseLink form={form} links={links} />}
      {action === 2 && (
        <ChooseFile
          form={form}
          maxSize={5000000}
          accept={['application/pdf']}
          nameFieldNameFileBucket={nameFieldNameFileBucket}
          errorMessage="O arquivo deve ser no formato PDF e de até 5MB"
        />
      )}
      {showPreview && (
        <Stack
          flex="1"
          justifyContent="center"
          alignItems="center"
          breakpointDownInvert={device.laptopM}
          marginTop="10px"
          data-testid="show-preview"
        >
          <PreviewPopupImage
            onChange={(image) => {
              form.setValue('popupFile', image);
              form.trigger('popupFile');
            }}
            popupFile={popupFile}
          />
        </Stack>
      )}
    </Stack>
  );
};
