import { Stack } from 'components/atoms';
import Title from 'components/atoms/Title';
import Tooltip from 'components/atoms/Tooltip';
import { Icon } from 'globalStyles';
import { fileToBase64 } from 'helpers/functions';
import { useRef, useState } from 'react';

import { ButtonEdit, TextHint } from './styled';
import { useTheme } from 'hooks/useTheme';

interface IBackgroundImage {
  background: string;
  setBackground: (background: string, name: string) => void;
  disabled?: boolean;
}

export const BackgroundImage: React.FC<IBackgroundImage> = ({
  background,
  setBackground,
  disabled,
}): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [tooltipError, setTooltipError] = useState(false);
  const [showLabel, setShowLabel] = useState(false);

  const showTooltipError = () => {
    // if (inputRef) inputRef.current. = '';
    if (tooltipError) return;
    setTooltipError(true);

    setTimeout(() => setTooltipError(false), 5000);
  };

  const validateFile = (file: File) => {
    setTooltipError(false);
    if (!file) return;

    const validTypes = ['image/png'];
    const isLessThan1MB = file.size <= 1000000;

    // validate dimensions
    if (!validTypes.includes(file.type) || !isLessThan1MB) {
      return showTooltipError();
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      window.URL.revokeObjectURL(img.src);
      if (width !== 1100 || height !== 650) {
        return showTooltipError();
      } else {
        setTooltipError(false);
        void fileToBase64(file).then((result) => {
          const base64 = `data:${file.type};base64,${result ?? ''}`;
          setBackground(base64, file.name);
        });
      }
    };
  };

  const { currentTheme } = useTheme();

  return (
    <Stack direction="column">
      <Tooltip
        width={'197px'}
        title={'Arquivo no formato inválido.'}
        content={
          'Arquivo no formato inválido. O arquivo deve ser em formato PNG, de no máximo 1MB e nas proporções 1100px X 650px.'
        }
        direction={'bottom'}
        isOpenTootip={tooltipError}
      >
        <Stack
          width="433px"
          height="259px"
          border={`4px solid ${currentTheme.colors.secondary}`}
          borderRadius="0.25rem"
          justifyContent="flex-end"
          alignItems="flex-end"
          backgroundImage={background}
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          backgroundPosition="center"
          onClick={() => {
            inputRef.current?.click();
          }}
          onKeyDown={() => inputRef.current?.click()}
          backgroundColor={currentTheme.colors.disabled}
          data-testid="background-image"
        >
          {!disabled && (
            <ButtonEdit
              position="relative"
              borderRadius="0.625rem 0 0.25rem 0"
              onMouseEnter={() => setShowLabel(true)}
              onMouseLeave={() => setShowLabel(false)}
              padding="0.5rem 0.5rem"
              borderLeft="1px solid white"
              borderTop="1px solid white"
              right="-4px"
              bottom="-4px"
              data-testid="button-edit"
            >
              <Stack gap="4px" alignItems="center" width="100%">
                {showLabel && (
                  <TextHint>
                    O arquivo deve ser em formato PNG, de no máximo 1MB e nas proporções 1100px X
                    650px.
                  </TextHint>
                )}
                <Icon src="/assets/icons/icon-edit.svg" />
              </Stack>
            </ButtonEdit>
          )}

          {!background && <Title>Escolher arquivo do computador</Title>}

          <input
            // ref={(ref) => (inputRef = ref)}
            ref={inputRef}
            type="file"
            id="upload"
            hidden
            accept="image/png"
            data-testid="input-file"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!e.target.files) return;
              validateFile(e.target.files[0]);
            }}
          />
        </Stack>
      </Tooltip>
    </Stack>
  );
};
