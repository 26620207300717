import { InputForm } from 'components/atoms';
import { Control } from 'react-hook-form';

interface ISelectTitlePopupButtonProps {
  control: Control<any>;
}
export const SelectTitlePopupButton: React.FC<ISelectTitlePopupButtonProps> = ({ control }) => {
  return (
    <InputForm
      tooltipTitle="Obrigatório"
      tooltipLabel="limite de até 90 caracteres"
      type="text"
      name="popupButtonTitle"
      placeholder="Insira o título do pop-up..."
      control={control}
      showCurrentCount
      maxLength={90}
      label="Título do pop-up*"
    />
  );
};
