import styled from 'styled-components';

interface IModal {
  display?: string;
}
export const Modal = styled.div<IModal>`
  /* The Modal (background) */
  z-index: 999991;
  display: ${(props) => (props.display ? props.display : 'none')};
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: #1f1e228c;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  /* Style the horizontal ruler */
  hr {
    border: 1px solid ${(props) => props.theme.colors.backgroundGray}; 
    margin-bottom: 1.5rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  right: -12px;
`;

export const IconClose = styled.img`
  width: 24px;
  cursor: pointer;
  position: relative;
  right: -20px;
`;

interface IModalContentBox {
  width?: string;
  height: string;
}
export const ModalContentBox = styled.div<IModalContentBox>`
  width: ${(props: IModalContentBox) => (props.width ? props.width : '537px')};
  height: ${(props: IModalContentBox) => props.height};
`;

interface ISection {
  alignItems?: string;
}
export const ModalContent = styled.section<ISection>`
  width: 100%;
  height: calc(100% - 76px);
  background: ${(props) => props.theme.colors.secondary}; 
  border-radius: 0.375rem 7px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  align-items: ${(props: ISection) => props.alignItems};
  padding: 1.875rem;
  animation: fadeIn linear 0.5s;
  -webkit-animation: fadeIn linear 0.5s;
  -moz-animation: fadeIn linear 0.5s;
  -o-animation: fadeIn linear 0.5s;
  -ms-animation: fadeIn linear 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

interface IP {
  width?: string;
  color?: string;
  marginBottom?: string;
}
export const Description = styled.p<IP>`
  width: ${(props: IP) => (props.width ? props.width : '100%')};
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 120%;
  text-align: center;
  color: ${(props: IP & { theme: { colors: { white: string } } }) => props.color ?? props.theme.colors.white};
  margin-bottom: ${(props: IP) => props.marginBottom ?? '0'};
`;

interface IP {
  width?: string;
  color?: string;
  marginBottom?: string;
}
export const Information = styled.p<IP>`
  font-size: 0.75rem;
  line-height: 120%;
  text-align: center;
  color: ${(props: IP & { theme: { colors: { white: string } } }) => props.color ?? props.theme.colors.white};
  margin-top: 1.25rem;
`;

export const WrapperButtons = styled.div`
  display: flex;
  width: 100%;
`;

interface IButton {
  background: string;
  color: string;
  borderRadius: string;
}
export const Button = styled.button<IButton>`
  background: ${(props: IButton) => props.background};
  color: ${(props: IButton) => props.color};
  border-radius: ${(props: IButton) => props.borderRadius};
  width: 50%;
  box-shadow: 0px 0px 16.9476px rgba(0, 0, 0, 0.13);
  padding: 1.625rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 120%;
`;
