import styled from 'styled-components';

export const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  background: transparent;
  cursor: default;

  img {
    cursor: pointer;
  }
`;
