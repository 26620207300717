import { Stack } from 'components/atoms';
import styled from 'styled-components';
import { device } from 'styles/breakpoint';

export const StackHeaderListDefault = styled(Stack)`
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 0.625rem;
  color: #2e2b37;
`;

export const ImageList = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 0.25rem;
  margin-top: -3px;
`;

export const NotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgb(135, 135, 135);
`;

interface IStackTableContentProps {
  showShadow?: boolean;
}
export const StackTableContent = styled(Stack) <IStackTableContentProps>`
  flex-direction: column;
  max-width: 680px;
  overflow-y: auto;
  border-radius: 0.375rem;
  box-shadow: ${({ showShadow }) => showShadow && '0px 0px 16.9476px rgba(0, 0, 0, 0.13)'};
  border-radius: 0.5rem !important;
  position: relative;
`;
