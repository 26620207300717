import { ButtonEdit, StatusDot } from 'components/atoms';
import { IMenuItem } from 'components/atoms/Menu';
import { ListDefault } from 'components/molecules';
import { Layout } from 'components/templates/Layout';
import { generateKeyId, limitLenghtString } from 'helpers/functions';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { IBackgroundModel } from 'models';
import { useEffect, useState } from 'react';
import { GetAllBackgrounds } from 'requests/BackgroundManagement';
import { EditCarouselBackground } from '../EditCarouselBackground';

const columnsHeader = ['Nº', 'Nome do Fundo', 'Status', 'Vigência', 'Editar'];
const pageLimit = 5;

export const StatusActive = {
  Ativo: { description: 'Ativo', key: 'Ativo', color: '#00D217' },
  Inativo: { description: 'Inativo', key: 'Inativo', color: '#FF8332' },
  Agendado: { description: 'Agendado', key: 'Agendado', color: '#2f80ed' },
};

interface IBackgroundManagementProps {
  subMenu: IMenuItem[];
}

export const BackgroundManagement = ({ subMenu }: IBackgroundManagementProps) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataEdit, setDataEdit] = useState<IBackgroundModel | null>(null);
  const [search, setSearch] = useState('');
  const [list, setList] = useState<Array<Array<string | number | JSX.Element | undefined>>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const iconThemes = useIconThemes();
  const { currentTheme } = useTheme();

  const formatColumns = (
    list: IBackgroundModel[]
  ): Array<Array<string | number | JSX.Element | undefined>> => {
    return list.map((item) => [
      item?.id,
      limitLenghtString(item?.name),
      <StatusDot
        description={StatusActive[item.status ?? 'Inativo'].description}
        color={StatusActive[item.status ?? 'Inativo'].color}
        key={generateKeyId()}
      />,
      item.validity,
      <ButtonEdit onClick={() => setDataEdit(item)} key={generateKeyId()} />,
    ]);
  };

  const getBackgroundData = async (
    _currentPage: number,
    _pageLimit: number,
    _searchText: string
  ): Promise<void> => {
    try {
      setLoading(true);
      setList([]);
      await GetAllBackgrounds({
        page: _currentPage,
        quantity: _pageLimit,
        name: _searchText,
      }).then(({ data, total }) => {
        setTotalRecords(total);
        setList(formatColumns(data));
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      void getBackgroundData(currentPage, pageLimit, search);
    }, 300);
    return () => clearTimeout(delayDebounce);
  }, [search, currentPage]);
  useEffect(() => {
    // define title page
    document.title = 'Gerenciador de Background | Turbo |  Backoffice Lojas';
  }, []);

  return (
    <>
      <Layout.Container>
        <Layout.Page width="740px" height="547px">
          <Layout.Header subMenu={subMenu} />
          <Layout.Body.Root>
            <Layout.Body.Back />
            <ListDefault
              name="Plano de Fundo"
              subTitleHeader="Configure um plano de fundo do Carrossel de Prêmios já publicado."
              pageLimit={pageLimit}
              columnsHeader={columnsHeader}
              totalRecords={totalRecords}
              search={search}
              setSearch={setSearch}
              loading={loading}
              list={list}
              setCurrentPage={setCurrentPage}
              img={iconThemes[currentTheme.theme]?.iconCarousel}
            />
          </Layout.Body.Root>
        </Layout.Page>
      </Layout.Container>
      {dataEdit && (
        <EditCarouselBackground
          data={dataEdit}
          getBackgroundData={() => {
            void getBackgroundData(currentPage, pageLimit, search);
            setDataEdit(null);
          }}
          setDataEdit={setDataEdit}
        />
      )}
    </>
  );
};
