import { Stack, StepCreated } from 'components/atoms';
import { IMenuItem } from 'components/atoms/Menu';
import PreviewPopupButton from 'components/atoms/PreviewPopupButton';
import { LayoutRegister } from 'components/templates';
import { popupButtonIcons } from 'helpers/enums';
import { usePopupButton } from 'hooks/popupButton';
import { useEffect } from 'react';
import { GetAllLists } from 'requests/Lists';
import { device } from 'styles/breakpoint';
import CreatePopupButton from '../CreatePopupButton';

interface IStepperBannerProps {
  subMenu?: IMenuItem[];
  routerBack: string;
}

export const PopupButtonCreate = ({ subMenu, routerBack }: IStepperBannerProps) => {
  const {
    setList,
    activeStep,
    setActiveStep,
    created,
    handleCloseConfirm,
    createFormPopupButtonStepper,
  } = usePopupButton();

  const [
    popupButtonCount,
    popupButtonIcon,
    name,
    popupButtonTitle,
    popupButtonSubTitle,
    popupButtons,
    popupButtonName,
  ] = createFormPopupButtonStepper.watch([
    'popupButtonCount',
    'popupButtonIcon',
    'name',
    'popupButtonTitle',
    'popupButtonSubTitle',
    'popupButtons',
    'popupButtonName',
  ]);

  useEffect(() => {
    document.title = 'Criar Pop-up | Turbo |  Backoffice Lojas';
  }, []);

  const handleChangeStep = (step: number) => {
    if (step === 0) {
      // saindo do fluxo, exibir modal
      handleCloseConfirm(routerBack);
      return;
    }
    // exibir step 1 apenas se tiver botoes a serem configurados
    if (step === 2 && popupButtonCount === 3) {
      //  para pular o step de configurar botao tanto para avançar quanto para voltar
      if (activeStep < step) {
        step = step + 1;
      } else {
        step = step - 1;
      }
    }
    setActiveStep(step);
  };
  const currentIcon = popupButtonIcons[popupButtonIcon - 1];

  useEffect(() => {
    GetAllLists(4).then((result) => {
      setList(result);
      // adicionar um objeto com value - que será referenciado ao novo popup criado
      const popups = result?.popups ?? [];
      createFormPopupButtonStepper.setValue('popups', [{ name, value: 0 }, ...popups]);
    });
  }, [setList]);

  return (
    <LayoutRegister.Container>
      {!created ? (
        <LayoutRegister.Card
          description="Criando um novo pop-up"
          subMenu={subMenu}
          onBack={() => handleChangeStep(activeStep - 1)}
        >
          <CreatePopupButton handleChangeStep={handleChangeStep} routerBack={routerBack} />
        </LayoutRegister.Card>
      ) : (
        <LayoutRegister.Card
          backText="Criando um novo pop-up"
          subMenu={subMenu}
          onBack={() => handleChangeStep(activeStep - 1)}
          confirmOnClick={false}
        >
          <StepCreated
            title={popupButtonName ?? ''}
            name="pop-ups"
            route={`${routerBack}/gerenciador`}
            height="100%"
          >
            <Stack flex="1" breakpointDownInvert={device.laptopM} display={'flex'}>
              <PreviewPopupButton
                icon={currentIcon?.src}
                title={popupButtonTitle}
                subTitle={popupButtonSubTitle}
                buttons={popupButtons.map((button) => {
                  return {
                    text: button.text,
                    color: button.idColor,
                  };
                })}
              />
            </Stack>
          </StepCreated>
        </LayoutRegister.Card>
      )}
      <Stack flex="1" breakpointDown={device.laptopM} display={'flex'}>
        <PreviewPopupButton
          icon={currentIcon?.src}
          title={popupButtonTitle}
          subTitle={popupButtonSubTitle}
          buttons={popupButtons.map((button) => {
            return {
              text: button.text,
              color: button.idColor,
            };
          })}
        />
      </Stack>
    </LayoutRegister.Container>
  );
};
