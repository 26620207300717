import { StyledStack } from 'components/atoms/Stack';
import styled from 'styled-components';

export const WrapTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.625rem;
  margin-top: 1.625rem;
  h1 {
    font-weight: 700;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 0.375rem;
    margin-right: 10px;
  }
`;

export const WrapToogle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  gap: 1rem;
  #name {
    width: 24.5rem;
    margin-right: 1.875rem;
  }
`;

// export const WrapSummary = styled.div`
//   display: grid;
//   grid-template-columns: 50% 50%;
//   margin-top: 1.625rem;
// `;
// export const StackModal = styled.div`
//   width: 100%;
//   height: 100%;
//   background-color: #000000b0;
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// export const StackActions = styled(StyledStack)`
//   padding-top: 0.75rem;
//   @media (max-width: 1040px) {
//     padding-top: 0.188rem;
//   }
// `;

export const TextHint = styled.p`
  font-size: 0.625rem;
  line-height: 120%;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
`;

interface IButtonEdit {
  position?: string;
  right?: string;
  bottom?: string;
  borderRadius?: string;
  padding?: string;
  borderLeft?: string;
  borderTop?: string;
}
export const ButtonEdit = styled.label<IButtonEdit>`
  position: ${({ position }) => position ?? 'absolute'};
  cursor: pointer;
  right: ${({ right }) => right ?? 0};
  bottom: ${({ bottom }) => bottom ?? 0};
  background: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ borderRadius }) => borderRadius ?? '0.625rem 0px 0.25rem'};
  padding: ${({ padding }) => padding ?? '0.75rem 0.625rem'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: ${({ borderLeft }) => borderLeft ?? '1px solid white'};
  border-top: ${({ borderTop }) => borderTop ?? '1px solid white'};
`;
