import { yupResolver } from '@hookform/resolvers/yup';
import { InputFormCustomValidation, Stack, StackModal, ToggleCustom } from 'components/atoms';
import StatusUser from 'components/atoms/StatusUser';
import TopicSummary from 'components/atoms/TopicSummary';
import TopicSummaryItemArray from 'components/atoms/TopicSummaryItemArray';
import UploadBanner from 'components/atoms/UploadBanner';
import {
  Modal,
  ModalEditActionBanner,
  ModalEditChaseOrder,
  ModalEditDate,
} from 'components/molecules';
import { ModalEditAudience } from 'components/molecules/ModalEditAudience';
import { LayoutModalContent } from 'components/templates';
import { diffDaysLabel, limitLenghtString } from 'helpers/functions';
import { useLoading } from 'hooks/loading';
import { useNotification } from 'hooks/notification';
import { useConfirm } from 'hooks/useConfirm';
import { isEmpty } from 'lodash';
import { IBannerModel, IListsModel } from 'models';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PostEditBanner } from 'requests/BannerManagement';
import { GetAllLists } from 'requests/Lists';
import { ValidityName, screenName } from 'requests/ValidityName';
import * as yup from 'yup';

import { WrapSummary, WrapTitle, WrapToogle } from './styled';
import { ModuloEnum, bannerLocalList } from 'helpers/enums';
import { getImage } from 'requests/Image';

const validationSchemaForm = yup.object({
  name: yup.string().required('Informe o nome do banner'),
});

interface IEditBannerProps {
  data: IBannerModel;
  handleCLoseModal: () => void;
  getBannersData: () => Promise<void>;
}
export const EditBanner: React.FC<IEditBannerProps> = ({
  data,
  handleCLoseModal,
  getBannersData,
}) => {
  const [lists, setLists] = useState<IListsModel>();
  const [showEditAcaoDoBanner, setShowEditAcaoDoBanner] = useState(false);
  const [showEditOrdemDeAparicao, setShowEditOrdemDeAparicao] = useState(false);
  const [showEditAudience, setShowEditAudience] = useState(false);
  const [showVigenciaDoBanner, setShowVigenciaDoBanner] = useState(false);
  const [bannerList, setBannerList] = useState<any[]>([]);
  const { confirm } = useConfirm();

  const notify = useNotification();
  const { handleHideLoading, handleShowLoading } = useLoading();

  const editBannerForm = useForm({
    resolver: yupResolver(validationSchemaForm),
  });

  const mapFilter = (_list: { value: number; label: string }[], _values?: number[]) => {
    if (!_list) return [];

    return (
      _values?.map(
        (itemValue) =>
          _list?.find((itemList) => itemList.value.toString() === itemValue.toString()) ??
          ({} as { value: number; label: string })
      ) ?? []
    );
  };

  const getInitialData = async (lists: IListsModel) => {
    try {
      if (!data) return;

      let _segments = [];

      if (data.idsSegments.length === 0) {
        _segments = [{ value: 0, label: 'Todos' }];
      } else {
        _segments = mapFilter(lists?.segments ?? [], data.idsSegments);
      }

      let _branchs = [];
      if (data.idsWhichBranches.length === 0) {
        _branchs = [{ value: 0, label: 'Todas' }];
      } else {
        _branchs = mapFilter(lists?.branchs ?? [], data.idsWhichBranches);
      }

      let _classifications = [];
      if (data.idsWhichClassifications.length === 0) {
        _classifications = [{ value: 0, label: 'Todas' }];
      } else {
        _classifications = mapFilter(lists?.categories ?? [], data.idsWhichClassifications);
      }
      setBannerList(lists?.banners ?? []);

      const { image } = await getImage({ moduloId: ModuloEnum.BANNER, id: data.id });

      const defaultValues = {
        id: data.id,
        name: data.name,
        active: data.active,
        activeInitial: data.active,
        action: data.action,
        banners: lists?.banners,
        linkType: data.linkType,
        link: data.link,
        internalLink: lists?.links.find((item) => item.value === data.idLinkInterno) ?? null,
        actionFile: null,
        actionLinkFile: data.actionLinkFile,
        file: image,
        fileImageBanner: null,
        endDateUndefined: data.endDateUndefined,
        defineDate: data.defineDate,
        startDate: data.startDate,
        endDate: data.endDate,
        activeStep: 1,
        whoCanSee: data.nameFileClient ? 2 : 1,
        csvFile: data.nameFileClient ? { name: data.nameFileClient } : null,
        cnpjs: data.customers,
        whichBranches: _branchs,
        classificationPeriod: data.classificationPeriod,
        whichClassifications: _classifications,
        segments: _segments,
        planCondition: data.planCondition,
        planPoints: data.planPoints,
        cnpjFile: data.nameFileClient ? { name: data.nameFileClient } : null,
        cnpjFileName: data.nameFileClient,
        nomeArquivoBucket: data.nameFileBucket,
        linkArquivoBucket: data.linkFile,
        status: data.status,
        localBanner: data.localBanner,
      };

      editBannerForm.reset(defaultValues);
    } catch (error) {
      console.error(error);
    }
  };

  const enableButton = () => {
    const { isDirty } = editBannerForm.formState;
    return isDirty && isEmpty(editBannerForm.formState.errors);
  };

  const getList = async () => {
    try {
      handleShowLoading();
      const result = await GetAllLists(ModuloEnum.BANNER);
      await getInitialData(result);
      setLists(result);
    } catch (_) {
    } finally {
      handleHideLoading();
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const {
    id,
    name,
    active,
    file,
    action,
    banners,
    startDate,
    endDate,
    endDateUndefined,
    defineDate,
    whichBranches,
    whichClassifications,
    classificationPeriod,
    segments,
    planCondition,
    planPoints,
    whoCanSee,
    cnpjFile,
    cnpjFileName,
    linkType,
    linkArquivoBucket,
    link,
    internalLink,
    actionFile,
    nomeArquivoBucket,
    actionLinkFile,
    fileImageBanner,
    activeInitial,
    cnpjs,
    status,
    localBanner,
  } = editBannerForm.watch();

  const localBannerSelected = useMemo(() => {
    return bannerLocalList.find((item) => item.value === localBanner);
  }, [localBanner, bannerLocalList]);

  const actionSelected =
    action === 3 ? 'Nenhuma ação' : action === 1 ? 'Acessar um link ou página' : 'Abrir um arquivo';

  const sendPayload = async () => {
    if (
      !endDateUndefined &&
      endDate &&
      moment(new Date()).format('YYYY-MM-DD') > moment(endDate).format('YYYY-MM-DD')
    ) {
      notify.error(
        'Período de vigência vencido!',
        'Por favor, selecione um novo período de vigência para o banner.',
        3000
      );
      return;
    }

    handleShowLoading();
    const data: IBannerModel = {
      id,
      name,
      active,
      action,
      linkType,
      link,
      startDate,
      endDate,
      endDateUndefined,
      defineDate,
      planCondition,
      planPoints,
      whoCanSee,
      nameFileBucket: nomeArquivoBucket,
      linkFile: linkArquivoBucket,
      actionLinkFile,
      classificationPeriod,
      idLinkInterno: internalLink?.value,
      file: '',
      nameFileClient: '',
      customers: cnpjs,
      idsWhichBranches: [],
      idsWhichClassifications: [],
      idsSegments: [],
      viewOrder: '0',
      validity: '',
      localBanner,
    };

    await PostEditBanner({
      data,
      fileImageBanner,
      filiais: whichBranches,
      classification: whichClassifications,
      segmentos: segments,
      cnpjFile: whoCanSee === 1 ? null : cnpjFile,
      internalLink,
      cnpjFileName: whoCanSee === 1 ? null : cnpjFile?.name || cnpjFileName,
      actionFile,
      dataAllBanner: banners,
    })
      .then(() => {
        notify.success('Banner atualizado com sucesso.', `O ${name} foi alterado.`);
        getBannersData().finally(() => handleCLoseModal());
      })
      .catch((error) => {
        notify.error('Erro ao atualizar banner.', error.message);
      });
    handleHideLoading();
  };

  const cancelEditModal = () => {
    if (enableButton()) {
      confirm().then(() => {
        handleCLoseModal();
      });
    } else {
      handleCLoseModal();
    }
  };

  const randomId = useMemo(() => (Math.random() * Math.random()).toString(), []);

  const viewOrderNumber = (): string => {
    const banner: number = banners?.findIndex((e: { value: number }) => e.value === id);
    return active ? String(banner + 1) : '-';
  };

  const isSchedule = () => {
    return status === 'Agendado';
  };

  const getStartDate = (): Date => {
    return startDate ?? new Date();
  };

  const inactiveBanner = () => {
    const options = {
      width: '33.563rem',
      height: '19.25rem',
      question: 'Inativar o Banner',
      information: `Você está Inativando um Banner que está Agendado para inicio em ${getStartDate().toLocaleDateString()}. Deseja continuar?`,
      labelButtonNegative: 'Não',
      labelButtonConfirm: 'Sim',
    };
    void confirm(options).then(() => {
      sendPayload();
    });
  };

  const validate = (val: string): Promise<boolean> => {
    return ValidityName({ name: val, screen: screenName.banner });
  };

  return data && lists ? (
    <StackModal>
      <Modal close={() => cancelEditModal()} position="absolute" zIndex="3" fullScreenTabled>
        <Stack alignItems="center" direction="row-reverse" height="100%">
          <LayoutModalContent
            title="Editando um banner"
            handleCancel={cancelEditModal}
            showHeaderFullScreen
            footer={{
              tip: !active ? 'Para editar este banner, você deve reativá-lo primeiro.' : '',
              onSave: () => {
                if (status === 'Agendado' && !active) {
                  inactiveBanner();
                } else {
                  sendPayload();
                }
              },
              enableButtonSave: () => enableButton(),
              onClose: () => cancelEditModal(),
            }}
          >
            <WrapTitle>
              <h1>Editando: {limitLenghtString(name)}</h1>
              <StatusUser
                type="Banner"
                status={activeInitial ? (isSchedule() ? 'Agendado' : activeInitial) : activeInitial}
              />
            </WrapTitle>
            <UploadBanner
              name="fileImageBanner"
              control={editBannerForm.control}
              imageLink={file}
              edit
              // onChange={(file: file) => editBannerForm.setValue('fileImageBanner', file)}
              disabled={!active}
            />
            <WrapToogle>
              <InputFormCustomValidation
                disabled={!active}
                type="text"
                label="Nome do banner*"
                placeholder="Crie um título..."
                tooltipLabel="Escolha um nome para seu banner. Ele será visível apenas no Gerenciador de banner."
                name="name"
                control={editBannerForm.control}
                form={editBannerForm}
                callbackValidate={validate}
              />

              <ToggleCustom
                defaultChecked={active}
                isSchedule={isSchedule()}
                onChange={(e) => {
                  editBannerForm.setValue('active', e.target.checked, {
                    shouldDirty: true,
                  });

                  if (e.target.checked) {
                    if (status === 'Agendado' || status === 'Ativo') {
                      editBannerForm.setValue('banners', bannerList, { shouldDirty: true });
                    } else {
                      const newListBanner = [...banners, { value: id, name }];
                      editBannerForm.setValue('banners', newListBanner, { shouldDirty: true });
                    }
                  } else {
                    if (status === 'Inativo') {
                      editBannerForm.setValue('banners', bannerList, { shouldDirty: true });
                    } else {
                      const newListBanner = banners.filter(
                        (item: { value: number }) => item.value !== id
                      );
                      editBannerForm.setValue('banners', newListBanner, { shouldDirty: true });
                    }
                  }
                }}
              />
            </WrapToogle>

            <WrapSummary>
              <div>
                <TopicSummary
                  title="Opções do banner*"
                  link="Editar >"
                  click={() => setShowEditAcaoDoBanner(true)}
                  itens={[`Ação: ${actionSelected}`, `Local: ${localBannerSelected?.label}`]}
                  marginBottom="1.125rem"
                  disabled={!active}
                />

                <TopicSummary
                  itens={[viewOrderNumber()]}
                  title="Ordem de aparição*"
                  link="Editar >"
                  click={() => setShowEditOrdemDeAparicao(true)}
                  marginBottom="1.125rem"
                  disabled={!active}
                />

                <TopicSummary
                  itens={
                    defineDate === 2
                      ? ['Exibir apenas durante período de resgate']
                      : [
                          `Data início: ${startDate.toLocaleDateString('pt-BR', {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit',
                          })} ${diffDaysLabel(startDate)}`,
                          `Data fim: ${
                            endDateUndefined
                              ? 'Indefinida'
                              : endDate.toLocaleDateString('pt-BR', {
                                  year: '2-digit',
                                  month: '2-digit',
                                  day: '2-digit',
                                })
                          }`,
                        ]
                  }
                  title="Vigência do banner*"
                  link="Editar >"
                  click={() => setShowVigenciaDoBanner(true)}
                  disabled={!active}
                />
              </div>
              <div>
                <TopicSummary
                  disabled={!active}
                  itens={[
                    whichBranches &&
                    whichBranches.filter((item: { value: number }) => item.value !== 0).length >=
                      1 ? (
                      <TopicSummaryItemArray
                        title="Filiais:"
                        key={randomId}
                        arrayItems={whichBranches?.map((item: { label: string }) => item.label)}
                        columns={2}
                        customClickMaisItens={() => setShowEditAudience(true)}
                      />
                    ) : (
                      'Filiais: Todas'
                    ),
                    `Classificação: ${
                      whichClassifications && whichClassifications.length
                        ? whichClassifications.map((item: { label: string }) => item.label)
                        : 'Todas'
                    } - ${
                      classificationPeriod === 1
                        ? 'Classificação do período atual'
                        : 'Classificação do período passado'
                    }`,
                    `Segmentos: ${
                      segments && segments.length
                        ? segments.map((item: { label: string }) => item.label)
                        : 'Todos'
                    } `,

                    `Pontuação Platinum: ${
                      planPoints
                        ? planCondition === 1
                          ? `Maior que ${planPoints}`
                          : `Igual a ${planPoints}`
                        : ''
                    }`,
                    `CNPJs: ${whoCanSee === 1 ? 'Todos' : cnpjFile?.name || cnpjFileName}`,
                  ]}
                  title="Público alvo*"
                  link="Editar >"
                  click={() => setShowEditAudience(true)}
                />
              </div>
            </WrapSummary>
            {showEditAcaoDoBanner && (
              <ModalEditActionBanner
                form={editBannerForm}
                title={`Editando "${name}"`}
                links={lists.links}
                onClose={() => setShowEditAcaoDoBanner(false)}
              />
            )}

            {showEditOrdemDeAparicao && (
              <ModalEditChaseOrder
                form={editBannerForm}
                title={`Editando "${name}"`}
                onClose={() => setShowEditOrdemDeAparicao(false)}
              />
            )}

            {showVigenciaDoBanner && (
              <ModalEditDate
                form={editBannerForm}
                title={`Editando "${name}"`}
                onClose={() => setShowVigenciaDoBanner(false)}
              />
            )}

            {showEditAudience && (
              <ModalEditAudience
                form={editBannerForm}
                title={`Editando "${name}"`}
                onClose={() => setShowEditAudience(false)}
                list={lists}
              />
            )}
          </LayoutModalContent>
        </Stack>
      </Modal>
    </StackModal>
  ) : null;
};
