import { Stack } from 'components/atoms';
import MenuCard from 'components/atoms/MenuCard';

interface IOptionsProps {
  title: string;
  subtitle: string;
  icon: string;
  iconHover: string;
  onClick: () => void;
  value?: string;
}

interface ILayoutCardOptions {
  options: IOptionsProps[];
  columns?: number;
}

export const LayoutCardOptions = ({ options, columns = 3 }: ILayoutCardOptions) => {
  return (
    <Stack display="grid" gridTemplateColumns={`repeat(${columns}, 1fr)`} gap="18px">
      {options.map((item, index) => (
        <MenuCard
          data-testid={'menu-default-' + index}
          key={index}
          title={item.title}
          subtitle={item.subtitle}
          icon={item.icon}
          iconHover={item.iconHover}
          onClick={item.onClick}
          mode={columns === 1 ? 'landscape' : 'portrait'}
        />
      ))}
    </Stack>
  );
};
