import styled from 'styled-components';

interface IInputTextAreaStyledProps {
  isInvalid?: boolean;
}
export const InputTextAreaStyled = styled.textarea<IInputTextAreaStyledProps>`
  background: none;
  border: none;
  border-radius: none;
  width: 100%;
  padding: 0 10px;
  position: relative;
  min-height: 30px;
  font-size: 13.5px;
  resize: none;
  color: ${({ isInvalid, theme }) => (isInvalid ? theme.colors.error : '')};

  &:focus {
    outline: none !important;
    border-color: none !important;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.secondary};
    opacity: 0.35;
  }

  /*when disabled change color text with opacity 0.35 in text*/
  &:disabled {
    color: rgba(46, 43, 52, 0.35);
    cursor: not-allowed;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;
