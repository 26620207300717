import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { IBackgroundModel } from 'models/entities/background.model';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PostBackground } from 'requests/BackgroundManagement';
import * as yup from 'yup';

import { useLoading } from './loading';
import { useNotification } from './notification';
import { useConfirm } from './useConfirm';

interface ICarouselBackgroundContext {
  created: boolean;
  setCreated: (val: boolean) => void;
  createBackgroundForm: ReturnType<typeof useForm<IBackgroundModel>>;
  loading: boolean;
  isValid: () => boolean;
  handleCreateCarouselBackground: () => Promise<void>;
  setLoading: (val: boolean) => void;
  handleClose: (url: string) => void;
}

const validationSchema = yup.object({
  name: yup.string().required('Informe o nome'),
  backgroundFile: yup.mixed().required('Informe o arquivo'),
  startDate: yup.date().required('Informe a data inicial'),
  endDate: yup.date().required('Informe a data final'),
});

const CarouselBackgroundContext = createContext({} as ICarouselBackgroundContext);

export const CarouselBackgroundProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const notify = useNotification();
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const createBackgroundForm = useForm<IBackgroundModel>({
    defaultValues: { defineDate: 1 },
    resolver: yupResolver(validationSchema),
    // mode: 'onTouched',
  });

  const [created, setCreated] = useState(false);
  const [loading, setLoading] = useState(false);

  const { handleHideLoading, handleShowLoading } = useLoading();

  const handleCreateCarouselBackground = async () => {
    handleShowLoading();
    setLoading(true);
    setCreated(false);

    await PostBackground(createBackgroundForm.getValues())
      .then((_) => {
        setCreated(true);
      })
      .catch((_) => {
        setCreated(false);
        notify.error('Houve um problema', 'Sua solicitação não pode ser concluída.');
      })
      .finally(() => {
        setLoading(false);
        handleHideLoading();
      });
  };

  const isValid = (): boolean => {
    return createBackgroundForm.formState.isValid && isEmpty(createBackgroundForm.formState.errors);
  };

  const handleClose = (url: string) => {
    void confirm()
      .then(() => {
        navigate(url);
      })
      .catch(() => {});
  };

  return (
    <CarouselBackgroundContext.Provider
      value={{
        handleClose,
        created,
        setCreated,
        loading,
        isValid,
        handleCreateCarouselBackground,
        setLoading,
        createBackgroundForm,
      }}
    >
      {children}
    </CarouselBackgroundContext.Provider>
  );
};

export const useCarouselBackground = () => {
  const context = useContext(CarouselBackgroundContext);

  return context;
};
