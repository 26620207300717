import { B, IButtonStyledProps } from './styled';
import React from 'react';

interface IButtonProps extends IButtonStyledProps, React.HTMLAttributes<HTMLButtonElement> {
  text?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children: React.ReactNode;
}
export const Button: React.FC<IButtonProps> = ({
  text,
  onMouseEnter,
  onMouseLeave,
  children,
  ...prop
}) => (
  <B onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} {...prop}>
    {text ?? children}
  </B>
);
