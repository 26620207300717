import { CustomErrorApi } from 'helpers/customErrorApi';
import { fileToBase64 } from 'helpers/functions';
import { IAPIResponse } from 'models/dtos/api-response.model';
import {
  IPictureDicionaryPostResponseModel,
  IPictureDicionaryResponseModel,
} from 'models/dtos/picture-dicionary-list.model';
import { IPictureDicionaryExcelResponseModel } from 'models/dtos/picture-dicitionary-excel-response.model';
import { IPictureDicionaryExcelModel } from 'models/entities/picture-dicitionary-excel.model';
import { IPictureDictionaryModel } from 'models/entities/picture-dictionary.model';

import { api } from '../services/api';

export const GetAllPictureDictionary = async (filter: {
  page: number;
  quantity: number;
  name: string;
  idTypeAward: number | null;
}): Promise<{
  data: IPictureDictionaryModel[];
  total: number;
}> => {
  try {
    const params = {
      pagina: filter.page,
      quantidade: filter.quantity,
      nome: filter.name,
      ...(filter.idTypeAward !== null && { idtipoimagem: filter.idTypeAward }),
    };
    const response = await api.get<
      IAPIResponse<{
        quantidade_total: number;
        dicionarios: IPictureDicionaryResponseModel[];
      }>
    >('/carrossel/dicionario/listar', {
      params,
    });
    const { dicionarios, quantidade_total } = response.data?.Dado;
    const data: IPictureDictionaryModel[] = dicionarios.map((item) => ({
      idPictureDicionary: item.id_premio_imagem,
      name: item.nome,
      active: item.ativo,
      idTypeAward: item.id_tipo_imagem,
      dictinaryURL: item.imagem,
      typeAward: item.tipo_imagem,
    }));
    const notifications = response.data.Notificacoes;
    if (response.data.HttpStatus !== 200) {
      if (notifications && notifications?.length > 0) {
        throw new Error(notifications[0].Mensagem);
      } else {
        throw new Error('Tente novamente mais tarde.');
      }
    }
    return {
      data,
      total: quantidade_total,
    };
  } catch (_) {
    throw new Error('Tente novamente mais tarde.');
  }
};

export const GetExportPoctureDictionary = async (): Promise<IPictureDicionaryExcelModel> => {
  const response = await api.get<IAPIResponse<IPictureDicionaryExcelResponseModel>>(
    '/carrossel/dicionario/excel'
  );
  return {
    base64: response.data?.Dado.ExcelStringBase64,
  };
};

export const EditPictureDictionary = async (payload: {
  data: IPictureDictionaryModel;
  imageChange: boolean;
  base64ForImage?: string;
  imageName: string | null;
}): Promise<boolean> => {
  try {
    const { data, imageChange, base64ForImage, imageName } = payload;
    const internalData = {
      Id: data.idPictureDicionary,
      Nome: data.name,
      IdTipoImagem: data.idTypeAward,
      ImagemArquivoBase64: base64ForImage,
      NomeImagem: imageName,
      ImagemAlterada: imageChange,
      Ativo: data.active,
    };
    const response = await api.post<IAPIResponse<void>>(
      'carrossel/dicionario/editar',
      internalData
    );
    const notifications = response.data.Notificacoes;
    if (response.data.HttpStatus !== 200) {
      if (notifications && notifications?.length > 0) {
        throw new CustomErrorApi(notifications[0].Mensagem);
      } else {
        throw new Error('Verifique sua conexão e tente novamente.');
      }
    }
    return true;
  } catch (error) {
    if (error instanceof CustomErrorApi) {
      throw new Error(error.message);
    } else throw new Error('Verifique sua conexão e tente novamente.');
  }
};

export const PostPictureDictioary = async (
  payload: IPictureDictionaryModel
): Promise<IPictureDictionaryModel> => {
  try {
    const internalData = {
      Nome: payload.name,
      NomeImagem: payload.dictinaryFile?.name,
      ImagemArquivoBase64: await fileToBase64(payload.dictinaryFile),
      IdTipoImagem: payload.idTypeAward,
    };

    const response = await api.post<IAPIResponse<IPictureDicionaryPostResponseModel>>(
      'carrossel/dicionario/incluir',
      internalData
    );

    const notifications = response.data.Notificacoes;
    if (response.data.HttpStatus !== 200) {
      if (notifications && notifications?.length > 0) {
        throw new CustomErrorApi(notifications[0].Mensagem);
      } else {
        throw new Error('Não foi possível criar a imagem.');
      }
    }
    const data: IPictureDictionaryModel = {
      idPictureDicionary: response.data.Dado.id_dicionario,
      dictinaryURL: response.data.Dado.hash_imagem,
      name: response.data.Dado.nome,
      active: true,
      idTypeAward: payload.idTypeAward,
    };
    return data;
  } catch (error) {
    if (error instanceof CustomErrorApi) {
      throw new Error(error.message);
    } else throw new Error('Não foi possível criar a imagem.');
  }
};
