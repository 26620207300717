import styled from 'styled-components';

interface IWrapProps {
  display?: string;
}
export const Wrap = styled.div<IWrapProps>`
  width: 100%;
  height: 100%;
  background-color: #000000b0;
  position: absolute;
  display: ${({ display }) => display};
`;
